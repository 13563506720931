<template>
    <ConfirmDialog :style="{width: '500px'}" /> 
    <Dialog :style="{ width: '50rem' }" @hide="cerrarEmail" header="Envio factura" modal
        v-model:visible="dialogEmail">
        <div style="display: flex; justify-content: space-between; gap: 20px;">
            <select class="form-control" v-model="correoEnvio" id="">
                <option value="">Seleccione email</option>
                <option v-for="correo in Object.entries(correos.emails_envio)" :key="correo[0]" :value="correo[1]">{{ correo[0] }}</option>
            </select>
            <input type="text" v-model="correoEnvioText" class="form-control"/>
            <button class="btn btn-sm btn-light" style="border:1px solid grey" @click="enviarCorreo">Enviar</button>
        </div>
        <div style="margin-top: 20px;">
            <h5>Envios realizados</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            Fecha
                        </th>
                        <th>
                            Destino
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="envio in correos.envios_realizados" :key="envio.id_envio">
                        <td>
                            {{ envio.fecha_envio }}
                        </td>
                        <td>
                            {{ envio.destino_envio }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Dialog>
    <Dialog @hide="cerrarCobro" header="Abonar factura" modal
        v-model:visible="dialogCobro">
        <table class="table">
            <thead>
                <tr>
                    <th>
                        Factura a abonar
                    </th>
                    <th>
                        Importe total
                    </th>
                    <th>
                        Ya abonado
                    </th>
                    <th>
                        Numero factura
                    </th>
                    <th>
                        Importe a abonar
                    </th>
                    <th>
                        IVA
                    </th>
                    <th>
                        Concepto
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {{ facturaActual.factura }}
                    </td>
                    <td>
                        {{ facturaActual.importe_base }}
                    </td>
                    <td>
                        {{ facturaActual.importe_abonado }}
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" v-model="numeroFacturaAbonar">
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="number" v-model="importeFacturaAbonar">
                    </td>
                    <td>
                        <select class="form-control form-control-sm" v-model="ivaAbono" id="">
                            <option v-for="impuesto in impuestos" :key="impuesto.id" :value="impuesto.valor_iva">{{ impuesto.valor_iva }}</option>
                        </select>
                    </td>
                    <td>
                        <input class="form-control form-control-sm" type="text" v-model="conceptoFacturaAbonar">
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="display: flex; justify-content: end; gap: 15px;">
            <button @click="abonarFactura" class="btn btn-sm btn-primary">Abonar</button>
            <button @click="cerrarCobro" class="btn btn-sm btn-light">Cancelar</button>
        </div>
    </Dialog>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div class="col-sm-6">
                <h3>Informes facturas</h3>
            </div>            
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <div v-if="spinner==true" class="spinner"></div>
    <section :class="content">
        <form id="Factura" :action="'https://' + direccionglobal + urlabrir" method="post" target="TheWindow">
            <div style="display: grid; gap: 30px; grid-template-columns: 1fr 1fr 1fr 1fr;" >    
                <select v-show="nomostrar" multiple name="lineas_prefijo[]">
                    <option selected v-for="opt in lineaseleccionada" :key="opt">{{ opt }}</option>
                </select>
                <input type="text" v-show="nomostrar" name="companias_listado" v-model="companiaseleccionada">
                <input type="text" v-show="nomostrar" v-model="fechaCambiadaInicio" name="inicio">
                <input type="text" v-show="nomostrar" v-model="fechaCambiadaFin" name="fin">
                <div class="form-group">
                    <label for="">Empresa</label>
                    <select v-model="empresaseleccionada" id="empresa" name="empresas_listado" class="form-control">
                        <option value="">Todas</option>
                        <option v-for="empresa in empresas" :key="empresa.id_empresa" :value="empresa.id_empresa">{{ empresa.nombre_empresa }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Banco</label>
                    <select v-model="bancoseleccionado" id="empresa" name="bancos_listado" class="form-control">
                        <option value="">Todos</option>
                        <option v-for="banco in bancos" :key="banco.id_banco" :value="banco.id_banco">{{ banco.nombre_banco }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Compañía</label>
                    <Dropdown scrollHeight="400px" style="width: 100%;" showClear v-model="companiaseleccionada" class="multiselect-small" optionValue="idcompañia"
                        :options="companias" filter optionLabel="nombre" placeholder="Todas">
                    </Dropdown>
                </div>
                <div class="form-group">
                    <label for="">Prefijo/Serie</label>
                    <MultiSelect v-model="lineaseleccionada" style="width: 100%;" :options="lineas" optionLabel="nombre_serie" optionValue="id_serie"
                    placeholder="Seleccione serie" :maxSelectedLabels="2" class="multiselect-small"/>
                </div>
            </div>
            <div style="display: flex; gap: 40px;">
                <div class="form-group">
                    <label for="">Autorizadas</label>
                    <select name="autorizado" v-model="autorizadasselec" id="empresa" class="form-control">
                        <option value="">Todos</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Cobrado</label>
                    <select name="cobrado" v-model="cobradoselec" id="empresa" class="form-control">
                        <option value="">Todos</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Enviado</label>
                    <select name="enviado" v-model="enviadoselec" id="empresa" class="form-control">
                        <option value="">Todos</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Tramitador</label>
                    <select name="tramitador" v-model="tramitadorseleciconado" id="empresa" class="form-control">
                        <option value="">Todos</option>
                        <option v-for="tramitador in tramitadores" :key="tramitador.idusuario" :value="tramitador.idusuario">{{ tramitador.nombre }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Auto-Facturas</label>
                    <select name="auto_factura" v-model="autofacturasselec" id="empresa" class="form-control">
                        <option value="">Todos</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Factura-Albaranes</label>
                    <select name="facturas_albaranes" v-model="facturaalbaranesselec" id="empresa" class="form-control">
                        <option value="">Todas</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Exportadas</label>
                    <select name="exportada_factura" v-model="exportadasselec" id="empresa" class="form-control">
                        <option value="">Todos</option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Categoría</label>
                    <select name="tipo_trabajo" v-model="categoriaselec" id="empresa" class="form-control">
                        <option value="">Todos</option>
                        <option v-for="categoria in categorias" :key="categoria" :value="categoria">{{ categoria }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Intervalo de</label>
                    <select name="tipo_fechas" v-model="intervaloselec" id="empresa" class="form-control">
                        <option value="facturacion">Fechas facturación</option>
                        <option value="cobro">Fechas cobro</option>
                        <option value="autorizacion">Fechas autorización</option>
                        <option value="vencimiento">Fechas vencimiento</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Fecha inicio</label>
                    <input v-model="fechainicio" class="form-control" type="date">
                </div>
                <div class="form-group">
                    <label for="">Fecha fin</label>
                    <input v-model="fechafin" class="form-control" type="date">
                </div>
            </div>
            </form>
        <div style="margin-top: 15px;display: flex;justify-content: center;">
            <div class="btn-group">
                <button class="btn btn-default " @click="cargarListado()"><i style="margin: 5px;" class="fas fa-list"></i>Listado</button>
                <button class="btn btn-default " @click="abrirWebpdf"><i class="far fa-file-pdf" style="margin: 5px;"></i>PDF</button>
                <button class="btn btn-default " @click="abrirWeblistado" >Listado Facturas ({{ fechasuma0 }}-{{ fechasuma }})</button>
                <div class="btn-group open">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu">
                        <i class="far fa-file-excel" style="margin: 5px;"></i>
                        <span>Excel</span>
                    </button>
                    <ul id="dropdownMenu1" class="dropdown-menu" aria-labelledby="dropdownMenu">                        
                        <li class="dropdown-item"><a href="#" @click.stop="abrirWebexcel">Basico</a></li>
                        <li class="dropdown-item"><a href="#" @click.stop="abrirWebexcelampliado">Ampliado</a></li>
                    </ul>
                </div>
                <div class="btn-group open">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-controls="dropdownMenu1">
                        <i class="far fa-file" style="margin: 5px;"></i>
                        <span>Exportar</span>
                    </button>
                    <ul id="dropdownMenu1" class="dropdown-menu" aria-labelledby="dropdownMenu1">                        
                        <li v-for="exportacion in exportaciones" :key="exportacion.id" class="dropdown-item"><a href="#" @click.stop="abrirWebexportacion(exportacion)">{{ exportacion.nombre }}</a></li>
                    </ul>
                </div> 
                <div>
                    <input type="text" class="form-control" placeholder="Nº Asiento siguiente:" v-model="nasiento">                     
                </div>
            </div>
        </div>
        <div style="margin-top: 15px;" v-if="mostrarTabla">
            <table class="table table-striped table-responsive">
                <thead>
                    <tr>
                        <th style="text-align: center;">
                            Fecha
                        </th>
                        <th style="text-align: center;">
                            Fecha Vto.
                        </th>
                        <th style="text-align: center;">
                            Autorizada
                        </th>
                        <th style="text-align: center;">
                            Nº
                        </th>
                        <th style="text-align: center;">
                            Referencia
                        </th>
                        <th style="text-align: center;">
                            Cliente
                        </th>
                        <th style="text-align: center;">
                            NIF
                        </th>
                        <th style="text-align: center;">
                            B.I. Normal
                        </th>
                        <th style="text-align: center;">
                            Imp. Normal
                        </th>
                        <th style="text-align: center;">
                            B.I. Reducido
                        </th>
                        <th style="text-align: center;">
                            Imp. Reducido
                        </th>
                        <th style="text-align: center;">
                            B.I. sin imp.
                        </th>
                        <th style="text-align: center;">
                            Tipo imp.
                        </th>
                        <th style="text-align: center;">
                            Dto. Antes IVA (%)
                        </th>
                        <th style="text-align: center;">
                            Dto. Después IVA (%)
                        </th>
                        <th style="text-align: center;">
                            Importe
                        </th>
                        <th>
                            <div style="display: flex; gap: 5px; justify-content: center;">
                                <span> Cobrada </span>
                                <input @change="marcarcobradas" v-model="todascobradas" type="checkbox">
                            </div>
                        </th>
                        <th >
                            <div style="display: flex; gap: 5px;">
                                <span>Enviada</span>
                                <input @change="marcarenviadas" type="checkbox" v-model="todasenviadas">
                            </div>
                        </th>
                        <th style="text-align: center;">
                            Exportada
                        </th>
                        <th>

                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="factura in facturas" :key="factura.id_factura">
                        <td>
                            {{ factura.fecha_hoy_factura }}
                        </td>
                        <td>
                            {{ factura.vencimiento_factura }}
                        </td>
                        <td>
                            <Calendar @date-select="modificarFecha(factura)" v-model="factura.fecha_autorizacion_factura"/>
                        </td>
                        <td v-if="factura.tipo_factura=='abono'">
                            {{ factura.prefijo }}{{ factura.numero_abonado }}/{{ factura.factura_abonada.prefijo }}{{ factura.factura_abonada.numero_factura }}
                        </td>
                        <td v-else>
                            {{ factura.prefijo }}{{ factura.numero_factura }}
                        </td>
                        <td>
                            {{ factura.expediente_factura }}
                        </td>
                        <td>
                            {{ factura.nombre_cliente_factura }}
                        </td>
                        <td>
                            {{ factura.nif_cliente }}
                        </td>
                        <td v-if="factura.tipo_factura=='abono'">
                            -{{ factura.base_16_factura }}€
                        </td>
                        <td v-else>
                            {{ factura.base_16_factura }}€
                        </td>
                        <td v-if="factura.tipo_factura=='abono'">
                            -{{ factura.iva_16_factura }}€
                        </td>
                        <td v-else>
                            {{ factura.iva_16_factura }}€
                        </td>
                        <td v-if="factura.tipo_factura=='abono'">
                            -{{ factura.base_7_factura }}€
                        </td>
                        <td v-else>
                            {{ factura.base_7_factura }}€
                        </td>
                        <td v-if="factura.tipo_factura=='abono'">
                            -{{ factura.iva_7_factura }}€
                        </td>
                        <td v-else>
                            {{ factura.iva_7_factura }}€
                        </td>
                        <td v-if="factura.tipo_factura=='abono'">
                            -{{ factura.base_0_factura }}€
                        </td>
                        <td v-else>
                            {{ factura.base_0_factura }}€
                        </td>
                        <td v-if="factura.tipo_iva_16_factura && factura.tipo_iva_7_factura">
                            {{ factura.tipo_iva_16_factura }} y {{ factura.tipo_iva_7_factura }}
                        </td>
                        <td v-else-if="factura.tipo_iva_16_factura">
                            {{ factura.tipo_iva_16_factura }}
                        </td>
                        <td v-else-if="factura.tipo_iva_7_factura">
                            {{ factura.tipo_iva_7_factura }}
                        </td>
                        <td v-else>

                        </td>
                        <td>
                            {{ factura.descuento }}%
                        </td>
                        <td>
                            {{ factura.descuento_despuesIVA }}%
                        </td>
                        <td>
                            {{ factura.total }}€
                        </td>
                        <td v-if="factura.cobrada_factura == 'NO'">
                            <div style="display: flex; justify-content: center;gap: 2px;">
                                <p style="margin: 0;">
                                    NO
                                </p>
                                <input v-model="factura.marcadacobrada" type="checkbox">
                            </div>
                        </td>
                        <td v-else style="text-align: center;">
                            {{ factura.fecha_cobro }}
                            <span v-if="factura.nombre_banco">
                                {{ factura.nombre_banco }}
                            </span>
                        </td>
                        <td v-if="factura.enviada_factura == 'NO'">
                            <div style="display: flex; justify-content: center;gap: 2px;">
                                <p style="margin: 0;">
                                    NO
                                </p>
                                <input v-model="factura.marcadaenviada" type="checkbox">
                            </div>
                        </td>
                        <td v-else style="text-align: center;">
                            SI
                        </td>
                        <td v-if="factura.exportada_contabilidad == 0" style="text-align: center;">
                            NO
                        </td>
                        <td v-else style="text-align: center;">
                             SI
                        </td>
                        <td>
                            <button @click="abrirPDF(factura.url_factura_pdf)" class="btn"><i class="far fa-file-pdf"></i></button>
                        </td>
                        <td style="display: flex; gap: 5px;" v-if="factura.tipo_factura=='abono'">                            
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="abrirEnviarEmail(factura)">Enviar Email</button>
                        </td>
                        <td style="display: flex; gap: 5px;"  v-else>
                            <div style="display: grid; gap: 5px">
                                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="abrirEnviarEmail(factura)">Enviar Email</button>
                                <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="abrirCobro(factura)">Generar Abono</button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><p style="font-weight: bold;">Total</p></td>
                        <td><p style="font-weight: bold;">{{ totalbi }}€</p></td>
                        <td><p style="font-weight: bold;">{{ totaliva16 }}€</p></td>
                        <td><p style="font-weight: bold;">{{ totalbi7 }}€</p></td>
                        <td><p style="font-weight: bold;">{{ totaliva7 }}€</p></td>
                        <td><p style="font-weight: bold;">{{ totalbi0 }}€</p></td>
                        <td></td>
                        <td><p style="font-weight: bold;">{{ totaldesc }}€</p></td>
                        <td><p style="font-weight: bold;">{{ totaldescdesp }}€</p></td>
                        <td><p style="font-weight: bold;">{{ totaltotal }}€</p></td>
                        <td>
                            <div style="display: grid; gap: 3px;">
                                <div style="display: flex; gap: 2px;">
                                    <span>Fecha</span>
                                    <input type="date" class="form-control" v-model="fechacobrar">
                                </div>
                                <select class="form-control" v-model="bancocobrar" id="">
                                    <option value="">Seleccione banco</option>
                                    <option v-for="banco in bancos" :key="banco.id_banco" :value="banco.id_banco">{{ banco.nombre_banco }}</option>
                                </select>
                                <button @click="cobrarFacturas" class="btn btn-sm btn-light" style="border: 1px solid grey;">Marcar cobradas</button>
                            </div>
                        </td>
                        <td>
                            <button @click="enviarFacturas" class="btn btn-sm btn-light" style="border: 1px solid grey;">Marcar enviadas</button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
import ConfirmDialog from 'primevue/confirmdialog';
import MultiSelect from 'primevue/multiselect';
import Dropdown from 'primevue/dropdown';
import { PwgsApi } from '../../services/PwgsApi';
import Calendar from 'primevue/calendar';
export default ({ 
    data() {
        return {
            spinner:false,
            content:'content',
            fechasuma0:'',
            nomostrar:false,
            direccionglobal:'',
            ivaAbono:'',
            conceptoFacturaAbonar:'',
            correoEnvioText:'',
            correoEnvio:'',
            mostrarTabla:false,
            facturas:[],
            bancos: [],
            empresas: [],
            companias: [],
            lineas: [],
            tramitadores: [],  
            categorias:[],
            empresaseleccionada: '',
            bancoseleccionado:'',
            companiaseleccionada:'',
            lineaseleccionada:'',
            tramitadorseleciconado:'',
            autofacturasselec:'',
            facturaalbaranesselec:'',
            enviadoselec:'',
            exportadasselec:'',
            cobradoselec:'',
            autorizadasselec:'',
            bancocobrar:'',
            intervaloselec:'facturacion',
            fechainicio:'',
            fechafin:'',
            fechacobrar:'',
            categoriaselec:'',
            totalbi:0,
            totaliva16:0,
            totalbi7:0,
            totaliva7:0,
            totalbi0:0,
            totaldesc:0,
            totaldescdesp:0,
            totaltotal:0,
            todasenviadas:false,
            todascobradas:false,
            bodyguardado:{},
            correos:{},
            facturaActual:'',
            dialogEmail:false,
            dialogCobro:false,
            importeFacturaAbonar:0,
            numeroFacturaAbonar:0,
            impuestos:[],
            fechaCambiadaInicio:'',
            fechaCambiadaFin:'',
            fechasuma:'',
            urlabrir:'',
            exportaciones:[],
            nasiento:'',
        }
    },
    components: {
        Dropdown,
        MultiSelect,
        Calendar,
        ConfirmDialog,
    },
    methods:{
        async abrirWebexportacion(exportacion){
            if(!this.facturas.length > 0){
                alert('Primero debe mostrar el listado de facturas.');
                return;
            }else{
                const api = new PwgsApi();
                try{
                    var ids = [];
                    this.facturas.forEach(factura => {
                        ids.push(factura.id_factura);
                    });
                    const response = await api.post('facturas/exportacion-contable', 
                    { programa: exportacion.id, ids_facturas: ids , n_asiento: this.nasiento}, 
                    { responseType: 'blob' } // Importante: especifica que esperas una respuesta binaria (blob)
                );


                if (!(response instanceof Blob)) {
                    throw new Error('La respuesta no es un archivo válido');
                }

                console.log('Tipo de archivo:', response.type);
                console.log('Tamaño del archivo descargado:', response.size);

                // Crea una URL para el blob y luego crea un enlace para descargarlo
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(response);

                link.href = url;
                link.setAttribute('download', 'archivo.zip'); // Nombre del archivo descargado
                document.body.appendChild(link);

                // Disparar la descarga
                link.click();

                // Limpieza: eliminar el enlace temporal y revocar el URL
                link.remove();
                window.URL.revokeObjectURL(url);
                }catch(e){
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
                }
            }
        },
        abrirWebexcelampliado(){
            this.urlabrir = '/impreso_facturas_07.php?tipo_documento=xls&ampliado=1';
            this.fechaCambiadaInicio = this.fechaesp(this.fechainicio);
            this.fechaCambiadaFin = this.fechaesp(this.fechafin);
            window.open('', 'TheWindow');
            setTimeout(() => {
                document.getElementById('Factura').submit();
            }, 500);
        },
        abrirWebexcel(){
            this.urlabrir = '/impreso_facturas_07.php?tipo_documento=xls';
            this.fechaCambiadaInicio = this.fechaesp(this.fechainicio);
            this.fechaCambiadaFin = this.fechaesp(this.fechafin);
            window.open('', 'TheWindow');
            setTimeout(() => {
                document.getElementById('Factura').submit();
            }, 500);
            
        },
        abrirWebpdf(){
            this.urlabrir = '/impreso_facturas_07.php';
            this.fechaCambiadaInicio = this.fechaesp(this.fechainicio);
            this.fechaCambiadaFin = this.fechaesp(this.fechafin);
            window.open('', 'TheWindow');
            setTimeout(() => {
                document.getElementById('Factura').submit();
            }, 500);
        },
        abrirWeblistado(){
            this.urlabrir = '/impreso_facturas_listado.php';
            this.fechaCambiadaInicio =this.fechasuma0;
            this.fechaCambiadaFin = this.fechasuma;
            window.open('', 'TheWindow');            
            setTimeout(() => {
                document.getElementById('Factura').submit();
            }, 500);
        },
        async abonarFactura(){
            try{
                const api = new PwgsApi();
                await api.post('facturas/'+this.facturaActual.id_factura+'/abonar', {importe: this.importeFacturaAbonar, concepto: this.conceptoFacturaAbonar, 
                    iva: this.ivaAbono, numero:this.numeroFacturaAbonar});
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Factura abonada correctamente', life: 2000 });
                this.cerrarCobro();
                this.recargarLista();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        async cargarImpuestos(){
            const api = new PwgsApi();
            const ress = await api.get('impuestos');
            this.impuestos = ress.datos;
        },
        abrirPDF(link){
            let direccionglobal = localStorage.getItem('direccion');
            let auxurlpdf = "https://" + direccionglobal+'/' + link;

            window.open(auxurlpdf,'_blank')
        },
        async cargarListado(){
            this.spinner = true;
            this.content = 'content difuminado';
            const api = new PwgsApi();
            const body = {
                tipo_fechas:this.intervaloselec,
                inicio: this.fechaesp(this.fechainicio),
                fin: this.fechaesp(this.fechafin),
                bancos_listado:this.bancoseleccionado,
                empresas_listado:this.empresaseleccionada,
                companias_listado:this.companiaseleccionada,
                lineas_prefijo:this.lineaseleccionada,
                tramitador:this.tramitadorseleciconado,
                auto_factura:this.autofacturasselec,
                facturas_albaranes:this.facturaalbaranesselec,
                enviado:this.enviadoselec,
                exportada_factura:this.exportadasselec,
                cobrado:this.cobradoselec,
                autorizado:this.autorizadasselec,
                tipo_trabajo:this.categoriaselec,
            }
            this.bodyguardado = body;
            const resp = await api.post('facturas',body);
            this.facturas = resp.datos;
            var totalbit = 0;
            var totaliva16t = 0;
            var totalbi7t = 0;
            var totaliva7t = 0;
            var totalbi0t = 0;
            var totaldesct = 0;
            var totaldescdespt = 0;
            var totaltotalt = 0;
           
            if(this.facturas.length > 0){
                for(let factura of this.facturas){
                    factura.marcadacobrada=false;
                    factura.marcadaenviada=false;

                    factura.base_16_factura = parseFloat(factura.base_16_factura).toFixed(2);
                    totalbit += parseFloat(factura.base_16_factura);
                    factura.iva_16_factura = parseFloat(factura.iva_16_factura).toFixed(2);
                    totaliva16t += parseFloat(factura.iva_16_factura);
                    
                    factura.base_7_factura = parseFloat(factura.base_7_factura).toFixed(2);
                    totalbi7t += parseFloat(factura.base_7_factura);
                    factura.iva_7_factura = parseFloat(factura.iva_7_factura).toFixed(2);
                    totaliva7t += parseFloat(factura.iva_7_factura);

                    factura.base_0_factura = parseFloat(factura.base_0_factura).toFixed(2);
                    totalbi0t += parseFloat(factura.base_0_factura);


                    factura.descuento = parseFloat(factura.descuento).toFixed(2);
                    var suma = factura.base_16_factura+factura.base_7_factura;
                    console.log('suma', factura.base_16_factura, factura.base_7_factura, suma, factura.descuento);
                    var desc = parseFloat(suma)*parseFloat(factura.descuento)/100;
                    console.log('desc', desc);
                    totaldesct += parseFloat(desc);
                    factura.descuento_despuesIVA = parseFloat(factura.descuento_despuesIVA).toFixed(2);
                    totaldescdespt += parseFloat(factura.descuento_despues_impuesto);
                    factura.total = parseFloat(factura.total).toFixed(2);
                    totaltotalt += parseFloat(factura.total);
                }
                console.log('totalbitfinal', totalbit);
                this.totalbi = parseFloat(totalbit).toFixed(2);
                this.totaliva16 = parseFloat(totaliva16t).toFixed(2);
                this.totalbi7 = parseFloat(totalbi7t).toFixed(2);
                this.totaliva7 = parseFloat(totaliva7t).toFixed(2);
                this.totalbi0 = parseFloat(totalbi0t).toFixed(2);
                this.totaldesc = parseFloat(totaldesct).toFixed(2);
                this.totaldescdesp = parseFloat(totaldescdespt).toFixed(2);
                this.totaltotal = parseFloat(totaltotalt).toFixed(2);
                this.mostrarTabla = true;
                this.spinner = false;
                this.content = 'content';
            }else{
                this.mostrarTabla = false;
                this.spinner = false;
                this.content = 'content';
            }
        },
        async enviarCorreo(){
            if(this.correoEnvioText == ''){
                alert('Debe indicar un correo');
                return;
            }
            const api = new PwgsApi();
            var emails = [];
            emails.push(this.correoEnvioText);
            try{
                await api.put('facturas/'+this.facturaActual.id_factura+'/enviar-factura', {'emails': emails, tipo:'email', metodo_envio:'CC'});
                this.abrirEnviarEmail(this.facturaActual);
                this.$toast.add({ severity: 'success', summary: 'Enviada', detail: 'Factura enviada correctamente', life: 2000 });
            }catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        timestampToDateTime(timestamp) {
            // Convertir el timestamp de segundos a milisegundos
            const date = new Date(timestamp * 1000);

            // Obtener el día, mes, año, horas, minutos y segundos
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            // Formatear la fecha y la hora como DD/MM/YYYY HH:mm:ss
            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
        async abrirEnviarEmail(factura){
            const api = new PwgsApi();
            this.correos = await api.get('facturas/'+factura.id_factura+'/formulario-envio-email');
            console.log('ññrh', this.correos);
            if(this.correos.envios_realizados && this.correos.envios_realizados.length > 0){
                for(let envio of this.correos.envios_realizados){
                    envio.fecha_envio = this.timestampToDateTime(envio.fecha_envio);
                }
            }
            this.facturaActual = factura;
            this.dialogEmail = true;
        },
        async abrirCobro(factura){
            console.log('facturaaver', factura);
            this.facturaActual = factura;
            if(factura.formulario_abonos.parciales==1){
                this.dialogCobro = true;
                this.importeFacturaAbonar = factura.importe_base;
                this.numeroFacturaAbonar = factura.siguiente_numero;
                this.ivaAbono = factura.iva_defecto;
            }else{
                this.confirmar();
            }
        },
        confirmar(){
            console.log('facturallega');
            this.$confirm.require({
                    message: '¿Seguro que quieres abonar la factura?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClass: 'p-button-secondary p-button-outlined',
                    rejectLabel: 'Cancelar',
                    acceptLabel: 'Aceptar',
                    accept: () => {
                    this.pagarfactura()
                    },
                    reject: () => {
                        return;
                    }
                });
            
        },
        async pagarfactura() {
            //PUT  modulos/pwgsapi/index.php/facturas/:id_servicio/enviar-pago-tpv
            const api = new PwgsApi();
            try{
            await api.post('facturas/'+this.facturaActual.id_factura+'/abonar');
            this.$toast.add({ severity:'success', summary: 'Abonado', detail: 'Factura abonada', life: 2000 });
            this.recargarLista();          
            }catch(e){
            this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        cerrarEmail(){
            this.correos = {};
            this.facturaActual = '';
            this.dialogEmail = false;
            this.correoEnvio = '';
            this.correoEnvioText = '';
        },
        cerrarCobro(){
            this.dialogCobro = false;
            this.facturaActual = '';
        },
        async cobrarFacturas(){
            if(this.bancocobrar == ''){
                alert('Dabe seleciconar un banco');
                return;
            }            
            const api = new PwgsApi();
            for(let factura of this.facturas){
                if(factura.marcadacobrada){
                    await api.put('facturas/'+factura.id_factura+'/banco', {fecha:this.fechaesp(this.fechacobrar), banco:this.bancocobrar});
                }
            }
            this.recargarLista();
        },
        async enviarFacturas(){
            const api = new PwgsApi();
            for(let factura of this.facturas){
                if(factura.marcadaenviada){
                    await api.put('facturas/'+factura.id_factura+'/enviada-factura', {enviada:'SI'});
                }
            }
            this.recargarLista();
        },
        async recargarLista(){
            const api = new PwgsApi();
            const resp = await api.post('facturas',this.bodyguardado);
            this.facturas = resp.datos;
        },
        async modificarFecha(factura){
            const api = new PwgsApi();
            await api.put('facturas/'+factura.id_factura+'/fecha-autorizacion', {fecha:factura.fecha_autorizacion_factura.toLocaleDateString()});
        },
        marcarcobradas(){
            if(this.todascobradas){
                for(let factura of this.facturas){
                    factura.marcadacobrada=true;
                }
            }else{
                for(let factura of this.facturas){
                    factura.marcadacobrada=false;
                }
            }
        },
        marcarenviadas(){
            if(this.todasenviadas){
                for(let factura of this.facturas){
                    factura.marcadaenviada=true;
                }
            } else{
                for(let factura of this.facturas){
                    factura.marcadaenviada=false;
                }
            }
        },        
        async cargarBancos(){
            const api = new PwgsApi();
            const resp = await api.get('bancos');
            this.bancos = resp.datos;
        },
        async cargarEmpresas(){
            const api = new PwgsApi();
            const resp = await api.get('empresas');
            this.empresas = resp.datos;
        },
        async cargarCompanias(){
            const api = new PwgsApi();
            const resp = await api.get('companias');
            this.companias = resp.datos;
        },
        async cargarLineas(){
            const api = new PwgsApi();
            const resp = await api.get('lineas-facturacion');
            this.lineas = resp.datos;
        },
        async cargarTramitadores(){
            const api = new PwgsApi();
            const resp = await api.get('usuarios/gestion');
            this.tramitadores = resp.datos;
        },
        async cargarCategorias(){
            const api = new PwgsApi();
            const resp = await api.get('tipos-trabajos');
            this.categorias = resp;
        },
        async cargarFechas(){
            const fecha = Date.now();
            const hoy = new Date(fecha);
            var año_actual = new Date().getFullYear();
            this.fechainicio = this.fechaing('01/01/'+año_actual);
            this.fechafin = this.fechaing(hoy.toLocaleDateString());
            this.fechacobrar = this.fechaing(hoy.toLocaleDateString());
            //this.fechainicio = new Date();
            //this.fechafin = new Date();
        },
        fechaing(fecha) {
            const [dia, mes, año] = fecha.split("/");
            return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
        },
        fechaesp(fecha) {
            const [año, mes, dia] = fecha.split("-");
            return `${dia}/${mes}/${año}`;
        },
        sumardias(fecha) {
            console.log('entrasuma', fecha);
            const date = new Date(fecha);
            const nDias = 15;
            date.setDate(date.getDate() + nDias);
            this.fechasuma0 = this.fechaesp(this.fechainicio);
            this.fechasuma=date.toLocaleDateString();
        },
        async cargarExportaciones(){
            const api = new PwgsApi();
            const resp = await api.get('programas-exportacion-contable-activos');
            this.exportaciones = resp.datos;
        },
    },
   
    watch:{
        fechainicio(value){
            this.sumardias(value);
        },
        correoEnvio(value){
            this.correoEnvioText = value;
        }
    },
    mounted() {
        this.direccionglobal = localStorage.getItem('direccion');
        this.cargarImpuestos();
        this.cargarBancos();
        this.cargarEmpresas();
        this.cargarCompanias();
        this.cargarLineas();
        this.cargarTramitadores();
        this.cargarFechas();
        this.cargarCategorias();
        this.cargarExportaciones();
    },    
})


</script>
<style scoped>
    .difuminado{
        filter: blur(6px);
        pointer-events: none;
        -webkit-user-select: none;
            /* Safari */
        -ms-user-select: none;
            /* IE 10 and IE 11 */
        user-select: none;
            /* Standard syntax */
    }
    .card {
        width: 50%;
        margin: 20% auto;
    }
    .spinner {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 9px solid;
        border-color: #d9e3ff;
        border-right-color: #007bff;
        animation: spinner-d3wgkg 1s infinite linear;
        position: absolute;
        z-index:1000;
        top: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -50px;
            
    }

    @keyframes spinner-d3wgkg {
        to {
            transform: rotate(1turn);
        }
    }
</style>