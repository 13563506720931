<template>
    <Dialog :style="{ width: '50rem' }" @hide="cerrarDialog" :header="'Documentos de '+nombreactual" modal v-model:visible="dialogDocs">
        <div style="display: flex; gap: 20px;">
            <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event)" multiple="true"
                        chooseLabel="Elegir Archivo" />
            <button class="btn btn-primary" @click="uploadFile()">Subir archivo</button>
        </div>
        <table style="margin-top: 15px" v-if="documentosActuales.length > 0" class="table">
            <thead>
                <tr>
                    <th>
                        Fecha Subida
                    </th>
                    <th>
                        Fecha Vto.
                    </th>
                    <th>
                        Nombre
                    </th>
                    <th>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="doc in documentosActuales" :key="doc.id_documento">
                    <td>
                        {{ doc.fecha_subido_documento }}
                    </td>
                    <td>
                        <div v-if="doc.fecha_vencimiento_documento == '0000-00-00'">
                            Sin vcto.
                        </div>
                        <div v-else>
                            <input type="date" class="form-control" @change="cambiarFechaDoc(doc)" v-model="doc.fecha_vencimiento_documento">
                        </div>
                    </td>
                    <td>
                        {{ doc.nombre }}
                    </td>
                    <td>
                        <div style="display: flex; gap: 5px;">
                            <button class="btn btn-xs btn-light" style="border: 1px solid grey;" @click="descargarDoc(doc)">Descargar</button>
                            <button class="btn btn-xs btn-light" style="border: 1px solid grey;" @click="eliminarDoc(doc)">Eliminar</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </Dialog>
    <div class="col-md-6">
        <seccion-datos :titulo="documentacionrequerida" :botonGuardar="false" class="card-success">
            <div class="row">
                <div class="col-md-8">
                    <div class="input-group">
                        <select class="custom-select form-control-sm select2" v-model="seleccionado">
                            <option value="">Seleccione documentación</option>
                            <option v-for="documento in documentaciones" :key="documento.id_documentacion" :value="documento.id_documentacion">{{documento.nombre_documentacion}} 
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-primary" @click="añadirdocumentacion(seleccionado)">{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-sm table-striped">
                        <tbody>
                            <tr v-for="docs in operario_docum" :key="docs.documentacion_id_documentacion">
                                <td> {{ docs.documentacion.datos.nombre_documentacion }}</td>
                                <td><button class="btn btn-block btn-outline-primary btn-xs" @click="abrirDocs(docs)">Ver documentos</button></td>
                                <td><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminar(docs.id_documentacion_asignacion)">{{ $t('general.eliminar') }}</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </seccion-datos>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import FileUpload from 'primevue/fileupload';
export default {
    props: ['id', 'operario'],
    components:{
        FileUpload,
    },
    data() {
        return {
            dialogDocs: false,
            documentaciones: [],
            seleccionado: '',
            operario_docum: '',
            documentacionrequerida:"Documentación requerida",
            documentoActual:'',
            nombreactual:'',
            documentosActuales:[],
            archivosubido:null,
        }
    },
    methods: {
        async cambiarFechaDoc(doc){
            const api = new PwgsApi();
            try{
                await api.put('operarios/' + this.id + '/documentacion-requerida-vto/' + doc.id_documento, {fecha:doc.fecha_vencimiento_documento});
            }catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        async uploadFile() {
            console.log('archivosubido', this.archivosubido);
            if(!this.archivosubido){
                alert('Debe seleccionar un archivo');
                return;
            }
            const formData = new FormData();
            formData.append("adjunto_fichero", this.archivosubido);
            const api = new PwgsApi;
            try{
                await api.post('operarios/' + this.id + '/documentacion-requerida-documento/'+this.documentoActual.documentacion_id_documentacion, formData);  
                this.archivosubido = [];
                this.$toast.add({ severity: 'success', summary: 'Añadido', detail: 'Documento añadido correctamente', life: 2000 });
                this.abrirDocs(this.documentoActual);
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }    
        },
        subirarchivo(event) {
            for (let i = 0; i < event.files.length;i++){
            this.archivosubido = event.files[i];
            }
        },
        descargarDoc(doc){
            let direccionglobal = localStorage.getItem('direccion');
            let auxurl = doc.url_descarga;
            let auxurlpdf = "https://" + direccionglobal + auxurl;

            window.open(auxurlpdf,'_blank')
        },
        async abrirDocs(doc){
            const api = new PwgsApi();
            this.documentoActual = doc;
            this.nombreactual = doc.documentacion.datos.nombre_documentacion;
            this.dialogDocs=true;
            const datos = await api.get('operarios/' + this.id + '/documentacion-requerida/'+doc.documentacion_id_documentacion);
            this.documentosActuales = datos.datos;
        },
        cerrarDialog(){
            this.dialogDocs = false;
        },
        async cargarDatosOperario() {
            /* GET  modulos/pwgsapi/index.php/operarios/:id/documentacion-requerida/:id_documentacion */
            const api = new PwgsApi;
            const datos = await api.get('operarios/' + this.id + '/documentacion-requerida');
            this.operario_docum = datos.datos;
            const dats = await api.get('documentacion-requerida');
            this.documentaciones = dats.datos;
            this.operario_docum.forEach(element => {
                this.documentaciones = this.documentaciones.filter(documento => documento.id_documentacion != element.documentacion_id_documentacion);
            });
        },
        async eliminar(id) {
            /* DELETE  modulos/pwgsapi/index.php/operarios/:id/companias/:id_compania */
            const api = new PwgsApi;
            await api.delete('operarios/' + this.id + '/documentacion-requerida/' + id);
            this.cargarDatosOperario();
        },
        async eliminarDoc(doc) {
            const api = new PwgsApi;
            await api.delete('operarios/' + this.id + '/documentacion-requerida-documento/' + doc.id_documento);
            this.abrirDocs(this.documentoActual);
        },
        async añadirdocumentacion(id) {
            /* POST  modulos/pwgsapi/index.php/operarios/:id/companias */
            const api = new PwgsApi;
            await api.post('operarios/' + this.operario.id + '/documentacion-requerida', {id_documentacion:id});
            this.cargarDatosOperario();
            this.seleccionado = '';
        }
    },
    watch: {
        id() {
            this.cargarDatosOperario();
        }
    },
    mounted() {
        this.cargarDatosOperario();
    }
}
</script>