<template>
    <div class="callout" style="margin: 0;">
        <div class="row">        
            <div class="col-md-4">
                <SelectButton v-model="opcionseleccionada" :options="options" />
            </div>
            <div class="col-md-2">
                <div class="row">
                    <Dropdown style="width:300px" scrollHeight="400px" showClear v-model="estadopenselec"
                        :options="estadospendientes" @change="filtrarestadopenselec()" filter optionLabel="nombre" placeholder="Estado pendiente">
                    </Dropdown>
                </div>
            </div>
            <div class="col-md-4">
                <div class="input-group">
                    <input style="height: 42px;" type="text" class="form-control" placeholder="Buscador" @keyup.enter="obtenerExpedientes(mievento)"  v-model="provinciabuscar">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" @click="obtenerExpedientes(mievento)" title="Buscar">Buscar</button>
                    </div>
                </div>
            </div>   
            <div class="col-md-1">
                <Button icon="fas fa-file-excel" class="btn btn-secondary" label="Exportar" style="height: 42px;"
                @click="exportarcsv()" />
            </div>
            <div class="col-md-1">
                <Button icon="pi pi-plus-circle" class="btn btn-primary" label="Nuevo" title="Nuevo servicio" style="height: 42px;"
                @click="nuevoservicio()" />
            </div>   
        </div>
        <div class="row">
        </div>
        <Accordion style="margin:25px 0;">
            <AccordionTab>
                <template #header>
                    <div class="row">
                        <span>
                            Busqueda avanzada
                        </span>
                        <span @click.stop style="margin-left:50px">                            
                            <span v-if="compsele"><Chip :label="compsele.nombre" @remove="compsele='';filtrarcompsele()" removable ></Chip>&nbsp;</span>
                            <span v-if="tipodanoseleccionado"><Chip @click.stop @remove="tipodanoseleccionado = '';filtrartipodanoseleccionado()" :label="tipodanoseleccionado.danyo" removable ></Chip>&nbsp;</span>
                            <span v-if="estadopenselec"><Chip @click.stop :label="estadopenselec.nombre" @remove="estadopenselec = '';filtrarestadopenselec()" removable ></Chip>&nbsp;</span>
                            <span v-if="tramitadoreseleccionado"><Chip @click.stop :label="tramitadoreseleccionado.nombre" @remove="tramitadoreseleccionado = '';filtrartramitadoreseleccionado()" removable ></Chip>&nbsp;</span>
                            <span v-if="operarioseleccionado"><Chip @click.stop :label="operarioseleccionado.nombre"  @remove="operarioseleccionado = '';filtraroperarioseleccionado()" removable ></Chip>&nbsp;</span>
                            <span v-if="tiposservicioseleccionado" ><Chip @click.stop :label="tiposservicioseleccionado.nombre" @remove="tiposservicioseleccionado = '';filtrartiposservicioseleccionado()" removable ></Chip>&nbsp;</span>
                            <span v-if="satisfaccionselec"><Chip @click.stop :label="'Nivel satisfacción: '+satisfaccionselec" @remove="satisfaccionselec=''" removable ></Chip></span>
                            <span v-if="urgente"><Chip @click.stop :label="'Urgente'" @remove="urgente='';obtenerExpedientes(mievento)" removable ></Chip></span>
                        </span>
                    </div>
                </template>
                    <div class="col-md-3">
                        <div class="row">
                            <div v-if="opcionseleccionada=='Activos'">
                                <b>Facturas:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('factura gris', mievento)" class="Gris" label="  " />&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('factura rojo', mievento)" class="Rojo" label="  " />&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('factura amarillo', mievento)" class="Amarillo" label="  " />&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('factura verde', mievento)" class="Verde" label="  " />
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="opcionseleccionada=='Activos' || opcionseleccionada=='Finalizados'">
                                <b>Presupuestos:</b>&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('presupuesto gris', mievento)" class="Gris" label="  " />&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('presupuesto roja', mievento)" class="Rojo" label="  " />&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('presupuesto naranja', mievento)" class="Naranja" label="  " />&nbsp;&nbsp;
                                <Button @click="obtenerExpedientesFact('presupuesto verde', mievento)" class="Verde" label="  " />&nbsp;
                            </div>
                        </div>
                    </div>
                    <div class="row">  
                        <div class="col-md-10">
                            <div style="display: flex; flex-wrap: wrap; gap: 15px; margin-top: 20px;">
                                <Dropdown @change="filtrarcompsele()" style="width:300px" scrollHeight="400px" showClear v-model="compsele" :options="companyias" filter optionLabel="nombre" placeholder="Filtro Compañia">
                                    <template #option="slotProps">
                                        <div class="codigo">{{ slotProps.option.nombre }}&nbsp;
                                            <span class="badge badge-primary">{{ slotProps.option.prefijo }}</span>&nbsp;
                                            <span class="badge badge-secondary">{{ slotProps.option.encurso }}</span>&nbsp;
                                            <span class="badge badge-success">{{ slotProps.option.facturar }}</span>
                                        </div>
                                    </template>
                                </Dropdown>
                                <Dropdown  scrollHeight="400px" showClear v-model="tipodanoseleccionado" :options="tiposdano" filter optionLabel="danyo" @change="filtrartipodanoseleccionado" style="width:250px" placeholder="Tipo daño">
                                </Dropdown>
                                <Dropdown style="width:200px" scrollHeight="400px" showClear
                                    v-model="tramitadoreseleccionado" @change="filtrartramitadoreseleccionado()" :options="tramitadores" filter optionLabel="nombre"
                                    placeholder="Tramitador">
                                </Dropdown>
                                <Dropdown scrollHeight="400px" style="width:300px" showClear v-model="operarioseleccionado"
                                    :options="operarios" filter optionLabel="nombre" @change="filtraroperarioseleccionado()" placeholder="Operario">
                                </Dropdown>
                                <Dropdown style="width:250px" scrollHeight="400px" showClear
                                    v-model="tiposservicioseleccionado" @change="filtrartiposservicioseleccionado()" :options="tiposservicio" filter optionLabel="nombre"
                                    placeholder="Tipos servicio">
                                </Dropdown>
                                <Dropdown scrollHeight="400px" style="width:250px" showClear v-model="satisfaccionselec"
                                    :options="satisfacciones" filter optionLabel="nombre" optionValue="valor"
                                    placeholder="Nivel satisfacción">
                                </Dropdown>
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" type="checkbox" @change="obtenerExpedientes(mievento)" id="urgente" v-model="urgente">
                                    <label for="urgente" class="custom-control-label">Urgente</label>
                                </div>
                            </div>
                        </div>     
                    </div>
            <p>&nbsp;</p>
            <div class="row">
                <h4>Facturación</h4>
            </div>
            <div class="row" style="margin: 0; gap: 15px; align-items: end;">        
                <div class="form-group">
                    <label for="">Tipo</label>
                    <select style="width: 200px;" v-model="tipofacturaselec" class="form-control" id="">
                        <option value="">Seleccione tipo</option>
                        <option value="albaran">Albarán</option>
                        <option value="abono">Abono</option>
                        <option value="presupuesto">Presupuesto</option>
                        <option value="factura">Factura</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Serie</label>
                    <select style="width: 250px;" v-model="lineafacturaselec" class="form-control" id="">
                        <option value="">Todas</option>
                        <option v-for="linea in lineasFacturacion" :key="linea.id_serie" :value="linea">{{ linea.nombre_serie }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Nº / Año</label>
                    <div class="row" style="margin: 0; gap: 10px;">
                        <input style="width: 120px;" type="text" v-model="numerofacturaselec" class="form-control" id="">
                        <span style="font-size: x-large;">/</span>
                        <input style="width: 120px;" type="number" v-model="añofacturaselec" class="form-control">
                    </div>
                </div>
                <div class="form-group">
                    <label for="">Importe (desde - hasta)</label>
                    <div class="row" style="margin: 0; gap: 10px;">
                        <div class="row" style="margin: 0;">
                            <input style="width: 120px;" type="number" v-model="importedesdefactura" class="form-control" id="">
                            <span style="font-size: x-large;">€</span>
                        </div>
                        <span style="font-size: x-large;">-</span>
                        <div class="row" style="margin: 0;">
                            <input style="width: 120px;" type="number" v-model="importehastafactura" class="form-control">
                            <span style="font-size: x-large;">€</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="">Enviadas</label>
                    <select class="form-control" v-model="enviadasfacturaselec" id="">
                        <option value="">--</option>
                        <option value="SI">Si</option>
                        <option value="NO">No</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Cobradas</label>
                    <select class="form-control" v-model="cobradasfacturaselec" id="">
                        <option value="">--</option>
                        <option value="SI">Si</option>
                        <option value="NO">No</option>
                    </select>    
                </div>
                <div style="margin-bottom:15px">
                    <button type="button" class="btn btn-primary" @click="obtenerExpedientes(this.mievento)" title="Buscar">Buscar</button>   
                </div>  
            </div>
            </AccordionTab>
        </Accordion> 
    </div>
    <div class="row"> 
    </div>
    <div :class="miclasee" :style="difuminado">
        <tabla-datos :exportar="exportar" v-if="this.opcionseleccionada != 'Finalizados' && mostrar" :exped="true" :botoncrear="1" :columnas="columnas"
            :datos="expedientes" :filtros="filtros" :filtrosBuscador="filtrosBuscador" :total="total" :servicios="true"
            @quieroDatos="obtenerExpedientes($event); mievento = $event;"  :num="numdatos" @page="onPage($event)">
            <!--:page="pageselec"-->
        </tabla-datos>
        <tabla-datos :exportar="exportar" v-if="this.opcionseleccionada == 'Finalizados' && mostrar" :facturacion="true" :exped="true" :botoncrear="1"
            :columnas="columnasfinalizados" :datos="expedientes" :filtros="filtros" :filtrosBuscador="filtrosBuscador" :servicios="true"
            :total="total" @quieroDatos="obtenerExpedientes($event); mievento = $event;"  :num="numdatos" @page="onPage($event)">
             <!--:page="pageselec"-->
        </tabla-datos>
    </div>
    <Dialog v-model:visible="visible" modal header="Datos del siniestro">
        <p>
            <b>COMPAÑIA: </b>{{ datosbasicosamostrar.compania.nombre }}<br>
            <b>Nº EXPEDIENTE: </b>{{ datosbasicosamostrar.codigo }}<br>
            <b>DESCRIPCIÓN SINIESTRO: </b>{{ datosbasicosamostrar.danos }}<br>
            <b>NOMBRE: </b>{{ datosbasicosamostrar.asegurado.nombre }}<br>
            <b>DOMICILIO: </b>{{ datosbasicosamostrar.asegurado.direccion }}<br>
            <b>POBLACIÓN: </b>{{ datosbasicosamostrar.poblacion }}<br>
            <b>PROVINCIA: </b>{{ datosbasicosamostrar.provincia }}<br>
            <b>CÓDIGO POSTAL: </b>{{ datosbasicosamostrar.cp }}<br>
            <b>PERSONA CONTACTO: </b>{{ datosbasicosamostrar.clientes.asegurado.persona_contacto }}<br>
            <b>TELÉFONOS: </b>{{ datosbasicosamostrar.asegurado.telefonos[0] + " - " +
            datosbasicosamostrar.asegurado.telefonos[1] + " - " + datosbasicosamostrar.asegurado.telefonos[2] }}<br>
            <b>NIF: </b>{{ datosbasicosamostrar.asegurado.nif }}
            <b>POLIZA: </b>{{ datosbasicosamostrar.asegurado.poliza }}
        </p>
    </Dialog>
</template>
<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
import SelectButton from 'primevue/selectbutton';
import { defineAsyncComponent } from 'vue';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import Chip from 'primevue/chip';
export default {
    props: ['selectcompany','companyias','propsactivo', 'satisfaselec'],
    components: {  
        "tabla-datos": TablaDatos,
        SelectButton,Dropdown,Dialog, Accordion, AccordionTab,Chip
    },
    data() {
        return {
            permisogestiontipos:null,
            enviadasfacturaselec:'',
            cobradasfacturaselec:'',
            importedesdefactura:'',
            importehastafactura:'',
            añofacturaselec:'',
            numerofacturaselec:'',
            lineafacturaselec:'',
            tipofacturaselec:'',
            lineasFacturacion:[],
            mostrar: false,
            //pageselec:1,
            numdatos:15,
            provinciabuscar:'',
            satisfaccionselec:'',
            satisfacciones:[],
            operarios:'',
            difuminado:'',
            lazy: true,
            expedientes: [],
            campo: "datos_basicos.color_factura",
            tittle: "hola",
            compsele: '',
            urgente: '',
            visible:false,
            estadopenselec: '',
            estadospendientes: [],
            tiposservicio: '', 
            tiposservicioseleccionado:'',
            parametros: null,
            tipodanoseleccionado: '',
            tiposdano: '',
            miclasee:'',
            tramitadores: '',
            buscador: '',
            mievento: [],
            datosbasicosamostrar:[],
            tramitadoreseleccionado:'',
            operarioseleccionado:'',
            permisosusuario:[],
            colorseleccionado:'Gris',
            opcionseleccionada:'Activos',
            filtrosBuscador: ['codigo_servicio'],
            exportar: false,
            parametrocodexp:'',
            filtros: {
            },
            options: ['Activos', 'Finalizados', 'Eliminados', 'Todos'],
            opcionescolor: ['Gris', 'Verde', 'Rojo','Todos'],
            columnas: [
                {
                    field: 'icono',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            icono: 'fas fa-info',
                            nombreId: 'idservicios',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                    this.mostrardatosbasicos(id);
                            }
                        },
                    ]         
                },
                {
                    header: 'Expediente',
                    field: 'codigo',
                    sortable: false,
                    class: 'codigo',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id,codigo) => {
                                console.log('Editar expediente ' + id);
                                localStorage.setItem('nombreservicio'+id,codigo);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id,
                                        nombreservicio:codigo,
                                    }
                                });
                            }
                        },
                    ]  
                },
                {
                    header: 'Pendiente de',
                    field: 'pendiente',
                    sortable: false,
                },
                {
                    header: 'Tramitador',
                    field: 'tramitador',
                    sortable: false,
                    class: 'tramitador',
                },
                {
                    header: 'Gestor tipos',
                    field: 'tipos_servicio',
                    sortable: false,
                    class: 'tiposservicio',
                },
                {
                    header: 'Fecha',
                    field: 'fecha',
                    sortable: true,
                    class: 'fecha',
                },
                {
                    header: 'Días Apertura',
                    field: 'dias_apertura',
                    sortable: true,
                },
               
                {
                    header: 'Fecha espera',
                    field: 'fecha_espera',
                    sortable: true,
                },
                {
                    header: 'Días sin Actualizar',
                    field: 'dias_sin_actualizar',
                    sortable: false,
                },
              
               
                {
                    header: 'F./P.',
                    field: 'facturapresupuesto',
                    sortable:false,
                },

                {
                    data: null,
                    acciones: [
                        
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',

                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id,codigo) => {
                                if (confirm('Desea eliminar el servicio ' + codigo)) {
                                    
                                    this.eliminarservicio(id);
                                }
                            }
                        },
                        {
                            nombre: 'Recuperar',
                            class: 'recuperar',
                            tipo: 'success',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',
                            icono: 'fa fa-recycle',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id,codigo) => {
                                if (confirm('Desea recuperar el servicio ' + codigo)) {
                                    this.recuperarservicio(id);
                                    
                                }
                            }
                        },       
                    ]
                }
            ],
            columnasfinalizados: [
                {
                    field: 'icono',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            icono: 'fas fa-info',
                            nombreId: 'idservicios',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                this.mostrardatosbasicos(id);
                            }
                        },
                    ]

                },
                {
                    header: 'Expediente',
                    field: 'codigo',
                    sortable: false,
                    class: 'codigo',
                    acciones: [
                        {
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id, codigo) => {
                                console.log('Editar expediente ' + id);
                                localStorage.setItem('nombreservicio' + id, codigo);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id,
                                        nombreservicio: codigo,
                                    }
                                });
                            }
                        },
                    ]
                },
                
                {
                    header: 'Tramitador',
                    field: 'tramitador',
                    sortable: false,
                    class: 'tramitador',
                },
                {
                    header: 'Gestor tipos',
                    field: 'tipos_servicio',
                    sortable: false,
                    class: 'tiposservicio',
                },
                {
                    header: 'F.emisión',
                    field: 'fecha_ultima_factura',
                    sortable: true,
                    class: 'fecha_ultima_factura',
                },
                {
                    header: 'F.factura',
                    field: 'fecha_factura',
                    sortable: true,
                    class: 'fecha_factura',
                },
                {
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            link: true,
                            tipo: 'default',
                            nombreId: 'idservicios',

                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            nombreIddos: 'codigo',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id, codigo) => {
                                console.log('Editar expediente ' + id);
                                localStorage.setItem('nombreservicio' + id, codigo);
                                this.$router.push({
                                    name: 'Servicio',
                                    params: {
                                        id: id,
                                        nombreservicio: codigo,
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Recuperar',
                            class: 'recuperar',
                            tipo: 'success',
                            nombreId: 'idservicios',
                            nombreIddos: 'codigo',
                            icono: 'fa fa-recycle',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id, codigo) => {
                                if (confirm('*Desea recuperar el servicio ' + codigo+'?')) {
                                    localStorage.setItem('nombreservicio' + id, codigo);

                                    this.recuperarservicio(id);
                                }
                            }
                        },
                    ]
                },
                {
                    nombre: 'Facturas',
                    class: 'facturas',
                    field:'facturas',
                }
            ]
        }
    },
    methods: {
        //https://sonia.pwgs.app/modulos/pwgsapi/index.php/listados-servicios/activos
        obtenerExpedientesFact(factura, evento){
            this.buscador = factura;
            this.obtenerExpedientes(evento,);
        },
        async obtenerExpedientes(event) {
            if(this.permisosusuario.length<1){
                await this.comprobarPermisos();
            }   
            this.difuminado = "filter:blur(4px)"; 
         if(this.opcionseleccionada != undefined){
            let auxcolumnas = {
                header: 'Fecha de eliminación',
                field: 'fecha_eliminacion',
                sortable: true,
                class: 'fecha_eliminacion',
            };
            event.buscador = this.buscador;
            if (this.compsele) { event.id_compania = this.compsele.idcompañia }
  
             if (this.opcionseleccionada == 'Activos') {    
                if(this.columnas[10]){            
                    this.columnas[10].acciones[1].class = "miclase";
                }
                else{
                    this.columnas[9].acciones[1].class = "miclase";

                }
            }
            if (this.opcionseleccionada == "Eliminados") {
                this.columnas[2] = auxcolumnas;

             }
            else {
                if (this.permisosusuario.includes('Administración') || this.permisosusuario.includes('Super Administración')) {
                    if(this.columnas[10]){
                        this.columnas[10].acciones[1].class = "eliminar"; 
                    }
                    else {
                        this.columnas[9].acciones[1].class = "eliminar"; 

                    }
                }
            }
            if (this.opcionseleccionada != "Eliminados") {
                this.columnas[2] = {
                    header: 'Pendiente de',
                    field: 'pendiente',
                    sortable: false,
                    class: 'pendiente',
                
                } ;
                if (this.columnas[10]) {
                    
                    this.columnas[10].acciones[1].class = "miclase";
                }
                else{
                    this.columnas[9].acciones[1].class = "miclase";

                }
            }
            else {
                if (this.columnas[10]) {
                    this.columnas[10].acciones[0].class = "miclase";


                    this.columnas[10].acciones[1].class = "recuperar";
                }
                else {
                    this.columnas[9].acciones[1].class = "recuperar";

                }
            }
            event.urgente = this.urgente;
            if (this.estadopenselec) {
                event.id_esp_pend = this.estadopenselec.id;
            }
             if (this.tipodanoseleccionado) {

                event.tipo_dano = this.tipodanoseleccionado.danyo;
            }
            if(this.provinciabuscar != ''){
                event.buscador = this.provinciabuscar;
            }
            console.log('tipofactura', this.tipofacturaselec);
            if(this.tipofacturaselec && this.tipofacturaselec!='null'){
                event.tipo_factura=this.tipofacturaselec;
            }
            if(this.lineafacturaselec && this.lineafacturaselec!='null'){
                event.id_serie_factura = this.lineafacturaselec.id_serie;
            }
            if(this.numerofacturaselec && this.numerofacturaselec!='null'){
                event.numero_factura = this.numerofacturaselec;
            }
            if(this.añofacturaselec && this.añofacturaselec!='null'){
                event.year_factura = this.añofacturaselec;
            }
            if(this.importedesdefactura && this.importedesdefactura!='null'){
                event.importe_desde_factura = this.importedesdefactura;
            }
            if(this.importehastafactura && this.importehastafactura!='null'){
                event.importe_hasta_factura = this.importehastafactura;
            }
            if(this.enviadasfacturaselec && this.enviadasfacturaselec!='null'){
                event.enviada_factura = this.enviadasfacturaselec;
            }
            if(this.cobradasfacturaselec && this.cobradasfacturaselec!='null'){
                event.cobrada_factura = this.cobradasfacturaselec;
            }
            if (this.tiposservicioseleccionado) {
                event.id_tipo = this.tiposservicioseleccionado.id;
            }
            if (this.tramitadoreseleccionado) {
                event.id_tramitador = this.tramitadoreseleccionado.id;
            }
            if (this.operarioseleccionado) {
                event.ids_operarios = [this.operarioseleccionado.id];
                console.log('operselec',this.operarioseleccionado);
            }
            if(this.satisfaccionselec){
                event.satisfaccion = this.satisfaccionselec;
            }
            this.cargargestortipos();
            const api = new PwgsApi;
            console.log('evnt', event);
            const aux = await api.post('listados-servicios/' + this.opcionseleccionada.toLowerCase(), event);
            this.expedientes = aux.datos;
            this.total = aux.n_total_registros;
            this.$emit('evento_total_servicios', this.total); //Es llamado por ./Escritorio.vue
            this.difuminado = "";
         }
                 this.cargarCodexp();

        },
        async cargartiposdanyo() {
            const api = new PwgsApi();
            const datos = await api.get('tipos-dano');
            this.tiposdano = datos.datos;
        },
        exportarcsv() {
          this.exportar = !this.exportar
        },
        async cargargestortipos() {
            if(this.permisogestiontipos == null){
                const api = new PwgsApi();
                const datos = await api.get('parametros-pwgs/gestion_tipos');
                console.log("datostipos", datos);
                console.log("columnas4", this.columnas[4]);
                this.permisogestiontipos = datos.gestion_tipos;
            }
            if ( this.permisogestiontipos != 1) {
                this.columnasfinalizados.splice(3, 1);
                console.log("columnas4", this.columnas[4]);
                if (this.columnas[4].field == 'tipos_servicio') {
                    this.columnas.splice(4, 1);
                }
            }
        },
        async cargartiposservicio() {
            const api = new PwgsApi();
            const datos = await api.get('tipos-servicio');
            this.tiposservicio = datos.datos;
        },
        async obtenerEstadosPendientes() {
            //GET https://api.pwgs.apuyen/modulos/pwgsapi/index.php/tipos-estado-pendiente
            const api = new PwgsApi();
            let aux = await api.get('listados-servicios/estados-pendientes');
            for(let dat of Object.values(aux.datos)){
                var est = {"id":dat.id_estado, "nombre":dat.nombre};
                this.estadospendientes.push(est);
            }
        },
        async mostrardatosbasicos(id) {
            const api = new PwgsApi();
            this.datosbasicosamostrar = await api.get('servicios/' + id + '/datos-basicos');
            this.visible = true;            
        },
        filtraroperarioseleccionado() {
            this.mievento["page"]=1;
            this.mievento["first"]=0;
            localStorage.setItem('operarioseleccionado', JSON.stringify(this.operarioseleccionado));
            if (this.operarioseleccionado) {
                this.obtenerExpedientes(this.mievento);
            } else {
                delete this.mievento["ids_operarios"];
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos15");
            }
        },
        filtrartiposservicioseleccionado() {
            this.mievento["page"]=1;
            this.mievento["first"]=0;
            localStorage.setItem('tiposservicioseleccionado', JSON.stringify(this.tiposservicioseleccionado));
            if (this.tiposservicioseleccionado) {
                this.obtenerExpedientes(this.mievento);
            }
            else {
                delete this.mievento["id_tipo"];
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos13");
            }
        },
        filtrarestadopenselec() {
            this.mievento["page"]=1;
            this.mievento["first"]=0;
            console.log("estadopenselec1", this.estadopenselect);
            if (this.estadopenselec) {
                console.log("estadopenselec2", this.estadopenselect);
                localStorage.setItem('estadopenselec', JSON.stringify(this.estadopenselec));
                this.obtenerExpedientes(this.mievento);
            }
            else {
                localStorage.setItem('estadopenselec', null);
                delete this.mievento["id_esp_pend"];
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos9");
            }
        },
        filtrartipodanoseleccionado() {
            this.mievento["page"]=1;
            this.mievento["first"]=0;
            localStorage.setItem('tipodanoseleccionado', JSON.stringify(this.tipodanoseleccionado))

            if (this.tipodanoseleccionado) {
                this.obtenerExpedientes(this.mievento);
            }
            else {
                delete this.mievento["tipo_dano"];
                localStorage.removeItem('estadopenselec');
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos11");
            }
        },
        onPage(evnt){
            localStorage.setItem("pageselec", evnt.page);
            localStorage.setItem("numdats", evnt.rows);
            console.log("evvvv", evnt);
        },
        filtrarcompsele() {
            this.mievento["page"]=1;
            this.mievento["first"]=0;
            localStorage.setItem("compsele", JSON.stringify(this.compsele));

            if (this.compsele) {
                this.obtenerExpedientes(this.mievento);
            }
            else {
                delete this.mievento["id_compania"];
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos7");
            }
        },
        filtrartramitadoreseleccionado() {
            this.mievento["page"]=1;
            this.mievento["first"]=0;
            localStorage.setItem('tramitadoreseleccionado', JSON.stringify(this.tramitadoreseleccionado));
            if (this.tramitadoreseleccionado) {
                this.obtenerExpedientes(this.mievento);
            }
            else {
                delete this.mievento["id_tramitador"];
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos17");
            }
        },
        componenteDinamico(ruta) {
            return defineAsyncComponent(() => import(`@/${ruta}`));
        },
        nuevoservicio() {
            this.$router.push({ name: 'Crear Servicio' });
        },
        async eliminarservicio(id) {
            //PUT /servicios/:id/elimina
            const api = new PwgsApi();
        try{
            await api.put('servicios/' + id + '/elimina');
            this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Servicio eliminado correctamente', life: 2000 });
            this.obtenerExpedientes();
        }
        catch (error) {
            this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
        }
        },
        async cargarTramitadores() {
            //GET pwgsapi/index.php/usuarios/tramitadores
            const api = new PwgsApi();
            const datos = await api.get('usuarios/gestion');            
            this.tramitadores = datos.datos;
            let tramitadorcero = { id: "0",nombre:"Sin Tramitador"};
            this.tramitadores.unshift(tramitadorcero);
        },
        async cargarOperarios() {
            //GET pwgsapi/index.php/usuarios/tramitadores
            const api = new PwgsApi();
            const datos = await api.get('operarios/simple?sortField=nombre&sortOrder=1');            
            this.operarios = datos.datos;
            let operariocero = { id: "0",nombre:"Sin Operario"};
            this.operarios.unshift(operariocero);
        },
        async recuperarservicio(id) {
             //PUT /servicios/:id/recupera
            const api = new PwgsApi();
            try{
                await api.put('servicios/' + id + '/recupera');
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Servicio recuperado correctamente', life: 2000 }); 
                this.$router.push({
                    name: 'Servicio',
                    params: {
                        id: id
                    }
                });            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        cargarSatisfacciones(){
            this.satisfacciones=[
                {nombre:'0: Neutro', valor:'0'},
                {nombre:'1: Muy insatisfecho', valor:'1'},
                {nombre:'2: Poco satisfecho', valor:'2'},
                {nombre:'3: Satisfecho', valor:'3'},
                {nombre:'4: Muy satisfecho', valor:'4'}
            ]
        },
        async comprobarPermisos(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const permisos = await api.get('/usuarios/'+auxusuario+'/permisos');
            for(let permiso of permisos.datos){
                this.permisosusuario.push(permiso.nombre_permiso);
            }
            console.log('perms', this.permisosusuario);
        },
        async obtenerlineas(){
            const api = new PwgsApi();
            const resp = await api.get('lineas-facturacion');
            this.lineasFacturacion = resp.datos;
        },
        async cargarCodexp() {
            const api = new PwgsApi();
            this.parametrocodexp = await api.get('parametros-pwgs/ocultar_codigo_expediente_asegurado_operario');
            console.log("parametro", this.parametrocodexp);
            if (this.parametrocodexp.ocultar_codigo_expediente_asegurado_operario == 1) {
                let aux = {
                    header: 'Cod. servicio',
                    field: 'idservicios',
                    sortable: false,
                };
                this.columnas.splice(2, 1, aux);
                this.columnasfinalizados.splice(2, 1, aux);
            }
        },
    },
    mounted() {
        this.tipofacturaselec = localStorage.getItem('tipofacturaselec') ?? '';
        this.lineafacturaselec = localStorage.getItem('lineafacturaselec') ?? '';
        this.numerofacturaselec = localStorage.getItem('numerofacturaselec') ?? '';
        var numfacsel = localStorage.getItem('numerofacturasels') ?? '';
        console.log('numfasel', numfacsel);
        this.añofacturaselec = parseInt(localStorage.getItem('añofacturaselec'))?? '';
        this.importedesdefactura = parseInt(localStorage.getItem('importedesdefactura'))?? '';
        this.importehastafactura = parseInt(localStorage.getItem('importehastafactura'))?? '';
        this.enviadasfacturaselec = localStorage.getItem('enviadasfacturaselec')?? '';
        this.cobradasfacturaselec = localStorage.getItem('cobradasfacturaselec')?? '';
        this.tramitadoreseleccionado = JSON.parse(localStorage.getItem('tramitadoreseleccionado'))?? '';
        this.operarioseleccionado = JSON.parse(localStorage.getItem('operarioseleccionado'))?? '';
        this.tiposservicioseleccionado = JSON.parse(localStorage.getItem('tiposservicioseleccionado'))?? '';
        this.tipodanoseleccionado = JSON.parse(localStorage.getItem('tipodanoseleccionado'))?? '';
        this.estadopenselec = JSON.parse(localStorage.getItem('estadopenselec'))?? '';
        this.satisfaccionselec = JSON.parse(localStorage.getItem('satisfaccionselec'))?? '';
        console.log("stobt2", this.satisfaccionselec);
        //this.pageselec = parseInt(localStorage.getItem('pageselec'));
        //this.numdatos = parseInt(localStorage.getItem('numdats'));
        //console.log('pageselec',this.pageselec);
        this.mostrar = true;
        this.obtenerlineas();
        this.obtenerEstadosPendientes();
        this.cargartiposdanyo();
        this.cargartiposservicio();
        this.cargarTramitadores();
        this.cargarOperarios();
        this.cargarSatisfacciones();
        if(this.satisfaselec>-1){
            console.log('entrasatiscc',this.satisfaselec);
            this.satisfaccionselec = this.satisfaselec;
        }
    },
    activated() {
        if (this.compsele == '') {
            let aux;
            aux = localStorage.getItem('compsele');
            try {
                this.compsele = '';
                this.compsele = JSON.parse(aux);
                console.log("compsele2", this.compsele);
            }
            catch(error){console.log("error",error)}
        }
    },
    watch: {
        provinciabuscar(){
            this.mievento["first"]=0;
            this.mievento["page"]=1;
        },
        tipofacturaselec(value){
            if(value == ''){
                delete this.mievento["tipo_factura"]
            }
                localStorage.setItem("tipofacturaselec", value);
        },
        lineafacturaselec(value){
            if(value == ''){
                delete this.mievento["id_serie_factura"]
            }
                localStorage.setItem("lineafacturaselec", value);
            
        },
        numerofacturaselec(value){
            if(value == ''){
                delete this.mievento["numero_factura"]
            }
                localStorage.setItem("numerofacturaselec", value);
            
        },
        añofacturaselec(value){
            if(value == ''){
                delete this.mievento["year_factura"]
            }
                localStorage.setItem("añofacturaselec", value);
            
        },
        importedesdefactura(value){
            if(value == ''){
                delete this.mievento["importe_desde_factura"]
            }
                localStorage.setItem("importedesdefactura", value);
            
        },
        importehastafactura(value){
            if(value == ''){
                delete this.mievento["importe_hasta_factura"]
            }
                localStorage.setItem("importehastafactura", value);
            
        },
        enviadasfacturaselec(value){
            if(value == ''){
                delete this.mievento["enviada_factura"]
            }
                localStorage.setItem("enviadasfacturaselec", value);
            
        },
        cobradasfacturaselec(value){
            if(value == ''){
                delete this.mievento["cobrada_factura"]
            }
                localStorage.setItem("cobradasfacturaselec", value);
            
        },
        satisfaselec(){
            this.satisfaccionselec = this.satisfaselec;
        },
        satisfaccionselec(){
            console.log('stobt', this.satisfaccionselec);
            localStorage.setItem('satisfaccionselec', JSON.stringify(this.satisfaccionselec));
            

            if(this.satisfaccionselec){
                this.obtenerExpedientes(this.mievento);

            }else{
                delete this.mievento["satisfaccion"];
                this.obtenerExpedientes(this.mievento);

            }
        },
        opcionseleccionada() {
            this.obtenerExpedientes(this.mievento);
        },

        selectcompany() {
            this.compsele = this.$props.selectcompany;
            console.log("12345");
            this.obtenerExpedientes(this.mievento);
        },
       /* compsele() {
            localStorage.setItem("compsele", JSON.stringify(this.compsele));

           if(this.compsele){
               this.obtenerExpedientes(this.mievento);



           }
           else{
               delete this.mievento["id_compania"];
               this.obtenerExpedientes(this.mievento);
               console.log("listados-servicios/activos7");



           }
          
        },*/
     /*   estadopenselec() {
            localStorage.setItem('estadopenselec', JSON.stringify(this.estadopenselec));

            if (this.estadopenselec) {
                this.obtenerExpedientes(this.mievento);


            }
            else {
                delete this.mievento["id_esp_pend"];
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos9");


            }
            //id_esp_pend
        },*/
       /* tipodanoseleccionado() {
            localStorage.setItem('tipodanoseleccionado', JSON.stringify(this.tipodanoseleccionado))

            if (this.tipodanoseleccionado) {

                this.obtenerExpedientes(this.mievento);


            }
            else {
                delete this.mievento["tipo_dano"];
                this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos11");



            }
        },*/
     /*   tiposservicioseleccionado() {
            localStorage.setItem('tiposservicioseleccionado', JSON.stringify(this.tiposservicioseleccionado));

            if (this.tiposservicioseleccionado) {
                this.obtenerExpedientes(this.mievento);


            }
            else {
                delete this.mievento["id_tipo"];
               // this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos13");

            }
        },*/
      /*  operarioseleccionado() {
            localStorage.setItem('operarioseleccionado', JSON.stringify(this.operarioseleccionado));


            if(this.operarioseleccionado){
                this.obtenerExpedientes(this.mievento);

            }else{
                delete this.mievento["ids_operarios"];
               // this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos15");

            }
        },*/
        /*tramitadoreseleccionado() {
            localStorage.setItem('tramitadoreseleccionado', JSON.stringify(this.tramitadoreseleccionado));

            if (this.tramitadoreseleccionado) {
                this.obtenerExpedientes(this.mievento);


            }
            else {
                delete this.mievento["id_tramitador"];
               // this.obtenerExpedientes(this.mievento);
                console.log("listados-servicios/activos17");


            }
        }*/
    },
    computed: {
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
.p-datatable-header{
    visibility:hidden;
}
/*.ui-datatable tr.ui-datatable-even:hover, .ui-datatable tr.ui-datatable-odd:hover ,.p-datatable-tbody tr:hover,.codigo:hover {

}*/
.miclase{
display: none;
}
.Gris{
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}
.Verde{
    background: #28a745 !important;
    border-color: #28a745 !important;
}
.Rojo{
    background-color: #e20909 !important;
    border-color: #e20909 !important;
}
.Amarillo{
    background-color: #ffcc00 !important;
    border-color: #ffcc00 !important;
}
.Naranja {
    background-color: #f97316 !important;
    border-color: #f97316 !important;
}
.Todos{
    Background-color: #007bff !important;
    border-color: #007bff !important;
}
.codigo{
    cursor: pointer !important;
}
.acciones{
    cursor: pointer !important;
}
.p-selectbutton .p-button.p-highlight{
    background: #007bff !important;
    border-color: #007bff !important;
    color: white !important;
}
.p-selectbutton .p-button{
    background: #f4f6f9 !important;
    border-color: grey !important;
    color: black !important;
}
.p-dropdown-panel {
    border: 1px solid;
    color: #c1c1c1;
}
.pi-filter-icon {
    display: none !important;
}

.p-column-filter-menu-button.p-link {
    display: none !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    display: none !important;
}
.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
.p-chip{
    background-color: #007bff;
    color: #f0f7ff;
    border-radius: 16px;
    padding: 0 0.75rem;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>