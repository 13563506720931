<template>

    <div class="row">
        <div class="col-md-12">
            <strong>{{ $t('general.descripcion') }}</strong>
            <p v-html="servicio.danos"></p>
        </div>
        <div class="col-md-12">
            <strong>{{ $t('general.notaimportante') }}</strong>
            <p v-html="servicio.nota_importante"></p>
        </div>
        <div v-if="servicio.limite_autonomia" class="col-md-12">
            <strong>Límite autonomía</strong>
            <p v-html="servicio.limite_autonomia"></p>
        </div>
         <div class="col-md-12">
            <strong>Observaciones</strong>
            <p v-html="observaciones"></p>
        </div>
        <div class="col-md-5">
            <strong>{{ $t('general.codigo') }}</strong>
            <p>{{servicio.codigo}}</p>
        </div>
        <div class="col-md-5">
            <strong>{{ $t('general.naviso') }}</strong>
            <p>{{servicio.n_aviso}}</p>
        </div>
        <div class="col-md-2">
            <strong>{{ $t('general.anyo') }}</strong>
            <p>{{siniestro.anno_servicio }}</p>
        </div>
        <div class="col-md-6">
            <strong>{{ $t('general.compania') }}</strong>
            <p>{{servicio.compania.nombre}}</p>
        </div>
        <div class="col-md-6">

            <strong>{{ $t('general.companiacorreduria') }}</strong>
            <p></p>
        </div>
        <div class="col-md-12">
            <strong>{{ $t('general.empresafactura') }}</strong>
            <p>{{ servicio.empresa_emisor_factura.nombre_empresa }}</p>
        </div>
    </div>

</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default {
    props: ['id', 'servicio', 'guardartoken','servicioext'],
    components: {
    },
    data() {
        return {
            siniestro: [],
            observaciones:'',
        }
    },
    methods: {
             
    async cargarservicio() {
        //get  modulos/pwgsapi/index.php/servicios/:id
        const api = new PwgsApi;
        const datos = await api.get("servicios/" + this.$props.id);
        this.siniestro = datos.datos;
        this.observaciones = datos.datos.observaciones;

    },

    },
    mounted() {
        this.cargarservicio();
        
    },
    watch: {
        id() {
            this.cargarservicio();

        },
       
    }
}
</script >


