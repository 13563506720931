<template>
    <div class="row">
        <div class="col-md-6">
            <seccion-datos titulo="Parámetros" @cambios="guardarDatos()" class="card card-success">
                
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th>Carga trabajo</th>
                                    <th>Calidad</th>
                                    <th>Tiempo trabajo</th>
                                    <th>Coste medio</th>
                                    <th>Incidencias</th>
                                </tr>
                            </thead>
                            <tbody>                                
                                <tr>
                                   <td>
                                        <div style="display: flex; align-items: center;">
                                            <input class="form-control form-control-sm" type="number" v-model="datosoperario.carga_operario">
                                            <span>%</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input class="form-control form-control-sm" type="number" v-model="datosoperario.calidad_operario">
                                            <span>%</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input class="form-control form-control-sm" type="number" v-model="datosoperario.tiempo_medio_operario">
                                            <span>días</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input class="form-control form-control-sm" type="number" v-model="datosoperario.coste_medio_operario">
                                            <span>€</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input class="form-control form-control-sm" type="number" v-model="datosoperario.incidencias_operario">
                                            <span>%</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';

export default {
    components: {
  
    },
    props: ['id', 'operario'],
    data() {
        return {      
            datosoperario:'',      
        }
    },
    methods: {
        async guardarDatos(){
            const api = new PwgsApi();
            try{
                await api.put('operarios/'+this.id, {
                    carga_operario:this.datosoperario.carga_operario, 
                    calidad_operario:this.datosoperario.calidad_operario, 
                    tiempo_medio_operario:this.datosoperario.tiempo_medio_operario, 
                    coste_medio_operario:this.datosoperario.coste_medio_operario, 
                    incidencias_operario:this.datosoperario.incidencias_operario  
                })
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos actualizados correctamente', life: 2000 });
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        }
    },
    watch: {
        operario(){
            this.datosoperario = this.operario;
        }
    },
    mounted() {
        if(this.operario){
            this.datosoperario = this.operario;
        }
    }
}
</script>