<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>Lista Condiciones Presupuestos</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-10">
                    <tabla-datos :columnas="columnas" :datos="condiciones_presupuestos" :filtros="filtros" :filtrosBuscador="filtrosBuscador"
                        :total="total" @quieroDatos="obtenerCondicionesPresupuestos($event)">
                    </tabla-datos>
                </div>
            </div>
        </section>
    </div>
    
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import TablaDatos from '../../../TablaDatos.vue';

export default {
    components: {
        "tabla-datos": TablaDatos,
    },
    data() {
        return {
            total: '',
            condiciones_presupuestos: [],
            parametros: null, //query params de DataTable
            filtrosBuscador: ['texto'], //filtros para el buscador
            filtros: { //filtros de columna
                'texto': { value: '', matchMode: 'contains' }
            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'texto',
                    sortable: true,
                },
                {
                    header: 'Defecto',
                    componente: {
                        ruta: 'components/pages/configuracion/condicionespresupuestos/CondicionDefecto.vue',
                        nombre: 'CondicionDefecto',
                        field: 'defecto',
                        props: {
                            checked: (datos) => {
                                return datos.defecto == '1'
                            }
                        }
                        
                    }
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                
                                this.$router.push({
                                    name: 'Condición Presupuesto',
                                    params: {
                                        id
                                    }
                                });
                                
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar la especialidad pendiente ' + id);
                                if (confirm('Desea eliminar la especialidad pendiente')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    methods: {
        async obtenerCondicionesPresupuestos(parametros) {
            const api = new PwgsApi;
            const params = parametros;
            const aux = await api.get('condiciones', params);
            this.condiciones_presupuestos = aux.datos;
            this.total = aux.n_total_registros;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[2].header = this.$t("general.acciones");
        },
        async eliminar(id) {
            console.log('eliminando estado pendiente ' + id + '...');
            const api = new PwgsApi;
            await api.delete('condiciones/' + id);
            this.obtenerCondicionesPresupuestos();
        },
    },
    mounted() {
        this.obtenerCondicionesPresupuestos();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>