<template>
    <div class="row">
        <div class="col-md-6">
            <seccion-datos titulo="Códigos postales" :botonGuardar="false" class="card card-info">
                <div style="display: flex; justify-content: end;"  v-if="modificar == '1'">
                   <div class="input-group input-group-sm" style="margin-top: 20px; margin-bottom: 0px; width: auto;">
                        <input type="text" class="form-control" placeholder="Código postal" v-model="codigoPostal">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary btn-flat" @click="añadircodigo()">Añadir</button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-sm table-striped">
                            <tbody>                                
                                <tr v-for="codigo in listado_codigos" :key="codigo.id_operario_cp">
                                    <td align="left"> {{ codigo.codigo_postal }}</td>                                    
                                    <td align="right"  v-if="modificar == '1'">
                                        <div class="col-xs-4" style="width:60px">
                                            <button type="button" class="btn btn-block btn-outline-danger btn-xs"
                                                @click="eliminarcodigo(codigo)">{{
                                                $t('general.eliminar')}}</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';

export default {
    components: {
    },
    props: ['id', 'operario'],
    data() {
        return {
            listado_codigos: [],
            codigoPostal:'',
            modificar: '1',
        }
    },
    methods: {     

        async cargarcodigosoperario() {
            //GET  modulos/pwgsapi/index.php/operarios/:id/especialidades
            const api = new PwgsApi();
            let aux;
            aux = await api.get('operarios/' + this.$props.id + '/codigos-postales');
            this.listado_codigos = aux.datos;
            console.log('cps',this.listado_codigos);
        },
        async eliminarcodigo(codigo) {
            //DELETE  modulos/pwgsapi/index.php/operarios/:id/especialidades/:id_especialidad
            const api = new PwgsApi();
            await api.delete('operarios/' + this.$props.id + '/codigos-postales/' + codigo.codigo_postal);
            this.cargarcodigosoperario();
        },
        async añadircodigo() {
            //post   modulos/pwgsapi/index.php/operarios/:id/especialidades
            if(this.codigoPostal == ''){
                alert("Debes añadir un código postal.")
                ;return;
            }
            const api = new PwgsApi();
            await api.post('operarios/' + this.$props.id + '/codigos-postales',{cp: this.codigoPostal});
            this.cargarcodigosoperario()
        },
        async comprobarModificar(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const mod = await api.get('operarios/'+auxusuario+'/codigos-postales')
            console.log('mod', mod, this.operario);
            if(mod){
                this.modificar = mod.modificar;
            }
        },
    },
    watch: {
        id() {
            this.cargarcodigosoperario();
        }
    },
    mounted() {
        this.comprobarModificar();
        this.cargarcodigosoperario();
    }
}
</script>