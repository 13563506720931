<template>
    <div class="card-footer" v-if="dats">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend ">
                                <span class="input-group-text">{{ $t('general.total') }} {{ $t('general.cliente') }}</span>
                            </div>
                            <input style="text-align: end" type="text" class="form-control" :value="dats.totales.cliente + '&euro;'" readonly>
                            <!--
                                <input type="text" v-if="operario.Compañia "  class="form-control" v-model="operario.totales.cliente"  readonly>
                                <input type="text" v-if="operario._"  class="form-control" v-model="operario.totales.cliente"  readonly>
                                <input type="text" v-else class="form-control"  readonly>
                            -->                        
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ $t('general.total') }} {{ $t('general.coste') }}</span>
                            </div>
                            <input type="text" style="text-align: end" :value="formattedOperarioTotal(dats.totales.operario.base, dats.totales.operario.contado)"   class="form-control" readonly>
                            <!--<input type="text" v-if="operario.Compañia" v-model="operario.totales.operario.base" class="form-control" readonly>
                            <input type="text" v-if="operario._" v-model="operario.totales.operario.base" class="form-control" readonly>-->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{ $t('general.rentabilidad')}}</span>
                            </div>
                            <input type="text" style="text-align: end" :value="dats.totales.beneficio.importe + '&euro;'" class="form-control" readonly>
                            <input type="text" style="text-align: end" :value="dats.totales.beneficio.porcentaje + '%'" class="form-control" readonly :title="$t('general.seleccionado') + ' ' + $t('general.para facturar')">
                        </div>
                    </div>
                </div>
            </div>
</template>
<script>
    export default({
        props:[
        'datos',
        'actualizar',
    ],
    data() {
        return {
            dats:'',
        };
    },
    methods:{
        tototo(){
            console.log('llegahola', this.dats);
        },
        actualizatotales(id, resultados){
            console.log('llegafinal', this.dats);
            this.dats = resultados.operario[id];
            console.log('llegafinal', this.dats);
        },
        formattedOperarioTotal(base, contado) {
            if(base){
            let baseFormatted = `${base}€`;
            if (contado && contado > 0) {
                baseFormatted += ` (A operario ${contado}€)`;
            }
            return baseFormatted;
            }
        }
    },
    mounted(){
        this.dats = this.datos;
        console.log('datss', this.dats);
    },
    watch:{
    }
    })
</script>