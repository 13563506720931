<template>
    <div class="row">
        <div class="col-md-6">
            <!-- Inicio Datos !-->
            <compania-datos-basicos v-if="esNuevaCia" />
            <compania-datos-basicos v-else :datos="datos"/>
            <!-- Fin Datos -->

            <!-- Inicio Acceso usuario -->
            <compania-datos-acceso-usuario v-if="!esNuevaCia" :datos="datos" />
            <!-- Fin Acceso usuario -->

        </div>
        <div class="col-md-6">
            
            <!-- Inicio Opciones -->
            <compania-datos-opciones 
                v-if="!esNuevaCia"
                v-bind="$props"
            />
            <!-- Fin Opciones -->
        </div>
        
    </div>
</template>


<script>
//import { PwgsApi } from '../../../services/PwgsApi.js';
import CompaniaDatosBasicos from './secciones/CompaniaDatosBasicos.vue'
import CompaniaDatosOpciones from './secciones/CompaniaDatosOpciones.vue'
import CompaniaDatosAccesoUsuario from './secciones/CompaniaDatosAccesoUsuario.vue'

export default {
    components: {
        CompaniaDatosBasicos,
        CompaniaDatosOpciones,
        CompaniaDatosAccesoUsuario,
    },
    props: {
        datos: {
            type: Object,
        },
        id: {
            default: 0
        }
    },
    computed: {
        esNuevaCia() {
            return this.id == 0;
        }
    },
    methods: {
        /*async guardar(datos) {
            this.$emit('cambios', datos);
        }*/
    }
}
</script>