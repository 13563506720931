<template>
    <li class="nav-item dropdown ">
        <a :class="aauxserv" data-toggle="dropdown" @click="activo()" id="av" href="#">
            <i class="far fa-bell"></i>&nbsp;{{ tipoaviso }}&nbsp;
            <span class="badge badge-danger ">{{numero_avisos}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
            style="height: auto; max-height: 90vh; overflow-x: hidden; min-width: 400px;">
            <div class="dropdown-divider"></div>
            <ListaAvisos :avisos="avisos" v-on:evento_expediente_avisos_tipo="cargarExpedienteAvisos" />
        </div>
    </li>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import ListaAvisos from './AvisosLista.vue';


export default({
    components:{
        ListaAvisos
    },
    props:{
        tipo_servicio: String
    }
    ,data() {
        return {
            avisos: [],
            numero_avisos: '',
            tipoaviso: '',
            aauxserv: 'nav-link'
        }
    },
    methods: {
        async cargardatos() {
            /* get  modulos/pwgsapi/index.php/avisos */
            const api = new PwgsApi;
            const datos = await api.post('avisos');
            this.avisos = datos.datos;
            this.numero_avisos = datos.n_total_avisos;
            console.log('avss', this.avisos);
        },
        removeractivos() {
            try{
                this.$parent.removeractivo();
            }
            catch (error) {
                console.log("error");
            }
            this.aauxserv = 'nav-link active';
            document.getElementById('av').className = 'nav-link active';


        },    
        activo() {
          //  setTimeout(() => {
            console.log("asdfgh");
                this.aauxserv = 'nav-link';
        //    }, 2000);
      },
        cargarExpedienteAvisos: function(tipo,titulo){
            //alert(tipo);
            this.tipoaviso = titulo;

            console.log("tipoavisoselec", tipo);
            localStorage.setItem("tipobuscador", tipo);
            this.$router.push({ name: 'Escritorio', query: { tipo_buscador: tipo } })
            this.removeractivos();
            this.aauxserv = 'nav-link active';
            document.getElementById('av').className = 'nav-link active';

           // this.$emit('evento_expediente_avisos_tipo', tipo); //Es llamado a ./Escritorio.vue
        }
    

    },
    mounted() {
        this.cargardatos();
    },
   
    watch: {
        tipo_servicio() {
            console.log("123saddd");
            this.aauxserv = 'nav-link active';
            document.getElementById('av').className = 'nav-link active';
           

        }
    }
})
</script>
