<template>
    <div class="card" v-if="servicio">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-file-invoice mr-2"></i>{{$t('general.facturar')}}</h3>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="card-body" v-if="sacarerror==true">
            <div class="alert alert-danger" role="alert">
                <h4>{{ errorvalidar }}</h4>
            </div>
        </div>
        <div class="card-body" v-if="sacarerror==false && servicio.empresa_emisor_factura">
            <div class="alert alert-danger" role="alert" v-if="servicio.reasignado==1">
                <h5>EXPEDIENTE REASIGNADO, ¿DESEA FARCTURARLO IGUALMENTE?</h5>
                <div class="custom-control custom-switch ">
                    <input type="checkbox" @click="finalizarsinfacturar()" checked class="custom-control-input"
                        id="facturar123">
                    <label class="custom-control-label" for="facturar123">{{textoreasignado}}</label>
                </div>
            </div>
            <TabView v-if="textoreasignado=='Si'">
                <TabPanel v-for="destinatariofactura of Object.entries(listatrabajosfacturar)"
                    :key="destinatariofactura" :header="destinatariofactura[0].replace(/_/g, ' ')">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card card-widget">
                                <div class="card-body">
                                    <h4>{{ servicio.empresa_emisor_factura.nombre_empresa }}<br><small>CIF: {{
                                            servicio.empresa_emisor_factura.nif_empresa }}</small></h4>
                                    <p>
                                        {{ servicio.empresa_emisor_factura.direccion_empresa }}<br>
                                        {{ servicio.empresa_emisor_factura.codigo_postal_empresa }} {{
                                        servicio.empresa_emisor_factura.ciudad_empresa }} ({{
                                        servicio.empresa_emisor_factura.provincia_empresa
                                        }})<br>
                                        Telf: {{ servicio.empresa_emisor_factura.telefono_empresa }}<br>
                                        Email: {{ servicio.empresa_emisor_factura.email_empresa }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card card-widget">
                                <div class="card-body">
                                    <h4>{{ destinatariofactura[1].datos_facturacion.nombre }}<br><small>CIF: {{
                                            destinatariofactura[1].datos_facturacion.nif }}</small></h4>
                                    <p>
                                        {{ destinatariofactura[1].datos_facturacion.direccion }}<br>
                                        {{ destinatariofactura[1].datos_facturacion.codigo_postal }} {{
                                        destinatariofactura[1].datos_facturacion.poblacion }} ({{
                                        destinatariofactura[1].datos_facturacion.provincia }})<br>
                                        Telf: {{ destinatariofactura[1].datos_facturacion.telefono1}}<br>
                                        Email: {{destinatariofactura[1].datos_facturacion.email}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="renderdirecciones==true">
                        <div class="col-md-12">
                            <div class="card card-widget">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                              <b>El asegurado tiene estas otras direcciones. Desea facturar a alguna de ellas? </b>  
                                        </div>
                                        <div class="col-md-12">
                                            <select class="form-control" v-model="direccion">
                                                    <option value="defecto">Facturar a la direccion por defecto</option>
                                                    <option :value="direcciones.direccion" v-for="direcciones in datosfacturacion.direcciones_facturacion" :key="direcciones">
                                                        {{ direcciones.direccion }}&nbsp;,&nbsp;{{direcciones.poblacion}}&nbsp;{{direcciones.cp}}&nbsp;({{direcciones.provincia}})
                                                    
                                                    </option>
                                                </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-widget">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">{{ $t('general.tipo') }}</label>
                                                <select class="form-control" v-model="tipo_factura">
                                                    <option value="factura">{{ $t('general.factura') }}</option>
                                                    <option value="albaran"
                                                        v-if="datosfacturacion.tipo_factura_albaran == 1">{{
                                                        $t('general.albaran') }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Serie</label>
                                                <select class="form-control" v-model="serieseleccionada">
                                                    <option v-for="serie in datosfacturacion.series" :key="serie"
                                                        :value="serie.datos.id_serie">
                                                        {{ serie.datos.nombre_serie }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3" v-if="tipo_factura == 'factura'">
                                            <div class="form-group" style="margin-top:2rem">
                                                <div class="custom-control custom-switch ">
                                                    <input type="checkbox" v-model="obligsignumero" class="custom-control-input" id="siguientenumero">
                                                    <label class="custom-control-label" for="siguientenumero">Siguiente Nº factura</label>
                                                </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-if="tipo_factura == 'factura' && datosfacturacion.establecer_numero_factura == 1 ">
                                        <div class="form-group"
                                            >
                                            <label>Nº</label><br>
                                            <Dropdown editable :disabled="obligsignumero" optionLabel="numero"  v-model="numeroseleccionado" 
                                            :options="listahuecos" emptyFilterMessage="No hay huecos disponibles">
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-else-if="tipo_factura == 'factura'">
                                        <div class="form-group"
                                            >
                                            <label>Nº</label><br>
                                            <input type="text" class="form-control" v-model="siguientenumero" disabled>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-if="tipo_factura == 'factura'">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">{{ $t('general.anyo') }}</label>
                                            <select class="form-control" v-model="anyo">
                                                <option v-for="anyos in datosfacturacion.years" :key="anyos">
                                                    {{ anyos }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"
                                                >{{
                                                $t('general.fecha') }}</label>
                                            <input :disabled="fechadeshab || datosfacturacion.establecer_fecha_factura != 1" type="date" v-model="fechaseleccionada"
                                                class="form-control input-sm">
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="tipo_factura=='factura'">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">{{ $t('general.forma de pago')
                                                }}</label>
                                            <select class="form-control" v-model="pagoseleccionado">
                                                <option value="">Seleccione forma de pago</option>
                                                <option v-for="pago in datosfacturacion.formas_pago" :value="pago"
                                                    :key="pago">
                                                    {{ pago }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="pagoseleccionado == 'Giro Bancario' || pagoseleccionado == 'Transferencia Bancaria'">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">{{ $t('general.cuenta bancaria')
                                                }}</label>
                                            <select class="form-control" v-model="cuentabancariaseleccionada">
                                                <option value="">Seleccione número de cuenta</option>
                                                <option v-for="cuenta in datosfacturacion.cuentas_bancarias" :key="cuenta.id_cuenta" :value="cuenta.numero">
                                                    {{ cuenta.numero }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" v-if="vencimientoactivado">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">{{ $t('general.vencimiento') }}</label>
                                            <input type="date" v-model="vencimiento" class="form-control input-sm">
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="datosfacturacion.establecer_codigo_cliente">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">{{ $t('general.codigo cliente')
                                                }}</label>
                                            <input type="text" v-model="codigocliente" class="form-control input-sm">
                                        </div>
                                    </div>
                                    <div class="col-md-4" v-if="datosfacturacion.sistema_compania.servicios_relacionados">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">{{ $t('general.gremio albaran')
                                                }}</label>
                                            <select v-model="especialidadseleccionada" class="form-control">
                                                <option value="">{{ $t('general.seleccionar') }}</option>
                                                <option v-for="grm in datosfacturacion.sistema_compania.servicios_relacionados" :key="grm">
                                                    {{ grm }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="col-md-12">
                        <div class="card card-widget">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">{{ $t('general.descripcion') }}</label>
                                            <textarea class="form-control" v-model="descripcionfactura" rows="3"
                                                :placeholder="$t('general.descripcion factura')"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <div class="custom-control custom-switch ">
                                                <input type="checkbox" @click="ensenyarfotos=!ensenyarfotos"
                                                    class="custom-control-input" id="agregar_fotos_factura">
                                                <label class="custom-control-label" for="agregar_fotos_factura">{{
                                                    $t('general.agregar fotos') }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="ensenyarfotos">
                                        <facturacionfotos />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>        
                    <div class="card card-widget" style="width: 100%;">
                        <div class="card-body">                            
                            <div class="col-md-12">
                                <TabView v-if="datosfacturacion.equipos">
                                    <TabPanel v-for="equipo in datosfacturacion.equipos" :key="equipo" :header="equipo.nombre_equipo">
                                        <ul>
                                            <li>
                                                Marca: {{equipo.marca}}
                                            </li>
                                            <li v-for="adicional in Object.entries(equipo.adicionales)" :key="adicional">
                                                {{adicional[0]}}:&nbsp;{{adicional[1]}}
                                            </li>
                                        </ul>
                                    </TabPanel>
                                </TabView>
                            </div>                            
                        </div>
                    </div>           
                    <facturacionnueva :listatrabajosfacturar="listatrabajosfacturar" :destinatario="destinatariofactura"
                        :ffac="ffac" :id="id" :facturar="facturar" :servicio="servicio" :tipo_factura="tipo_factura"
                        :serieseleccionada="serieseleccionada" :numeroseleccionado="numeroseleccionado"
                        :fechaseleccionada="fechaesp(fechaseleccionada)" :asegurado="asegurado" :vencimiento="vencimiento"
                        :codigocliente="codigocliente" :pagoseleccionado="pagoseleccionado"
                        :cuentabancariaseleccionada="cuentabancariaseleccionada" :descripcionfactura="descripcionfactura"
                        :especialidadseleccionada="especialidadseleccionada" :datosfacturacion="datosfacturacion" />
                    <div class="col-md-12" v-if="destinatariofactura[1].mostrarTPV==true">                        
                        <div class="card card-widget">
                            <div class="card-header">
                                <h3 class="card-title">Opciones de pago por TPV</h3>
                            </div>               
                            <div class="card-body">
                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                    <div>
                                        <label for="descripcionPago">Descripción de pago</label>
                                    </div>
                                    <div style="display: flex; flex-direction: column; justify-content: end;">
                                        <div class="row" style="justify-content: end;">
                                            <span>Descripción de baremo</span>
                                            <input style="margin-left: 10px;" v-model="dsc[destinatariofactura[0]]" value="1" type="radio">
                                        </div>
                                        <div class="row" style="justify-content: end;">
                                            <span>Introducir descripción</span>
                                            <input style="margin-left: 10px;" v-model="dsc[destinatariofactura[0]]" value="2" type="radio">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <textarea style="margin: 20px 0;width: 100%;" rows="4" v-model="descripcionPago[destinatariofactura[0]]" id="descripcionPago"></textarea>
                                </div>
                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                    <label for="chk">Generar factura cuando se reciba el pago</label>
                                    <input v-model="factura[destinatariofactura[0]]" type="checkbox" id="chk">
                                </div>
                                <div v-if="destinatariofactura[1].datos_facturacion.nif == ''" class="row" style="margin-top: 20px;justify-content: end;">
                                    <span style="background-color: #FF7575;">¡Atención! El asegurado/cliente no tiene NIF</span>
                                </div>
                                <div style="margin-top: 10px;margin-bottom: 10px;display: flex; justify-content: space-between;">
                                   <label for="">Enviar enlace de pago</label> 
                                   <div style="display: flex; flex-direction: column;">
                                        <div style="display: flex; justify-content: end;">
                                            <span>SMS</span>
                                            <input style="margin-left: 10px;" type="radio" id="sms" v-model="smsemail[destinatariofactura[0]]" value="1">
                                        </div>
                                        <div style="display: flex; justify-content: end;">
                                            <span>Email</span>
                                            <input style="margin-left: 10px;" type="radio" id="email" v-model="smsemail[destinatariofactura[0]]" value="2">
                                        </div>
                                   </div>
                                </div>
                                <div  class="row" style="justify-content: end;" v-if="destinatariofactura[1].datos_facturacion.nif == ''">                                    
                                    <button disabled title="Enviar pago (El asegurado/cliente debe tener NIF)"><i class="fas fa-credit-card"></i></button>
                                </div>
                                <div v-else class="row" style="justify-content: end;">
                                    <button @click="enviarPago(destinatariofactura)" title="Enviar pago"><i class="fas fa-credit-card"></i></button>
                                </div>
                            </div>
                        </div>                        
                    </div>
        </div>
        </TabPanel>
        </TabView>        

    </div>
    <div class="card-footer">
        <button v-if="listatrabajosfacturar && sacarerror==false && textoreasignado=='Si'"
            class="btn btn-block btn-primary" @click.stop="facturarfactura()" type="button">Crear factura/s</button>
    </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import facturacionnueva from './FacturacionNueva.vue';
import facturacionfotos from './FacturacionNueva/FacturacionNuevaFotos.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import $ from 'jquery';

export default {
    props: ['id', 'servicio','ffac'],
    components: {
        facturacionnueva,
        facturacionfotos,
        TabView,
        TabPanel,
        
},
    data() {
        return {
            smsemail:[],
            dsc:[],
            listatrabajosfacturar: '',
            facturar: false,
            rendercompania: true,
            renderasegurado: false,
            renderperjudicado: false,
            datosfacturacion: '',
            lineasnueva: '',
            fechaseleccionada: '',
            especialidadseleccionada: '',
            asegurado: '',
            siguientenumero: '',
            numeroseleccionado: '',
            serieseleccionada: '',
            vencimiento: '',
            vencimientoactivado:false,
            codigocliente: '',
            cuentabancariaseleccionada: '',
            pagoseleccionado: '',
            descripcionfactura: '',
            lineas: '',
            tipo_factura: 'factura',
            cuentas: [],
            items: [],
            ensenyarfotos:false,
            errorvalidar: '',
            sacarerror: null,
            checked: true,
            textoreasignado: 'Si',
            listahuecos:[],
            anyo: new Date().getFullYear(),
            obligsignumero: false,
            direccion:"defecto",
            fechadeshab:false,
            factura:[],
            descripcionPago:[],
            conceptoDespues:[],
            porcentajeDespues:[],
            valorDespues: [],
            renderdirecciones: false,        
        }
    },
    methods: {
            fechaing(fecha) {
                const [dia, mes, año] = fecha.split("/");
                return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
            },
            fechaesp(fecha) {
                const [año, mes, dia] = fecha.split("-");
                return `${dia}/${mes}/${año}`;
            },
            async enviarPago(datosFact){
                let datos = datosFact[1];
                let nombre = datosFact[0];
                const api = new PwgsApi();
                if(this.factura[nombre]){
                    let body = {'id_cliente':datos.datos_facturacion.idcliente, 'tipo_envio':this.smsemail[nombre], 'tipo_texto_pago':this.dsc[nombre],
                    'texto':this.descripcionPago[nombre], 'genera_factura':this.factura[nombre], 'descuento':this.porcentajeDespues[nombre], 
                    'descuento_importe':this.valorDespues[nombre], 'concepto_descuento_factura':this.conceptoDespues[nombre], 
                    'cuenta_bancaria':this.cuentabancariaseleccionada, 'anyo':this.anyo, 'fecha_factura':this.fechaseleccionada};
                    
                    try{
                        const resp = await api.put('facturas/'+this.id+'/enviar-pago-tpv',body);
                        
                        if(resp == 'OK') {
                            this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Pago enviado correctamente', life: 2000 });
                        }else{
                            this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'No se pudo enviar el pago', life: 5000 });
                        }
                    }catch(e){
                        this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'No se pudo enviar el pago', life: 5000 });
                    }
                }else{
                    
                    try{
                        const resp = await api.put('facturas/'+this.id+'/enviar-pago-tpv',{'id_cliente':datos.datos_facturacion.idcliente, 'tipo_envio':this.smsemail[nombre], 'tipo_texto_pago':this.dsc[nombre],
                        'texto':this.descripcionPago[nombre], 'genera_factura':this.factura[nombre]});
                        
                        if(resp == 'OK') {
                            this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Pago enviado correctamente', life: 2000 });
                        }else{
                            this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'No se pudo enviar el pago', life: 5000 });
                        }
                    }catch(e){
                        this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'No se pudo enviar el pago', life: 5000 });
                    }
                }
            },
            modificarConceptoDespues(concepto, nombre){
                this.conceptoDespues[nombre] = concepto;
            },
            modificarValorDespues(valor, nombre){
                this.valorDespues[nombre] = valor;
            },
            modificarPorcentajeDespues(porcentaje, nombre){
                this.porcentajeDespues[nombre] = porcentaje;
            },
           async cargardatos() {
            //POST  modulos/pwgsapi/index.php/facturas/:id_servicio/previo-facturacion
            const api = new PwgsApi();
            this.items = [];
            this.listatrabajosfacturar = [];
            this.listatrabajosfacturar = await api.post('facturas/' + this.$props.id + '/previo-facturacion');
            console.log('listatrabajosfacturar', this.listatrabajosfacturar);
            for (let tip of Object.entries(this.listatrabajosfacturar)) {
                let subidadatos = {label:tip[0]}
                this.items.push(subidadatos);
                let nombre = tip[0].split('_');
                if(nombre == 'Cliente' || nombre[0] == 'Perjudicado'){
                    this.valorDespues[tip[0]] = '';
                    this.porcentajeDespues[tip[0]] = '';
                    this.conceptoDespues[tip[0]] = '';
                    this.descripcionPago[tip[0]]= '';
                    this.factura[tip[0]] = false;
                    this.dsc[tip[0]] = 1;
                    this.smsemail[tip[0]] = 1;
                    let idcli = tip[1].datos_facturacion.idcliente;
                    let datosComun = await api.get('servicios/'+this.id+'/comunicaciones-ste/'+idcli);
                    if(datosComun.comunicaciones !=1 || datosComun.pasarela_pago !=1){
                        tip[1].mostrarTPV = false;
                    }
                    else{
                        tip[1].mostrarTPV = true;
                    }
                }else{
                    tip[1].mostrarTPV = false;
                }
            }
            console.log('mostraracabado');
            setTimeout(() => {
                this.mostrar();
            }, 1000);
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
        finalizarsinfacturar() {
            if(this.textoreasignado=='Si')
            { this.textoreasignado = "No"; }
            else{
                this.textoreasignado = "Si";
            }

        },

        async datoscliente() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/clientes
            this.asegurado = '';
            var clientdatos = '';
            const api = new PwgsApi();
            clientdatos = await api.get('servicios/' + this.$props.id + '/clientes');
            this.asegurado = clientdatos.asegurado;
        },
        async cargarCuentas() {
            const api = new PwgsApi();
            let aux = await api.get('bancos/0/cuentas');
            for (let i = 0; i < aux.datos.length; i++) {
                this.cuentas[i] = aux.datos[i].iban_cuenta + "-" + aux.datos[i].iban2_cuenta + "-" + aux.datos[i].entidad_cuenta + "-" + aux.datos[i].oficina_cuenta + "-" + aux.datos[i].control_cuenta + "-" + aux.datos[i].numero_cuenta;
            }
        },
        async cargardatosfactura() {
            //GET  modulos/pwgsapi/index.php/facturas/:id_servicio/validar-facturacion
            const api = new PwgsApi();
            try{
                this.datosfacturacion = await api.get('facturas/' + this.$props.id + '/validar-facturacion');
                console.log('datosfact', this.datosfacturacion);
                if(this.datosfacturacion.dias_vencimiento_defecto){
                    if(this.datosfacturacion.cuentas_bancarias.length > 0){
                        this.datosfacturacion.cuentas_bancarias.forEach(aux => {
                            var cuenta =  aux.iban_cuenta + "-" + aux.iban2_cuenta + "-" + aux.entidad_cuenta + "-" + aux.oficina_cuenta + "-" + aux.control_cuenta + "-" + aux.numero_cuenta;                    
                            aux.numero = cuenta;
                        });
                    }
                    this.pagoseleccionado = this.datosfacturacion.forma_pago_defecto;
                    this.cuentabancariaseleccionada = this.datosfacturacion.cuenta_bancaria_defecto;
                    this.vencimientoactivado = true;
                    this.vencimiento = this.addDaysToDate(this.fechaseleccionada, this.datosfacturacion.dias_vencimiento_defecto);
                }
                console.log('datosfact1', this.datosfacturacion);
                console.log('datosfact1', this.servicio);
                this.tipo_factura = this.datosfacturacion.tipo_factura_defecto;
                this.serieseleccionada = this.datosfacturacion.series[0].datos.id_serie;
                this.sacarerror = false;
            }
             catch (error) {
               // this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
               
                this.errorvalidar = error;
                this.sacarerror = true;
            }
            if (this.fechaseleccionada[9] == ",") { this.fechaseleccionada = this.fechaseleccionada.substring(0, 8) }
            if (this.datosfacturacion.direcciones_facturacion>0){
                this.renderdirecciones = true;
            }
        },
        async siguientefactura() {
            //POST  modulos/pwgsapi/index.php/facturas/:id_servicio/siguiente-numero
            const api = new PwgsApi();
            let datos;
            let subidadatos = {year:this.anyo}
            datos = await api.post('facturas/' + this.$props.id + '/siguiente-numero', subidadatos);
            if(this.serieseleccionada){
                this.siguientenumero = datos[this.serieseleccionada].numero;
                console.log('signum', this.siguientenumero);
                this.listahuecos = datos[this.serieseleccionada].huecos_serie;
            
            if (this.obligsignumero == true) {
                this.numeroseleccionado = this.siguientenumero;

            }
            if (this.obligsignumero == false) {
                this.numeroseleccionado = "";

            }
            }
        },
        facturarfactura() {
            console.log('entrafacturarfactura');
            this.facturar = !this.facturar;
            this.$parent.movertoggle();
        },
        comprobarsalto() {
            let modificado = true;
            for (let i = 0; i < this.siguientenumero.length; i++) {
                if (this.siguientenumero[i] == this.numeroseleccionado) {
                    modificado = false;
                }
            }
            if (modificado == true) {
                this.$toast.add({ severity: 'warn', summary: 'Cambio de serie', detail: 'Se va a producir un salto en la serie', life: 2000 });
            }
        },
        mostrar() {

            if (this.listatrabajosfacturar.Cliente){
                $('#' + this.$props.id + ' a[href="#custom-tabs-two-Cliente"]').tab('show');
            }
            else {
                $('#' + this.$props.id +' a[href="#custom-tabs-two-Compañia"]').tab('show');

            }

        },
        addDaysToDate(initialDate, daysToAdd) {
            // Convertir la fecha inicial a un objeto Date
            const date = new Date(initialDate);
            // Sumar los días
            date.setDate(date.getDate() + parseInt(daysToAdd));
            
            // Formatear la fecha resultante a yyyy-mm-dd
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            
            return `${year}-${month}-${day}`;
        },
        async facturacion(subidadatos) {
            //POST  modulos/pwgsapi/index.php/facturas/:id_servicio/facturar
            const api = new PwgsApi();
            try {
                await api.post('facturas/' + this.$props.id + '/facturar', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Factura/s creada/s correctamente', life: 2000 });
                this.$parent.movertoggle();
                this.cargardatos();
                this.datoscliente();
                this.cargardatosfactura();
                this.obligsignumero = true;
                this.siguientefactura();

             }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        reload(){
            this.fechaseleccionada = new Date().toLocaleString();
                this.fechaseleccionada = String(this.fechaseleccionada);
                let aux = this.fechaseleccionada.split(',');
                this.fechaseleccionada = this.fechaing(aux[0]);
            this.sacarerror = null;
            this.cargardatos();
            this.datoscliente();
            this.cargardatosfactura();
            this.obligsignumero = true;
            this.siguientefactura();
        }
    },
    mounted() { 
        this.fechaseleccionada = new Date().toLocaleString();
                this.fechaseleccionada = String(this.fechaseleccionada);
                let aux = this.fechaseleccionada.split(',');
                this.fechaseleccionada = this.fechaing(aux[0]);
        this.cargardatos();
        this.datoscliente();
        this.cargardatosfactura();
        this.obligsignumero = true;
        this.siguientefactura();
    },    
    watch: {
        sacarerror(value){
            console.log('sacarerr', value);
        },
        fechaseleccionada(){
            this.vencimiento = this.addDaysToDate(this.fechaseleccionada, this.datosfacturacion.dias_vencimiento_defecto);
        },
        id() {
            this.fechaseleccionada = new Date().toLocaleString();
                this.fechaseleccionada = String(this.fechaseleccionada);
                let aux = this.fechaseleccionada.split(',');
                this.fechaseleccionada = this.fechaing(aux[0]);
            this.sacarerror = null;
            this.obligsignumero = true;
            this.cargardatos();
            this.datoscliente();
            this.cargardatosfactura();      
        },
        servicio(){
            console.log('srvsc', this.servicio);
            this.fechaseleccionada = new Date().toLocaleString();
                this.fechaseleccionada = String(this.fechaseleccionada);
                let aux = this.fechaseleccionada.split(',');
                this.fechaseleccionada = this.fechaing(aux[0]);
            console.log("Servicio222", this.servicio);
            this.cargardatos();
            this.datoscliente();
        },
        anyo() {
            this.siguientefactura();
            
        },
        serieseleccionada() {
            this.siguientefactura();
        },
        obligsignumero() {
            if (this.obligsignumero == true) {
                this.numeroseleccionado = this.siguientenumero;
                this.fechadeshab = false;
                
            }
            if (this.obligsignumero == false) {
                this.numeroseleccionado = "";
                this.fechadeshab = false;

            }
        },
        numeroseleccionado() {
            if (this.numeroseleccionado.fecha) {
                console.log('entranumsel1');
                this.fechaseleccionada = new Date(this.numeroseleccionado.fecha);
                this.fechaseleccionada = new Date(this.fechaseleccionada).toLocaleString();
                this.fechaseleccionada = String(this.fechaseleccionada);
                let aux = this.fechaseleccionada.split(',');
                this.fechaseleccionada = this.fechaing(aux[0]);
                this.fechadeshab = true;
            }
            else {
                console.log('entranumsel2');
                this.fechaseleccionada = new Date().toLocaleString();
                this.fechaseleccionada = String(this.fechaseleccionada);
                let aux = this.fechaseleccionada.split(',');
                this.fechaseleccionada = this.fechaing(aux[0]);
                this.fechadeshab = false;


            }
        }
     
    }
}
</script>
