<template>
    <div class="row">
        <div class="col-md-12">
            <div class="callout callout-success">
                <div v-if="!cobrado" class="ribbon_no_cobrada ribbon-top-right"><span>{{$t('general.no cobrada')}}</span></div>
                <div v-if="cobrado" class="ribbon_cobrada ribbon-top-right"><span>{{$t('general.cobrada')}}</span></div>
                <h5 v-if="abono == false">{{$t('general.factura')}} <strong>{{factura.factura.numero_factura}}</strong><i style="margin-left: 5px;" v-if="exportada" title="Exportada contabilidad" class="fas fa-file-export"></i></h5>
                <h5 v-if="abono == true">Abono <strong>{{factura.factura.numero_abonado}}</strong></h5>
                <div class="row" style="margin: 0; gap:5px">
                  <strong>Cliente: </strong>
                  {{ factura.factura.nombre_cliente }}
                </div>
                <div class="row" style="margin-top: 15px;">
                    <div class="col-md-4"><input type="date" @input="actualizarFecha()" :disabled="bloquearfecha" v-model="datosf.fecha_hoy" class="form-control input-xs" ></div>
                    <div class="col-md-3"><h4>{{ datosf.base_imponible }}&euro;</h4></div>
                    <div class="col-md-5">
                        <button type="button" class="btn btn-default btn-flat" title="Pdf" @click="abrirurl()" ><i class="fas fa-file-invoice"></i></button>  
                        <button v-if="!bloqueocobrada" type="button" class="btn btn-default btn-flat" title="Marcar como cobrada" @click="divmarcarcobrada = !divmarcarcobrada" ><i class="fas fa-file-invoice-dollar"></i></button>
                        <button v-if="abono==false && !parciales" type="button" class="btn btn-default btn-flat" @click="confirmar()" :title="$t('general.abonar')"><i class="fas fa-undo-alt"></i></button>
                        <button v-if="abono==false && parciales" type="button" class="btn btn-default btn-flat" @click="formabonar = !formabonar" :title="$t('general.abonar')"><i class="fas fa-undo-alt"></i></button>
                        <button v-if="eliminable" type="button" class="btn btn-default btn-flat" @click="eliminarfactura=!eliminarfactura" title="Eliminar"><i class="fas fa-times"></i></button>
                    </div>
                </div>
                <div v-if="formabonar==true"  style="margin-top: 15px;">
                  <div class="row">
                    <div class="form-group col-md-8" style="margin-bottom: 0;">
                      <label for="">Concepto</label>
                      <input type="text" class="form-control form-control-sm" placeholder="Concepto" v-model="conceptoAbono">
                    </div>
                    <div class="form-group col-md-4" style="margin-bottom: 0;">
                      <label for="">IVA</label>
                      <select class="form-control form-control-sm" v-model="impuestoselec" id="">
                        <option v-for="impuesto in impuestos" :key="impuesto.id" :value="impuesto.valor_iva">{{ impuesto.valor_iva }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="input-group input-group-sm" style="margin-top: 5px; margin-bottom: 0px;">
                    <input type="number" class="form-control" placeholder="Importe" v-model="importeabono">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-primary btn-flat" @click="abonarparcial()" title="Abonar">Abonar</button>
                    </div>
                  </div>
                </div>
                <div v-if="divmarcarcobrada==true" style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px; align-items: end; margin-top: 15px;" >
                  <div class="form-group" style="margin-bottom: 0;">
                    <label for="banco" style="margin-bottom: 0;">Marcar como cobrada</label>
                    <select v-model="bancoselec" class="form-control form-control-sm" id="banco">
                      <option value="">Seleccione banco</option>
                      <option v-for="banco in bancos" :key="banco.id_banco" :value="banco.id_banco">{{ banco.nombre_banco }}</option>
                    </select>
                  </div>
                  <div class="input-group input-group-sm"  style="margin-top: 20px; margin-bottom: 0px;">
                    <input type="date" class="form-control" v-model="fechapago">
                    <div class="input-group-append">
                      <button type="button" class="btn btn-info btn-flat" @click="divmarcarcobrada = !divmarcarcobrada;marcarcobrada()">Cobrada</button>
                    </div>
                  </div>
                </div>
                <div class="input-group input-group-sm" v-if="eliminarfactura==true" style="margin-top: 20px; margin-bottom: 0px;">
                  <input type="text" class="form-control" placeholder="Motivo" v-model="motivoeliminacion">
                  <div class="input-group-append">
                    <button type="button" class="btn btn-danger btn-flat" @click="eliminar()" title="Eliminar">Eliminar</button>
                  </div>
                </div>
                <div class="row" >
                    <div class="col-md-12">
                        <br>
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend">
                              <select class="form-control form-control-sm" v-model="envioSelec" id="">
                                <option v-for="[key ,envio] in Object.entries(envios)" :key="key" :value="key">{{ envio }}</option>
                              </select>
                              
                            </div>
                            <input  type="text" class="form-control" :disabled="envioSelec!='email' || correoseleccionado!=''" v-model="correoEnvio" placeholder="Email">
                            <span class="input-group-append">
                                <button type="button" class="btn btn-info btn-flat" @click="enviarfactura()">{{$t('general.enviar')}}</button>
                            </span>
                        </div>
                        <div>
                          <label for="">Destinatario</label>
                          <Dropdown :disabled="envioSelec!='email'" v-model="correoseleccionado" :options="correos" optionLabel="label" optionGroupLabel="label" optionValue="value" optionGroupChildren="items" style="width: 100%;">
                            
                          </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import Dropdown from 'primevue/dropdown'
export default {
  props: ['id', 'servicio', 'factura','togglecargar', 'eliminable'],
    components:{
      Dropdown,
    },
    data() {
        return {
          
          importeabono:'',
          conceptoAbono:'',
          mostrarModulo: false,
          datosf: '',
          datosurl: "",
          url: "",
          emails: '',
          motivo: "Prueba",
          motivoeliminacion:"",
          destino: "Destino",
          eliminarfactura:false,
          cobrado:false,
          divmarcarcobrada:false,
          bancos:[],
          bancoselec: '',
          fechapago:'',
          bloqueocobrada: false,
          abono:null,
          parciales: false,
          formabonar:false,
          impuestos: [],
          impuestoselec:'',
          envios:[],
          correos:[],
          envioSelec:'email',
          correoseleccionado:'',
          correoEnvio:'',
          bloquearfecha:false,
          exportada:false,
        }
    },
    methods: {
      async abonarparcial(){
        try{
          const api = new PwgsApi();
          await api.post('facturas/'+this.$props.factura.factura.id_factura+'/abonar', {importe: this.importeabono, concepto: this.conceptoAbono, iva: this.impuestoselec});
          this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
          this.$parent.cargardatosFactura();
        }catch(e){
          this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
        }
        
      },
      confirmar(){
        this.$confirm.require({
                message: '¿Seguro que quieres abonar la factura?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'Cancelar',
                acceptLabel: 'Aceptar',
                accept: () => {
                  this.pagarfactura()
                },
                reject: () => {
                    return;
                }
            });
        
      },
      async marcarcobrada(){
        if(this.bancoselec == ''){
          alert('Debe seleccionar un banco');
          return;
        }
        const api = new PwgsApi();
        const resp = await api.put('facturas/'+this.$props.factura.factura.id_factura+'/banco', {banco: this.bancoselec, fecha: this.fechaesp(this.fechapago)});
        console.log(resp);
        this.$parent.cargardatosFactura();
      },
      fechaing(fecha) {
          const [dia, mes, año] = fecha.split("/");
          return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
      },
      fechaesp(fecha) {
          const [año, mes, dia] = fecha.split("-");
          return `${dia}/${mes}/${año}`;
      },
      async cargardatos() {
        const api = new PwgsApi();
        
        const resp = await api.get('/bancos');
        this.bancos = resp.datos;
        const ress = await api.get('impuestos');
        this.impuestos = ress.datos;
        this.envios = this.factura.envios_disponibles;
        var destinatarios = this.factura.correos;
        var correos = [];
        var correogeneral = {
          label: 'Seleccione destinatario',
          items: [
            {label:'Seleccione destinatario', value: ''}
          ]
        };
        correos.push(correogeneral);
        for(let [key, value] of Object.entries(destinatarios)){
          var nuevo = {};
          if(key == 'Compañía' || key == 'Otros Compañía'){
            nuevo = {
              label:key,
              items:[]
            }
            for(let val of value){
              nuevo.items.push({label:val, value:val});
            }
            correos.push({nuevo});
          }else{
            nuevo ={
              label:key,
              items:[]
            };
            for(let [nombre, correo] of Object.entries(value)){
              nuevo.items.push({label:nombre+'('+correo+')', value:correo});
            }
            correos.push(nuevo);
          }
        }
        console.log('correoscreados', correos);
        this.correos = correos;
        if(this.factura.factura.trabajos[0]){
          var ivadefecto = this.factura.factura.trabajos[0].iva;
          console.log('ivadefecto', ivadefecto);
          var impuestoencontrado =this.impuestos.find(i => i.valor_iva == ivadefecto);
          console.log('impuestoencontrado', impuestoencontrado);
          this.impuestoselec = impuestoencontrado.valor_iva;
        }
        if(this.factura.factura.cobrada == 'SI')this.cobrado = true;
        if(this.factura.abonos.parciales == '1')this.parciales = true;
        console.log(this.factura);
        if(this.factura.bloqueos.cobrada == 1){
          this.bloqueocobrada = true;
        }
        if(this.factura.bloqueos.fecha_factura == '1'){
          this.bloquearfecha = true;
        }
        if(this.factura.factura.exportada_contabilidad == '1'){
          this.exportada = true;
        }
        console.log('factt', this.factura);
        this.datosf = this.$props.factura.factura;
        console.log('datosf',this.datosf);
        this.datosf.tipo == 'abono'?this.abono=true:this.abono=false;
        console.log('abono',this.abono, this.datosf);
        if(this.cobrado){
          this.fechapago = this.datosf.fecha_cobro;
        }else{
          this.fechapago = this.fechaing(new Date().toLocaleString().substring(0, 9));
        }
       
        this.datosf.fecha_hoy = this.fechaing(this.datosf.fecha_hoy);
        this.url = this.$props.factura.url_factura_pdf.slice(5);
        this.datosurl = this.datosurl + this.url + "'";
        console.log('facturass', this.factura);
      },
      async actualizarFecha(){
        const api = new PwgsApi();
        await api.put('facturas/'+this.$props.factura.factura.id_factura+'/modificar-factura', {fecha_factura: this.datosf.fecha_hoy});
      },
      abrirurl() {
        window.open(this.datosurl,'_blank', "width=800, height=600");
      },
      async enviarfactura() {
        //PUT  modulos/pwgsapi/index.php/facturas/:id_factura/enviar-factura
        try {
          var subidadatos = {};
          var claves = [];
          if(this.envioSelec == 'email'){
            subidadatos = { tipo: this.envioSelec, emails: [this.correoEnvio] };
            claves = [];
            if(this.factura.parametros_sistema_compania.length > 0){
              for(let clave of this.factura.parametros_sistema_compania){
                if(clave.tipo == 'hidden'){
                  claves.push(clave.valor);
                }
              }
            }
            subidadatos.clave_certificado = claves;
          }else{
            subidadatos = { tipo: this.envioSelec};
            claves = [];
            if(this.factura.parametros_sistema_compania.length > 0){
              for(let clave of this.factura.parametros_sistema_compania){
                if(clave.tipo == 'hidden'){
                  claves.push(clave.valor);
                }
              }
            }
            subidadatos.clave_certificado = claves;
          }
          const api = new PwgsApi();
          await api.put('facturas/' + this.$props.factura.factura.id_factura + '/enviar-factura', subidadatos);
          this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });

        }
        catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 5000 });
      }
        

      },
      async pagarfactura() {
        //PUT  modulos/pwgsapi/index.php/facturas/:id_servicio/enviar-pago-tpv
        const api = new PwgsApi();
        try{
          await api.post('facturas/'+this.$props.factura.factura.id_factura+'/abonar');
          this.$toast.add({ severity:'success', summary: 'Abonado', detail: 'Factura abonada', life: 2000 });
          this.$parent.cargardatosFactura();
        }catch(e){
          this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
        }
      },
      async eliminar() {
      //DELETE  modulos/pwgsapi/index.php/facturas/:id_factura/eliminar-factura
      if(this.motivoeliminacion == ''){
        alert('Se debe indicar un motivo.')
        return;
      }else{
        const api = new PwgsApi;
        const params = {motivo:this.motivoeliminacion};
        await api.deletebody('facturas/' + this.$props.factura.factura.id_factura, params);
        this.$parent.cargardatosFactura();
      }
    }
  },
  mounted() {
    console.log('secarga');
    let aux = localStorage.getItem('direccion');
    this.datosurl = "https://" + aux;

    this.cargardatos();
  },
  watch: {
    id() {
      this.cargardatos();
    },
    togglecargar() {
      this.cargardatos();
    },
    correoseleccionado(value){
      this.correoEnvio = value;
    }
  }
    
}
</script>
<style scoped>
.ribbon_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_cobrada::before,
.ribbon_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #2980b9;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}

.ribbon_no_cobrada {
  width: 90px;
  height: 90px;
  overflow: hidden;
  position: absolute;
}
.ribbon_no_cobrada::before,
.ribbon_no_cobrada::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #c42020;
}
.ribbon_no_cobrada span {
  position: absolute;
  display: block;
  width: 165px;
  padding: 5px 0;
  background-color: #d32c42;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}


/* top right*/
.ribbon-top-right {
  top: -8px;
  right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}


</style>