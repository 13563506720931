<template>
    <seccion-datos @cambios="validarYGuardar($data)">
        <div class="form-group">
            <label>{{ $t('general.nombre') }}</label>
            <input type="text" class="form-control form-control-sm" placeholder="" v-model="nombre">
        </div>
        <div class="form-group">
            <label>{{ $t('general.identificacion') }}</label>
            <input type="text" class="form-control form-control-sm" placeholder="" v-model="prefijo">
        </div>  
        <div class="row"> 
            <div class="col-md-9">
                <div class="form-group">
                    <label>{{ $t('general.direccion') }}</label>
                    <input type="text" class="form-control form-control-sm" placeholder="" v-model="domicilio">
                </div> 
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{ $t('general.cpostal') }}</label>
                    <input type="text" class="form-control form-control-sm" placeholder="" v-model="cp">
                </div>  
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.poblacion') }}</label>
                    <input type="text" class="form-control form-control-sm" placeholder="" v-model="poblacion">
                </div>  
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.provincia') }}</label>
                    <input type="text" class="form-control form-control-sm" placeholder="" v-model="provincia">
                </div> 
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.telefono') }}</label>
                    <input type="text" class="form-control form-control-sm" placeholder="" v-model="telefono">
                </div>  
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Fax</label>
                    <input type="text" class="form-control form-control-sm" placeholder="" v-model="fax">
                </div> 
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control form-control-sm" placeholder="" v-model="email">
                </div> 
            </div>
        </div>
    </seccion-datos>
</template>

<script>
export default {
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return  {
            nombre: '',
            prefijo: '',
            domicilio: null,
            cp: null,
            poblacion: null,
            provincia: null,
            telefono: null,
            fax: null,
            email: null,
            errores: [],
        }
    },
    watch: {
        datos() {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
            else {
                for (let dato in this.$data) {
                    this[dato] = null;
                }
            }
            this.datoscompania = this.$t('general.datoscompania');
           
        },
        validarYGuardar(datos) {
            try {
                const datosEnviar = {...datos};
                delete datosEnviar.errores;
                this.validar(datosEnviar);
                this.guardar(datosEnviar);
             
            } 
            catch (error) {
                this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 5000});
            }
        },
        validar(datos) {
            if (datos.nombre.trim() === '') {
                this.errores.push('Debe especificar un nombre');
            }
            if(datos.prefijo){
            if (datos.prefijo.trim() === '') {
                this.errores.push('Debe especificar un prefijo');
            }
            }
        }
    },
    created() {
        this.init();
    }
}
</script>