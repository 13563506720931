<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Centralita</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">            
            <div class="card card-default card-tabs">
                <div class="card-header p-1 pt-1">
                    <ul class="nav nav-pills" ref="tabHeaders">
                        <li  class="nav-item">
                            <a class="nav-link active" href="#informe" data-toggle="tab">
                                Informe llamadas
                            </a>
                        </li>
                        <li  class="nav-item">
                            <a @click="cargarConfiguracion(); cargarExtensiones(); cargarColas(); cargarGrabaciones()" class="nav-link" data-toggle="tab" href="#configuracion">
                                Configuración
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" ref="tabContents">
                        <div class="tab-pane active container-grid" id="informe">
                            <div style="display: flex; gap: 10px; align-items: center;">
                                <b>1º. Filtro</b>
                                <div style="display: flex; gap: 3px; align-items: center;">
                                    <input type="date" class="form-control form-control-sm" v-model="fechaInicio">
                                    <span>-</span>
                                    <input type="date" class="form-control form-control-sm" v-model="fechaFin">
                                </div>
                                <input style="width: 250px;" type="text" class="form-control form-control-sm" placeholder="Origen" v-model="filtroOrigen">
                                <input style="width: 250px;" type="text" class="form-control form-control-sm" placeholder="Destino" v-model="filtroDestino">
                                <select class="form-control form-control-sm" style="width: 250px;" v-model="filtroTipo" id="">
                                    <option value="directa">Evento - Directa</option>
                                    <option value="interactiva">Evento - Interactiva</option>
                                    <option value="redirigir">Evento - Redirigir tramitador</option>
                                    <option value="autocita">Auto-visita</option>
                                </select>
                                <button class="btn btn-sm btn-primary" style="border: 1px solid grey" @click="cargarDatos">Filtrar</button>
                            </div>
                            <div style="display: flex; justify-content: end; align-items: center; gap: 10px; margin: 20px 0;">
                                <b>2º. Búsqueda genérica</b>
                                <input style="width: 250px;" type="text" class="form-control form-control-sm" v-model="globalFilter">
                            </div>
                            <div v-if="spinner==true" class="spinner"></div>
                            <div :class="spinner == true?'difuminado':''">
                                <DataTable 
                                stripedRows
                                v-model:filters="filters" 
                                :value="datosFiltrados"
                                responsiveLayout="scroll"
                                dataKey="id"
                                :globalFilterFields="['tiempo_inicio', 'origen', 'destino', 'direccion', 'duracion', 'contestado']">
                                
                                    <template #empty> No hay llamadas disponibles. </template>

                                    <!-- Column Definitions -->
                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong style="cursor: pointer" @click="toggleOrden('fecha')">Fecha</strong>
                                                <button @click="toggleOrden('fecha')" :aria-sort="fechaOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                                    <i :class="fechaIcon"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            {{ data.tiempo_inicio }}
                                        </template>
                                    </Column>
                                    
                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong style="cursor: pointer" @click="toggleOrden('origen')">Origen</strong>
                                                <button @click="toggleOrden('origen')" :aria-sort="origenOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                                    <i :class="origenIcon"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            {{ data.origen }}
                                        </template>
                                    </Column>
                                    
                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong style="cursor: pointer" @click="toggleOrden('destino')">Destino</strong>
                                                <button @click="toggleOrden('destino')" :aria-sort="destinoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                                    <i :class="destinoIcon"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            {{ data.destino }}
                                        </template>
                                    </Column>

                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong style="cursor: pointer" @click="toggleOrden('tipo')">Tipo</strong>
                                                <button @click="toggleOrden('tipo')" :aria-sort="tipoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                                    <i :class="tipoIcon"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            {{ data.direccion }}
                                        </template>
                                    </Column>

                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong style="cursor: pointer" @click="toggleOrden('duracion')">Duración (s)</strong>
                                                <button @click="toggleOrden('duracion')" :aria-sort="duracionOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                                    <i :class="duracionIcon"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            {{ data.duracion }}
                                        </template>
                                    </Column>

                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong style="cursor: pointer" @click="toggleOrden('estado')">Estado</strong>
                                                <button @click="toggleOrden('estado')" :aria-sort="estadoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                                    <i :class="estadoIcon"></i>
                                                </button>
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            {{ data.contestado }}
                                        </template>
                                    </Column>

                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong>Detalle</strong>                                                
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="detalle(data)">Detalle</button>
                                        </template>
                                    </Column>

                                    <Column>
                                        <template #header>
                                            <div class="d-flex justify-content-between">
                                                <strong>Reproducir</strong>                                                
                                            </div>
                                        </template>
                                        <template #body="{ data }">
                                            <div v-if="data.reproductor">
                                                <audio controls>
                                                    <source :src="data.fichero_audio" type="audio/mpeg">
                                                    Tu navegador no soporta el elemento de audio
                                                </audio>
                                            </div>
                                            <button v-else-if="data.mostrar_reproductor=='SI'" class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="data.reproductor=true">Reproducir</button>
                                            <div v-else>
                                                
                                            </div>
                                        </template>
                                    </Column>

                                </DataTable> 
                            </div>
                        </div>
                        <div class="tab-pane container-grid" id="configuracion">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="card card-primary card-outline">
                                        <div class="card-header">
                                            <h5>Esquema de ejecución de llamadas entrantes</h5>
                                        </div>
                                        <div v-if="configuracion" class="card-body">
                                            <div v-for="config in Object.entries(configuracion)" :key="config[0]" style="display: grid;">
                                                
                                                <Timeline :value="config[1]"  class="customized-timeline">
                                                    <template #marker="slotProps">
                                                        <div v-if="slotProps.item.titulo=='inicio'" style="width: 90px; padding: 5px; background-color: red; ;margin-top: 15px;justify-content: center; align-items: center; display: flex">
                                                            <span style="color: white;">{{ config[0] }}</span>
                                                        </div>
                                                        <div v-else style="width: 90px; display: flex; justify-content: center;">
                                                            <span style="width: 2rem; height: 2rem; border-radius: 15px; justify-content: center; align-items: center; display: flex" :class="slotProps.item.icono.split(' ')[2] ? slotProps.item.icono.split(' ')[2]:'bg-light'">
                                                                <i :class="slotProps.item.icono.split(' ')[0]+' '+slotProps.item.icono.split(' ')[1]"></i>
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <template #content="slotProps">
                                                        <div v-if="slotProps.item.titulo != 'Colgar' && slotProps.item.titulo!='inicio'" style="background-color: #f0f0f0; border: 1px solid #ddd; display: flex; justify-content: space-between; padding: 10px; border-radius: 3px;" :style="slotProps.item.reproductor?'margin-bottom:20px':''">
                                                            <div style="display: flex; gap: 5px;">
                                                                <span style="color:#337ab7; cursor: pointer;" @click="abrirRutina(slotProps.item)">{{ slotProps.item.titulo }}</span>
                                                                <span style="color: #555;">{{ slotProps.item.subtitulo }}</span>
                                                            </div>
                                                            <div v-if="slotProps.item.horario" >
                                                                <span style="color: #999; font-size: 12px;"><i class="fa fa-clock" style="margin-right:5px ;"></i>{{ slotProps.item.horario }}</span>
                                                            </div>
                                                            <div v-else-if="slotProps.item.reproductor">
                                                                <audio controls>
                                                                    <source :src="slotProps.item.fichero_audio" type="audio/mpeg">
                                                                    Tu navegador no soporta el elemento de audio
                                                                </audio>
                                                            </div>
                                                            <button v-else-if="slotProps.item.fichero_audio" class="btn btn-sm btn-warning" @click="slotProps.item.reproductor=true">Reproducir</button>
                                                        </div>
                                                    </template>
                                                </Timeline>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="card card-success card-outline">
                                        <div class="card-header">
                                            <h5>Extensiones</h5>
                                        </div>
                                        <div class="card-body">
                                            <table class="table">
                                                <tbody>
                                                    <tr class="mensaje" style="cursor: pointer;" v-for="extension in extensiones" :key="extension.id">
                                                        <td>
                                                            <div @click="abrirDatosExtension(extension)" style=" display: flex; justify-content: space-between; padding: 4px;">
                                                                <div style="display: flex; gap: 10px;">
                                                                    <i class="fa fa-phone"></i>
                                                                    <b>{{ extension.id }}</b>
                                                                    <span>{{ extension.nombre_extension }}</span>
                                                                </div>
                                                                <div>
                                                                    <span  class="badge bg-success" v-if="extension.datos_estado.nombre == 'Conectado'" style="color: white;">Conectado</span>
                                                                    <span  class="badge bg-secondary" v-else-if="extension.datos_estado.nombre == 'Desconectado'" style="color: black;">Desconectado</span>
                                                                    <span  class="badge bg-warning" v-else-if="extension.datos_estado.nombre == 'En llamada'" style="color: white;">En llamada</span>
                                                                    <span  class="badge bg-secondary" v-else style="color: black;">{{ extension.datos_estado.nombre }}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>                                            
                                        </div>
                                    </div>
                                    <div class="card card-warning card-outline">
                                        <div class="card-header">
                                            <h5>Colas</h5>
                                        </div>
                                        <div class="card-body">
                                            <table class="table">
                                                <tbody>
                                                    <tr class="mensaje" style="cursor: pointer;" v-for="cola in colas" :key="cola.id_cola" @click="accionCola2(cola)">
                                                        <td>
                                                            <div style=" display: flex; justify-content: space-between; padding: 4px;">
                                                                <div style="display: flex; gap: 10px;">
                                                                    <i class="fas fa-users"></i>
                                                                    <span>{{ cola.nombre_cola }}</span>
                                                                </div>
                                                                <div>
                                                                    <span class="badge bg-primary" style="color: white;">{{ cola.miembros.length }}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>                                            
                                        </div>
                                    </div>
                                    <div class="card card-danger card-outline">
                                        <div class="card-header">
                                            <h5>Grabaciones personalizadas</h5>
                                        </div>
                                        <div class="card-body">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Fichero</th>
                                                        <th>Reproducción</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="grabacion in grabaciones" :key="grabacion.id_grabacion">
                                                        <td>
                                                            {{ grabacion.nombre_grabacion }}
                                                        </td>
                                                        <td>
                                                            {{ grabacion.fichero_grabacion }}
                                                        </td>
                                                        <td>
                                                            <audio controls>
                                                                <source :src="grabacion.fichero_audio" type="audio/mpeg">
                                                                Tu navegador no soporta el elemento de audio
                                                            </audio>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Dialog ref="refDetalle" modal header="Datos llamada" :style="{ width: '35vw' }"
        :closeOnEscape="false" v-model:visible="visibleDetalle" closable>
        <div class="card card-success card-outline">
            <div class="card-header">
                <h5> Llamada</h5>
            </div>
            <div class="card-body">                              
                <div style="display: flex; gap: 10px;">
                    <i class="fa fa-calendar"></i>
                    <span >{{ llamadaDetalle.tiempo_inicio }}</span>
                </div>
                <div style="display: flex; gap: 10px;">
                    <i class="fa fa-phone"></i>
                    <span>{{ llamadaDetalle.origen }} ({{ llamadaDetalle.cdr_in }})</span>
                </div>
                <div style="display: flex; gap: 10px;">
                    <i class="fa fa-phone-volume"></i>
                    <span>{{ llamadaDetalle.destino }} ({{ llamadaDetalle.cdr_out }})</span>
                </div>
                <div style="display: flex; gap: 10px;">
                    <i class="fa fa-arrows-alt-h"></i>
                    <span>{{ llamadaDetalle.direccion}}</span>
                </div>
                <div style="display: flex; gap: 10px;">
                    <i class="fa fa-clock"></i>
                    <span>{{ llamadaDetalle.duracion}}s</span>
                </div>
            </div>
        </div>
        <div class="card card-danger card-outline">
            <div class="card-header">
                <h5>Detalle</h5>
            </div>
            <div class="card-body">                              
                <Timeline :value="llamadaDetalle.detalles"  class="customized-timeline">
                    <template #marker="slotProps">
                        <div v-if="slotProps.item.descripcion=='inicio'" style="width: 90px; padding: 5px; background-color: red; ;margin-top: 15px;justify-content: center; align-items: center; display: flex">
                            <span style="color: white;">{{ llamadaDetalle.origen }}</span>
                        </div>
                        <div v-else-if="slotProps.item.descripcion=='final'" style="width: 90px; display: flex; justify-content: center;">
                            <span style="width: 2rem; height: 2rem; border-radius: 15px; justify-content: center; align-items: center; display: flex" class="bg-light">
                                <i class="fa fa-phone"></i>
                            </span>
                        </div>
                        <div v-else style="width: 90px; display: flex; justify-content: center;">
                            <span style="width: 2rem; height: 2rem; border-radius: 15px; justify-content: center; align-items: center; display: flex" class="bg-blue">
                                <i class="fa fa-phone"></i>
                            </span>
                        </div>
                    </template>
                    <template #content="slotProps">
                        <div v-if="slotProps.item.descripcion != 'inicio' && slotProps.item.descripcion!='final'" class="card">
                            <div class="card-header" style="display: flex; justify-content: space-between">
                                <span style="color:#337ab7; cursor: pointer;">{{ slotProps.item.destino }}</span>
                                <div style="display: flex; gap: 5px;">
                                    <span class="badge bg-green" v-if="slotProps.item.contestado == 'contestado'" style="color: white;">Contestado</span>
                                    <span v-else class="badge bg-secondary" style="color: white;">{{ slotProps.item.contestado }}</span>
                                    <div v-if="slotProps.item.tiempo_inicio" >
                                        <span style="color: #999; font-size: 12px;"><i class="fa fa-clock" style="margin-right:5px ;"></i>{{ slotProps.item.tiempo_inicio }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                {{ slotProps.item.descripcion }}
                            </div>
                        </div>
                    </template>
                </Timeline>
            </div>
        </div>
    </Dialog>
    <Dialog ref="refRutina" modal header="Datos rutina" :style="{ width: '40vw' }"
        :closeOnEscape="false" v-model:visible="visibleRutina" closable>
        <div>
            <Timeline :value="colasDetalle" class="customized-timeline">
                <template #marker="slotProps">
                    <div style="width: 90px; display: flex; justify-content: center;">
                        <span style="width: 2rem; height: 2rem; border-radius: 15px; justify-content: center; align-items: center; display: flex" :class="slotProps.item.icono.split(' ')[2] ? slotProps.item.icono.split(' ')[2]:'bg-light'">
                            <i :class="slotProps.item.icono.split(' ')[0]+' '+slotProps.item.icono.split(' ')[1]"></i>
                        </span>
                    </div>
                </template>
                <template #content="slotProps">
                    <div v-if="slotProps.item.titulo != 'Colgar'" style="background-color: #f0f0f0; border: 1px solid #ddd; display: flex; justify-content: space-between; padding: 10px; border-radius: 3px; margin-bottom: 15px; align-items: start;">
                        <div style="display: grid;">
                            <span style="color:#337ab7; cursor: pointer;" @click="accionRutina(slotProps.item)">{{ slotProps.item.titulo }}</span>
                            <span style="color: #555;">{{ slotProps.item.subtitulo }}</span>
                        </div>
                        <div v-if="slotProps.item.horario" >
                            <span style="color: #999; font-size: 12px;"><i class="fa fa-clock" style="margin-right:5px ;"></i>{{ slotProps.item.horario }}</span>
                        </div>
                        <div v-else-if="slotProps.item.reproductor">
                            <audio controls>
                                <source :src="slotProps.item.fichero_audio" type="audio/mpeg">
                                Tu navegador no soporta el elemento de audio
                            </audio>
                        </div>
                        <button v-else-if="slotProps.item.fichero_audio" class="btn btn-sm btn-warning" @click="slotProps.item.reproductor=true">Reproducir</button>
                    </div>               
                </template>
            </Timeline>
        </div>
    </Dialog>
    <Dialog ref="refAccionRutina" modal header="Datos rutina" :style="{ width: '40vw' }"
        :closeOnEscape="false" v-model:visible="visibleAccionRutina" closable>
        <div>
            <Timeline :value="accionesRutina" class="customized-timeline">
                <template #marker="slotProps">
                    <div style="width: 90px; display: flex; justify-content: center;">
                        <span style="width: 2rem; height: 2rem; border-radius: 15px; justify-content: center; align-items: center; display: flex" :class="slotProps.item.icono.split(' ')[2] ? slotProps.item.icono.split(' ')[2]:'bg-light'">
                            <i :class="slotProps.item.icono.split(' ')[0]+' '+slotProps.item.icono.split(' ')[1]"></i>
                        </span>
                    </div>
                </template>
                <template #content="slotProps">
                    <div v-if="slotProps.item.titulo != 'Colgar'" style="background-color: #f0f0f0; border: 1px solid #ddd; display: flex; justify-content: space-between; padding: 10px; border-radius: 3px; margin-bottom: 15px; align-items: start;">
                        <div style="display: grid;">
                            <span style="color:#337ab7; cursor: pointer;" @click="accionRutina(slotProps.item)">{{ slotProps.item.titulo }}</span>
                            <span style="color: #555;">{{ slotProps.item.subtitulo }}</span>
                        </div>
                        <div v-if="slotProps.item.horario" >
                            <span style="color: #999; font-size: 12px;"><i class="fa fa-clock" style="margin-right:5px ;"></i>{{ slotProps.item.horario }}</span>
                        </div>
                        <div v-else-if="slotProps.item.reproductor">
                            <audio controls>
                                <source :src="slotProps.item.fichero_audio" type="audio/mpeg">
                                Tu navegador no soporta el elemento de audio
                            </audio>
                        </div>
                        <button v-else-if="slotProps.item.fichero_audio" class="btn btn-sm btn-warning" @click="slotProps.item.reproductor=true">Reproducir</button>
                    </div>               
                </template>
            </Timeline>
        </div>
    </Dialog>
    <Dialog ref="refCola" modal header="Datos cola" :style="{ width: '35vw' }"
        :closeOnEscape="false" v-model:visible="visibleCola" closable>
        <div class="card">
            <div class="card-header" style="background-position:center; height: 120px;" :style="{ backgroundImage: `url(${require('@/assets/img/cola.jpg')})` }">
                <h3>{{ colaDetalles.cola.nombre_cola }}</h3>
            </div>
            <div class="card-body">
                <div style="display: flex;">
                    <div class="col-md-4 border-right" style="display: grid; align-items: center; justify-content: center">
                        <b style="text-align: center">{{ colaDetalles.cola.estrategia_cola }}</b>
                        <p>ESTRATEGIA</p>
                    </div>
                    <div class="col-md-4 border-right" style="display: grid; align-items: center; justify-content: center">
                        <b style="text-align: center">{{ colaDetalles.cola.tiempo_max_espera_cola }} seg.</b>
                        <p>TIEMPO MÁX EN COLA</p>
                    </div>
                    <div class="col-md-4" style="display: grid; align-items: center; justify-content: center">
                        <b style="text-align: center">{{ totalmiembros }}</b>
                        <p>Nº MIEMBROS</p>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <table class="table table-bordered">
                    <thead>
                        <tr style="background-color: #337ab7; border-color: #337ab7;">
                            <th style="color: white;">Miembros</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="miembro in colaDetalles.cola.miembros" :key="miembro.interface">
                            <th>{{ miembro.interface.split('/')[1] }}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Dialog>
    <Dialog ref="refMensajes" modal header="Datos menú" :style="{ width: '35vw' }"
        :closeOnEscape="false" v-model:visible="visiblemensajerutinas" closable>        
        <div>
            <div style="display: grid;gap: 15px">                
                <div class="mensaje" v-for="mensaje in mensajes" @click="rutinaMensaje(mensaje)" :key="mensaje" style=" border: 1px solid #ddd; display: flex; justify-content: center; padding: 6px; border-radius: 3px; cursor: pointer;">
                    <p style="margin: 0;">{{ mensaje.titulo }}</p>
                </div>
            </div>
        </div>
    </Dialog><Dialog ref="refDatosExtension" modal header="Datos extensión" :style="{ width: '35vw' }"
        :closeOnEscape="false" v-model:visible="visibleDatosExtension" closable>      
        <div class="card">
            <div style="height: 120px; display: flex; background-color: #00a7d0;align-items: center;gap: 20px;" class="card-header">
                <div>
                    <img width="65px" src="../../../../assets/img/operador.jpg"
                        class="user-image img-circle elevation-2" alt="User Image">
                </div>
                <div>
                    <h3 style="color: white;">{{ datosExtension.id }}</h3>
                    <p style="margin: 0; color: white;">{{ datosExtension.nombre_extension }}</p>
                </div>
            </div>
            <div class="card-body" style="padding: 0;">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Estado</span>
                                    <div>
                                        <span style="color: #007bff">{{ datosExtension.datos_estado.nombre }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Codecs</span>
                                    <div>
                                        <span style="color: #007bff">{{ datosExtension.allaw }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Acceso Buzón de voz</span>
                                    <div>
                                        <span style="color: #007bff">{{ datosExtension.acceso_buzon }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Clave Buzón Voz</span>
                                    <div>
                                        <span style="color: #007bff">{{ datosExtension.clave_buzon_voz }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Servidor SIP</span>
                                    <div>
                                        <span style="color: #007bff">{{ datosExtension.servidor_sip }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Clave SIP</span>
                                    <div>
                                        <span style="color: #007bff">{{ datosExtension.password }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Conexión</span>
                                    <div>
                                        <span style="color: #007bff">{{ datosExtension.conexion }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Bria 5</span>
                                    <div style="display: grid;">
                                        <span style="color: #007bff; cursor: pointer" @click="descargar(datosExtension.bria5.Windows)">Descargar (Windows)</span>
                                        <span style="color: #007bff; cursor: pointer" @click="descargar(datosExtension.bria5.Mac)">Descargar (Mac)</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div style="display: flex; justify-content: space-between;">
                                    <span>Bria Enterprise</span>
                                    <div style="display: grid;">
                                        <span style="color: #007bff; cursor: pointer" @click="descargar(datosExtension.bria_enterprise.Windows)">Descargar (Windows)</span>
                                        <span style="color: #007bff; cursor: pointer" @click="descargar(datosExtension.bria_enterprise.Mac)">Descargar (Mac)</span>
                                        <span style="color: #007bff; cursor: pointer" @click="descargar(datosExtension.bria_enterprise.Android)">Android</span>
                                        <span style="color: #007bff; cursor: pointer" @click="descargar(datosExtension.bria_enterprise.IOS)">IOS</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Dialog>

</template>
<script>
import Timeline from 'primevue/timeline';
import { PwgsApi } from '../../../../services/PwgsApi';
import { FilterMatchMode } from 'primevue/api';
import DataTable from 'primevue/datatable';
export default {
    data() {
        return{
            spinner:false,
            visibleAccionRutina:false,
            visibleCola:false,
            numDetalle:null,
            colasDetalle:[],
            llamadaDetalle:null,
            visibleDetalle:false,
            configuracion:null,
            globalFilter:null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            fechaInicio:'',
            fechaFin:'',
            filtroOrigen:'',
            filtroDestino:'',
            filtroBusqueda:'',
            filtroTipo:'',
            datos:'',
            ordenarPor:'fecha',
            fechaOrdenAscendente:'true',
            origenOrdenAscendente:'false',
            destinoOrdenAscendente:'false',
            tipoOrdenAscendente:'false',
            duracionOrdenAscendente:'false',
            estadoOrdenAscendente:'false',
            fechaIcon:'fas fa-sort-down',
            origenIcon:'fas fa-sort-up',
            destinoIcon:'fas fa-sort-up',
            tipoIcon:'fas fa-sort-up',
            duracionIcon:'fas fa-sort-up',
            estadoIcon:'fas fa-sort-up',
            visibleRutina:false,
            accionesRutina:[],
            accionDetalle:null,
            totalmiembros:null,
            colaDetalles:'',
            extensiones:null,
            visiblemensajerutinas:false,
            mensajes:[],
            visibleDatosExtension:false,
            datosExtension:null,
            grabaciones:[],
        }
    },
    components:{
        DataTable,
        Timeline,
    },
    computed:{
        datosFiltrados(){
            if (this.datos) {
                let filtrados = this.datos;
                filtrados.sort((a, b) => {
                    if(this.ordenarPor == 'fecha') {                        
                        const fechaA = this.parseDate(a.tiempo_inicio);
                        const fechaB = this.parseDate(b.tiempo_inicio);
                        console.log('fechaord', this.fechaOrdenAscendente, fechaA, fechaB);
                        return this.fechaOrdenAscendente ? fechaB - fechaA : fechaA - fechaB;                        
                    }else if (this.ordenarPor === 'origen') {
                        const valorA = a.origen.toLowerCase();
                        const valorB = b.origen.toLowerCase();
                        return this.origenOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                    } else if (this.ordenarPor === 'destino') {
                        const valorA = a.destino.toLowerCase();
                        const valorB = b.destino.toLowerCase();
                        return this.destinoOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                    } else if (this.ordenarPor === 'tipo') {
                        const valorA = a.direccion.toLowerCase();
                        const valorB = b.direccion.toLowerCase();
                        return this.tipoOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                    } else if (this.ordenarPor === 'duracion') {
                        const valorA = a.duracion.toLowerCase();
                        const valorB = b.duracion.toLowerCase();
                        return this.duracionOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);
                    } else {
                        const valorA = a.contestado.toLowerCase();
                        const valorB = b.contestado.toLowerCase();
                        return this.estadoOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB); 
                    }
                });
                return filtrados;
            } else {             
                return [];
            }
        }
    },
    methods: {
        async cargarColas(){
            const api = new PwgsApi();
            var colasaux = await api.post('centralita-pwgs/configuracion', {"tipo":"colas"});
            this.colas = Object.values(colasaux);
        },
        async cargarGrabaciones(){
            const api = new PwgsApi();
            var grabacionesaux = await api.post('centralita-pwgs/configuracion', {"tipo":"grabaciones"});
            this.grabaciones = grabacionesaux;
        },
        descargar(link){
            console.log(link);
            window.open(link,'_blank');        
        },
        async abrirDatosExtension(extension){
            const api = new PwgsApi();
            try{
                var datos = await api.post('centralita-pwgs/configuracion/'+extension.id, {"tipo":"extensiones"});
                this.datosExtension=datos;
                this.visibleDatosExtension = true;
            }catch(e){
                console.error('Error cargando datos de extension', e);
            }
        },
        rutinaMensaje(mensaje){
            this.colasDetalle = mensaje.rutinas;
            this.visiblemensajerutinas = false;
            this.visibleRutina = true;
        },
        async cargarExtensiones(){
            if(!this.extensiones){
                const api = new PwgsApi();
                var extens = await api.post('/centralita-pwgs/configuracion', {"tipo":"extensiones"});
                this.extensiones = Object.values(extens);
                console.log('extens', this.extensiones);
            }
        },
        accionCola(dato){
            this.totalmiembros = dato.cola.miembros.length;
            this.colaDetalles = dato;
            this.visibleCola = true;
        },
        accionCola2(cola){
            var dato = {cola:cola};
            this.totalmiembros = dato.cola.miembros.length;
            this.colaDetalles = dato;
            this.visibleCola = true;
        },
        async accionRutina(dato){
            if(dato.cola){
                this.accionCola(dato);
            }else if(dato.id_accion){
                this.accionesRutina = [];
                const api = new PwgsApi();
                try{
                    var acs = await api.post('centralita-pwgs/configuracion/'+this.accionDetalle, {"tipo":"ejecuciones", "telefono":this.numDetalle, 'id_subaccion':dato.id_accion});
                    for(let colaaux of Object.values(acs)[0].sub_rutinas){
                        if(colaaux.fichero_audio){
                            colaaux.reproductor = false;
                        }
                        this.accionesRutina.push(colaaux);
                    }
                    this.visibleAccionRutina = true;
                    this.visibleRutina = false;
                }catch(e){
                    console.error('Error al cargar cola', e);
                }
            }else{
                return;
            }
        },
        async abrirRutina(dato){
            if(dato.id_accion){
                this.colasDetalle = [];
                const api = new PwgsApi();
                try{
                    var cola = await api.post('centralita-pwgs/configuracion/'+dato.id_accion, {"tipo":"ejecuciones", "telefono":dato.exten});                   
                    this.numDetalle = Object.keys(cola)[0];
                    this.accionDetalle = dato.id_accion;
                    var key = Object.keys(Object.values(cola)[0])[0];
                    console.log('a ver', key);
                    if(key == 'rutinas'){
                        for(let colaaux of Object.values(cola)[0].rutinas){
                            if(colaaux.fichero_audio){
                                colaaux.reproductor = false;
                            }
                            this.colasDetalle.push(colaaux);
                        }
                        this.visibleRutina = true;
                    }else{
                        this.mensajes = Object.values(cola)[0];
                        /*for(let colaaux of Object.values(cola)[0][key].rutinas){
                            if(colaaux.fichero_audio){
                                colaaux.reproductor = false;
                            }
                            this.colasDetalle.push(colaaux);
                        }*/
                        this.visiblemensajerutinas = true;
                    }
                    
                }catch(e){
                    console.error('Error al cargar cola', e);
                }
            }else{
                return;
            }
        },
        detalle(dato){
            this.llamadaDetalle = dato;
            if(this.llamadaDetalle.detalles[0].descripcion !='inicio'){
                this.llamadaDetalle.detalles.unshift({descripcion:'inicio'});
            }
            if(this.llamadaDetalle.detalles[this.llamadaDetalle.detalles.length - 1].descripcion !='final'){
                this.llamadaDetalle.detalles.push({descripcion:'final'});
            }
            this.visibleDetalle = true;
        },
        async cargarConfiguracion(){
            if(!this.configuracion){
                const api = new PwgsApi();
                this.configuracion = await api.post('centralita-pwgs/configuracion', {tipo: 'ejecuciones'});
                console.log('configuracion', this.configuracion);
                for(let conf of Object.entries(this.configuracion)){
                    conf[1].unshift({titulo:'inicio', numero:conf[0]});
                    conf[1].forEach(element => {
                        if(element.fichero_audio){
                            element.reproductor = false;
                        }
                    });
                }
            }
        },
        toggleOrden(campo) {
            this.ordenarPor = campo;
            if(campo == 'fecha'){
                this.fechaOrdenAscendente = !this.fechaOrdenAscendente;
                this.fechaIcon = this.fechaOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.origenOrdenAscendente = false;
                this.origenIcon = 'fa-sort-up';
                this.destinoOrdenAscendente = false;
                this.destinoIcon = 'fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fa-sort-up';
                this.duracionOrdenAscendente = false;
                this.duracionIcon = 'fa-sort-up';
                this.estadoOrdenAscendente = false;
                this.estadoIcon = 'fa-sort-up';                
            }else if(campo == 'origen'){
                this.origenOrdenAscendente = !this.origenOrdenAscendente;
                this.origenIcon = this.origenOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fa-sort-up';
                this.destinoOrdenAscendente = false;
                this.destinoIcon = 'fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fa-sort-up';
                this.duracionOrdenAscendente = false;
                this.duracionIcon = 'fa-sort-up';
                this.estadoOrdenAscendente = false;
                this.estadoIcon = 'fa-sort-up';
            }else if(campo == 'destino'){
                this.destinoOrdenAscendente = !this.destinoOrdenAscendente;
                this.destinoIcon = this.destinoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fa-sort-up';
                this.origenOrdenAscendente = false;
                this.origenIcon = 'fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fa-sort-up';
                this.duracionOrdenAscendente = false;
                this.duracionIcon = 'fa-sort-up';
                this.estadoOrdenAscendente = false;
                this.estadoIcon = 'fa-sort-up';
            }else if(campo == 'tipo'){
                this.tipoOrdenAscendente = !this.tipoOrdenAscendente;
                this.tipoIcon = this.tipoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fa-sort-up';
                this.origenOrdenAscendente = false;
                this.origenIcon = 'fa-sort-up';
                this.destinoOrdenAscendente = false;
                this.destinoIcon = 'fa-sort-up';
                this.duracionOrdenAscendente = false;
                this.duracionIcon = 'fa-sort-up';
                this.estadoOrdenAscendente = false;
                this.estadoIcon = 'fa-sort-up';
            }else if(campo == 'duracion'){
                this.duracionOrdenAscendente = !this.duracionOrdenAscendente;
                this.duracionIcon = this.duracionOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fa-sort-up';
                this.origenOrdenAscendente = false;
                this.origenIcon = 'fa-sort-up';
                this.destinoOrdenAscendente = false;
                this.destinoIcon = 'fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fa-sort-up';
                this.estadoOrdenAscendente = false;
                this.estadoIcon = 'fa-sort-up';
            }else{
                this.estadoOrdenAscendente = !this.estadoOrdenAscendente;
                this.estadoIcon = this.estadoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.fechaOrdenAscendente = false;
                this.fechaIcon = 'fa-sort-up';
                this.origenOrdenAscendente = false;
                this.origenIcon = 'fa-sort-up';
                this.destinoOrdenAscendente = false;
                this.destinoIcon = 'fa-sort-up';
                this.tipoOrdenAscendente = false;
                this.tipoIcon = 'fa-sort-up';
                this.duracionOrdenAscendente = false;
                this.duracionIcon = 'fa-sort-up';
            }
        },
        async cargarDatos(){
            this.spinner = true;
            const api = new PwgsApi();
            this.datos = [];
            try{
                var datoss = await api.post('centralita-pwgs/llamadas', 
                {
                    fecha_inicio:this.fechaesp(this.fechaInicio), fecha_fin: this.fechaesp(this.fechaFin),
                    origen:this.filtroOrigen, destino:this.filtroDestino, tipo_event:this.filtroTipo
                })
                for(let dts of Object.entries(datoss)){
                    var dato = dts[1];
                    dato.id = dts[0];
                    dato.tiempo_inicio = this.convertirFecha(dato.tiempo_inicio);
                    dato.reproductor = false;
                    this.datos.push(dato);
                }
                this.spinner = false;
            }catch(e){
                console.error('Error al cargar datos', e);
                this.spinner = false;
            }
        },
        convertirFecha(date){
            const fecha = date.split(" ")[0];
            const [year, month, day] = fecha.split('-');
            return day+'/'+month+'/'+year+' '+date.split(' ')[1];
        },
        parseDate(dateString) {
            console.log('fechallega', dateString);
            const fecha = dateString.split(" ")[0];
            const [hour, minute,second] = dateString.split(" ")[1].split(":");
            const [day, month, year] = fecha.split('/');
            return new Date(`${year}-${month}-${day} ${hour}:${minute}:${second}`);
        },
        cargarFechas(){
            const fecha = Date.now();
            const hoy = new Date(fecha).toLocaleDateString();
            this.fechaInicio = this.fechaing(hoy);
            this.fechaFin = this.fechaing(hoy);
        },
        fechaing(fecha) {
            const [dia, mes, año] = fecha.split("/");
            return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
        },
        fechaesp(fecha) {
            const [año, mes, dia] = fecha.split("-");
            return `${dia}/${mes}/${año}`;
        },
    },
    watch:{
        globalFilter(newFilter) {
            this.filters.global.value = newFilter;
        },
    },
    mounted() { 
        this.cargarFechas();
        this.cargarDatos();
    },
}

</script>
<style  scoped>

    ::v-deep(.customized-timeline) {

        .p-timeline-event-opposite {
            flex: 0;
        }
    }

    .card-header::after{
        display:none;
    }

    .mensaje:hover{
        background-color: #f0f0f0;
    }
    .difuminado{
        filter: blur(6px);
        pointer-events: none;
        -webkit-user-select: none;
            /* Safari */
        -ms-user-select: none;
            /* IE 10 and IE 11 */
        user-select: none;
            /* Standard syntax */
    }
    .spinner {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border: 9px solid;
        border-color: #d9e3ff;
        border-right-color: #007bff;
        animation: spinner-d3wgkg 1s infinite linear;
        position: absolute;
        z-index:1000;
        top: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -50px;
            
    }

    @keyframes spinner-d3wgkg {
        to {
            transform: rotate(1turn);
        }
    }
</style>
