<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div v-if="(id > 0)" class="col-sm-6">
                    <h1>{{ $t('general.lineafacturacion') }}</h1>
                </div>
                <div v-else class="col-sm-6">
                    <h1>{{ $t('general.nuevalineafacturacion') }}</h1>
                </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <!-- Datos Perito -->
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{linea.nombre_serie}}</h3>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                                <li class="list-group-item">
                                    <b>{{ $t('general.numeroactual') }}</b> <a class="float-right">{{linea.siguiente_numero}}</a>
                                </li>
                                <li class="list-group-item">
                                    <b>{{ $t('general.numeroactualabono') }}</b> <a class="float-right">{{linea.siguiente_numero_abono}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                    <h5>{{ $t('general.ultimamodificacion') }}</h5>
                    <p>15/12/2021 15:35h</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_lineafacturacion" data-toggle="tab">{{ $t('general.datos') }}</a></li>  
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_lineafacturacion">
                            <lineafacturacion-datos 
                            :id="id"
                            :linea="linea"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import LineaFActuracionDatos from './datos/LineaFacturacionDatos.vue';
export default {
    props: ['id'],
    components: {
        'lineafacturacion-datos': LineaFActuracionDatos,
    },
    data() {
        return {
            linea: [],
        }
    },
    methods: {
        async obtenerLineas(id) {
            /* get  modulos/pwgsapi/index.php/lineas-facturacion/:id */
            const api = new PwgsApi;
            const datos = await api.get('lineas-facturacion/' + id);
            this.linea = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/lineasfacturacion/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.linea.nombre_serie;
                    this.$store.getters.ventanas[i].alt = "Línea Facturación: " + this.linea.nombre_serie + ' || ' + this.linea.prefijo_factura_serie;
                }
            }
            /**/
        },
    },
    mounted() {
        this.obtenerLineas(this.id);
    },
    watch: {
        id() {
            this.obtenerLineas(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/lineasfacturacion/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.linea.nombre_serie;
                    this.$store.getters.ventanas[i].alt = "Línea Facturación: " + this.linea.nombre_serie + ' || ' + this.linea.prefijo_factura_serie;
                }
            }
            /**/
        }

    }
}
</script>
