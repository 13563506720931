<template>
    <div class="row">
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-12">
                    <servicio-presupuesto-datos-descripcion :seleccionado="seleccionado" :id="id"/>          
                </div>
                <div class="col-md-12">
                    <servicio-presupuesto-datos-condiciones :seleccionado="seleccionado"  :id="id"/>
                </div>
                <div class="col-md-12">
                    <servicio-presupuesto-datos-perito :seleccionado="seleccionado" :id="id" />
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <servicio-presupuesto-datos-generados @actualizar="actualizar" :seleccionado="seleccionado" :id="id" />
        </div>
    </div>
</template>
<script>
import serviciopresupuestodatosdescripcion from './presupuesto_descripcion.vue'; 
import serviciopresupuestodatoscondiciones from './presupuesto_condiciones.vue';
import serviciopresupuestodatosperito from './presupuesto_perito.vue';
import serviciopresupuestodatosgenerados from './presupuesto_generados.vue';


export default ({
        props: ['id','seleccionado',],

    data() {
        return {
        }},
    components: {
        'servicio-presupuesto-datos-descripcion': serviciopresupuestodatosdescripcion,
        'servicio-presupuesto-datos-condiciones': serviciopresupuestodatoscondiciones,
        'servicio-presupuesto-datos-perito': serviciopresupuestodatosperito,
        'servicio-presupuesto-datos-generados': serviciopresupuestodatosgenerados,
    },
    methods: {
        actualizar(){
            console.log('actualizar2'); 
            this.$emit('actualizar');
        }
    },
 
    watch: {
        
    }
})
</script>