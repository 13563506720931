<template>
    <div class="row">
        <div class="col-md-8">
            <i class="far fa-address-card mr-1"></i> {{ $t('general.nombre') }}
            <div class="row" style="column-gap: 5px;">
                <p class="">
                    <strong>{{perjudicado.nombre}}</strong>
                </p>
                <button style="height: 30px;" type="button" title="Notificar al perjudicado para que se ponga en contacto con nosotros" class="btn btn-outline-info btn-sm"
                        v-on:click='notificar()'><i class="fas fa-sms"></i></button> 
            </div>
            
        </div>
        <div class="col-md-4">               
            <i class="far fa-id-card mr-1"></i> {{ $t('general.identificacion') }}
            <p class="">
                <strong>{{ perjudicado.nif }}</strong>
            </p>
        </div>
    </div>
    <div class="row">

        <div class="col-md-6 col-xs-12" v-if="perjudicado.telefono1 && perjudicado.telefono1.length >= 9">
            <div v-if="perjudicado.telefono1_descripcion==''" class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 1
                </div>
                <input type="radio" v-if="tlfpref == 1" title="teléfono prioritario" value="1" v-model="tlfpref">
            </div>
            <div v-else class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ perjudicado.telefono1_descripcion.slice(0,29) }}
                </div>
                <input type="radio" v-if="tlfpref == 1" title="teléfono prioritario" value="1" v-model="tlfpref">
            </div>
            <div class="btn-group" style="display: flex; flex-wrap: wrap;">
                <button type="button" title="Llamar" v-if="perjudicado.telefono1" v-on:click='llamar(perjudicado.telefono1)'
                    class="btn btn-outline-info  btn-sm">{{perjudicado.telefono1}}</button>
            </div>
        </div>

        <div class="col-md-6 col-xs-12" v-if="perjudicado.telefono2 && perjudicado.telefono2.length >= 9">
            <div v-if="perjudicado.telefono2_descripcion==''" class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 2
                </div>
                <input type="radio" v-if="tlfpref == 2" title="teléfono prioritario" value="2" v-model="tlfpref">
            </div>
            <div v-else class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ perjudicado.telefono2_descripcion.slice(0,29) }}
                </div>
                <input type="radio" v-if="tlfpref == 2" title="teléfono prioritario" value="2" v-model="tlfpref">
            </div>
            <div class="btn-group" style="display: flex; flex-wrap: wrap;">
                <button type="button" title="Llamar" v-if="perjudicado.telefono2" v-on:click='llamar(perjudicado.telefono2)'
                    class="btn btn-outline-info  btn-sm">{{perjudicado.telefono2}}</button>
            </div>
        </div>

        <div class="col-md-6 col-xs-12" v-if="perjudicado.telefono3 && perjudicado.telefono3.length >= 9">
            <div v-if="perjudicado.telefono3_descripcion==''" class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 3
                </div>
                <input type="radio" v-if="tlfpref == 3" title="teléfono prioritario" value="3" v-model="tlfpref">
            </div>
            <div v-else class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ perjudicado.telefono3_descripcion.slice(0,29) }}
                </div>
                <input type="radio" v-if="tlfpref == 3" title="teléfono prioritario" value="3" v-model="tlfpref">
            </div>
            <div class="btn-group" style="display: flex; flex-wrap: wrap;">
                <button type="button" title="Llamar" v-if="perjudicado.telefono3" v-on:click='llamar(perjudicado.telefono3)'
                    class="btn btn-outline-info  btn-sm">{{perjudicado.telefono3}}</button>
            </div>
        </div>
        
    </div>
    <div class="row">
        <div class="col-md-8">
            <i class="fas fa-map-marker-alt mr-1"></i> {{ $t('general.direccion') }}
            <p class="">
                <strong>{{ perjudicado.direccion }}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-city mr-1"></i> {{ $t('general.ciudad') }}
            <p class="">
                <strong>{{ perjudicado.poblacion }}</strong>
            </p>
        </div>
    </div>
</template>
<script>  
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','perjudicado'],
    data(){
        return {
            tlfpref:0, 
        }
        
    },
    setup() {     
    },
    methods: {
        async notificar(){
            const api = new PwgsApi();
            let subidadatos = {id_cliente : this.$props.perjudicado.idcliente};

            const resp = await api.put('servicios/'+this.id+'/notificar-cliente',subidadatos);
            console.log('notidic',resp);
        },
        comprobarPref(){
            if(this.perjudicado.telefono_prioritario==1){
                this.tlfpref = '1';
            }else if(this.perjudicado.telefono_prioritario==2){
                this.tlfpref = '2';
            }else if(this.perjudicado.telefono_prioritario==3){
                this.tlfpref = '3';
            }
        },
       llamar : function(){
           window.open("tel:963559516");
       }
    },
    mounted(){
        this.comprobarPref();
    },
})


</script>