<template>
    <li class="nav-item" 
        :class="{ active: esVentanaActiva }"
        role="presentation">
        <tab-link 
            :nombre="ruta.name" 
            :titulo="ruta.name"
            :activo="activo"
            :ruta="ruta"
            @tabSelected="seleccionarRuta" 
            @mouseover="mostrarBotonCerrar" 
            >
                <tab-close v-if="ruta.name !='Escritorio'" @click.prevent="cerrarVentana" :mostrar="mostrarCerrar"></tab-close>
            </tab-link>
    </li>
</template>
<script>
import TabLink from './TabLink.vue'
import TabClose from './TabClose.vue'
export default {
    emits: [
        'rutaSeleccionada',
        'ventanaCerrar',
    ],
    components: {
        TabLink,
        TabClose,
    },
    props: ['ruta'],
    data() {
        return {
            mostrarCerrar: true,
        }
    },
    computed: {
        esVentanaActiva() {
            return this.ruta.fullPath === this.$route.fullPath;
        },
        activo() {
            return this.esVentanaActiva;
        },
        titulo() {
            return this.ruta.name; 
        }
    },
    methods: {
        seleccionarRuta() {
            this.$emit('rutaSeleccionada', this.ruta);

        },
        cerrarVentana() {
            this.$emit('ventanaCerrar', this.ruta);
        },
        
        mostrarBotonCerrar() {
            //console.log('Mostramos botón cerrar');
            this.mostrarCerrar = true;
        },
      
    },
    watch: {
      
    }
}
</script>
<style scoped>
.nav-item {
    position: relative;
}
.nav-item.active {
    background: rgb(141, 171, 252);
}
</style>