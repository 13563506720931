<template>
        <section class="content-header" style="margin-top: -40px;">
            <div class="row ">
                <div class="col-md-8">
                    <trabajosrealizadostotales 
                        :actualizartotales = "actualizartotales"
                        :recargar="recargar" 
                        :id="id" 
                        :totalrent="totalrent" 
                        :servicio="servicio" 
                        :totalcliente="totalcliente" 
                        :totaloperario="totaloperario" 
                        :operarios="operarios" /> 
                </div>
                <div class="col-md-4 text-right">
                    <div class="row">
                        <div class="col-md-8">   
                            <div class="btn-group btn-group-toggle btn-sm" data-toggle="buttons" :title="$t('general.filtrarpor')">
                                <label class="btn btn-outline-secondary" :class="{ 'active': selectedOption === 'option_b3' }">
                                    <input type="radio" name="options" id="option_b3" value="option_b3" v-model="selectedOption" @change="mostrartodo()" checked autocomplete="off"> {{$t('general.todos')}}
                                </label>
                                <label class="btn btn-outline-secondary" :class="{ 'active': selectedOption === 'option_b1' }">
                                    <input type="radio" name="options" id="option_b1" value="option_b1" v-model="selectedOption" @change="togglecliente()" autocomplete="off"> {{$t('general.a cliente')}}
                                </label>
                                <label class="btn btn-outline-secondary" :class="{ 'active': selectedOption === 'option_b2' }">
                                    <input type="radio" name="options" id="option_b2" value="option_b2" v-model="selectedOption" @change="toggleoperario()" autocomplete="off"> {{$t('general.a operario')}}
                                </label>
                            </div>
                        </div>
                        <div :class="facturaClass" class="col-md-4" style="margin: 0; height: 42px; display: flex; justify-content: center; align-items: center">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" v-model="checkfacturar" class="custom-control-input" @change="facturar()" id="para_facturar">
                                <label class="custom-control-label" for="para_facturar">{{ $t('general.para facturar')}}</label>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
         <div class="col-md-12" v-if="operariostrabajo !='' && mostrarboton==true">
            <Button type="button"  @click="mostrarnuevooperario = !mostrarnuevooperario" style="font-size:small" title="Nuevo trabajo">Nuevo Operario</Button>
                        <p>&nbsp;</p>  
         </div>
         <div class="col-md-12" v-if="mostrarnuevooperario && mostrarboton==true">
            <TrabajoRealizadoNuevoOperario :id="id"  :operariostrabajo="operariostrabajo" :servicio="servicio">
            </TrabajoRealizadoNuevoOperario>
            <p>&nbsp;</p> 
         </div>
         <div class="card card-warning" v-if="!operarios.operario">
            <div class="card-header" style="text-align: center;">
                <h5>Para agregar trabajos debe crear primero una cita.</h5>
            </div>
         </div>
         <div v-else>
             <div class="col-md-12">     
                 <trabajosrealizadosoperarios 
                    ref="trabajosoperarios"
                    :actualizartotales = "actualizartotales"
                     :recargar="recargar" 
                     :servicio="servicio" 
                     :totalrent="totalrent" 
                     :ocultarcliente="ocultarcliente" 
                     :ocultaroperario="ocultaroperario" 
                     :totalcliente="totalcliente" 
                     :totaloperario="totaloperario"  
                     :id="id" 
                     :operarios="operarios"/>
             </div>
             <div class="col-md-12">            
                 <trabajosrealizadosproveedores 
                     :recargar="recargar" 
                     :servicio="servicio" 
                     :totalrent="totalrent" 
                     :ocultarcliente="ocultarcliente" 
                     :ocultaroperario="ocultaroperario" 
                     :totalcliente="totalcliente" 
                     :totaloperario="totaloperario" 
                     :id="id" 
                     :operarios="operarios"/>
             </div>
         </div>
        </section>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import TrabajoRealizadoNuevoOperario from './TrabajoRealizadoNuevoOperario.vue';
import trabajosrealizadosoperarios from './TrabajosRealizadosOperario.vue';
import trabajosrealizadosproveedores from './TrabajosRealizadosProveedor.vue';
import trabajosrealizadostotales from './TrabajosRealizadosTotales.vue';
export default ({
    props:['id','servicio','actualizarfacturado','auxtrabajos'],
    setup() {
        return;
    },
    components: {
       trabajosrealizadosoperarios,
        trabajosrealizadostotales,
        trabajosrealizadosproveedores,
        TrabajoRealizadoNuevoOperario
    },
    data() {
         return {  
            actualizartotales:false,
            ocultarcliente: false,
            ocultaroperario: false,
            colorFactura: '',
            checkfacturar: false,
            test: '',
            recargar: false,
            mostrarnuevooperario:false,
            selectedOption: 'option_b3', 
            operarios: [] ,// Agregar esta línea
            operariostrabajo: '',
            mostrarboton: false,
        };
    },
    methods: {

        togglecliente() {
            this.selectedOption = 'option_b1'
            this.ocultarcliente = false;
            this.ocultaroperario = true;
        },
        toggleoperario() {
            this.selectedOption = 'option_b2'
            this.ocultaroperario = false;
            this.ocultarcliente = true;
        },
        mostrartodo() {
            this.selectedOption = 'option_b3'
            this.ocultarcliente = false;
            this.ocultaroperario = false;
        },
        togglerecargar(idoper) {
            this.cargardatos(idoper);
        },
        async cargardatos(idoper) {
            const api = new PwgsApi();
            
            var opers = await api.get('trabajos/' + this.id + '/trabajos-servicio');
            
            console.log('datosactu',idoper ,opers, this.operarios);

            if(idoper){
                //var opercambio = {};
                /*for(let oper of Object.entries(opers.operario)){
                    if(oper[0] == idoper.operarios_idoperario){
                        opercambio.id = oper[0];
                        opercambio.datos = oper[1];
                    }
                }*/
                this.operarios.operario[idoper.operarios_idoperario] = opers.operario[idoper.operarios_idoperario];
                this.operarios.totales = opers.totales;
                console.log('datosac resultados',this.operarios);
                this.$refs.trabajosoperarios.actualiztotales(idoper.operarios_idoperario, this.operarios);
                this.actualizartotales = !this.actualizartotales;
            }else{
                this.operarios = opers;
            }
            this.cargaroperarios();
        },
        
        async facturar() { 
            const api = new PwgsApi();
            //alert("facturar "+this.checkfacturar);
            const subidadatos = { "facturar_servicio": this.checkfacturar ? 1 : 0 };
                try {
                    this.test = await api.put('trabajos/' + this.$props.id + '/facturar-servicio', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                catch (error) {
                    this.checkfacturar = false;
                    this.$toast.add({ severity: 'error', summary: "AcciÃ³n no realizada", detail: error, life: 5000 });
                }
        },
        async cargaroperarios() {
            const api = new PwgsApi();
            let aux = await api.get('trabajos/' + this.$props.id + '/operarios-sin-trabajos');
            this.operariostrabajo = aux.operarios_sin_trabajos;
            console.log("operariossintrabajos", aux);
            this.mostrarboton = true;
        },
    },
    computed: {
        facturaClass() {
            switch (this.colorFactura) {
                case 'FondoVerde':
                    return 'col-md-4 bg-success disabled card';
                case 'FondoRojo':
                    return 'col-md-4 bg-danger disabled card';
                default:
                    return 'col-md-4 card'; // por si acaso
            }
        }
    },
    watch: {
        actualizarfacturado(){
            console.log('entracargardatos');
            this.cargardatos();
        },
        checkfacturar(newVal) {
            //console.log(newVal);
            //alert("facturar "+this.checkfacturar);            
            // Actualiza colorFactura basado en el nuevo valor de checkfacturar
            if(this.servicio.color_factura == 'FondoVerde' || this.servicio.color_factura == 'FondoRojo'){
                this.colorFactura = newVal ? 'FondoVerde' : 'FondoRojo';
            }else{
                this.colorFactura = '';
            }
        },
        id() { 
            this.cargardatos();

        },
        recargar() {
            this.cargardatos();
        },
        servicio(){
            if (typeof this.servicio.facturar_servicio !== 'undefined') {                
                this.checkfacturar = this.servicio.facturar_servicio == "1";
            }
            this.colorFactura = this.servicio.color_factura ? this.servicio.color_factura : ''; 
            this.cargardatos();
        },
        auxtrabajos() {
            this.cargardatos();
        }
    },
    mounted() {
        // AsegÃºrate de que 'servicio' estÃ¡ disponible y tiene la propiedad 'facturar_servicio'
        if (this.servicio && typeof this.servicio.facturar_servicio !== 'undefined') {
            this.checkfacturar = this.servicio.facturar_servicio == "1";
        }
        // Inicializa colorFactura basÃ¡ndose en la prop servicio
        this.colorFactura = this.servicio && this.servicio.color_factura ? this.servicio.color_factura : ''; 
        this.cargardatos();
    },        
})
</script>
