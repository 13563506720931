<template>
    <div class="row">
        <div class="col-md-8">
            <div class="form-group">
                <input type="text" v-model="auxbuscador" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <button type="button" class="btn btn-default" @click="buscarcliente()">Buscar Cliente</button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <Dropdown v-model="aux" @change="cargarasegurado()" :options="buscador" class="md:w-20rem" />
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ $t('general.nombre') }}</label>
                <input type="text" v-model="clienteasegurado.nombre" class="form-control input-sm">
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label>{{ $t('general.razonsocial') }}</label>
                <input type="text" v-model="clienteasegurado.razon_social" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>{{ $t('general.nif') }}</label>
                <input type="text" v-model="clienteasegurado.nif" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ $t('general.direccion') }}</label>
                <input type="text" v-model="clienteasegurado.direccion" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.poblacion') }}</label>
                <input type="text" v-model="clienteasegurado.poblacion" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.cpostal') }}</label>
                <input type="text" v-model="clienteasegurado.codigo_postal" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.provincia') }}</label>
                <input type="text" v-model="clienteasegurado.provincia" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-4">

            <div class="form-group">
                <label>{{ $t('general.telefono') }} 1</label>
                <div class="input-group">
                    <input type="text" v-model="clienteasegurado.telefono1" class="form-control input-sm">
                    <div class="input-group-append">
                        <span class="input-group-text" title="¿Es Móvil?">
                            <input type="checkbox" name="telefono1_movil" v-model="clienteasegurado.telefono1_movil"
                                v-bind:true-value="1" v-bind:false-value="0">
                        </span>
                    </div>
                </div>
                <textarea class="form-control input-sm" v-model="clienteasegurado.telefono1_descripcion"
                    :placeholder="$t('general.descripcion')"></textarea>
                <div class="row" style="justify-content: center; column-gap: 2em;">
                    <label for="radio1" style="margin: 0;">Preferente</label>
                    <input id="radio1" value="1" v-model="preferente" type="radio">
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.telefono') }} 2</label>
                <div class="input-group">
                    <input type="text" v-model="clienteasegurado.telefono2" class="form-control input-sm">
                    <div class="input-group-append" title="¿Es Móvil?">
                        <span class="input-group-text">
                            <input type="checkbox" v-model="clienteasegurado.telefono2_movil" v-bind:true-value="1"
                                v-bind:false-value="0">
                        </span>
                    </div>
                </div>
                <textarea class="form-control input-sm" v-model="clienteasegurado.telefono2_descripcion"
                    :placeholder="$t('general.descripcion')"></textarea>
                <div class="row" style="justify-content: center; column-gap: 2em;">
                    <label for="radio2" style="margin: 0;">Preferente</label>
                    <input id="radio2" value="2" v-model="preferente" type="radio">
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.telefono') }} 3</label>
                <div class="input-group">
                    <input type="text" v-model="clienteasegurado.telefono3" class="form-control input-sm">
                    <div class="input-group-append" title="¿Es Móvil?">
                        <span class="input-group-text">
                            <input type="checkbox" v-model="clienteasegurado.telefono3_movil" v-bind:true-value="1" v-bind:false-value="0">
                        </span>
                    </div>
                </div>
                <textarea class="form-control input-sm" v-model="clienteasegurado.telefono3_descripcion"
                    :placeholder="$t('general.descripcion')"></textarea>
                <div class="row" style="justify-content: center; column-gap: 2em;">
                    <label for="radio3" style="margin: 0;">Preferente</label>
                    <input id="radio3" value="3" v-model="preferente" type="radio">
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label>Email</label>
                <input type="text" v-model="clienteasegurado.email" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-12">
            <label>IBAN ***</label>
            <div class="input-group mb-3">
                <input type="text" class="form-control form-control-sm" style="width:20px" v-model="numero_iban1"
                    maxlength="4">
                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                <input type="text" class="form-control form-control-sm" v-model="numero_iban2" maxlength="4">
                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                <input type="text" class="form-control form-control-sm" v-model="numero_entidad" maxlength="4">
                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                <input type="text" class="form-control form-control-sm" v-model="numero_oficina" maxlength="4">
                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                <input type="text" class="form-control form-control-sm" v-model="numero_dc" maxlength="4">
                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                <input type="text" class="form-control form-control-sm" v-model="numero_cuenta" maxlength="4">
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label>{{ $t('general.poliza') }}</label>
                <input type="text" v-model="clienteasegurado.poliza" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-6" v-if="permisosusuario.includes('soporte')">
            <div class="form-group">
                <label>{{ $t('general.subcuenta') }}</label>
                <br>
                <input type="text" v-model="clienteasegurado.contador_subcuenta" class="form-control input-sm">
            </div>  
        </div>
        <div class="col-md-6" v-else>
            <div class="form-group">
                <label>{{ $t('general.subcuenta') }}</label>
                <br>
                <label>{{ clienteasegurado.contador_subcuenta }}</label>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ $t('general.companyia') }}</label>
                <input type="text" v-model="clienteasegurado.nombre_compania" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label>{{ $t('general.observaciones') }}</label>
                <textarea v-model="clienteasegurado.observaciones" class="form-control"></textarea>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import Dropdown from 'primevue/dropdown';
export default ({
    props: ['id', 'servicio', 'asegurado','guardaraseguradotoken'],

    setup() {
    },
    data() {
        return {
            preferente:0,
            clienteasegurado: [],
            clientes: [],
            aux: '',
            buscador: [],
            auxbuscador: '',
            ids: [],
            idcliente: '',
            numero_iban1:'',
            numero_iban2: '',
            numero_cuenta: '',
            numero_entidad: '',
            numero_oficina: '',
            numero_dc:'',
            permisosusuario:[],
        }
    },
    mounted() {
        //this.clienteasegurado = this.$props.asegurado;
        this.idcliente = this.$props.asegurado.idcliente;
        this.listaclientes();
        this.cargarasegurado();
        this.comprobarPreferente();
        this.comprobarpermisos();
    },
    methods: {
        comprobarPreferente(){
            if(this.asegurado.telefono_prioritario!=0) {
                this.preferente = this.asegurado.telefono_prioritario;
            }    
        },
        async listaclientes() { 
            //GET  modulos/pwgsapi/index.php/clientes/simple
            const api = new PwgsApi;
            const datos = await api.get('clientes/simple');
            this.clientes = datos.datos;  
        },
        buscarcliente() { 
            this.buscador.length = 0;
            if (this.auxbuscador.length < 3) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Debe introducir al menos 3 caracteres para buscar un cliente', life: 5000 });

            }
            else {
                for (let i = 0; i < this.clientes.length; i++) {
                    if (this.clientes[i].nombre.includes(this.auxbuscador)) {
                        this.buscador.push(this.clientes[i].nombre);
                        this.ids.push(this.clientes[i].idcliente);
                    }
                }
            }
        },
        async cargarasegurado() { 
        //GET  modulos/pwgsapi/index.php/clientcl
            for (let i = 0; i < this.buscador.length; i++){
                if (this.aux == this.buscador[i]) { 
                    this.idcliente = this.ids[i];
                }
            }
           const api = new PwgsApi;
            const datos = await api.get('clientes/' + this.idcliente);
            this.clienteasegurado = datos.datos;
            this.numero_iban1 = this.clienteasegurado.numero_iban1;
            this.numero_iban2 = this.clienteasegurado.numero_iban2;
            this.numero_cuenta = this.clienteasegurado.numero_cuenta;
            this.numero_entidad = this.clienteasegurado.numero_entidad;
            this.numero_oficina = this.clienteasegurado.numero_oficina;
            this.numero_dc = this.clienteasegurado.numero_dc;

        },
        async guardarasegurado() { 
            //PUT /clientes/:id/modificar-cliente-servicio/:id_servicio
            const datossubida = { 
                provincia: this.clienteasegurado.provincia, 
                poblacion: this.clienteasegurado.poblacion, 
                nombre: this.clienteasegurado.nombre, 
                razon_social: this.clienteasegurado.razon_social, 
                nif: this.clienteasegurado.nif, 
                telefono1: this.clienteasegurado.telefono1, 
                telefono2: this.clienteasegurado.telefono2, 
                telefono3: this.clienteasegurado.telefono3, 
                email: this.clienteasegurado.email, 
                fax: this.clienteasegurado.fax,  
                direccion: this.clienteasegurado.direccion, 
                codigo_postal: this.clienteasegurado.codigo_postal, 
                telefono1_descripcion: this.clienteasegurado.telefono1_descripcion,
                telefono2_descripcion: this.clienteasegurado.telefono2_descripcion,
                telefono3_descripcion: this.clienteasegurado.telefono3_descripcion,
                numero_iban1: this.numero_iban1,
                numero_iban2: this.numero_iban2,
                numero_cuenta: this.numero_cuenta,
                numero_entidad: this.numero_entidad,
                numero_oficina: this.numero_oficina,
                numero_dc: this.numero_dc,
                subcuenta: this.clienteasegurado.contador_subcuenta
            };
            if(this.preferente!=0){                    
                datossubida.telefono_prioritario = this.preferente; 
            }
            try {
                const api = new PwgsApi;
                await api.put('clientes/' + this.idcliente + '/modificar-cliente-servicio/' +this.$props.id, datossubida);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.$emit('actualizarasegurado');
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async asignarasegurado() { 
            // POST  modulos/pwgsapi/index.php/clientes/:id/modificar-cliente-servicio/:id_servicio

            try {
                const api = new PwgsApi; 
                const subidadatos = { nombre: this.clienteasegurado.nombre };
                await api.put('clientes/' + this.idcliente + '/modificar-cliente-servicio/' + this.$props.id, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

        },
        async comprobarpermisos(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
            var permisos = resppermisos.datos;
            for(let permiso of permisos){
                this.permisosusuario.push(permiso.nombre_permiso);
            }
        }
    },
    components: {
        'Dropdown': Dropdown,
    },
    watch: {
        id() { 
            this.auxbuscador = '';
            this.listaclientes();
            this.clienteasegurado = this.$props.asegurado;
            this.idcliente = this.$props.asegurado.idcliente;
            this.cargarasegurado();

        },
        aux() { 
            this.cargarasegurado()
        },
        guardaraseguradotoken() { 
            this.guardarasegurado()
            this.asignarasegurado();
            this.$parent.$parent.$parent.$parent.cargardatos();

        }
    },
    
})




</script>
