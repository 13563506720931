<template>
    <div class="container-fluid">
        <Dialog :style="{ width: '60rem' }" @hide="cerrarDialog" header="Palabras clave" modal
         v-model:visible="claveDialog">
            <p v-for="clave in Object.keys(claves)" :key="clave">{{ clave }}</p>
        </Dialog>
        <Dialog @hide="cerrarDialogPlantilla" header="Modificar plantilla" modal
         v-model:visible="plantillaDialog">
            <div style="display: grid; grid-template-columns: 1fr 1fr; align-items: start; gap: 25px;">
                <div style="display: grid; gap: 15px;">
                    <div class="form-group">
                        <label for="">Orientación</label>
                        <select name="" id="" class="form-control mb-2" v-model="docPlantilla.orientacion_plantilla">
                            <option value="P">Vertical</option>
                            <option value="L">Horizontal</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="">Cabecera</label>
                        <Editor editorStyle="height: 160px" v-model="docPlantilla.cabecera_html_documento">
                        </Editor>
                    </div>
                    <div class="form-group">
                        <label for="">Cuerpo</label>
                        <Editor editorStyle="height: 160px" v-model="docPlantilla.cuerpo_html_documento">
                        </Editor>
                    </div>
                    <div class="form-group">
                        <label for="">Pie</label>
                        <Editor editorStyle="height: 160px" v-model="docPlantilla.pie_html_documento">
                        </Editor>
                    </div>
                    <div>
                        <button class="btn btn-sm btn-success" @click="guardarPlantilla">Guardar</button>
                    </div>
                </div>
                <div>
                    <h4 style="font-weight: bolder;">Palabras clave</h4>
                    <p v-for="clave in Object.keys(clavesPlantilla)" :key="clave">{{ clave }}</p>
                </div>
            </div>
        </Dialog>
        <section class="content-header">
            <div style="display: flex; justify-content: space-between;">
                <h3>Gestión documentos</h3>
                <div style="display: flex; gap: 10px;">
                    <input style="width: 250px;" v-model="nombreNuevoDocumento" type="text" class="form-control" placeholder="Nombre documento">
                    <select style="width: 200px;" v-model="tipoNuevoDocumento" class="form-control" id="">
                        <option value="">Tipo documento</option>
                        <option v-for="sec in Object.entries(secciones)" :key="sec[0]" :value="sec[0]">{{ sec[1] }}</option>
                    </select>
                    <div>
                        <button class="btn  btn-light" style="border: 1px solid grey;" @click="crearDocumento">Crear documento</button>
                    </div>
                </div>
            </div>            
        </section>
        <section class="content">
            <table class="table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Sección</th>
                        <th>Documento</th>
                        <th>Compañías</th>
                        <th>Operario</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="doc in documentos" :key="doc.id_documento">
                        <td>
                            <input type="text" v-model="doc.nombre" class="form-control">
                        </td>
                        <td>
                            <select name="" id="" class="form-control mb-2" v-model="doc.seccion">
                                <option v-for="sec in Object.entries(secciones)" :key="sec[0]" :value="sec[0]">{{ sec[1] }}</option>
                            </select>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input v-model="doc.parte_xdefecto_documento" v-bind:true-value="1" v-bind:false-value="0" type="checkbox" aria-label="Checkbox for following text input">
                                    </div>
                                </div>
                                <input type="text" disabled class="form-control" value="Parte Operario" aria-label="Text input with checkbox">
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input v-model="doc.defecto_visita" v-bind:true-value="1" v-bind:false-value="0" type="checkbox" aria-label="Checkbox for following text input">
                                    </div>
                                </div>
                                <input type="text" disabled class="form-control" value="Asign. a visita" aria-label="Text input with checkbox">
                            </div>
                        </td>
                        <td>
                            <TabView v-if="doc.tipo_documento == 'fichero'" :activeIndex="0" @tab-change="cambia($event, doc)">
                                <TabPanel :index="fichero" header="Fichero">
                                    <div style="display: grid; grid-template-columns: 1fr 2fr;">
                                        <button class="btn btn-lg" v-if="doc.ruta" @click="descargar(doc)"><i class="fas fa-file"></i></button>
                                        <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event, doc)" :auto="true" chooseLabel="Adjuntar" />
                                    </div>
                                </TabPanel>
                                <TabPanel :index="Plantilla" header="Plantilla">
                                    <button class="btn btn-sm btn-primary" @click="abrirPlantilla(doc)">Modificar plantilla</button>
                                </TabPanel>
                            </TabView>
                            <TabView v-else :activeIndex="1" @tab-change="cambia($event, doc)">
                                <TabPanel :index="fichero" header="Fichero">
                                    <div style="display: grid; grid-template-columns: 1fr 2fr;">
                                        <button class="btn btn-lg" v-if="doc.ruta" @click="descargar(doc)"><i class="fas fa-file"></i></button>
                                        <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event, doc)" :auto="true" chooseLabel="Adjuntar" />
                                    </div>
                                </TabPanel>
                                <TabPanel :index="Plantilla" header="Plantilla">
                                    <button class="btn btn-sm btn-primary" @click="abrirPlantilla(doc)">Modificar plantilla</button>
                                </TabPanel>
                            </TabView>
                        </td>
                        <td style="display: grid;">
                            <MultiSelect  v-model="doc.companyias" :options="companias" optionLabel="nombre"
                            optionValue="id" placeholder="Seleccione compañía" :maxSelectedLabels="3" class="multiselect-small" style="width: 350px;"/>
                            <div style="display: flex; gap: 5px; align-items: center;">
                                <label style="margin: 0;" for="">Mostrar a compañías:</label>
                                <input v-bind:true-value="1" v-bind:false-value="0" type="checkbox" v-model="doc.mostrar_companyias">
                            </div>
                        </td>
                        <td>
                            <select class="form-control" v-model="doc.mostrar_operarios" id="">
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                            <div v-if="doc.mostrar_operarios==1">
                                <div style="display: flex; gap: 5px; align-items: center;">
                                    <label style="margin: 0;" for="">Firma obligatoria:</label>
                                    <input v-bind:true-value="1" v-bind:false-value="0" type="checkbox" v-model="doc.firma_obligatoria">
                                </div>
                                <input type="text" class="form-control" v-model="doc.descripcion_operario">
                            </div>
                        </td>
                        <td style="display: flex; gap: 10px;">
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey" @click="mostrarClaves(doc)">Palabras clave</button>
                            <button class="btn btn-sm btn-primary" @click="guardarDatos(doc)">Guardar</button>
                            <button class="btn btn-sm btn-danger" @click="eliminar(doc)">Eliminar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </div>    
</template>
<script>
import Editor from 'primevue/editor';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { PwgsApi } from '../../../../services/PwgsApi';
import MultiSelect from 'primevue/multiselect';
import FileUpload from 'primevue/fileupload';
export default {
    data() {
        return {
            documentos: [],
            secciones: [],
            companias: [],
            claves:[],
            claveDialog: false,
            plantillaDialog:false,
            docPlantilla:null,
            clavesPlantilla:'',
            nombreNuevoDocumento:'',
            tipoNuevoDocumento:'',
        }
    },
    components: {
        MultiSelect,
        TabView,
        TabPanel,
        FileUpload,
        Editor,
    },
    methods: {
        async crearDocumento(){
            const api = new PwgsApi();
            if(this.nombreNuevoDocumento == ''){
                alert('Debe indicar un nombre');
                return;
            }
            if(this.tipoNuevoDocumento == ''){
                alert('Debe indicar un tipo');
                return;
            }
            await api.post('gestor-documentos', {
                nombre: this.nombreNuevoDocumento,
                seccion: this.tipoNuevoDocumento,
            });
            this.obtenerDocumentos();
        },
        async cambia(evento, doc){
            const api = new PwgsApi();
            console.log('Cambiado', evento, doc);
            var body = {};
            evento.index == 0 ? body.tipo = 'fichero' : body.tipo = 'plantilla'; 
            await api.put('gestor-documentos/'+doc.id_documento+'/tipo-documento', body);           
        },
        async guardarDatos(doc){
            const api = new PwgsApi();
            var body = {};
            
            body.parte_operario = doc.parte_xdefecto_documento;
            body.nombre = doc.nombre;
            body.seccion = doc.seccion;
            body.defecto_visita = doc.defecto_visita;
            body.ids_companias = doc.companyias;
            body.mostrar_companyias = doc.mostrar_companyias;
            body.mostrar_operario = doc.mostrar_operarios;
            body.firma_obligatoria = doc.firma_obligatoria;
            body.descripcion_operario = doc.descripcion_operario;
            console.log('body', doc, body);
            try{
                await api.put('gestor-documentos/'+doc.id_documento, body);                
                this.$toast.add({ severity:'success', summary: 'Realizado', detail: 'Datos modificados.', life: 2000 });                
            }catch(e) {
                this.$toast.add({severity:'error', summary: 'Error', detail: e, life: 5000});
            }
        },
        async guardarPlantilla(){
            const api = new PwgsApi();
            var body = {};
            body.orientacion = this.docPlantilla.orientacion_plantilla;
            body.cabecera = this.docPlantilla.cabecera_html_documento;
            body.cuerpo = this.docPlantilla.cuerpo_html_documento;
            body.pie = this.docPlantilla.pie_html_documento;
            const resp = await api.put('gestor-documentos/'+this.docPlantilla.id_documento+'/plantilla', body);
            if(resp == 'ok'){
                this.$toast.add({ severity:'success', summary: 'Realizado', detail: 'Plantilla modificada.', life: 2000 });
            }else{
                this.$toast.add({severity:'error', summary: 'Error', detail: 'Error al modificar.', life: 5000});
            }
        },
        async abrirPlantilla(doc){
            const api = new PwgsApi();
            this.clavesPlantilla = await api.get('gestor-documentos/'+doc.id_documento+'/palabras-clave');
            this.docPlantilla = doc;
            this.plantillaDialog = true;
        },
        cerrarDialogPlantilla(){
            this.docPlantilla = null;
            this.plantillaDialog = false;
        },
        async descargar(doc){
            var urlglobal = localStorage.getItem('direccion');
            var url = '';
            if(urlglobal == 'null.pwgs.app'){
                url  = "https://desarrollofront.pwgs.app/"+doc.ruta;
            }else{
                url  = "https://"+urlglobal+'/documentos.php?documento=docs_gestion/'+doc.ruta;
            }
            window.open(url);
        },
        async subirarchivo(event, doc) {
            const formData = new FormData();
            formData.append('adjunto_fichero', event.files[0]);
            const api = new PwgsApi();
            const resp = await api.post('gestor-documentos/'+doc.id_documento+'/guardar-documento',formData);
            if(resp == 'OK'){
                this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Archivo adjuntado.', life: 2000 });
            }else{
                this.$toast.add({severity:'error', summary: 'Error', detail: 'Error al adjuntar.', life: 5000});
            }
            this.obtenerDocumentos();
        },
        async eliminar(doc){
            const api = new PwgsApi();
            await api.delete('gestor-documentos/'+doc.id_documento);
            this.obtenerDocumentos();
        },
        cerrarDialog(){
            this.claves = [];
            this.claveDialog = false;
        },
        async mostrarClaves(doc){
            const api = new PwgsApi();
            this.claves = await api.get('gestor-documentos/'+doc.id_documento+'/palabras-clave');
            console.log('claves', this.claves);
            this.claveDialog = true;
        },
        async obtenerDocumentos(){
            const api = new PwgsApi();
            const resp = await api.get('gestor-documentos');
            this.documentos = resp.datos;
            console.log('documentos', this.documentos);
        },
        async obtenerSecciones(){
            const api = new PwgsApi();
            this.secciones = await api.get('gestor-documentos/secciones');
            console.log('secciones', this.secciones);
        },
        async obtenerCompanias(){
            const api = new PwgsApi();
            const resp = await api.get('companias/simple');
            this.companias = resp.datos;
        },
    },
    mounted() {
        this.obtenerDocumentos();
        this.obtenerSecciones();
        this.obtenerCompanias();
    }
}
</script>
<style>
</style>