<template>
    <div class="card-body">
        <div class="col-md-8">
            <seccion-datos :titulo="titulocliente" @cambios="guardar(id)">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                                <input type="text" v-model="clientedatos.nombre" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.razonsocial') }}</label>
                                <input type="text" v-model="clientedatos.razon_social" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_completo">NIF</label>
                                <input type="text" v-model="clientedatos.nif" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.telefono') }} 1</label>
                                <input type="text" v-model="clientedatos.telefono1" class="form-control nombre_completo dato" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">Fax</label>
                                <input type="text" v-model="clientedatos.fax" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.telefono') }} 2</label>
                                <input type="text" v-model="clientedatos.telefono2" class="form-control nombre_completo dato" >
                            </div>
                            <div class="form-group">
                                <label for="nombre_completo">Email</label>
                                <input type="text" v-model="clientedatos.email" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label >{{ $t('general.direccion') }}</label>
                                <input type="text" v-model="clientedatos.direccion" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label >{{ $t('general.cpostal') }}</label>
                                <input type="text" v-model="clientedatos.codigo_postal" class="form-control nombre_completo dato" >
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t('general.ciudad') }}</label>
                                <input type="text" v-model="clientedatos.poblacion" class="form-control nombre_completo dato" >
                            </div>   
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label >{{ $t('general.provincia') }}</label>
                                <input type="text" v-model="clientedatos.provincia" class="form-control nombre_completo dato" >
                            </div>   
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label >IBAN</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control form-control-sm" style="width:20px">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" class="form-control form-control-sm" >
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" class="form-control form-control-sm" >
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" class="form-control form-control-sm" >
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" class="form-control form-control-sm" >
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" class="form-control form-control-sm" >
                            </div>   
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4" v-if="permisosusuario.includes('soporte')">
                            <div class="form-group">
                                <label>{{ $t('general.subcuenta') }}</label>
                                <br>
                                <input type="text" v-model="clientedatos.contador_subcuenta" class="form-control nombre_completo dato" >
                            </div>  
                        </div>
                        <div class="col-md-4" v-else>
                            <div class="form-group">
                                <label>{{ $t('general.subcuenta') }}</label>
                                <br>
                                <label>{{ clientedatos.contador_subcuenta }}</label>
                            </div>  
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label >{{ $t('general.compania') }}</label>
                                <input type="text" v-model="clientedatos.nombre_compania" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label >{{ $t('general.personacontacto') }}</label>
                                <input type="text" v-model="clientedatos.persona_contacto" class="form-control nombre_completo dato" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label >{{ $t('general.observaciones') }}</label>
                                <textarea v-model="clientedatos.observaciones" class="form-control"></textarea>
                            </div>  
                        </div>
                    </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';

export default {
    props: ['cliente', 'id'],
    components: {
    },
    data() {
        return {
            clientedatos: [],
            titulocliente:'',
            permisosusuario:[],
        }
    },
    methods: {
        cargardatos() {
            this.clientedatos = this.cliente;
            this.titulocliente = this.$t('general.datos');
        },
        async guardar(id) {
        /* put  modulos/pwgsapi/index.php/peritos/:id */
                let datossubida = {provincia:this.clientedatos.provincia,poblacion:this.clientedatos.poblacion,nombre: this.clientedatos.nombre, razon_social: this.clientedatos.razon_social,nif: this.clientedatos.nif,telefono1: this.clientedatos.telefono1,telefono2:this.clientedatos.telefono2,fax:this.clientedatos.fax,email:this.clientedatos.email,direccion:this.clientedatos.direccion,codigo_postal: this.clientedatos.codigo_postal, contador_subcuenta: this.clientedatos.contador_subcuenta};
            const api = new PwgsApi;
            if (this.id > 0) {
                try{
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('clientes/' + id, datossubida);
                this.$store.dispatch('cambiarGuardando', false);
                this.$parent.obtenerCliente(this.id);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/peritos/  */
                let datossubida = { provincia: this.clientedatos.provincia, poblacion: this.clientedatos.poblacion, nombre: this.clientedatos.nombre, razon_social: this.clientedatos.razon_social, nif: this.clientedatos.nif, telefono1: this.clientedatos.telefono1, telefono2: this.clientedatos.telefono2, fax: this.clientedatos.fax, email: this.clientedatos.email, direccion: this.clientedatos.direccion, codigo_postal: this.clientedatos.codigo_postal, contador_subcuenta: this.clientedatos.contador_subcuenta};
                let res;
                try {
                    res = await api.post('clientes', datossubida);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/clientes/' + res.id);

                }
                catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
        async comprobarpermisos(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
            var permisos = resppermisos.datos;
            for(let permiso of permisos){
                this.permisosusuario.push(permiso.nombre_permiso);
            }
        }
    },
    mounted() {
        this.cargardatos();
        this.comprobarpermisos();
    },
    watch: {
        id() {
            this.cargardatos();
        }
    }
}
</script>