<template>
    <br>
    <br>
    <div class="row">
        <div class="col-12">
            <div class="card card-default">
                <div class="card-header ch" style="display: flex; justify-content: space-between;">
                    <h4 class="card-title">{{$t('general.archivos')}}</h4>
                    <div class="btn-group">
                        <button title="Descargar archivos" @click="descargarArchivo" class="btn btn-sm btn-light"><i class="fas fa-cloud-download-alt"></i></button>
                        <button title="Enviar archivos a operario" @click="enviarAOperario" class="btn btn-sm btn-light"><i class="fas fa-share-square"></i></button>
                    </div>
                </div>
                <div class="card-body" style="padding-bottom: 0;">
                    <div class="row" style="justify-content: space-around;row-gap: 10px;">
                        <Galleria v-model:activeIndex="activeIndex" v-model:visible="displayCustom" :responsiveOptions="responsiveOptions" :value="archivos" :numVisible="7"
                            containerStyle="max-width: 850px" :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnails="false">
                            <template #item="slotProps">
                                <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr" :src='"https://"+urlglobal+"/documentos.php?documento="  + slotProps.item.documento + "&servicio=" + slotProps.item.servicios_idservicio' preview :alt="slotProps.item.descripcion"/>
                                <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" + slotProps.item.servicios_idservicio' preview :alt="slotProps.item.descripcion"/>
                            </template>
                            <template #thumbnail="slotProps">
                                <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr" :src='"https://"+urlglobal+"/documentos.php?documento="  + slotProps.item.documento + "&servicio=" + slotProps.item.servicios_idservicio' preview :alt="slotProps.item.descripcion"/>
                                <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" + slotProps.item.servicios_idservicio' preview :alt="slotProps.item.descripcion"/>
                            </template>
                        </Galleria>
                        <div v-for="(archivos, index) in archivos" @click="console.log(archivos)" :key="archivos">
                            <div style="display: grid" v-if="archivos.image==''">
                                <i class="fas fa-file fa-8x"></i>
                                <div v-if="archivos.cuando">
                                    <span style="width: 100px;" v-if="archivos.cuando=='despues'" class="badge bg-danger bg-blue">después</span>
                                    <span v-else style="width: 100px;" class="badge bg-danger bg-blue">{{ archivos.cuando }}</span>
                                </div>
                                <input @blur="handleBlur(archivos.id_documento)" style="width: 100px; margin-top: 5px" placeholder="Descripción" type="text" v-model="descripcion[archivos.id_documento]">
                                <div style="display: flex; flex-wrap: wrap; column-gap: 5px; row-gap: 5px;">
                                    <button v-for="accion in archivos.acciones" :key="accion" :title="accion.nombre" @click="pulsado(accion.accion)" class="btn btn-sm" :style="accion.clase"><i :class="accion.icono"></i></button>
                                </div>
                            </div>
                            <div v-else-if="archivos.propiedad_cliente!=0">
                                <div class="image-container">
                                    <img style="width: 100px; height: 127px; border: 5px solid goldenrod;"  @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento="  + archivos.documento + "&servicio=" + archivos.servicios_idservicio' width="100"  preview :alt="archivos.descripcion" />
                                    <img style="width: 100px; height: 127px; border: 5px solid goldenrod;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" + archivos.servicios_idservicio' width="100" preview :alt="archivos.descripcion" />
                                    <div v-if="archivos.cuando">
                                        <span style="width: 100px;" v-if="archivos.cuando=='despues'" class="badge bg-danger bg-blue">después</span>
                                        <span style="width: 100px;" v-else class="badge bg-danger bg-blue">{{ archivos.cuando }}</span>
                                    </div>
                                    <input @blur="handleBlur(archivos.id_documento)" style=" width: 100px; margin-top: 5px" placeholder="Descripción" type="text" v-model="descripcion[archivos.id_documento]">
                                    <div style="width: 300px;height: 300px;"  class="image-zoom-overlay">
                                        <img style="width: 100%; height: 100%;" @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento="  + archivos.documento + "&servicio=" + archivos.servicios_idservicio' :alt="archivos.descripcion" />
                                        <img style="width: 100%; height: 100%;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + archivos.documento + "&servicio=" + archivos.servicios_idservicio' :alt="archivos.descripcion" />
                                    </div>
                                </div>
                                <div style="display: flex; flex-wrap: wrap; column-gap: 5px; row-gap: 5px;">
                                    <button v-for="accion in archivos.acciones" :key="accion" :title="accion.nombre" @click="pulsado(accion.accion)" class="btn btn-sm" :style="accion.clase"><i :class="accion.icono"></i></button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="image-container">
                                    <img style="width: 100px; height: 127px;" @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" + archivos.servicios_idservicio' width="100" preview :alt="archivos.descripcion" />
                                    <img style="width: 100px; height: 127px;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" + archivos.servicios_idservicio' width="100" preview :alt="archivos.descripcion" />
                                    <div v-if="archivos.cuando">
                                        <span style="width: 100px;" v-if="archivos.cuando=='despues'" class="badge bg-danger bg-blue">después</span>
                                        <span style="width: 100px;" v-else class="badge bg-danger bg-blue">{{ archivos.cuando }}</span>
                                    </div>
                                    <input @blur="handleBlur(archivos.id_documento)" style=" width: 100px; margin-top: 5px" placeholder="Descripción" type="text" v-model="descripcion[archivos.id_documento]">
                                    <div style="width: 300px;height: 300px;" class="image-zoom-overlay">
                                        <img style="width: 100%; height: 100%;" @click="imageClick(index)" v-if="urlglobal != 'null.pwgs.app'" :src='"https://"+urlglobal+"/documentos.php?documento=" + archivos.documento + "&servicio=" + archivos.servicios_idservicio' :alt="archivos.descripcion" />
                                        <img style="width: 100%; height: 100%;" @click="imageClick(index)" v-if="urlglobal == 'null.pwgs.app'" :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" + archivos.servicios_idservicio' :alt="archivos.descripcion" />
                                    </div>
                                </div>
                                <div style="display: flex; flex-wrap: wrap; column-gap: 5px; row-gap: 5px;">
                                    <button v-for="accion in archivos.acciones" :key="accion" :title="accion.nombre" @click="pulsado(accion.accion)" class="btn btn-sm" :style="accion.clase"><i :class="accion.icono"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <input type="file" @change="uploadFile" multiple ref="file">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Galleria from 'primevue/galleria';
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','visita'],
    setup() {
    },
    components: {
        Galleria
    },
     data() {
         return {
            archivos:'',
            descripcion:[],
            activeIndex: 0,
            toggle:false,
            urlglobal: "",
            displayCustom: false,
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
        }
    },
    methods: {
        async handleBlur(id){
            console.log(this.descripcion[id]);
            const api = new PwgsApi();
            try{
                await api.put('servicios/'+this.id+'/accion-archivo/'+id+'?accion=descripcion',{descripcion:this.descripcion[id]});
            }catch(e) {
                console.error('Error al actualizar descripción del archivo', e);
            }
        },
        imageClick(index) {
            this.activeIndex = index;
            this.displayCustom = true;
        },
        async pulsado(url){
            const api = new PwgsApi;
            if(url.includes('eliminar')){
                this.$confirm.require({
                    message: '¿Seguro que quieres eliminar el archivo?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClass: 'p-button-secondary p-button-outlined',
                    rejectLabel: 'Cancelar',
                    acceptLabel: 'Aceptar',
                    accept: async() => {
                        var resp = await api.put(url);
                            console.log('respuestaAccion', resp);
                            this.$parent.$parent.obtenerVisitas(this.$props.id, this.$props.visita.visita.idvisitador);
                        },
                    reject: () => {
                        return;
                    }
                });
            }else{
                var resp = await api.put(url);
                console.log('respuestaAccion', resp);
                this.$parent.$parent.obtenerVisitas(this.$props.id, this.$props.visita.visita.idvisitador); 
            }
        },
        async uploadFile() {
            const formData = new FormData();
            for (let i = 0; i < this.$refs.file.files.length;i++){
                formData.append(this.$refs.file.files[i].name, this.$refs.file.files[i], this.$refs.file.files[i].name);
            }
            const api = new PwgsApi;
            formData.append("tipo_documento", 'ficherosvisitas');      
            formData.append("id_operario",this.$props.visita.visita.operarios_idoperario);
            formData.append("id_visita",this.$props.visita.visita.idvisitador);
            await api.post('servicios/' + this.$props.id + '/guardar-documento', formData);
           /* for (let i = 0; i < this.$refs.file.files.length; i++) {
                formData.append(this.$refs.file.files[i].name, this.$refs.file.files[i], this.$refs.file.files[i].name);
            }*/
            this.$parent.$parent.obtenerVisitas(this.$props.id, this.$props.visita.visita.idvisitador);   
            
        },
        cargarDescripciones(){
            for(let im of this.visita.archivos){
                this.descripcion[im.id_documento] = im.descripcion;
            }
        },
        async enviarAOperario(){
            if(this.visita.visita.email_operario!=''){
                const api = new PwgsApi;
                await api.put('servicios/'+this.id+'/enviar-archivos-operario/'+this.$props.visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Archivos enviados correctamente', life: 2000 });
            }else{
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El operario no tiene email definido', life: 5000 });
            }
        },
        async descargarArchivo() {
            try {
                const api = new PwgsApi();

                // Realiza la solicitud GET para obtener el archivo binario
                const response = await api.get(
                    `servicios/${this.id}/descargar-documentos-visita/${this.visita.visita.idvisitador}`,
                    { responseType: 'blob' } // Configura Axios para recibir un archivo binario
                );

                // Verifica si la respuesta es un Blob
                if (!(response instanceof Blob)) {
                    throw new Error('La respuesta no es un archivo válido');
                }

                // Crear un Blob con el tipo MIME correcto
                const blob = new Blob([response], { type: response.type || 'application/zip' });

                console.log('Tamaño del archivo descargado:', blob.size);

                // Crear un enlace para descargar el archivo
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);

                link.href = url;
                link.setAttribute('download', 'archivo.zip'); // Nombre del archivo descargado
                document.body.appendChild(link);

                // Disparar la descarga
                link.click();

                // Limpieza: eliminar el enlace temporal y revocar el URL
                link.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error al descargar el archivo:', error);
                throw error; // Re-lanzar el error para ser manejado
            }
        },
        async comprobarSe(){
            const api = new PwgsApi();            
            const se = await api.get('/servicios/'+this.id+'/comunicaciones-ste');
            if(se.comunicaciones!=1){
                var index=0;
                for(var arch of this.visita.archivos){
                    for (let i = arch.acciones.length - 1; i >= 0; i--) {
                        if (arch.acciones[i].nombre === "No visible para el cliente") {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.visita.archivos[index].acciones.splice(i, 1);
                        }
                    }
                    index++;
                }
            }
        },
      
    },
    mounted() {
        this.urlglobal = localStorage.getItem("direccion");
        this.comprobarSe();
        this.archivos = this.visita.archivos;
        console.log('archvs', this.archivos);
        this.cargarDescripciones();
    },
})
</script>
<style>
.image-container {
    position: relative;
    display: grid;   
}

.image-zoom-overlay {
    width: 350px;
    position: absolute;
    top: -60px;
    left: -300px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-container:hover .image-zoom-overlay {
    display: flex;
}

.imgcarr{
    width: 600px;
}

@media screen and (max-width:600px){
    .imgcarr{
    width: 300px;
}
}
.ch::after{
    display: none;
}
</style>
