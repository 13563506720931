<template>
    <Checkbox  @change="onChanged"></Checkbox>
</template>
<script>
import Checkbox from 'primevue/checkbox';
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    components: {
        Checkbox
    },
    props: [
        'props',
        'datos',
    ],
    emits: [
        'obtenerExp'
    ],
    data() {
        return {
            checked: false
        }
    },
    methods: {
        async onChanged() {
        console.log("datos", this.$props.datos);
        console.log("props,",this.$props);
        const api = new PwgsApi();
        let ids = [];
        ids.push(this.datos.id_aviso);
        let subidadatos = {tipo:this.$props.props.nombre,ids_avisos:ids,estado:1,}
        await api.put('avisos/revisar-avisos', subidadatos);
            this.$emit('obtenerExp');
            this.$emit('quieroDatos');
        }
    },
    mounted() {
        
    }
}
</script>