<template>
    <div class="container pt-3">
        <div class="card card-default">
            <div class="card-header bg-black">
                <h3 class="card-title"><i class="fas fa-mail-bulk mr-2"></i>{{ $t('general.enviar') }} Email</h3>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="maximize">
                        <i class="fas fa-expand"></i>
                    </button>
                </div> 
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <select class="form-control frm" v-model="plantilla">
                                <option :value="{}" selected>Elige una plantilla</option>
                                <option v-for="plant in plantillas" :key="plant.id" :value="plant">{{ plant.titulo_texto }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">@</span>
                            </div>
                            <input readonly type="text" class="form-control" placeholder="Email" :value="email">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-at"></i></span>
                            </div>
                            <input type="text" class="form-control" v-model="asunto" placeholder="Asunto">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" v-model="contenido" placeholder="Texto Email" rows="5"></textarea>
                    </div>
                    <div class="col-md-12">
                        <button type="button" @click="enviarEmail()" class="btn btn-dark btn-block">{{ $t('general.enviar') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';

  

export default ({
    props: ['email','asegurado','servicio','id'],
    setup() { 

    },
    data() {
        return {
            asunto: "",
            contenido:"",
            plantillas: [],
            plantilla: {},
        }
    },
    components: {
        
    },
    methods: {
        async enviarEmail() {
            //PUT index.php/servicios/:id/enviar-email
            const api = new PwgsApi();
            let subidadatos = { mensaje: this.contenido, tipo_envio: "email_seguimiento", asunto: this.asunto, emails: this.email}
            try{
                await api.put('/servicios/' + this.$props.id + '/enviar-email', subidadatos);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        
       },
       async cargarPlantillas(){
            const api = new PwgsApi();
            const aux = await api.get('/mensajes-predefinidos?filters={"tipo_mensaje":{"value":"email","matchMode":"equals"}');
            this.plantillas = aux.datos;
        }
    },
    mounted(){
        this.cargarPlantillas();
    },
    watch:{
        plantilla(){
            if(this.plantilla=={}){
                this.contenido='';
            }else{
                this.contenido = this.plantilla.texto;

            }
        }
    }
})


</script>