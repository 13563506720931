<template>
    <div class="container-fluid">
        <section class="content-header">
            <div v-if="editandoUsuario" class="row mb-2">
                <h3>{{ $t('general.usuario') }}</h3>
            </div>
            <div v-if="!editandoUsuario" class="row mb-2">
                <h3>{{ $t('general.nuevousuario') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación de una usuario -->
                <div v-if="editandoUsuario" class="col-md-2">
                    <!-- Datos Usuario -->
                    <usuario-resumen :usuario="usuario" :id="id" :num_expedientesactivos="num_expedientesactivos"
                        :companiasexpedientes="companiasexpedientes" />
                    <!-- Futura implementación -->
                    <usuario-puntuacion />
                    <div class="callout callout-warning">
                        <h5>{{ $t('general.ultimoacceso') }}</h5>
                        <p>15/12/2021 15:35h</p>
                    </div>
                    <div class="callout callout-info">
                        <h5>{{ $t('general.ultimamodificacion') }}</h5>
                        <p>12/12/2021 10:11h</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="card card-default card-tabs">
                        <div v-if="editandoUsuario" class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li v-if="factoadmin" class="nav-item"><a class="nav-link" href="#datos_resumen" data-toggle="tab">{{
                                        $t('general.resumen') }}</a></li>
                                <li class="nav-item"><a class="nav-link  active" href="#datos_usuario"
                                        data-toggle="tab">{{ $t('general.datos') }}</a></li>
                                <!--<li v-if="editandoUsuario" class="nav-item"><a class="nav-link" href="#acceso_usuario" data-toggle="tab">Acceso</a></li>-->
                                <li v-if="factoadmin" class="nav-item"><a class="nav-link" href="#jornadas_usuario" data-toggle="tab">{{
                                        $t('general.jornadas') }}</a></li>
                                <li v-if="factoadmin" class="nav-item"><a class="nav-link" href="#permisos_usuario" data-toggle="tab">{{
                                        $t('general.permisos') }}</a></li>
                                <li v-if="factoadmin" class="nav-item"><a class="nav-link" href="#reasignacion_usuarios"
                                        data-toggle="tab">{{ $t('general.reasignacionexpedientes') }}</a></li>
                                <li v-if="factoadmin" class="nav-item"><a class="nav-link" href="#centralita_usuario" data-toggle="tab">{{
                                        $t('general.centralita') }}</a></li>
                                <li v-if="factoadmin" class="nav-item"><a class="nav-link" href="#webmail_usuario"
                                        data-toggle="tab">Webmail</a></li>
                                <li v-if="factoadmin" class="nav-item"><a class="nav-link" href="#carga_usuario" data-toggle="tab">{{
                                        $t('general.carga') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_usuario">
                            <usuario-datos :usuario="usuario" :id="id" :factoadmin="factoadmin"/>
                        </div>
                        <div class="tab-pane" v-if="editandoUsuario" id="datos_resumen">
                            <usuario-resumen-estadisticas />
                        </div>
                        <div class="tab-pane" v-if="editandoUsuario" id="jornadas_usuario">
                            <usuario-jornadas :usuario="usuario" :id="id" />
                        </div>
                        <div class="tab-pane" v-if="editandoUsuario" id="permisos_usuario">
                            <usuario-permisos :usuario="usuario" :id="id" />
                        </div>
                        <div class="tab-pane" v-if="editandoUsuario" id="reasignacion_usuarios">
                            <usuario-reasignaciones :expedientesactivos="expedientesactivos" :usuario="usuario" :id="id" />
                        </div>
                        <div class="tab-pane" v-if="editandoUsuario" id="centralita_usuario">
                            <usuario-centralita :usuario="usuario" :id="id" />
                        </div>
                        <div class="tab-pane" v-if="editandoUsuario" id="webmail_usuario">
                            <usuario-webmail :usuario="usuario" :id="id" />
                        </div>
                        <div class="tab-pane" v-if="editandoUsuario" id="carga_usuario">
                            <usuario-carga :usuario="usuario" @recargar="recargar" :id="id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from './../../../services/PwgsApi.js';
import UsuarioResumen from './UsuarioResumen.vue';
import UsuarioResumenEstadisticas from './resumen/UsuarioResumenEstadisticas.vue'
import UsuarioPuntuacion from './UsuarioPuntuacion.vue';
import UsuarioDatos from './datos/UsuarioDatos.vue';
import UsuarioJornadas from './jornadas/UsuarioJornadas.vue';
import UsuarioPermisos from './permisos/UsuarioPermisos.vue';
import UsuarioReasignaciones from './reasignaciones/UsuarioReasignaciones.vue';
import UsuarioCentralita from './centralita/UsuarioCentralita.vue';
import UsuarioWebmail from './webmail/UsuarioWebmail.vue';
import UsuarioCarga from './carga/UsuarioCarga.vue';
export default {
    props: ['id','nombre'],
    data() {
        return {
            usuario: [],
            expedientesactivos: [],
            num_expedientesactivos: "",
            companiasexpedientes:"",
            factoadmin:false,
               }
    },
    methods: {
        async obtenerUsuario(id) {
            const api = new PwgsApi();
            this.$store.dispatch('cambiarCargando', true);
            const datos = await api.get('usuarios/' + id); 
            this.$store.dispatch('cambiarCargando', false);
            this.usuario = [];
            this.usuario = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/usuarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.usuario.nombre;
                    this.$store.getters.ventanas[i].alt = "Usuario: " + this.usuario.nombre + ' || ' + this.usuario.nombre_completo;
                }
            }
            /**/
            return this.usuario;
        },
        async sacarexpedientesactivos() {
            const api = new PwgsApi();
            this.expedientesactivos = await api.get('usuarios/' + this.$props.id + '/reparto-manual');
            this.num_expedientesactivos = Object.keys(this.expedientesactivos.datos.expedientes).length-1;
            this.companiasexpedientes = Object.keys(this.expedientesactivos.datos.expedientes.companias).length;

        },
        cambios() {
            this.obtenerUsuario();
        },
        recargar() {
            this.obtenerUsuario(this.id);
        },
        async comprobarpermisos(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
            var permisos = resppermisos.datos;
            const factencontrado = permisos.find(permiso => permiso.nombre_permiso == "Facturación");
            const adminencontrado= permisos.find(permiso => permiso.nombre_permiso == "Administración");
            const superadminencontrado= permisos.find(permiso => permiso.nombre_permiso == "Super Administración");
            console.log('gggs',factencontrado, adminencontrado);
            if(factencontrado || adminencontrado || superadminencontrado){
                console.log('entra');
                this.factoadmin = true;
            }
        }
    },
    computed: {
        editandoUsuario() {
            return this.id > 0;
        },
    },
    mounted() {
        this.obtenerUsuario(this.id);
        this.sacarexpedientesactivos();
        this.comprobarpermisos();
    },
    components: {
        'usuario-resumen': UsuarioResumen,
        'usuario-puntuacion': UsuarioPuntuacion,
        'usuario-datos': UsuarioDatos,
        'usuario-jornadas': UsuarioJornadas,
        'usuario-permisos': UsuarioPermisos,
        'usuario-reasignaciones': UsuarioReasignaciones,
        'usuario-centralita': UsuarioCentralita,
        'usuario-webmail': UsuarioWebmail,
        'usuario-carga': UsuarioCarga,
        'usuario-resumen-estadisticas': UsuarioResumenEstadisticas,
    },
    watch: {
        id() {
            this.obtenerUsuario(this.id);
            this.sacarexpedientesactivos();
            this.comprobarpermisos();
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/usuarios/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.usuario.nombre;
                    this.$store.getters.ventanas[i].alt = "Usuario: " + this.usuario.nombre+ ' || ' + this.usuario.nombre_completo;
                }
            }
            /**/
        }
    }
}
</script>