<template>
    <seccion-datos 
        :titulo="opciones" 
        @cambios="guardar($data)" 
        >
        <div class="row"> 
            <div class="col-md-6">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input 
                        class="custom-control-input custom-control-input-danger" 
                        type="checkbox" 
                        id="factura-por-partidas" 
                        v-model="factura_partidas_companyia"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                        >
                        <label for="factura-por-partidas" class="custom-control-label">{{$t('general.facturaporpartidas')}}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input 
                        class="custom-control-input" 
                        type="checkbox" 
                        id="repetir-partidas" 
                        v-model="repetir_partidas_companyia"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                        >
                        <label for="repetir-partidas" class="custom-control-label">{{$t('general.repetirpartidas')}}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input 
                        class="custom-control-input" 
                        type="checkbox" 
                        id="envio-automatico-factura" 
                        v-model="envio_automatico_factura"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                        >
                        <label for="envio-automatico-factura" class="custom-control-label">{{$t('general.envioautomaticofactura')}}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input 
                        class="custom-control-input custom-control-input-warning " 
                        type="checkbox" 
                        id="abonos-parciales" 
                        v-model="abonos_parciales"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                        >
                        <label for="abonos-parciales" class="custom-control-label">{{$t('general.abonosparciales')}}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input 
                        class="custom-control-input custom-control-input-danger" 
                        type="checkbox" 
                        id="modificar_fecha_factura" 
                        v-model="modificar_fecha_factura"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                        >
                        <label for="modificar_fecha_factura" class="custom-control-label">{{$t('general.modificafechasdefactura')}}</label>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                        <input 
                        class="custom-control-input custom-control-input-danger" 
                        type="checkbox" 
                        id="establecer-num-factura" 
                        v-model="establecer_numero_factura"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                        >
                        <label for="establecer-num-factura" class="custom-control-label">{{$t('general.establecernumerodefactura')}}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input 
                        class="custom-control-input custom-control-input-warning" 
                        type="checkbox" 
                        id="albaranes-cia" 
                        v-model="albaranes_companyia"
                        v-bind:true-value="1" 
                        v-bind:false-value="0"
                        >
                        <label for="albaranes-cia" class="custom-control-label">{{$t('general.albaranes')}}</label>
                    </div>
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-11">
                            <div class="custom-control custom-checkbox">
                                <input 
                                class="custom-control-input custom-control-input-warning" 
                                type="checkbox" 
                                id="albaranes-cia-defecto" 
                                v-model="albaranes_companyia_defecto"
                                v-bind:true-value="1" 
                                v-bind:false-value="0"
                                >
                                <label for="albaranes-cia-defecto" class="custom-control-label">{{$t('general.pordefecto')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-11">
                            <div class="custom-control custom-checkbox">
                                <input 
                                class="custom-control-input custom-control-input-warning" 
                                type="checkbox" 
                                id="albaranes-sin-nif" 
                                v-model="albaranes_sinnif_companyia"
                                v-bind:true-value="1" 
                                v-bind:false-value="0"
                                >
                                <label for="albaranes-sin-nif" class="custom-control-label">{{$t('general.nifnoobligatorio')}}</label>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="row">
            <!-- Si hay seleccionada una serie, esto se oculta -->
            <div class="col-md-6">
                <div class="form-group">
                    <label for="empresa-que-factura">{{$t('general.empresafactura')}}</label>
                    <select class="custom-select form-control form-control-sm" v-model="empresas_factura_id_empresa">
                        <option value="0">{{$t('general.empresaprincipal')}}</option>
                        <option v-for="empresa in todasEmpresas" :key="'empresa-' + empresa.id" :value="empresa.id">{{ empresa.nombre_empresa }}</option>
                    </select>
                </div> 
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="empresa-que-factura">Forma de pago</label>
                    <select class="custom-select form-control form-control-sm" v-model="forma_pago">
                        <option value="">Seleccione forma de pago</option>
                        <option v-for="formapago in Object.entries(formas_pago)" :key="formapago[0]" :value="formapago[0]">{{ formapago[0] }}</option>
                    </select>
                </div> 
            </div>
            <div class="col-md-6" v-if="mostrarcuentas">
                <label for="empresa-que-factura">Cuentas</label>
                <select class="custom-select form-control form-control-sm" v-model="cuenta_bancaria">
                    <option value="">Seleccione forma de pago</option>
                    <option v-for="cuenta in cuentas" :key="cuenta.id_cuenta" :value="cuenta.id_cuenta">{{ cuenta.numero }}</option>
                </select>
            </div>
        </div>
        <!-- Inicio Series -->
        <!-- Solo si tiene el módulo de facturación habilitado -->
        <compania-facturacion-series 
            :datos="datos" 
            :botonGuardar="false" 
            />
        <!-- Fin Series -->
    </seccion-datos>
</template>

<script>
import CompaniaFacturacionSeries from './CompaniaFacturacionSeries.vue';

export default {
    components: {
        CompaniaFacturacionSeries
    },
    props: {
        datos: {
            type: Object
        },
    },
    inject: ['guardar'],
    data() {
        return  {
            factura_partidas_companyia: null,
            establecer_numero_factura: null,
            repetir_partidas_companyia: null,
            albaranes_companyia: null,
            albaranes_companyia_defecto: null,
            albaranes_sinnif_companyia: null,
            envio_automatico_factura: null, 
            abonos_parciales: null, 
            modificar_fecha_factura: null, 
            forma_pago:null,
            formas_pago:null,
            cuenta_bancaria: null,
            cuentas:null,
            empresas_factura_id_empresa: 0,
            opciones:'Opciones',
            formaPagoSelec:'',
            mostrarcuentas : false,
        }
    },
    computed: {
        todasEmpresas() {
            return this.$store.getters['datosApp/empresas'];
        }
    },
    watch: {
        datos() {
            this.init();
        },
        forma_pago(value){
            for(var forma of Object.entries(this.formas_pago)){
                if(forma[0] == value){
                    if(forma[1]=='1'){
                        this.mostrarcuentas = true;
                    }else{
                        this.mostrarcuentas = false;
                    }
                }
            }
        },
    },
    methods: {
        init() {
            if (this.datos) {
                for (let dato in this.$data) {
                    if (this.datos[dato] !== null && this.datos[dato] !== undefined) {
                        this[dato] = this.datos[dato];
                    }
                }
            }
            console.log('datoscompa', this.datos);
        },
    },
    created() {
        this.init();
        this.opciones = this.$t('general.opciones')
    },
}
</script>