<template>
    <Dropdown v-model="nueva" :options="this.marcas" optionLabel="nombre_marca" filter placeholder="Marca" class="fixed-size w-full md:w-14rem" @change="onChange">
        <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value.nombre_marca }}</div>
            </div>
            <span v-else>
                <div v-if="seleccion">{{ equipo.marca }}</div>
                <div v-else>{{ slotProps.placeholder }}</div>
            </span>
        </template>
        <template #option="slotProps">
            <div class="flex align-items-center">                
                <div>{{ slotProps.option.nombre_marca }}</div>
            </div>
        </template>
    </Dropdown>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props:['equipo', 'id_servicio'],
    data() {
        return {
            marcas:[],
            seleccion:false,
            nueva:'',
        }
    },
    mounted(){
        this.seleccioned();
        this.cargarMarcas();
    },
    methods: {
        async cargarMarcas(){
            const api = new PwgsApi();
            const aux = await api.get('marcas');
            this.marcas = Object.values(aux.datos);
        },
        seleccioned(){
            if(this.equipo.marca){
                this.seleccion=true;
            }
        },
        async onChange(event){
            const api = new PwgsApi();
            let body={id_marca: event.value.id_marca};
            const resp = await api.put('servicios/'+this.id_servicio+'/equipos/'+this.equipo.id_servicio_equipo , body);
            console.log('respu', resp)
        }
    },
    watch:{
    }
})
</script>
<style>
.fixed-size{
width:60%;
}
</style>