import { createRouter, createWebHistory } from 'vue-router'
import store from './stores/store.js';
/*import HomePage from './components/pages/HomePage.vue'
import Layout from './components/Layout.vue'
import TestPage from './components/pages/TestPage.vue'
import NotFound from './components/pages/NotFound.vue'
import UsuariosPage from './components/pages/UsuariosPage.vue'
import UserAuth from './components/pages/auth/UserAuth.vue'*/


var isFirefox = typeof InstallTrigger !== 'undefined';
var router = null;
if(isFirefox){
    router = createRouter({
        history: createWebHistory(),
        companias:'compañias',
        routes: [
            {
                path: '/',
                redirect: {
                    name: 'Inicio'
                }
            },
            {
                path: '/login',
                name: 'login',
                component: () => import("./components/pages/auth/UserAuth.vue"),
            },
            {
                path: '/app',
                name: 'Inicio',
                component: () => import("./components/Layout.vue"),
                children: [
                    {
                        path: '/',
                        component: () => import("./components/pages/HomePage.vue"),
                    },
                    {
                        path: '/companias',
                        name: 'Compañías',
                        component: () => import("./components/pages/companyias/CompanyiasPage.vue"),
                    },
                    {
                        path: '/companias/crear',
                        name: 'Crear Compañía',
                        component: () => import('./components/pages/companyias/Compania.vue'),
                          props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/companias/:id',
                        name: 'Compañía',
                        component: () => import('./components/pages/companyias/Compania.vue'),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/usuarios',
                        name: 'Usuarios',
                        component: () => import("./components/pages/UsuariosPage.vue"),
                        children: [
                            
                        ]
                    },
                    {
                        path: '/usuarios/:id',
                        name: 'Usuario',
                        component: () => import('./components/pages/usuarios/Usuario.vue'),
                        props: ruta => ({id: +ruta.params.id}),
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                        
                    },
                    {
                        path: '/usuarios/crear',
                        name: 'Crear Usuarios',
                        component: () => import('./components/pages/usuarios/Usuario.vue'),
                          props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }  
                    },
                
                    {
                        path: '/servicios/:id',
                        name: 'Servicio',
                        component: () => import('./components/pages/servicios/Servicio.vue'),
                        props: ruta => ({id: +ruta.params.id}),
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/baremos',
                        name: 'Baremos',
                        component: () => import("./components/pages/TestPage.vue"),
                    },
                    {
                        path: '/configuracion/braremos',
                        name: 'Configuración baremos',
                        component: () => import("./components/pages/configuracion/baremos/baremos.vue"),
                    },
                    {
                        path: '/especialidades/:id',
                        name: 'Especialidad',
                        component: () => import('./components/pages/especialidades/Especialidad.vue'),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
    
                       
                    },
                     {
                        path: '/especialidades/crear',
                        name: 'Crear Especialidades',
                        component: () => import("./components/pages/especialidades/Especialidad.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/especialidades',
                        name: 'Especialidades',
                        component: () => import("./components/pages/especialidades/EspecialidadesPage.vue"),
                         props: true,
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/equiposMarcas',
                        name: 'EquiposMarcas',
                        component: () => import("./components/pages/configuracion/equiposmarcas/EquiposMarcasPage.vue"),
            
                    },
                    {
                        path: '/gestorTipos',
                        name: 'GestorTipos',
                        component: () => import("./components/pages/configuracion/gestortipo/gestortiposPage.vue"),
                    },
                    {
                        path: '/SMS',
                        name: 'SMS',
                        component: () => import("./components/pages/configuracion/sms/sms.vue"),
                    },
                    {
                        path: '/centralita',
                        name: 'Centralita',
                        component: () => import("./components/pages/configuracion/centralita/centralita.vue"),
                    },
                    {
                        path: '/escritorio',
                        name: 'Escritorio',
                        props: route => ({ tipo_buscador: route.query.tipo_buscador }),
                        component: () => import("./components/pages/escritorio/Escritorio.vue"),
                    },
                    {
                        path: '/Eventos',
                        name: 'Eventos',
                        component: () => import("./components/pages/configuracion/eventos/Eventos.vue"),
                    },
                    {
                        path: '/DocumentacionRequerida',
                        name: 'Documentación Requerida',
                        component: () => import("./components/pages/configuracion/documentacionrequerida/DocumentacionRequerida.vue"),
                    },
                    {
                        path: '/GestionDocumentos',
                        name: 'Gestión Documentos',
                        component: () => import("./components/pages/configuracion/gestiondocumentos/GestionDocumentos.vue"),
                    },
                    {
                        path: '/GestionCampos',
                        name: 'Gestión Campos',
                        component: () => import("./components/pages/configuracion/gestioncampos/GestionCampos.vue"),
                    },
                   /* {
                        path: '/servicios',
                        name: 'Servicios',
                        props: ({servicios: true}), 
                        component: () => import("./components/pages/escritorio/Escritorio.vue"),
                    },*/
                    /*   {
                        path: '/planning',
                        name: 'Planning',
                        component: () => import("./components/pages/servicios/planning/planning.vue"),
                    },*/
                    {
                        path: '/planningGeografico',
                        name: 'Planning Geográfico',
                        component: () => import("./components/pages/planninggeografico/PlanningGeografico.vue"),
                    },
                    {
                        path: '/facturacionOperarios',
                        name: 'Facturación Operarios',
                        component: () => import("./components/pages/facturacionoperarios/FacturacionOperarios.vue"),
                    },
                    {
                        path: '/facturacionAlbaranes',
                        name: 'Facturación Albaranes',
                        component: () => import("./components/pages/facturacionalbaranes/FacturacionAlbaranes.vue"),
                    },
                    {
                        path: '/facturacion',
                        name: 'Facturación',
                        component: () => import("./components/pages/Facturacion.vue"),
                    },
                    {
                        path: '/peritos',
                        name: 'Peritos',
                        component: () => import("./components/pages/peritos/PeritosPage.vue"),
                    },
                    {
                        path: '/peritos/:id',
                        name: 'Perito',
                        component: () => import("./components/pages/peritos/Perito.vue"),
                         props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/peritos/crear',
                        name: 'Crear Peritos',
                        component: () => import("./components/pages/peritos/Perito.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    
                    {
                        path: '/comerciales',
                        name: 'Comerciales',
                        component: () => import("./components/pages/comerciales/ComercialesPage.vue"),
                        
                    },
                    {
                        path: '/comerciales/:id',
                        name: 'comercial',
                        component: () => import("./components/pages/comerciales/Comercial.vue"),
                         props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/comerciales/crear',
                        name: 'Crear Comerciales',
                        component: () => import("./components/pages/comerciales/Comercial.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                         {
                        path: '/empresas',
                        name: 'Empresas',
                        component: () => import("./components/pages/empresas/EmpresasPage.vue"),
                    },
                                    
                         {
                        path: '/empresaprincipal',
                        name: 'DatosEmpresa',
                        component: () => import("./components/pages/empresaprincipal/DatosEmpresa.vue"),
                    },
                    {
                        path: '/empresas/:id',
                        name: 'Empresa',
                        component: () => import("./components/pages/empresas/Empresa.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/empresas/crear',
                        name: 'Crear Empresas',
                        component: () => import("./components/pages/empresas/Empresa.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                     {
                        path: '/Informes',
                         name: 'Informes',
                         target: "_blank",
    
                         component: () => {
                             let direccion = localStorage.getItem('direccion');
                             let aux = "https://" + direccion + "/informes.php";
                             window.open(aux,'_blank');
                                }
                       
                    },
                    {
                        path: '/Control',
                         name: 'Control',
                         target: "_blank",
    
                         component: () => {
                             let direccion = localStorage.getItem('direccion');
                             let aux = "https://" + direccion + "/informes_control.php";
                             window.open(aux,'_blank');
                                }
                       
                    },
                    {
                        path: '/bancos',
                        name: 'Bancos',
                        component: () => import("./components/pages/bancos/BancosPage.vue"),
                    },
                    {
                        path: '/bancos/:id',
                        name: 'Banco',
                        component: () => import("./components/pages/bancos/Banco.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/bancos/crear',
                        name: 'Crear Bancos',
                        component: () => import("./components/pages/bancos/Banco.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    ///
                    {
                        path: '/correos',
                        name: 'Correos',
                        component: () => import("./components/pages/correos/CorreosPage.vue"),
                    },
                    {
                        path: '/ste',
                        name: 'Sigue tu expediente',
                        component: () => import("./components/pages/ste/ste.vue"),
                    },
                    {
                        path: '/correos/:id',
                        name: 'Correo',
                        component: () => import("./components/pages/correos/Correos.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/correos/crear',
                        name: 'Crear Correos',
                        component: () => import("./components/pages/correos/Correos.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },  
                          {
                        path: '/planning',
                        name: 'Planning',
                        component: () => import("./components/pages/PlanningPage.vue"),
                    },
                    {
                        path: '/chat',
                        name: 'Chat',
                        component: () => import("./components/pages/ChatPage.vue"),
                    },     
                    {
                        path: '/configuracion',
                        name: 'Configuración',
                        component: () => import("./components/pages/configuracion/Configuracion.vue"),
                    },
                    {
                        path: '/clientes',
                        name: 'Clientes',
                        component: () => import("./components/pages/clientes/ClientesPage.vue"),
                    },
                    {
                        path: '/clientes/:id',
                        name: 'Cliente',
                        component: () => import("./components/pages/clientes/Cliente.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/clientes/crear',
                        name: 'Crear Clientes',
                        component: () => import("./components/pages/clientes/Cliente.vue"),
                        props:  ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/configuracion-exportacion',
                        name: 'Exportación contable',
                        component: () => import("./components/pages/configuracion/facturacion/exportacion.vue"),
                    },
                    {
                        path: '/lineasfacturacion',
                        name: 'Líneas Facturación',
                        component: () => import("./components/pages/lineasfacturacion/LineasFacturacionPage.vue"),
                    },
                    {
                        path: '/lineasfacturacion/:id',
                        name: 'Línea Facturación',
                        component: () => import("./components/pages/lineasfacturacion/LineaFacturacion.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                              {
                        path: '/lineasfacturacion/crear',
                        name: 'Crear Líneas Facturación',
                        component: () => import("./components/pages/lineasfacturacion/LineaFacturacion.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/mensajespredefinidos',
                        name: 'Mensajes Predefinidos',
                        component: () => import("./components/pages/configuracion/mensajespredefinidos/MensajesPredefinidosLista.vue"),
                    },
                    {
                        path: '/mensajespredefinidos/:id',
                        name: 'Mensaje Predefinido',
                        component: () => import("./components/pages/configuracion/mensajespredefinidos/MensajePredefinido.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/mensajespredefinidos/crear',
                        name: 'Crear Mensajes Predefinidos',
                        component: () => import("./components/pages/configuracion/mensajespredefinidos/MensajePredefinido.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/proveedores',
                        name: 'Proveedores',
                        component: () => import("./components/pages/proveedores/ProveedoresPage.vue"),
                    },
                    {
                        path: '/proveedores/:id',
                        name: 'Proveedor',
                        component: () => import("./components/pages/proveedores/Proveedor.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/proveedores/:id',
                        name: 'Crear Proveedores',
                     component: () => import('./components/pages/proveedores/Proveedor.vue'),
                          props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        
                        path: '/operarios',
                        name: 'Operarios',
                        component: () => import("./components/pages/operarios/OperariosPage.vue"),
                    },
                    {
                        path: '/operarios/:id',
                        name: 'Operario',
                        component: () => import("./components/pages/operarios/Operario.vue"),
                       props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                                              {
                        path: '/operarios/crear',
                        name: 'Crear Operarios',
                        component: () => import("./components/pages/operarios/Operario.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/estadospendientes',
                        name: 'Estados Pendientes',
                        component: () => import("./components/pages/configuracion/estadospendientes/EstadosPendientesPage.vue"),
                    },
                    {
                        path: '/estadospendientes/:id',
                        name: 'Estado Pendiente',
                        component: () => import("./components/pages/configuracion/estadospendientes/EstadosPendientes.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/estadospendientes/crear',
                        name: 'Crear Estados Pendientes',
                        component: () => import("./components/pages/configuracion/estadospendientes/EstadosPendientes.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/tiposdanyo',
                        name: 'Tipos de daño',
                        component: () => import("./components/pages/configuracion/tiposdanyo/TiposDanyoPage.vue"),
                    },
                    {
                        path: '/coberturasoperarios',
                        name: 'CoberturasOperarios',
                        component: () => import("./components/pages/configuracion/coberturasoperarios/CoberturasOperarios.vue"),
                    },
                    {
                        path: '/tiposdanyo/:id',
                        name: 'Tipo Daño',
                        component: () => import("./components/pages/configuracion/tiposdanyo/TiposDanyos.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/tiposdanyo/crear',
                        name: 'Crear Tipos de daño',
                        component: () => import("./components/pages/configuracion/tiposdanyo/TiposDanyos.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/condiciones',
                        name: 'Condiciones Presupuestos',
                        component: () => import("./components/pages/configuracion/condicionespresupuestos/CondicionesPresupuestosPage.vue"),
                    },
                    {
                        path: '/condiciones/:id',
                        name: 'Condición Presupuesto',
                        component: () => import("./components/pages/configuracion/condicionespresupuestos/CondicionesPresupuestos.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/condiciones/crear',
                        name: 'Crear Condiciones Presupuestos',
                        component: () => import("./components/pages/configuracion/condicionespresupuestos/CondicionesPresupuestos.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/test',
                        name: 'Test',
                        component: () => import("./components/pages/TestPage.vue"),
                    },
                    {
                        path: '/:notFound(.*)',
                        component: () => import("./components/pages/NotFound.vue"),
                    },
                    {
                        path: '/pedidos',
                        name: 'Pedidos',
                        component: () => import("./components/pages/pedidos/Pedidos.vue"),
                    },
                    {
                        path: '/servicio/crear',
                        name: 'Crear Servicio',
                        component: () => import("./components/pages/servicios/NuevoServicio.vue"),
                    },
                    {
                        path: '/festivos',
                        name: 'Festivos',
                        component: () => import("./components/pages/festivos/Festivos.vue"),
                    },
                ]
            },
    
    
            {
                path: '/:notFound(.*)',
                component: () => import("./components/pages/NotFound.vue"),
            },
        ]
    });
}else{
    router = createRouter({
        history: createWebHistory(),
        companias:'compañias',
        routes: [
            {
                path: '/',
                redirect: {
                    name: 'Inicio'
                }
            },
            {
                path: '/login',
                name: 'login',
                component: () => import("./components/pages/auth/UserAuth.vue"),
            },
            {
                path: '/app',
                name: 'Inicio',
                component: () => import("./components/Layout.vue"),
                children: [
                    {
                        path: '/',
                        component: () => import("./components/pages/HomePage.vue"),
                    },
                    {
                        path: '/companias',
                        name: 'Compañías',
                        component: () => import("./components/pages/companyias/CompanyiasPage.vue"),
                    },
                    {
                        path: '/companias/crear',
                        name: 'Crear Compañía',
                        component: () => import('./components/pages/companyias/Compania.vue'),
                          props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/companias/:id',
                        name: 'Compañía',
                        component: () => import('./components/pages/companyias/Compania.vue'),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/usuarios',
                        name: 'Usuarios',
                        component: () => import("./components/pages/UsuariosPage.vue"),
                        children: [
                            
                        ]
                    },
                    {
                        path: '/usuarios/:id',
                        name: 'Usuario',
                        component: () => import('./components/pages/usuarios/Usuario.vue'),
                        props: ruta => ({id: +ruta.params.id}),
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                        
                    },
                    {
                        path: '/usuarios/crear',
                        name: 'Crear Usuarios',
                        component: () => import('./components/pages/usuarios/Usuario.vue'),
                          props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }  
                    },
                
                    {
                        path: '/servicios/:id',
                        name: 'Servicio',
                        component: () => import('./components/pages/servicios/Servicio.vue'),
                        props: ruta => ({id: +ruta.params.id}),
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/baremos',
                        name: 'Baremos',
                        component: () => import("./components/pages/TestPage.vue"),
                    },
                    {
                        path: '/configuracion/braremos',
                        name: 'Configuración baremos',
                        component: () => import("./components/pages/configuracion/baremos/baremos.vue"),
                    },
                    {
                        path: '/especialidades/:id',
                        name: 'Especialidad',
                        component: () => import('./components/pages/especialidades/Especialidad.vue'),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
    
                       
                    },
                     {
                        path: '/especialidades/crear',
                        name: 'Crear Especialidades',
                        component: () => import("./components/pages/especialidades/Especialidad.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/especialidades',
                        name: 'Especialidades',
                        component: () => import("./components/pages/especialidades/EspecialidadesPage.vue"),
                         props: true,
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/equiposMarcas',
                        name: 'EquiposMarcas',
                        component: () => import("./components/pages/configuracion/equiposmarcas/EquiposMarcasPage.vue"),
            
                    },
                    {
                        path: '/gestorTipos',
                        name: 'GestorTipos',
                        component: () => import("./components/pages/configuracion/gestortipo/gestortiposPage.vue"),
                    },
                    {
                        path: '/SMS',
                        name: 'SMS',
                        component: () => import("./components/pages/configuracion/sms/sms.vue"),
                    },
                    {
                        path: '/centralita',
                        name: 'Centralita',
                        component: () => import("./components/pages/configuracion/centralita/centralita.vue"),
                    },
                    {
                        path: '/escritorio',
                        name: 'Escritorio',
                        props: tipo_buscador => ({tipo_buscador}),
                        component: () => import("./components/pages/escritorio/Escritorio.vue"),
                    },
                    {
                        path: '/Eventos',
                        name: 'Eventos',
                        component: () => import("./components/pages/configuracion/eventos/Eventos.vue"),
                    },
                    {
                        path: '/DocumentacionRequerida',
                        name: 'Documentación Requerida',
                        component: () => import("./components/pages/configuracion/documentacionrequerida/DocumentacionRequerida.vue"),
                    },
                    {
                        path: '/GestionDocumentos',
                        name: 'Gestión Documentos',
                        component: () => import("./components/pages/configuracion/gestiondocumentos/GestionDocumentos.vue"),
                    },
                    {
                        path: '/GestionCampos',
                        name: 'Gestión Campos',
                        component: () => import("./components/pages/configuracion/gestioncampos/GestionCampos.vue"),
                    },
                   /* {
                        path: '/servicios',
                        name: 'Servicios',
                        props: ({servicios: true}), 
                        component: () => import("./components/pages/escritorio/Escritorio.vue"),
                    },*/
                    /*   {
                        path: '/planning',
                        name: 'Planning',
                        component: () => import("./components/pages/servicios/planning/planning.vue"),
                    },*/
                    {
                        path: '/planningGeografico',
                        name: 'Planning Geográfico',
                        component: () => import("./components/pages/planninggeografico/PlanningGeografico.vue"),
                    },
                    {
                        path: '/facturacionOperarios',
                        name: 'Facturación Operarios',
                        component: () => import("./components/pages/facturacionoperarios/FacturacionOperarios.vue"),
                    },
                    {
                        path: '/facturacionAlbaranes',
                        name: 'Facturación Albaranes',
                        component: () => import("./components/pages/facturacionalbaranes/FacturacionAlbaranes.vue"),
                    },
                    {
                        path: '/facturacion',
                        name: 'Facturación',
                        component: () => import("./components/pages/Facturacion.vue"),
                    },
                    {
                        path: '/peritos',
                        name: 'Peritos',
                        component: () => import("./components/pages/peritos/PeritosPage.vue"),
                    },
                    {
                        path: '/peritos/:id',
                        name: 'Perito',
                        component: () => import("./components/pages/peritos/Perito.vue"),
                         props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/peritos/crear',
                        name: 'Crear Peritos',
                        component: () => import("./components/pages/peritos/Perito.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    
                    {
                        path: '/comerciales',
                        name: 'Comerciales',
                        component: () => import("./components/pages/comerciales/ComercialesPage.vue"),
                        
                    },
                    {
                        path: '/comerciales/:id',
                        name: 'comercial',
                        component: () => import("./components/pages/comerciales/Comercial.vue"),
                         props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/comerciales/crear',
                        name: 'Crear Comerciales',
                        component: () => import("./components/pages/comerciales/Comercial.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                         {
                        path: '/empresas',
                        name: 'Empresas',
                        component: () => import("./components/pages/empresas/EmpresasPage.vue"),
                    },
                                    
                         {
                        path: '/empresaprincipal',
                        name: 'DatosEmpresa',
                        component: () => import("./components/pages/empresaprincipal/DatosEmpresa.vue"),
                    },
                    {
                        path: '/empresas/:id',
                        name: 'Empresa',
                        component: () => import("./components/pages/empresas/Empresa.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/empresas/crear',
                        name: 'Crear Empresas',
                        component: () => import("./components/pages/empresas/Empresa.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                     {
                        path: '/Informes',
                         name: 'Informes',
                         target: "_blank",
    
                         component: () => {
                             let direccion = localStorage.getItem('direccion');
                             let aux = "https://" + direccion + "/informes.php";
                             window.open(aux,'_blank');
                                }
                       
                    },
                    {
                        path: '/Control',
                         name: 'Control',
                         target: "_blank",
    
                         component: () => {
                             let direccion = localStorage.getItem('direccion');
                             let aux = "https://" + direccion + "/informes_control.php";
                             window.open(aux,'_blank');
                                }
                       
                    },
                    {
                        path: '/bancos',
                        name: 'Bancos',
                        component: () => import("./components/pages/bancos/BancosPage.vue"),
                    },
                    {
                        path: '/bancos/:id',
                        name: 'Banco',
                        component: () => import("./components/pages/bancos/Banco.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/bancos/crear',
                        name: 'Crear Bancos',
                        component: () => import("./components/pages/bancos/Banco.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    ///
                    {
                        path: '/correos',
                        name: 'Correos',
                        component: () => import("./components/pages/correos/CorreosPage.vue"),
                    },
                    {
                        path: '/ste',
                        name: 'Sigue tu expediente',
                        component: () => import("./components/pages/ste/ste.vue"),
                    },
                    {
                        path: '/correos/:id',
                        name: 'Correo',
                        component: () => import("./components/pages/correos/Correos.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/correos/crear',
                        name: 'Crear Correos',
                        component: () => import("./components/pages/correos/Correos.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },  
                          {
                        path: '/planning',
                        name: 'Planning',
                        component: () => import("./components/pages/PlanningPage.vue"),
                    },
                    {
                        path: '/chat',
                        name: 'Chat',
                        component: () => import("./components/pages/ChatPage.vue"),
                    },     
                    {
                        path: '/configuracion',
                        name: 'Configuración',
                        component: () => import("./components/pages/configuracion/Configuracion.vue"),
                    },
                    {
                        path: '/clientes',
                        name: 'Clientes',
                        component: () => import("./components/pages/clientes/ClientesPage.vue"),
                    },
                    {
                        path: '/clientes/:id',
                        name: 'Cliente',
                        component: () => import("./components/pages/clientes/Cliente.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/clientes/crear',
                        name: 'Crear Clientes',
                        component: () => import("./components/pages/clientes/Cliente.vue"),
                        props:  ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/configuracion-exportacion',
                        name: 'Exportación contable',
                        component: () => import("./components/pages/configuracion/facturacion/exportacion.vue"),
                    },
                    {
                        path: '/lineasfacturacion',
                        name: 'Líneas Facturación',
                        component: () => import("./components/pages/lineasfacturacion/LineasFacturacionPage.vue"),
                    },
                    {
                        path: '/lineasfacturacion/:id',
                        name: 'Línea Facturación',
                        component: () => import("./components/pages/lineasfacturacion/LineaFacturacion.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                              {
                        path: '/lineasfacturacion/crear',
                        name: 'Crear Líneas Facturación',
                        component: () => import("./components/pages/lineasfacturacion/LineaFacturacion.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/mensajespredefinidos',
                        name: 'Mensajes Predefinidos',
                        component: () => import("./components/pages/configuracion/mensajespredefinidos/MensajesPredefinidosLista.vue"),
                    },
                    {
                        path: '/mensajespredefinidos/:id',
                        name: 'Mensaje Predefinido',
                        component: () => import("./components/pages/configuracion/mensajespredefinidos/MensajePredefinido.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/mensajespredefinidos/crear',
                        name: 'Crear Mensajes Predefinidos',
                        component: () => import("./components/pages/configuracion/mensajespredefinidos/MensajePredefinido.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/proveedores',
                        name: 'Proveedores',
                        component: () => import("./components/pages/proveedores/ProveedoresPage.vue"),
                    },
                    {
                        path: '/proveedores/:id',
                        name: 'Proveedor',
                        component: () => import("./components/pages/proveedores/Proveedor.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/proveedores/:id',
                        name: 'Crear Proveedores',
                     component: () => import('./components/pages/proveedores/Proveedor.vue'),
                          props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        
                        path: '/operarios',
                        name: 'Operarios',
                        component: () => import("./components/pages/operarios/OperariosPage.vue"),
                    },
                    {
                        path: '/operarios/:id',
                        name: 'Operario',
                        component: () => import("./components/pages/operarios/Operario.vue"),
                       props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                                              {
                        path: '/operarios/crear',
                        name: 'Crear Operarios',
                        component: () => import("./components/pages/operarios/Operario.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/estadospendientes',
                        name: 'Estados Pendientes',
                        component: () => import("./components/pages/configuracion/estadospendientes/EstadosPendientesPage.vue"),
                    },
                    {
                        path: '/estadospendientes/:id',
                        name: 'Estado Pendiente',
                        component: () => import("./components/pages/configuracion/estadospendientes/EstadosPendientes.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/estadospendientes/crear',
                        name: 'Crear Estados Pendientes',
                        component: () => import("./components/pages/configuracion/estadospendientes/EstadosPendientes.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/tiposdanyo',
                        name: 'Tipos de daño',
                        component: () => import("./components/pages/configuracion/tiposdanyo/TiposDanyoPage.vue"),
                    },
                    {
                        path: '/coberturasoperarios',
                        name: 'CoberturasOperarios',
                        component: () => import("./components/pages/configuracion/coberturasoperarios/CoberturasOperarios.vue"),
                    },
                    {
                        path: '/tiposdanyo/:id',
                        name: 'Tipo Daño',
                        component: () => import("./components/pages/configuracion/tiposdanyo/TiposDanyos.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/tiposdanyo/crear',
                        name: 'Crear Tipos de daño',
                        component: () => import("./components/pages/configuracion/tiposdanyo/TiposDanyos.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/condiciones',
                        name: 'Condiciones Presupuestos',
                        component: () => import("./components/pages/configuracion/condicionespresupuestos/CondicionesPresupuestosPage.vue"),
                    },
                    {
                        path: '/condiciones/:id',
                        name: 'Condición Presupuesto',
                        component: () => import("./components/pages/configuracion/condicionespresupuestos/CondicionesPresupuestos.vue"),
                        props: ruta => ({id: +ruta.params.id}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                       {
                        path: '/condiciones/crear',
                        name: 'Crear Condiciones Presupuestos',
                        component: () => import("./components/pages/configuracion/condicionespresupuestos/CondicionesPresupuestos.vue"),
                            props: ({id: 0}), // pasamos id de string a number
                        meta: {
                            titulo: ruta => ruta.params.id
                        }
                    },
                    {
                        path: '/test',
                        name: 'Test',
                        component: () => import("./components/pages/TestPage.vue"),
                    },
                    {
                        path: '/:notFound(.*)',
                        component: () => import("./components/pages/NotFound.vue"),
                    },
                    {
                        path: '/pedidos',
                        name: 'Pedidos',
                        component: () => import("./components/pages/pedidos/Pedidos.vue"),
                    },
                    {
                        path: '/servicio/crear',
                        name: 'Crear Servicio',
                        component: () => import("./components/pages/servicios/NuevoServicio.vue"),
                    },
                    {
                        path: '/festivos',
                        name: 'Festivos',
                        component: () => import("./components/pages/festivos/Festivos.vue"),
                    },
                ]
            },
    
    
            {
                path: '/:notFound(.*)',
                component: () => import("./components/pages/NotFound.vue"),
            },
        ]
    });
}

router.beforeEach((to, from, next) => {
    // acciones 
    let irALogin = false;
    if (to.name === 'login') {
        irALogin = true;
    }
   
    const token = localStorage.getItem('token');
    
     
    
   
    if (!token && !irALogin) {
        next({ name: 'login' });
    }
    else if (irALogin && token) {
        next({ name: 'Inicio' });
    }
  
  
    else {
        next();
        if (from.fullPath.match(/(nueva|nuevo)/)) {
            store.dispatch('reemplazarVentana', {
                from, 
                to,
            });
        }
        else {
            if (to.name != 'login'){
                store.dispatch('agregarVentana', to);
                }
        }
    }
});

export default router;