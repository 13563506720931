<template>
    <!-- Se encarga de renderizar un menu con submenus -->
    <nav-item v-for="menuitem in menudos" :key="menuitem.nombre" :ruta="menuitem.ruta"
        :clasesNavItem="menuitem.clasesNavItem" :clasesIcono="menuitem.clasesIcono">
        <!--slot-->
        <span>{{ menuitem.nombre}}</span>
        <!--slot-->
        <span v-if="menuitem.badge" style="position: relative; left: 130px;" class="badge" :class="['badge-' + menuitem.badge.tipo]">
            {{ chats }}
        </span>
        <i v-if="menuitem.submenu" @click.prevent="toggleSubmenu(menuitem)" :class="['right fas', menuitem.submenuvisible ? 'fa-angle-down' : 'fa-angle-left']"></i>

        <!-- Submenús con clases para el efecto de acordeón -->
        <div v-if="menuitem.submenu" :class="['submenu', 'collapse', { 'show': menuitem.submenuvisible }]">
            <nav-item v-for="submenuitem in menuitem.submenu" :key="submenuitem.nombre" :ruta="submenuitem.ruta"
                :style="submenuitem.style" :clasesIcono="submenuitem.clasesIcono">
                <span>{{ submenuitem.nombre }}</span>
            </nav-item>
        </div>
    </nav-item>
</template>

<script>
import { PwgsApi } from '../services/PwgsApi';
export default {
    data() {
        return {
            chats: 0,
            menudos: [
                {
                    nombre: 'Escritorio / Servicios',
                    ruta: '/escritorio',
                    clasesIcono: 'fas fa-home'
                },
                {
                    nombre: 'Configuración',
                    ruta: '/configuracion',
                    clasesIcono: 'fas fa-cogs',
                },
                {
                    nombre: 'Planning',
                    ruta: '/planning',
                    clasesIcono: 'fas fa-calendar-alt'
                },
            ]
        }
    },
    methods: {        
        toggleSubmenu(menuitem) {
            // Cambiar la visibilidad del submenú
            menuitem.submenuvisible = !menuitem.submenuvisible;
        },
        async comprobar() {
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const respplanning = await api.get('parametros-pwgs/planning_geografico');
            const resppermisos = await api.get('usuarios/' + auxusuario + '/permisos');
            var permisos = resppermisos.datos;
            const factencontrado = permisos.find(permiso => permiso.nombre_permiso == "Facturación");
            const adminencontrado = permisos.find(permiso => permiso.nombre_permiso == "Administración");
            const superadminencontrado = permisos.find(permiso => permiso.nombre_permiso == "Super Administración");

            if (factencontrado || adminencontrado || superadminencontrado) {
                var informes = {
                    nombre: 'Informes',
                    ruta: '/Informes',
                    clasesIcono: 'fas fa-desktop',
                    submenuvisible: false,
                    submenu: [
                    {
                        nombre: 'Facturación',
                        ruta: '/facturacion',
                        style:'padding-left:0',
                        clasesIcono: 'far fa-circle'
                    },    
                    {
                        nombre: 'Facturación operarios',
                        ruta: '/facturacionOperarios',
                        style:'padding-left:0',
                        clasesIcono: 'far fa-circle'
                    },
                    {
                        nombre: 'Facturación albaranes',
                        ruta: '/facturacionAlbaranes',
                        style:'padding-left:0',
                        clasesIcono: 'far fa-circle'
                    }
                    ]
                };
                var control = {
                    nombre: 'Control',
                    ruta: '/Control',
                    clasesIcono: 'fas fa-tachometer-alt'

                };
                this.insertarDespuesDePlanning(control);
                this.insertarDespuesDePlanning(informes);
            }
            if (respplanning.planning_geografico == 1) {
                var planningGeografico = {
                    nombre: 'Planning Geográfico',
                    ruta: '/planningGeografico',
                    clasesIcono: 'fas fa-map-marked-alt'
                }
                this.insertarDespuesDePlanning(planningGeografico);
            }

        },
        insertarDespuesDePlanning(nuevoItem) {
            const planningIndex = this.menudos.findIndex(item => item.nombre === 'Planning');
            if (planningIndex !== -1) {
                this.menudos.splice(planningIndex + 1, 0, nuevoItem);
            } else {
                // Si no encuentra 'Planning', lo agrega al final del array
                this.menudos.push(nuevoItem);
            }
        }
    },
    mounted() {
        this.comprobar();
    }

}
</script>
