<template>
    <div class="row">
        <div class="col-md-8">
            <i class="far fa-address-card mr-1"></i> {{ $t('general.nombre') }}
            <div class="row" style="column-gap: 5px;">
                <p class="">
                    <strong>{{asegurado.nombre}}</strong> 
                </p>
                <button style="height: 30px;" type="button" title="Notificar al asegurado para que se ponga en contacto con nosotros" class="btn btn-outline-info btn-sm"
                        v-on:click='confirmar1()'><i class="fas fa-sms"></i></button> 
            </div>
        </div>
        <div class="col-md-4">
            <i class="far fa-id-card mr-1"></i> {{ $t('general.identificacion') }}
            <p class="">
                <strong>{{ asegurado.nif }}</strong>
            </p>
        </div>
    </div>
    <div class="row" style="row-gap: 1em;">
        <div class="col-md-6 col-xs-12" v-if="asegurado.telefono1  && asegurado.telefono1.length>=9">
            <div v-if="asegurado.telefono1_descripcion==''" class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 1
                </div>
                <input type="radio" v-if="tlfpref == 1" title="teléfono prioritario" value="1" v-model="tlfpref">
            </div>
            <div v-else class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ asegurado.telefono1_descripcion.slice(0,29) }}
                </div>
                <input type="radio" v-if="tlfpref == 1" title="teléfono prioritario" value="1" v-model="tlfpref">
            </div>
            <div class="btn-group"  style="display: flex; flex-wrap: wrap;">
                <button type="button" v-if="asegurado.telefono1" v-on:click='llamar(asegurado.telefono1)'
                    class="btn btn-outline-info  btn-sm">{{ asegurado.telefono1 }}</button>
                <button type="button" v-if="asegurado.telefono1" class="btn btn-outline-info btn-sm"
                    v-on:click='sms(asegurado.telefono1)'><i class="fas fa-sms"></i></button>
                <button type="button" v-if="servicio.ste == 1" class="btn btn-outline-info btn-sm"
                    v-on:click='enviarSte("SMS", asegurado.telefono1)'><i title="Enviar Sigue Tu Expediente"
                        class="fas fa-house-user"></i></button>
                <button type="button" v-if="servicio.teleasistencia == 1" class="btn btn-outline-info btn-sm"
                    v-on:click='enviarTeleasistencia("SMS", asegurado.telefono1)'><i title="Enviar teleasistencia"
                        class="fas fa-video"></i></button>

            </div>
        </div>
        <div class="col-md-6 col-xs-12" v-if="asegurado.telefono2 && asegurado.telefono2.length>=9">
            <div v-if="asegurado.telefono2_descripcion==''" class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 2
                </div>
                <input type="radio" title="teléfono prioritario" v-if="tlfpref == 2" value="2" v-model="tlfpref">
            </div>
            <div v-else class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ asegurado.telefono2_descripcion.slice(0,29) }}
                </div>
                <input type="radio" v-if="tlfpref == 2" title="teléfono prioritario" value="2" v-model="tlfpref">
            </div>
            <div class="btn-group" v-if="asegurado.telefono2"  style="display: flex; flex-wrap: wrap;">
                <button type="button" v-on:click='llamar(asegurado.telefono2)' class="btn btn-outline-info  btn-sm">{{
                    asegurado.telefono2 }}</button>
                <button type="button" class="btn btn-outline-info btn-sm" v-on:click='sms(asegurado.telefono2)'><i
                        class="fas fa-sms"></i></button>
                <button type="button" v-if="servicio.ste == 1" class="btn btn-outline-info btn-sm"
                    v-on:click='enviarSte("SMS", asegurado.telefono2)'><i title="Enviar Sigue Tu Expediente"
                        class="fas fa-house-user"></i></button>
                <button type="button" v-if="servicio.teleasistencia == 1" class="btn btn-outline-info btn-sm"
                    v-on:click='enviarTeleasistencia("SMS", asegurado.telefono2)'><i title="Enviar teleasistencia"
                        class="fas fa-video"></i></button>

            </div>
        </div>
        <div class="col-md-6 col-xs-12" v-if="asegurado.telefono3  && asegurado.telefono3.length>=9">
            <div v-if="asegurado.telefono3_descripcion==''" class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 3
                </div>
                <input type="radio" v-if="tlfpref == 3" title="teléfono prioritario" value="3" v-model="tlfpref">
            </div>
            <div v-else class="row" style="column-gap: 1em;">
                <div>
                    <i class="fas fa-phone-alt"></i> {{ asegurado.telefono3_descripcion.slice(0,29) }}
                </div>
                <input type="radio" v-if="tlfpref == 3" title="teléfono prioritario" value="3" v-model="tlfpref">
            </div>
            <div class="btn-group"  style="display: flex; flex-wrap: wrap;" v-if="asegurado.telefono3">
                <button type="button" v-on:click='llamar(asegurado.telefono3)' class="btn btn-outline-info  btn-sm">{{
                    asegurado.telefono3 }}</button>
                <button type="button" class="btn btn-outline-info btn-sm" v-on:click='sms(asegurado.telefono3)'><i
                        class="fas fa-sms"></i></button>
                <button type="button" v-if="servicio.ste == 1" class="btn btn-outline-info btn-sm"
                    v-on:click='enviarSte("SMS", asegurado.telefono3)'><i title="Enviar Sigue Tu Expediente"
                        class="fas fa-house-user"></i></button>
                <button type="button" v-if="servicio.teleasistencia == 1" class="btn btn-outline-info btn-sm"
                    v-on:click='enviarTeleasistencia("SMS", asegurado.telefono3)'><i title="Enviar teleasistencia"
                        class="fas fa-video"></i></button>

            </div>
        </div>
        <div class="col-md-12" v-if="mostrarComunicacionSMS">
            <ComunicacionSMS :id="id" :numero="numero_envio" :asegurado="asegurado" :servicio="servicio" />
        </div>
    </div>
    <div class="row" v-if="asegurado.email">
        <div class="col-md-4" style="display: flex; flex-wrap: wrap;">
            <i class="fas fa-mail-bulk"></i> Email
            <button type="button" v-on:click='email(asegurado.email)' class="btn btn-block btn-outline-info btn-sm">{{
                asegurado.email }}</button>
            <button type="button" v-if="servicio.ste == 1" class="btn btn-outline-info btn-sm"
                v-on:click='enviarSte("EMAIL", asegurado.email)'><i title="Enviar Sigue Tu Expediente"
                    class="fas fa-house-user"></i></button>
            <button type="button" v-if="servicio.teleasistencia == 1" class="btn btn-outline-info btn-sm"
                v-on:click='enviarTeleasistencia("EMAIL", asegurado.email)'><i title="Enviar teleasistencia"
                    class="fas fa-video"></i></button>

        </div>
        <div class="col-md-12" v-if="mostrarComunicacionEmail">
            <ComunicacionEmail :id="id" :email="asegurado.email" :asegurado="asegurado" :servicio="servicio" />
        </div>

    </div>
    <div class="row">
        <div class="col-md-8">
            <i class="fas fa-map-marker-alt mr-1"></i> {{ $t('general.direccion') }}
            <p class="">
                <strong>{{asegurado.direccion}}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-city mr-1"></i> {{ $t('general.ciudad') }}
            <p class="">
                <strong>{{asegurado.poblacion}}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8">
            <i class="fas fa-map-marker-alt mr-1"></i> {{ $t('general.provincia') }}
            <p class="">
                <strong>{{ asegurado.provincia }}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="fas fa-city mr-1"></i> {{ $t('general.cp') }}
            <p class="">
                <strong>{{ asegurado.codigo_postal }}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <i class="fas fa-id-card mr-1"></i>IBAN
            <p class="">
                <strong>{{ asegurado.numero_iban1 }}-{{ asegurado.numero_iban2 }}-{{
                    asegurado.numero_entidad}}-{{asegurado.numero_oficina }}-{{ asegurado.numero_dc }}-{{
                    asegurado.numero_cuenta }}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <i class="fas fa-id-card mr-1"></i>{{ $t('general.personadecontacto') }}
            <p class="">
                <strong>{{ asegurado.persona_contacto}}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <i class="fas fa-id-card mr-1"></i>{{ $t('general.poliza') }}
            <p class="">
                <strong>{{ asegurado.poliza }}</strong>
            </p>
        </div>
        <div v-if="asegurado.numbero_iban2" class="col-md-12">
            <i class="fas fa-id-card mr-1"></i>{{ $t('general.subcuenta') }}
            <p class="">
                <strong>{{ asegurado.numero_iban2 }}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <i class="fas fa-id-card mr-1"></i>{{ $t('general.observaciones') }}
            <p class="">
                <strong>{{ asegurado.observaciones }}</strong>
            </p>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import ComunicacionEmail from '../../../comunicaciones/ComunicacionEmail.vue'
import ComunicacionSMS from '../../../comunicaciones/ComunicacionSMS.vue'
  
export default ({
    props: ['id', 'asegurado', 'servicio'],
    setup() {     
    },
    data() {
        return {
            tlfpref:0,
            mostrarComunicacionEmail: false,
            email_envio: '',
            mostrarComunicacionSMS: false,
            numero_envio: ''
        }
    },
    components: {
        ComunicacionEmail,
        ComunicacionSMS
    },
    methods: {
        llamar: function (telefono) {
            window.open("tel:" + telefono);
        },
        comprobarPref(){
            if(this.asegurado.telefono_prioritario==1){
                this.tlfpref = '1';
            }else if(this.asegurado.telefono_prioritario==2){
                this.tlfpref = '2';
            }else if(this.asegurado.telefono_prioritario==3){
                this.tlfpref = '3';
            }
        },
        async notificar(){
            console.log('notifica');
            const api = new PwgsApi();
            let subidadatos = {id_cliente : this.$props.servicio.asegurado.id};
            const resp = await api.put('servicios/'+this.id+'/notificar-cliente',subidadatos);
            console.log('notificado',resp);
        },
        confirmar1() {
            console.log('equiere');
            this.$confirm.require({
                message: '¿Seguro que quieres notificar al asegurado para que se ponga en contacto con nosotros?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'Cancelar',
                acceptLabel: 'Aceptar',
                accept: () => {
                    this.notificar();
                },
                reject: () => {
                    return;
                }
            });
        },
        email: function (email) {

            if (this.mostrarComunicacionEmail === true) {
                this.mostrarComunicacionEmail = false;
            } else {
                this.mostrarComunicacionEmail = true;
            }
            this.email_envio = email;
        },
        async enviarTeleasistencia(tipo, valor) {
            //PUT /servicios/:id/enviar-ste/:id_cliente
            const api = new PwgsApi();
            let subidadatos = { tipo: tipo, teleasistencia: 1 }
            if (tipo == "SMS") {
                subidadatos.telefono = valor;
            }
            try {
                await api.put('servicios/' + this.$props.id + '/enviar-ste/' + this.$props.servicio.asegurado.id, subidadatos);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async enviarSte(tipo, valor) {
            //PUT /servicios/:id/enviar-ste/:id_cliente
            const api = new PwgsApi();
            let subidadatos = { tipo: tipo }
            if (tipo == "SMS") {
                subidadatos.telefono = valor;
            }
            try {
                await api.put('servicios/' + this.$props.id + '/enviar-ste/' + this.$props.servicio.asegurado.id, subidadatos);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        sms: function (numero) {

            if (this.mostrarComunicacionSMS === true) {
                this.mostrarComunicacionSMS = false;
            } else {
                this.mostrarComunicacionSMS = true;
            }
            this.numero_envio = numero;

        }
    },
    mounted(){
        this.comprobarPref();
    }
})


</script>