<template>
    <div class="row">
        <div class="col-md-8">
            <seccion-datos :titulo="configuracioncarga" @cambios="guardar(this.id)">
                <table class="table table-sm table-bordered" v-if="cargado">
                    <thead>
                        <tr>
                            <th style="text-align:center;">Carga diaria</th>
                            <th style="text-align:center;">Máx activos</th>
                            <th style="text-align:center;">Jornada</th>
                            <th style="text-align:center;">Ratio activos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="background-color:#d9edf7">
                                <div style="display: flex;">
                                    <input type="number" style="width: 150px;" class="form-control form-control-sm" v-model="carga_diaria">({{porcentaje_carga_diaria }})
                                </div>
                            </td>
                            <td style="background-color:#d9edf7">
                                <div style="display: flex;">
                                    <input type="number" style="width: 150px;" class="form-control form-control-sm" v-model="max_activos">({{max_activos_porcentaje }})
                                </div>
                            </td>
                            <td style="background-color:#d9edf7">
                                <div style="display: flex;">
                                    <input type="number" style="width: 150px;" class="form-control form-control-sm" v-model="periodo_activos">%
                                </div>
                            </td>
                            <td style="background-color:#d9edf7">
                                {{ratio_activos}}
                            </td>
                        </tr>
                        <tr v-if="alerta!=1">
                            <td style="background-color:#d9edf7">
                                {{max_carga_diaria_calc}}
                            </td>
                            <td style="background-color:#d9edf7">
                                {{activos_calc}}
                                <span title="Expedientes activos que no tienen marcado 'Finalizar expediente' en 'Trabajos realizados'">
                                    ({{no_finalizados }})
                                </span>
                            </td>
                            <td style="background-color:#d9edf7">-</td>
                            <td style="background-color:#d9edf7">-</td>
                        </tr>
                        <tr v-else>
                            <td style="background-color:#f2dede">
                                {{max_carga_diaria_calc}}
                            </td>
                            <td style="background-color:#f2dede">
                                {{activos_calc}}
                                <span title="Expedientes activos que no tienen marcado 'Finalizar expediente' en 'Trabajos realizados'">
                                    ({{no_finalizados }})
                                </span>
                            </td>
                            <td style="background-color:#f2dede">-</td>
                            <td style="background-color:#f2dede">-</td>
                        </tr>
                    </tbody>
                </table>                    
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['usuario','id'],
    data() {
        return {
            alerta: null,
            max_carga_diaria_calc:'',
            activos_calc: '',
            no_finalizados: '',
            carga_diaria: '',
            porcentaje_carga_diaria:'',
            max_activos_porcentaje:'',
            max_activos: '',
            ratio_activos:'',
            periodo_activos: '',
            configuracioncarga: "Configuración de niveles de carga",
            cargado:false,
        }
    },
    methods: {
        async cargardatos() {
            if(this.usuario.length != 0){
                console.log('datosusu', this.usuario);
                this.$store.dispatch('cambiarCargando', true);
                this.alerta = this.usuario.cargas.alerta;
                this.carga_diaria = this.usuario.carga_diaria;
                this.max_activos = this.usuario.max_activos;
                this.periodo_activos = this.usuario.periodo_activos;
                this.porcentaje_carga_diaria = this.usuario.cargas.porcentaje_carga_diaria;
                this.max_activos_porcentaje = this.usuario.cargas.max_activos_porcentaje;
                this.ratio_activos = this.usuario.cargas.ratio_activos;
                this.max_carga_diaria_calc = this.usuario.cargas.max_carga_diaria_calc;
                this.activos_calc = this.usuario.cargas.activos_calc;
                this.no_finalizados = this.usuario.cargas.no_finalizados;
                this.$store.dispatch('cambiarCargando', false);
                this.configuracioncarga = this.$t('general.configuracioncarga');
                this.cargado = true;
            }
        },
        async guardar(id) {
            /* put  modulos/pwgsapi/index.php/usuarios/:id/carga */
            this.$store.dispatch('cambiarGuardando', true);
            const datosSubida = { carga_diaria: this.carga_diaria, max_activos: this.max_activos, periodo_activos: this.periodo_activos };
            try {
                const api = new PwgsApi;
               await api.put('usuarios/' + id + '/carga', datosSubida);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.$emit('recargar');
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        }
    },
    mounted() {
        this.cargardatos();
    },

    watch: {
        usuario() {
            this.cargardatos();
        }
    }
    }
</script>