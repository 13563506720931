<template>
    <div class="container-fluid">
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <section class="content-header">
                        <div class="row mb-2">
                            <h3>Nuevo Servicio</h3>
                        </div>
                    </section>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">&nbsp;</div>
                <div class="col-md-2">
                    <Button label="Vaciar formulario" style="width:100%;" @click="borrarDatos()"></Button>
                </div>
            </div>

            <div class="row">
                &nbsp;
            </div>
            <div class="row">
                <div class="col-md-10">

                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Siniestro</h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="Codigo123">Código</label>
                                        <input  type="text" class="form-control dato"
                                            v-model='datosclientenuevo.codigo' id="Codigo123" placeholder="Código">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="Codigo">Nº Aviso</label>
                                        <input  type="text" class="form-control dato"
                                            v-model='datosclientenuevo.aviso' id="Codigo" placeholder="Numero de aviso">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="Codigo">Empresa Factura</label>
                                        <Dropdown  :options="empresas"
                                            v-model="datosclientenuevo.empresaseleccionada" filter
                                            optionLabel="nombre_empresa" placeholder="Selecciona empresa"
                                            style="width:100%">
                                        </Dropdown>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="Codigo">Año del Siniestro</label>
                                        <input  type="text" class="form-control dato"
                                            v-model='datosclientenuevo.anyo' id="Codigo"
                                            placeholder="Año del Siniestro">
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="form-group">
                                        <label for="Codigo">Urgente</label><br>
                                        <input  type="checkbox" v-model='datosclientenuevo.urgente' id="Codigo">
    
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="align-items: end;">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="Codigo">Compañía</label>
                                        <Dropdown  :options="companyas"
                                            v-model="datosclientenuevo.companyaseleccionada" filter optionLabel="nombre"
                                            placeholder="Selecciona compañia" style="width:100%">
                                            <template  #option="slotProps">
                                                <div style="width: 100%;" @click="cambioContratos(slotProps.option.idcompañia)" class="flex align-items-center">
                                                    <div ><b v-if="slotProps.option.prefijo">{{ slotProps.option.prefijo
                                                            }} |</b>&nbsp;{{ slotProps.option.nombre }}</div>
                                                </div>
                                            </template>
                                        </Dropdown>

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="grid">
                                        <b>Contratos</b>
                                        <Dropdown :options="contratosSeguros" v-model="contratoSeguroSeleciconada"
                                        filter placeholder="Selecicona contrato" style="width: 100%;">
                                        </Dropdown>                                                                   
                                        <input type="text" class="form-control" v-model="contratoSeguroInput">                                        
                                    </div>
                                </div>  
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="Codigo">Compañía de seguros</label>
                                        <Dropdown :options="companiasSeguros"
                                            v-model="companiaSeguroSelec" filter optionValue="id_companyia_asitur"
                                            optionLabel="nombre" placeholder="Selecciona compañía de seguro"
                                            style="width:100%">
                                        </Dropdown>
                                    </div>
                                </div>  
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="descr">Descripción de los daños</label>
                                        <Textarea  v-model="datosclientenuevo.descripcion"
                                            class="form-control dato" id="descr"></Textarea>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="Observaciones">Observaciones</label>
                                        <Textarea  v-model="datosclientenuevo.observaciones"
                                            class="form-control dato" id="Observaciones"></Textarea>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="nota">Nota importante</label>
                                        <Textarea  v-model="datosclientenuevo.notaimportante"
                                            id="nota" class="form-control dato"></Textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="card card-success">
                        <div class="card-header">
                            <h3 class="card-title">Asegurado</h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <Dropdown  ref="buscadorclientes" showClear editable
                                            :options="clientes" v-model="clienteseleccionado" filter
                                            optionLabel="nombre" placeholder="Busqueda Clientes"
                                            @keyup.enter="cargarclientes()" style="width:100%">
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="clientenombre">Nombre</label>
                                        <input  :disabled="deshabilitado" type="text"
                                            class="form-control dato" v-model="datosclientenuevo.nombre"
                                            id="clientenombre" placeholder="Nombre">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="clienterazonsocial">Razón Social</label>
                                        <input  :disabled="deshabilitado" type="text"
                                            class="form-control dato" v-model='datosclientenuevo.razon_social'
                                            id="clienterazonsocial" placeholder="Razón Social">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="clientedireccion">Dirección</label>
                                        <input  :disabled="deshabilitado" type="text"
                                            class="form-control dato" v-model='datosclientenuevo.direccion'
                                            id="clientedireccion" placeholder="Dirección">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="clientenombre">Telefono 1</label>
                                        <div class="input-group md-12">
                                            <div class="col-md-4">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.telefono1'
                                                    id="clientenombre" placeholder="Teléfono">
                                            </div>
                                            <input  :disabled="deshabilitado" type="text"
                                                class="form-control dato"
                                                v-model='datosclientenuevo.telefono1_descripcion' id="clientedireccion"
                                                placeholder="Descripción">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="clientenombre">Telefono 2</label>
                                        <div class="input-group md-12">
                                            <div class="col-md-4">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.telefono2'
                                                    id="clientenombre" placeholder="Teléfono">
                                            </div>
                                            <input  :disabled="deshabilitado" type="text"
                                                class="form-control dato"
                                                v-model='datosclientenuevo.telefono2_descripcion' id="clientedireccion"
                                                placeholder="Descripción">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="clientenombre">Telefono 3</label>
                                        <div class="input-group md-12">
                                            <div class="col-md-4">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.telefono3'
                                                    id="clientenombre" placeholder="Teléfono">
                                            </div>
                                            <input  :disabled="deshabilitado" type="text"
                                                class="form-control dato"
                                                v-model='datosclientenuevo.telefono3_descripcion' id="clientedireccion"
                                                placeholder="Descripción">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="Fax">Fax</label>
                                        <input  :disabled="deshabilitado" type="text"
                                            class="form-control dato" v-model='datosclientenuevo.fax' id="Fax"
                                            placeholder="Fax">
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="mail">E-mail</label>
                                        <input  :disabled="deshabilitado" type="text"
                                            class="form-control dato" v-model='datosclientenuevo.email' id="mail"
                                            placeholder="E-mail">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="NIF">NIF</label>
                                        <input  :disabled="deshabilitado" type="text"
                                            class="form-control dato" v-model='datosclientenuevo.nif' id="NIF"
                                            placeholder="NIF">
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label for="iban">&nbsp;&nbsp;Número IBAN</label>
                                        <div id="iban" class="input-group md-12">
                                            <div class="col-md-1">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.numero_iban1'
                                                    id="iban1">
                                            </div>-
                                            <div class="col-md-1">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.numero_iban2'
                                                    id="iban2">
                                            </div>-
                                            <div class="col-md-2">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.numero_entidad'
                                                    id="entidad">
                                            </div>-
                                            <div class="col-md-2">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.numero_oficina'
                                                    id="oficina">
                                            </div>-
                                            <div class="col-md-1">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.numero_dc'
                                                    id="dc">
                                            </div>-
                                            <div class="col-md-3">
                                                <input  :disabled="deshabilitado" type="text"
                                                    class="form-control dato" v-model='datosclientenuevo.numero_cuenta'
                                                    id="numero_cuenta">
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="poblacion">Población</label>
                                        <input :disabled="deshabilitado" type="text" class="form-control dato"
                                            v-model='datosclientenuevo.poblacion' id="poblacion"
                                            placeholder="Población">
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="form-group">
                                        <label for="cp">Código Postal</label>
                                        <input :disabled="deshabilitado" type="text" class="form-control dato"
                                            v-model='datosclientenuevo.codigo_postal' id="cp" placeholder="C.P">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="provincia">Provincia</label>
                                        <input :disabled="deshabilitado" type="text" class="form-control dato"
                                            v-model='datosclientenuevo.provincia' id="provincia"
                                            placeholder="Provincia">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="perscontacto">Persona de Contacto</label>
                                        <input :disabled="deshabilitado" type="text" class="form-control dato"
                                            v-model='datosclientenuevo.persona_contacto' id="perscontacto"
                                            placeholder="Persona de Contacto">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="Poliza">Poliza</label>
                                        <input :disabled="deshabilitado" type="text" class="form-control dato"
                                            v-model='datosclientenuevo.poliza' id="Poliza" placeholder="Poliza">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="Subcuenta">Subcuenta</label>
                                        <input :disabled="deshabilitado" type="text" class="form-control dato"
                                            v-model='datosclientenuevo.contador_subcuenta' id="Subcuenta"
                                            placeholder="Automático si vacio">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2"></div>

            <div class="col-md-10">
                <Button label="Crear Expediente" style="width:100%" @click="guardar()"></Button>
            </div>
        </section>
    </div>
</template>
<script>
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    components: {

        Textarea, Dropdown, Button
    },
    props: [],

    data() {
        return {
            datosclientenuevo: {
                deshabilitado: false,
                codigo: '',
                empresaseleccionada: '', //id
                aviso: '',
                descripcion: '',
                notaimportante: '',
                observaciones: '',
                anyo: new Date().getFullYear(),
                companyaseleccionada: '', //id
                nombre: '',
                razon_social: '',
                direccion: '',
                telefono1: '',
                telefono2: '', telefono3: '',
                telefono1_descripcion: '',
                telefono2_descripcion: '',
                telefono3_descripcion: '',
                nif: '',
                email: '',
                fax: '',
                numero_cuenta: '',
                contador_subcuenta: '',
                poliza: '',
                persona_contacto: '',
                provincia: '',
                codigo_postal: '',
                poblacion: '',
                numero_oficina: '',
                numero_entidad: '',
                numero_dc: '',
                numero_iban1: '',
                numero_iban2: '',
                idcliente: '',
                urgente:0,
            },
            contratoSeguroSeleciconada:'',
            contratosSeguros:[],
            contratoSeguroInput:'',
            companyas: '',
            clientes: '',
            empresas: '',
            clienteseleccionado: '',
            companiasSeguros:'',
            companiaSeguroSelec:'',
        }
    },
    methods: {

        async guardar() {
            //POST  modulos/pwgsapi/index.php/servicios
            const api = new PwgsApi();
            let resultado = '';
            let idcliente = '';
            if(!this.clienteseleccionado){
               idcliente = await this.crearcliente();
            }else{
                if(!this.datosclientenuevo.idcliente){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'Debe seleccionar un cliente', life: 5000 });
                    return;
                }
                idcliente = this.datosclientenuevo.idcliente;
            }
            if(!idcliente){
                return;
            }
            let subidadatos = { 
                codigo: this.datosclientenuevo.codigo, n_aviso: this.datosclientenuevo.aviso, id_empresa: this.datosclientenuevo.empresaseleccionada.id, 
                id_compania: this.datosclientenuevo.companyaseleccionada.id, pap_year: this.datosclientenuevo.anyo, observaciones: this.datosclientenuevo.observaciones,
                 danos: this.datosclientenuevo.descripcion, nota_importante: this.datosclientenuevo.notaimportante, id_cliente: idcliente,
                 urgente:this.datosclientenuevo.urgente, id_compania_seguro:this.contratoSeguroInput, asitur_compania:this.companiaSeguroSelec};
            try {
                resultado = await api.post('servicios', subidadatos);
                this.borrarDatos();
                localStorage.setItem('nombreservicio' + resultado.id, this.datosclientenuevo.codigo);

                this.$router.push('/servicios/' + resultado.id);
                

            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }

        },
        async cambioContratos(idcomp) {
            console.log("slotprops", idcomp);
            const api = new PwgsApi();
            let aux = await api.get('servicios/' + idcomp + '/formulario-nuevo-servicio');
            this.datosclientenuevo.codigo = aux.codigo_auto;
            this.contratosSeguros = await api.get("companias/"+idcomp+"/contratos-servicios"); 
            console.log('compaseg', this.contratosSeguros);
            var resp = await api.get("companias/"+idcomp+"/companias-seguros");
            console.log('compsegs', resp);
            this.companiasSeguros = resp.datos;
            this.companiaSeguroSelec = '';
        },
        async cargarempresas() {
            //GET /modulos/pwgsapi/index.php/empresas/simple
            const api = new PwgsApi();
            this.empresas = await api.get('empresas/todas');
            for(let empresa of this.empresas) {
                this.datosclientenuevo.empresaseleccionada = empresa;
                break;
            }
        },
        borrarDatos() {
            this.datosclientenuevo = {};
            this.clienteseleccionado = '';

        },
        async crearcliente() {
            //POST  modulos/pwgsapi/index.php/clientes
            const api = new PwgsApi();
            let subidadatos = { nombre: this.datosclientenuevo.nombre, razon_social: this.datosclientenuevo.razon_social, direccion: this.datosclientenuevo.direccion, telefono1: this.datosclientenuevo.telefono1, telefono2: this.datosclientenuevo.telefono2, telefono3: this.datosclientenuevo.telefono3, telefono1_descripcion: this.datosclientenuevo.telefono1_descripcion, telefono2_descripcion: this.datosclientenuevo.telefono2_descripcion, telefono3_descripcion: this.datosclientenuevo.telefono3_descripcion, nif: this.datosclientenuevo.nif, email: this.datosclientenuevo.email, fax: this.datosclientenuevo.fax, numero_cuenta: this.datosclientenuevo.numero_cuenta, contador_subcuenta: this.datosclientenuevo.contador_subcuenta, poliza: this.datosclientenuevo.poliza, persona_contacto: this.datosclientenuevo.persona_contacto, provincia: this.datosclientenuevo.provincia, codigo_postal: this.datosclientenuevo.codigo_postal, poblacion: this.datosclientenuevo.poblacion, numero_oficina: this.datosclientenuevo.numero_oficina, numero_entidad: this.datosclientenuevo.numero_entidad, numero_dc: this.datosclientenuevo.numero_dc, numero_iban1: this.datosclientenuevo.numero_iban1, numero_iban2: this.datosclientenuevo.numero_iban2 };
            
            try {
                let aux = '';
                aux = await api.post('clientes', subidadatos);
                return aux.id;
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

            }
        },
        async cargarcompanyas() {
            //GET  modulos/pwgsapi/index.php/companias
            const api = new PwgsApi();
            let aux = '';
            aux = await api.get('companias/simple');
            this.companyas = aux.datos;
        },
        async cargarclientes() {
            //POST   modulos/pwgsapi/index.php/clientes/busqueda
            this.clientes = '';
            const api = new PwgsApi();
            let subidadatos = { buscador: this.$refs.buscadorclientes.modelValue }
            let aux = '';
            aux = await api.post('clientes/busqueda', subidadatos);
            this.clientes = aux.datos;
            this.$refs.buscadorclientes.show();
        },
        rellenarcliente() {
            // this.cliente = this.clienteseleccionado;
            this.datosclientenuevo.nombre = this.clienteseleccionado.nombre;
            this.datosclientenuevo.razon_social = this.clienteseleccionado.razon_social;
            this.datosclientenuevo.direccion = this.clienteseleccionado.direccion;
            this.datosclientenuevo.telefono1 = this.clienteseleccionado.telefono1;
            this.datosclientenuevo.idcliente = this.clienteseleccionado.id;
            this.datosclientenuevo.telefono2 = this.clienteseleccionado.telefono2;
            this.datosclientenuevo.telefono3 = this.clienteseleccionado.telefono3;
            this.datosclientenuevo.telefono1_descripcion = this.clienteseleccionado.telefono1_descripcion;
            this.datosclientenuevo.telefono2_descripcion = this.clienteseleccionado.telefono2_descripcion;
            this.datosclientenuevo.telefono3_descripcion = this.clienteseleccionado.telefono3_descripcion;
            this.datosclientenuevo.nif = this.clienteseleccionado.nif;
            this.datosclientenuevo.email = this.clienteseleccionado.email;
            this.datosclientenuevo.fax = this.clienteseleccionado.fax;
            this.datosclientenuevo.numero_cuenta = this.clienteseleccionado.numero_cuenta;
            this.datosclientenuevo.contador_subcuenta = this.clienteseleccionado.contador_subcuenta;
            this.datosclientenuevo.poliza = this.clienteseleccionado.poliza;
            this.datosclientenuevo.persona_contacto = this.clienteseleccionado.persona_contacto;
            this.datosclientenuevo.provincia = this.clienteseleccionado.provincia;
            this.datosclientenuevo.codigo_postal = this.clienteseleccionado.codigo_postal;
            this.datosclientenuevo.poblacion = this.clienteseleccionado.poblacion;
            this.datosclientenuevo.numero_oficina = this.clienteseleccionado.numero_oficina;
            this.datosclientenuevo.numero_entidad = this.clienteseleccionado.numero_entidad;
            this.datosclientenuevo.numero_dc = this.clienteseleccionado.numero_dc;
            this.datosclientenuevo.numero_iban1 = this.clienteseleccionado.numero_iban1;
            this.datosclientenuevo.numero_iban2 = this.clienteseleccionado.numero_iban2;
            this.$refs.buscadorclientes.hide();
        },

    },
    watch: {
        contratoSeguroSeleciconada(valor){
            this.contratoSeguroInput = valor;
        },
        clienteseleccionado() {
            if (this.clienteseleccionado) {
                this.deshabilitado = true;
                this.rellenarcliente();
            }
            else {
                this.deshabilitado = false;
                this.cliente = '';
            }
        }
    },
    mounted() {

        this.cargarempresas();
        this.cargarcompanyas();
        
        

    },
}
</script>
