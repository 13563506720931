<template>
    <div class="row">
        <div class="col-md-6">
        <seccion-datos :titulo="tiposdeservicio" :botonGuardar="false" class="card">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="input-group">
                                <select class="form-control" v-model="Seleccionado">
                                    <option value="">Seleccione tipo</option>
                                    <option v-for="servicio in servicios" :value="servicio.id" :key="servicio.id"> {{servicio.nombre}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-primary" @click="agregarServicio(Seleccionado)">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-sm table-striped">
                                <tbody v-if="serviciosop.length > 1">
                                    <tr v-for="sv in serviciosop" :value="sv.id" :key="sv.id_operario_tipo_servicio" >
                                        <td >{{sv.nombre}}</td>
                                        <td align="right">
                                            <div class="col-md-6">
                                                <button style="width:100px;height:100%" type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarServicio(sv.tipos_id)">{{ $t('general.eliminar') }}</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td v-if="serviciosop[0]">{{serviciosop[0].nombre}}</td>
                                        <td v-if="serviciosop[0]" align="right"><div class="col-md-4">
                                            <button type="button" class="btn btn-block btn-outline-danger btn-xs"
                                                @click="eliminarServicio(serviciosop[0].tipos_id)">{{ $t('general.eliminar') }}</button>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
    </seccion-datos>
        </div>
        </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario'],
    data() {
        return {
            servicios: [],
            Seleccionado: '',
            serviciosop: '',
            tiposdeservicio: "Tipos de servicio",
        }
    },
    watch: {
        id() {
            this.cargardatos();
        },
    },
    methods: {
        async cargardatos() {
            /* get  modulos/pwgsapi/index.php/tipos-servicio */
            const api = new PwgsApi;
            const datos = await api.get('tipos-servicio');
            this.servicios = datos.datos;
            this.tiposdeservicio = this.$t("general.tiposdeservicio");
            const datoss = await api.get('operarios/' + this.id + '/tipos-servicio');
            this.serviciosop = datoss.datos;
            console.log('dtstipos', this.servicios, this.serviciosop);
            this.serviciosop.forEach(element => {
                this.servicios = this.servicios.filter(documento => documento.id != element.id);
            });
        },
        async eliminarServicio(id_tipo_servicio) {
            /* delete  modulos/pwgsapi/index.php/operarios/:id/tipos-servicio/:id_tipo_servicio */
            const api = new PwgsApi;
            await api.delete('operarios/' + this.operario.id + '/tipos-servicio/' + id_tipo_servicio);
            this.cargardatos();
        },
        async agregarServicio(idservicio) {
            /* post  modulos/pwgsapi/index.php/operarios/:id/tipos-servicio */
            let repetido = 0;
            for (let serv of this.serviciosop) {
                if (idservicio == serv.tipos_id) {
                    repetido = repetido + 1;
                }
            }
            if (repetido < 1) {
                const api = new PwgsApi;
                const subidadatos = { id_tipo_servicio: this.Seleccionado };
                await api.post('operarios/' + this.operario.id + '/tipos-servicio', subidadatos);
            }
            this.cargardatos();
            this.Seleccionado = '';
        },
    },
    mounted() {
       
        this.cargardatos();
    },
}
</script>
