<template>
    <div class="card">
        <div class="card-header bg-default">
            <h3 class="card-title"><i class="far fa-list-alt mr-2"></i>Generados</h3>  
             <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs" @click="generarPresupuesto('Cliente'); generarPresupuesto('Compañia')">Generar Presupuesto</button>
            </div>   
        </div>
       
        <div class="card-body" v-if="presupuestosFiltrados.length > 0">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Número</th>
                        <th>Emisor</th>
                        <th>Cliente</th>
                        <th>Importe</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody v-for="presupuesto in presupuestosFiltrados" :key="presupuesto.presupuesto.id_factura">
                    <tr class="presu" data-widget="expandable-table" :aria-expanded="presupuesto.isNuevo?'true':'false'" :class="{
                            'table-success': presupuesto.presupuesto.presupuesto_activo_factura == 1,
                            'table-danger': presupuesto.presupuesto.presupuesto_activo_factura == 2,
                            'table-danger': presupuesto.presupuesto.estado == 2,
                            'table': presupuesto.presupuesto.presupuesto_activo_factura == 0
                        }">
                        <td>{{presupuesto.presupuesto.fecha_hoy }}</td>
                        <td>PRE-{{presupuesto.numero }}</td>
                        <td>{{presupuesto.presupuesto.nombre_distribuidor}}</td>
                        <td>{{presupuesto.presupuesto.nombre_cliente }}</td>
                        <td>{{presupuesto.presupuesto.total }} &euro;</td>
                        <td>
                            <!-- Condición para mostrar el estado apropiado -->
                            <span v-if="presupuesto.presupuesto.presupuesto_activo_factura == 1" class="badge bg-success">Aprobado</span>
                            <span v-if="presupuesto.presupuesto.estado == 2" class="badge bg-danger">Anulado</span>
                        </td>
                    </tr>
                       
                    <tr class="expandable-body" :class="presupuesto.isNuevo?'':'d-none'">
                        <td colspan="6" class="bg-light">
                            <servicio-presupuesto-generados-detalle :presupuesto="presupuesto" :id="id" :idfactura="presupuesto.presupuesto.id_factura" :correo="presupuesto.correos.Operarios"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-body" v-else>
            <p class="text-center">No hay presupuestos generados.</p>
        </div>
    </div> 
</template>
<script>
import { PwgsApi } from '../../../../../../services/PwgsApi';
import serviciopresupuestogeneradosdetalle from './presupuesto_generados_detalle.vue';

export default ({
        props: ['id', 'seleccionado'],

    data() {
        return {
            presupuestosgenerados: [],
            datospresupuestos: [],
        }
    },
    setup() {
        return;
    },
    components: {
        'servicio-presupuesto-generados-detalle': serviciopresupuestogeneradosdetalle,
    },computed: {
        // Filtra los presupuestos basados en el id_presupuesto seleccionado
        presupuestosFiltrados() {
            if(this.presupuestosgenerados.length == 0){
                return [];
            }
            console.log('presupuestosgenerados2', this.presupuestosgenerados);
            return this.presupuestosgenerados.filter(presupuesto =>
                presupuesto.presupuesto.presupuestos_id_presupuesto === this.$props.seleccionado.presupuesto.id_presupuesto
            );
        }
    },
    methods: {
        async cargar_presupuestos_generados(nuevo) {
            //GET  modulos/pwgsapi/index.php/presupuestos/:id_servicio/presupuestos-generados
            const api = new PwgsApi;
            this.datospresupuestos = await api.get('presupuestos/' + this.$props.id + '/presupuestos-generados');
            this.presupuestosgenerados = this.datospresupuestos;
            if(nuevo){
                if(this.presupuestosgenerados[this.presupuestosgenerados.length - 3] && this.presupuestosgenerados[this.presupuestosgenerados.length - 3].isNuevo){
                    this.presupuestosgenerados[this.presupuestosgenerados.length - 3].isNuevo = false;
                    this.presupuestosgenerados[this.presupuestosgenerados.length - 4].isNuevo = false;
                }
                this.presupuestosgenerados[this.presupuestosgenerados.length - 1].isNuevo = true;
                this.presupuestosgenerados[this.presupuestosgenerados.length - 2].isNuevo = true;
                console.log('presupuestosgenerados', this.presupuestosgenerados);
            }
        },
        async generarPresupuesto(destinatario) {
            const api = new PwgsApi;
            try {
                const resultado = await api.post('presupuestos/' + this.$props.seleccionado.presupuesto.id_presupuesto + '/generar-presupuesto', {
                    destinatario: destinatario
                });

                console.log('Presupuesto generado:', resultado);
                this.cargar_presupuestos_generados('nuevo');
                this.$emit('actualizar');
            } catch (error) {
                console.error('Error al generar el presupuesto:', error);
            }
            let aux = document.getElementsByClassName('presu');
            aux[aux.length - 1].click();

            console.log("aux", aux[aux.length - 1]);
        },
    },
    mounted() {
        this.cargar_presupuestos_generados();
    },
    watch: {
        id() {
            this.cargar_presupuestos_generados();
        },
     
    }

})
</script>
