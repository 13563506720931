<template>
    <div class="card">
        <div class="card-header bg-gray" style="display: flex; justify-content: start; height: 63px;">
            <h3 class="card-title"><i class="fab fa-telegram-plane mr-2"></i>{{ $t('general.enviodemensajes') }}</h3>
        </div>
        <div class="card-body">
            <div class="row" v-if="gremios">
                <div class="col-md-12">
                    <table class="table table-striped table-valign-middle">
                        <thead>
                            <tr>
                                <th v-for="columna in gremios.gremios.encabezado" :key="columna">{{ columna }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="filas in gremios.gremios.filas" :key="filas">
                                <td v-for="fila in filas" :key="fila">
                                    <div v-if="fila.campos">
                                        <div v-for="campo in fila.campos" :key="campo">
                                            <label class="btn active" v-if="campo.tipo == 'radio'">
                                                <input class="defecto" v-if="campo.valor == fila.campos[0].valor"
                                                    type="radio"
                                                    @click="tipoComunicacion(campo.valor); guardartoken(campo.nombre, campo.valor); auxtokengremio = campo.valor; selecciondefecto()"
                                                    name="opciones" :id="campo" autocomplete="off" />
                                                <input v-else type="radio"
                                                    @click="tipoComunicacion(campo.valor); guardartoken(campo.nombre, campo.valor); auxtokengremio = campo.valor; selecciondefecto()"
                                                    name="opciones" :id="campo" autocomplete="off" />
                                                {{ campo.desc }}
                                            </label>
                                            <label class="btn active" v-else-if="campo.desc && campo.tipo == 'button'">
                                                <button type="button" v-if="campo.desc == 'Actualizar'"
                                                    @click="actualizar(filas[0].columna1.campos[0].desc);"
                                                    class="btn btn-secondary">
                                                    <i class="fas fa-sync"> {{ campo.desc }}</i>
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                    <div v-if="!fila.campos">
                                        <p v-html="fila"></p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="col-md-6">
                            <label for="exampleInputEmail1">{{ $t('general.tipodeenvio') }}</label><br>
                            <select class="custom-select form-control-sm exampleInputEmail1 select2"
                                v-model="selected_tipo_envio">

                                <option :disabled="mensajes.disabled == 1"
                                    :selected="comunicaciones[0].nombre == mensajes.nombre"
                                    v-for="mensajes in comunicaciones" :key="mensajes" :value="mensajes">
                                    {{ mensajes.nombre }}
                                </option>
                            </select>
                            <div v-if="selected_tipo_envio && selected_tipo_envio.title">
                                <p>{{ selected_tipo_envio.title }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" v-if="selected_tipo_envio">
                    <div v-if="selected_tipo_envio.tipo_mensaje == 'responder_dialogo'">
                        <div class="col-md-12">
                            <table class="table table-striped table-valign-middle">
                                <thead>
                                    <tr>
                                        <th v-for="columna in gremios.comunicaciones.encabezado" :key="columna">{{
                                            columna }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="filas in gremios.comunicaciones.filas" :key="filas">
                                        <td v-for="fila in filas" :key="fila">
                                            <div v-if="fila.campos">
                                                <div v-for="campo in fila.campos" :key="campo">
                                                    <label class="btn active" v-if="campo.tipo == 'radio'">
                                                        <input class="defecto" @click="comprobarconsulta(auxtokengremio, campo.nombre, campo.valor); resp_dialogo = campo.valor"
                                                            v-if="campo.valor == fila.campos[0].valor" type="radio"
                                                            name="opciones" :id="campo" autocomplete="off" />
                                                        <input v-else type="radio" @click="comprobarconsulta(auxtokengremio, campo.nombre, campo.valor);resp_dialogo = campo.valor"
                                                            name="opciones" :id="campo" autocomplete="off" />
                                                        {{ campo.desc }}
                                                    </label>
                                                    <label class="btn active"
                                                        v-else-if="campo.desc && campo.tipo == 'button'">

                                                    </label>
                                                </div>
                                            </div>
                                            <div v-if="!fila.campos">
                                                <p v-html="fila"></p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="form-group" v-for="campo in selected_tipo_envio.campos" :key="campo.desc">
                        <!--v-if="!campo[1]" -->
                        <div class="col-md-6">
                            <!--<label v-if="campo.tipo != 'checkbox'" :for="campo.nombre">{{campo.desc}}</label><br>-->
                            <label v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && campo.desc"
                                :for="campo.nombre">{{ campo.desc }}</label><br
                                v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && campo.desc"><!--333-->
                            <label v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && !campo.desc"
                                :for="campo.nombre">{{ campo.nombre }}</label><br
                                v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && !campo.desc"><!--444-->
                            <select v-if="campo.tipo == 'select'" :class="'custom-select form-control ' + campo.nombre"
                                v-model="campo.valor">
                                <option value="" disabled selected hidden>Seleccione opción</option>
                                <option :disabled="campo.disabled == 'disabled'" v-for="opcion in campo.valores"
                                    @click="comprobarconsulta(auxtokengremio, campo.nombre, campo.valor)"
                                    :key="opcion.valor" :value="opcion.valor">{{ opcion.texto }}</option>
                            </select>
                            <div v-if="campo.tipo == 'radio'"></div>
                            <div v-if="campo.tipo == 'checkbox'">
                                <label :for="campo.desc">{{ campo.desc }}&nbsp;&nbsp;</label>
                                <input :disabled="mensajes && mensajes.disabled == 'disabled'"
                                    @click="comprobarconsulta(auxtokengremio, campo.nombre, campo.valor, 'tipoinput')"
                                    type="checkbox" :id="campo.desc" :class="'custom-checkbox ' + campo.nombre"
                                    v-model="campo.checked" v-bind:true-value="1" v-bind:false-value="0" />
                            </div>
                            <input :disabled="campo.disabled == 'disabled'" v-if="campo.tipo == 'text'" type="text"
                                v-model="campo.valor" :class="'form-control ' + campo.nombre" />

                        </div>
                        <Editor editorStyle="height: 160px" v-model="campo.valor"
                            v-if="campo.tipo == 'textarea' && campo.editor_html=='1'">
                        </Editor>
                        <textarea @click="console.log(campo)" :disabled="campo.disabled == 'disabled'"
                            v-if="campo.tipo == 'textarea' && campo.editor_html!='1'" v-model="campo.valor"
                            :class="'form-control ' + campo.nombre"></textarea>
                        <div v-if="Object.keys(campo) == 'documentos' && campo.documentos[1]">
                            <!-- <label :for="campo.documentos[1].nombre">{{ campo.documentos[1].desc }}</label><br>
                              <select v-if="Array.isArray(campo.documentos[0].valores)" :class="'custom-select form-control ' + campo.documentos[1].nombre" v-model="campo.documentos[0].valor">
                                    <option v-for="opcion in campo.documentos[0].valores" :key="opcion.valor" :value="opcion.valor">{{ opcion.texto }}</option>
                                </select><br><p></p>
                                <select v-if="!Array.isArray(campo.documentos[0].valores)" :class="'custom-select form-control ' + campo.documentos[1].nombre" v-model="campo.documentos[0].valor">
                                    <option :value="campo.documentos[0].valores.valor">{{ campo.documentos[0].valores.texto }}</option>
                                </select><br><p></p>-->
                            <FileUpload mode="basic" multiple="true" accept="image/*" id="asd"
                                chooseLabel="Subir Archivo" :maxFileSize="50000000"
                                @select="nombrefichero($event); campo.documentos[1].valor = nombrearchivo" />
                            <Button @click="cargardocumentos()" type="button">{{ $t('general.documentos') }}</button>
                            <br><!--111-->
                            <div class="col-md-6">{{ campo.documentos[1].valor }}<Button
                                    v-if="campo.documentos[1].valor" @click="campo.documentos[1].valor = ''"
                                    class="p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                    type="button" aria-label="Cancel" data-pc-name="button"
                                    data-pc-severity="danger"><span class="p-button-icon pi pi-times"
                                        data-pc-section="icon"></span></button>
                            </div>
                            <Dialog v-show="mostrardialogo" header="Documentos" v-model:visible="mostrardialogo" modal
                                :style="{ width: '50vw' }">
                                <div class="col-md-12" v-for="documento in documentos" :key="documento.id_documento">
                                    <Image :src="'https://' + urlglobal + '/' + documento.url_descarga" width="250"
                                        preview />
                                    &nbsp;
                                    <select @change="asociardocumento(documento)"
                                        v-if="Array.isArray(campo.documentos[0].valores)"
                                        :class="'custom-select form-control ' + campo.documentos[1].nombre"
                                        v-model="documento.valor">
                                        <option value="" disabled selected hidden>Seleccione opción</option>
                                        <option v-for="opcion in campo.documentos[0].valores" :key="opcion.valor"
                                            :value="opcion.valor">{{ opcion.texto }}</option>
                                    </select>
                                    &nbsp;
                                    <select @change="asociardocumento(documento)"
                                        v-if="!Array.isArray(campo.documentos[0].valores)" v-model="documento.valor"
                                        :class="'custom-select form-control ' + campo.documentos[1].nombre">
                                        <option value="" disabled selected hidden>Seleccione opción</option>
                                        <option :value="campo.documentos[0].valores.valor">
                                            {{ campo.documentos[0].valores.texto }}</option>
                                    </select>
                                    <div>&nbsp;</div>
                                </div>
                            </Dialog>
                        </div>
                        <div v-if="Object.keys(campo) == 'documentos' && !campo.documentos[1]">
                            <Button @click="cargardocumentos()" type="button">{{ $t('general.documentos')
                                }}</button>
                            <!--<FileUpload mode="basic" multiple="true" accept="image/*" id="asd" chooseLabel="Subir Archivo" :maxFileSize="50000000" @select="nombrefichero($event); campo.documentos[0].valor = nombrearchivo" />-->
                            <br>
                            <div class="col-md-6">{{ campo.documentos[0].valor }}<Button
                                    v-if="campo.documentos[0].valor" @click="campo.documentos[0].valor = ''"
                                    class="p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                    type="button" aria-label="Cancel" data-pc-name="button"
                                    data-pc-severity="danger"><span class="p-button-icon pi pi-times"
                                        data-pc-section="icon"></span></button>
                            </div>
                            <Dialog v-show="mostrardialogo" v-model:visible="mostrardialogo" modal
                                :style="{ width: '50vw' }">
                                <div class="col-md-12" v-for="documento in documentos" :key="documento.id_documento">
                                    <Image :src="'https://' + urlglobal + '/' + documento.url_descarga" width="250"
                                        preview />
                                </div>
                            </Dialog>
                        </div>
                        <!--archivoarrellenar-->
                        <div v-if="campo[0]">
                            <div v-if="campo[0].tipo == 'radio'">
                                <input type="radio" v-model="campo[0].valor" :id="campo[0].desc"
                                    autocomplete="off" />&nbsp;&nbsp;{{ campo[0].desc }}
                            </div>
                        </div>
                    </div>
                    <ul style="list-style-type: none;">
                        <li v-for="documento in documentosanyadidos" :key="documento.id_documento">
                            <p><button @click="eliminardocumento(documento)"
                                    class="p-button p-component p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                    type="button" aria-label="Cancel" data-pc-name="button" data-pc-severity="danger"
                                    data-pc-section="root"><span class="p-button-icon pi pi-times"
                                        data-pc-section="icon"></span></button>{{ documento.documento }}&nbsp;({{
                                documento.valor }})</p>
                        </li>
                    </ul>
                    <div class="col-md-12">
                        <button class="btn btn-success btn-block" @click="enviarMensaje()" type="button">{{
                            $t('general.enviar') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Editor from 'primevue/editor';
import { PwgsApi } from '../../../../services/PwgsApi';
//import FileUpload from 'primevue/fileupload';
import Dialog from 'primevue/dialog';
import Image from 'primevue/image';
import Button from 'primevue/button';
export default ({
    props: ['id'],
    components: {
        Dialog, Image, Button, Editor,
    },
    setup() {
        return;
    },
    data() {
        return {
            selected_tipo_envio: '',
            tipo_comunicacion: '',
            comunicaciones: [],
            nombrearchivo: [],
            gremios: '',
            columna1: "columna1",
            token: '',
            archivos: [],
            documentos: [],
            mostrardialogo: false,
            archivoasubir: '',
            urlglobal: '',
            tipocom: '',
            auxenvios: '',
            auxtokengremio: '',
            campospendientesconsulta: [],
            documentosanyadidos: [],
            nombrecampo: '',
            valorcampo: '',
            resp_dialogo:'',
        }
    },
    methods: {
        async enviarMensaje() {
            //POST  modulos/pwgsapi/index.php/servicios/:id/comunicacion-sistema
            const api = new PwgsApi();
            var subidadatos = {};
            //var aux = {};
            let documentos = "documentos";
            let tipodemensaje = "tipo_mensaje";
            let campos = "campos";
            let dialogo_generali = "dialogo_generali";
            let auxdatos = {};
            for (let i = 0; i < this.selected_tipo_envio.campos.length; i++) {
                if (Object.keys(this.selected_tipo_envio.campos[i]) == 'documentos') {
                    /* if (this.selected_tipo_envio.campos[i].documentos.length>1) {
                         aux.nombre_documento = this.selected_tipo_envio.campos[i].documentos[1].valor;
                         aux.tipo_archivo = this.selected_tipo_envio.campos[i].documentos[0].valor;
                     }
                     else{ 
                         aux.nombre_documento = this.selected_tipo_envio.campos[i].documentos[0].valor;
                         }*/

                    subidadatos[documentos] = [];
                    // subidadatos[documentos].push(aux);
                    subidadatos[documentos] = this.documentosanyadidos;0
                }
                else {
                    if (this.selected_tipo_envio.campos[i].valor) {
                        subidadatos[this.selected_tipo_envio.campos[i].nombre] = this.selected_tipo_envio.campos[i].valor;
                    }
                    else {
                        if (this.selected_tipo_envio.campos[i][0].tipo != "hidden") {
                            subidadatos[this.selected_tipo_envio.campos[i][0].nombre] = this.selected_tipo_envio.campos[i][0].valor;
                        }
                    }
                }
            }
            auxdatos[tipodemensaje] = this.selected_tipo_envio.tipo_mensaje;
            auxdatos[campos] = subidadatos;
            if (this.selected_tipo_envio.tipo_mensaje == "responder_dialogo"){
                auxdatos[dialogo_generali] = this.resp_dialogo;
            }
            if (this.nombrecampo) {
                auxdatos[this.nombrecampo] = this.valorcampo; /*incidencia 141 comunicaciones*/
            }
            try {
                await api.post('servicios/' + this.$props.id + '/comunicacion-sistema', auxdatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Mensaje enviado correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            
           
            this.selected_tipo_envio = '';
            this.$parent.cargarmensajes();
        },
        guardartoken(nombre, valor) {
            this.nombrecampo = nombre;
            this.valorcampo = valor;
        },
        async actualizar(idipas) {
            //index.php/servicios/:id/prestacion-ipas/:n_prestacion_ipas
            const api = new PwgsApi();
            if (confirm('¿Desea actualizar la prestación ' + idipas + '?')) {
                try {
                    await api.put('servicios/' + this.$props.id + '/prestacion-ipas/' + idipas);
                }
                catch (e) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });

                }
                this.tipoComunicacion();
                this.cargarGremios();
            }
        },
        /*    nombrefichero(event) {
                // POST  modulos/pwgsapi/index.php/servicios/:id/guardar-documento
                this.getBase64(event.files[0],this.$props.id);
                console.log("archivoasubir", this.archivoasubir);
                this.nombrearchivo = event.files[0].name;
                let aux = { nombre: event.files[0].name, url: event.files[0].objectUrl, tipo: event.files[0].type };
                this.archivos.push(aux);
                console.log("evento", event);
            },*/
        async cargardocumentos() {
            // GET:  modulos/pwgsapi/index.php/servicios/:id/documentos
            const api = new PwgsApi();
            try {
                this.documentos = await api.get('servicios/' + this.$props.id + '/documentos');
            }
            catch (e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });

            }
            this.mostrardialogo = true;
        },
        asociardocumento(archivo) {
            console.log("archivo", archivo);
            this.documentosanyadidos.push(archivo);
        },
        test() {
            setTimeout(() => {
                document.getElementsByClassName("defecto")[0].click();
            }, 1000);

        },
        /* async getBase64(file,id) {
                  const api = new PwgsApi();
                  var reader = new FileReader();
                  reader.readAsBinaryString(file);
                  reader.onload = async function () {
                      console.log("resultado",reader.result);
                      let subidadatos = { tipo_documento: 'ficherosvisitas', nombre: file.name, binario: reader.result };
                      await api.post('servicios/' + id + '/guardar-documento', subidadatos);
                  };
                  reader.onerror = function (error) {
                  this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
                  };
          },*/
        comprobarconsulta(tokengremio, tipomensaje, valormensaje, tipoinput) {
            console.log("tipomensaje", tipomensaje);
            console.log("tipoinput", tipoinput);
            console.log("valormensaje", valormensaje);
            if ((tipoinput == "tipoinput" && tipoinput == 1) || !tipoinput) {
                let auxmostrar = false;
                console.log("test123", this.campospendientesconsulta[tipomensaje]);
                if (this.campospendientesconsulta[tipomensaje] == true) {
                    auxmostrar = true;
                }
                console.log("averaver", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje]);
                if (this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar) {
                    for (let i = 0; i < this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar.length; i++) {
                        if (this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar[i] == tipomensaje);
                        auxmostrar = true;
                    }
                }
                if (auxmostrar == true) {
                    console.log("succcessss");
                    this.campospendientesconsulta[tipomensaje] = true;
                    this.tipoComunicacion(tokengremio, tipomensaje, valormensaje)
                }
            }
        },
        eliminardocumento(doc) {
            this.documentosanyadidos.splice(this.documentosanyadidos.indexOf(doc), 1);
        },
        async tipoComunicacion(tokengremio, tipomensaje, valormensaje) {
            this.comunicaciones = [];
            //post  modulos/pwgsapi/index.php/servicios/:id/comunicaciones-sistema
            this.urlglobal = localStorage.getItem('direccion');
            const api = new PwgsApi();
            this.tipocom = tokengremio;
            //this.comunicaciones = [];
            //this.selected_tipo_envio.campos = this.auxenvios;
            if (tokengremio) {

                let subidadatos = { id_gremio: tokengremio }
                if (tipomensaje && !valormensaje) {
                    subidadatos.tipo_mensaje = tipomensaje
                }
                if (tipomensaje && valormensaje) {
                    subidadatos.tipo_mensaje = this.selected_tipo_envio.tipo_mensaje;
                    console.log("tipomensaje", tipomensaje);
                    subidadatos[tipomensaje] = valormensaje;
                    console.log("subidadatos", subidadatos);
                    console.log("test666", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar);
                }
                try{

                    this.tipo_comunicacion = await api.post('servicios/' + this.$props.id + '/comunicaciones-sistema', subidadatos);
                }
                catch (e) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });

                }
                console.log("tipocom", this.tipo_comunicacion);
                console.log("valormensaje", valormensaje);
                if (tipomensaje && !valormensaje) {
                    console.log("selectenvio", this.selected_tipo_envio);
                    console.log("asdf123", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje]);
                    this.selected_tipo_envio.campos = this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos;
                }
            }
            else {

                this.tipo_comunicacion = await api.post('servicios/' + this.$props.id + '/comunicaciones-sistema');
            }
          
            for (const [tipo, nombre] of Object.entries(this.tipo_comunicacion.tipos_mensajes)) {
                let datos = { tipo_mensaje: tipo, nombre: nombre.nombre, campos: nombre.campos, title: nombre.title, disabled: nombre.disabled };
                console.log("tipopau", tipo);
                console.log("nombrepau", nombre);
                this.comunicaciones.push(datos);
            }
         /*   let aux = this.comunicaciones;
            for (let i = 0; i < this.comunicaciones.length; i++){
                let repetido = 0;
                for (let j = 0; j < aux.length; j++){
                    if (this.comunicaciones[i].nombre == aux[j].nombre) {
                        repetido++;
                        this.comunicaciones[i].repetido == repetido;
                    }
                }
            }*/
            console.log("comunicapau", this.comunicaciones);
          
            if (valormensaje) {
                for (let i = 0; i < this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos.length; i++) {
                    let repetidoaux = false;
                    console.log("test1", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i]);

                    for (let j = 0; j < this.selected_tipo_envio.campos.length; j++) {
                        if (this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i].nombre == this.selected_tipo_envio.campos[j].nombre) {
                            repetidoaux = true;
                            console.log("repetido");
                        }
                        console.log("test2", this.selected_tipo_envio.campos[j]);

                    }
                    if (repetidoaux == false) {
                        this.selected_tipo_envio.campos.push(this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i])
                    }
                }
            }
            console.log("comunicaciones333", this.comunicaciones);
            
        },
        async cargarGremios() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/comunicaciones-gremios
            this.gremios = '';
            const api = new PwgsApi();
            try {
                this.gremios = await api.get('servicios/' + this.$props.id + '/comunicaciones-gremios');
            }
            catch (e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });

            }

        },
        modificarselected() {
          //  console.log("tipo_envio", this.selected_tipo_envio.tipo_mensaje);
            if (this.selected_tipo_envio)  this.tipoComunicacion(this.tipocom, this.selected_tipo_envio.tipo_mensaje);
        },
        obtenerdisabled(valor) {
            if (valor) {
                return true;
            }
            else return false;
        },
        selecciondefecto() {
            setTimeout(() => {
                console.log("comunicaciones", this.comunicaciones);
                let token = false;
                for (let i = 0; i < this.comunicaciones.length; i++) {
                    if (this.comunicaciones[i].tipo_mensaje == "envioIPAS") {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;
                    }
                    else if (this.comunicaciones[i].tipo_mensaje == "INFO") {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;

                    }
                    else if (this.comunicaciones[i].tipo_mensaje == "INFOGENERALENVIAR") {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;

                    }

                }
                if (token == false) {
                    this.selected_tipo_envio = this.comunicaciones[0];

                }
            }, 2000);
        }
    },

    mounted() {
        this.tipoComunicacion();
        this.cargarGremios();
    },
    watch: {
        id() {
            this.comunicaciones = [];
            this.documentosanyadidos = [];
            this.selected_tipo_envio = '';
            this.tipoComunicacion();
            this.cargarGremios();

        },
        comunicaciones() {

        },
        gremios() {
            setTimeout(() => {
                if (this.gremios && document.getElementsByClassName("defecto")[0]) {
                    this.test();
                }
            }, 1000);


        },
        selected_tipo_envio() {
            console.log("selected123", this.selected_tipo_envio);//selected_tipo_envio.tipo_mensaje == "responder_dialogo"
            this.modificarselected();
        }

    },
    computed: {

    }
})
</script>