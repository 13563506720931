<template>
    <div class="container-fluid">
        <section class="content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card" id="card_lista_pedido">
                        <div class="card-header bg-gray">
                            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.listapedidos') }}</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                    <i class="fas fa-expand"></i>
                                </button>
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body" id="card_body_lista_pedido">
                            <Button type="button" @click="nuevopedido()">
                                Nuevo pedido
                            </Button>
                            <p></p>
                            <tabla-datos :columnas="columnas" :datos="pedidos" botones="true" :filtros="filtros"
                                :filtrosBuscador="filtrosBuscador" :total="total" @quieroDatos="obtenerPedidos">
                            </tabla-datos>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card" id="card_pedido" style="display: none">
                        <div class="card-header bg-gray">
                            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.pedido') }}</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                    <i class="fas fa-expand"></i>
                                </button>
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <component v-bind:is="componentpedido" :id_pedido="id_pedido" :id="id"
                                :key="indice_pedido" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="mostrarnuevo==true">
                <div class="col-md-12">
                    <div class="card" id="card_nuevo_pedido">
                        <div class="card-header bg-gray">
                            <h3 class="card-title"><i class="fas fa-list mr-2"></i>Nuevo{{ $t('general.pedido') }}</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                    <i class="fas fa-expand"></i>
                                </button>
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <nuevopedido :id="id" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="mostrarnuevosincompras == true">
                <div class="col-md-12">
                    <div class="card" id="card_nuevo_pedido">
                        <div class="card-header bg-gray">
                            <h3 class="card-title"><i class="fas fa-list mr-2"></i>Nuevo{{ $t('general.pedido') }}</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="maximize">
                                    <i class="fas fa-expand"></i>
                                </button>
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <label for="exampleInputEmail1">Proveedor</label>
                                    <select id="exampleInputEmail1" v-model="proveedorseleccionado"
                                        class="form-control">
                                        <option v-for="proveedor in proveedores" :key="proveedor.id_proveedor"
                                            :value="proveedor.id_proveedor">{{proveedor.nombre_proveedor}}</option>
                                    </select>
                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-3">
                                    <label>Material</label>
                                    <textarea v-model="material" class="form-control"></textarea>
                                </div>
                                <div class="col-md-1"></div>

                                <div class="col-md-3">
                                    <label>Fecha limite</label><br>
                                    <Calendar v-model="fechalimite" dateFormat="dd/mm/yy" />
                                </div>
                                <div class="col-md-1"><br>
                                    <button @click="anyadirpedido()" class="btn btn-primary">Añadir</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue';
import pedidoDatos from './Pedido.vue';
import nuevopedido from './NuevoPedido.vue';
import Calendar from 'primevue/calendar';

import $ from 'jquery';
export default {
    components: {
        "tabla-datos": TablaDatos,
        pedidoDatos,
        "nuevopedido": nuevopedido,
        Calendar
    },
    props: ['id', 'servicio'],
    data() {
        return {
            parametrosgenerales: { compras: '', gestion_proveedores: '' },
            indice_pedido: 0,
            id_pedido: '',
            componentpedido :'pedidoDatos',
            total: '',
            mostrarnuevo: false,
            mostrarnuevosincompras:false,
            pedidos: [],
            direccionglobal: '',
            material:'',
            url: [],
            proveedorseleccionado: '',
            fechalimite: new Date(),
            proveedores:'',
            parametros: null, //query params de DataTable
            filtrosBuscador: ['texto'], //filtros para el buscador
            filtros: { //filtros de columna
                'texto': { value: '', matchMode: 'contains' }
            },
            columnas: [
                {
                    header: 'Nº pedido',
                    field: 'id_pedido',
                    sortable: true,
                },{
                    header: 'Fecha',
                    field: 'fecha_pedido',
                    sortable: true,
                },{
                    header: 'Fecha est. entrega',
                    field: 'fecha_limite_pedido',
                    sortable: true,
                },{
                    header: 'Estado',
                    field: 'estado_pedido2',
                    sortable: true,
                },{
                    header: 'Total',
                    field: 'total',
                    sortable: true,
                },{
                    header: 'Comunicado',
                    field: 'comunicado',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_pedido',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id_pedido) => {    
                                this.editar(id_pedido); 
                            }
                        },{
                            nombre: 'PDF',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_pedido',
                            icono: 'far fa-file-pdf',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id_pedido) => {
                                this.descargarpdf(id_pedido);
                            }
                        },{
                            nombre: 'Excel',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_pedido',
                            icono: 'far fa-file-excel',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id_pedido) => {
                                this.descargarexcel(id_pedido);
                            }
                        },{
                            nombre: 'Comunicar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_pedido',
                            icono: 'fab fa-telegram-plane',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id_pedido) => {
                                this.$router.push({
                                    name: 'Mensaje Predefinido',
                                    params: {
                                        id_pedido
                                    }
                                });
                            }
                        },{ 
                            nombre: 'Expediente',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_pedido',
                            icono: 'fas fa-clipboard-list',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id_pedido) => {
                                this.$router.push({
                                    name: 'Mensaje Predefinido',
                                    params: {
                                        id_pedido
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id_pedido',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id_pedido) => {
                                console.log('Eliminar el pedido ' + id_pedido);
                                if (confirm('Eliminar el pedido')) {
                                    this.eliminar(id_pedido);
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    methods: {
        async obtenerPedidos() {
            const api = new PwgsApi;
            let auxs = await api.get('parametros-pwgs/gestion_proveedores');
            this.parametrosgenerales.gestion_proveedores = auxs.gestion_proveedores;
            let auxdos = await api.get('parametros-pwgs/compras');
            this.parametrosgenerales.compras = auxdos.compras;
            if(this.parametrosgenerales.compras != 1 && this.parametrosgenerales.gestion_proveedores != 1){
                this.permitirpedidos = false;
            }
            const aux = await api.get('servicios/' + this.$props.id + '/pedidos');
            this.pedidos = aux.datos;
            this.total = aux.n_total_registros;
            for (let i = 0; i < this.pedidos.length; i++){
                this.pedidos[i].fecha_limite_pedido = new Date(this.pedidos[i].fecha_limite_pedido).toLocaleDateString('es-ES');
                this.pedidos[i].fecha_pedido = new Date(this.pedidos[i].fecha_pedido).toLocaleDateString('es-ES');
            }
            if (this.parametrosgenerales.gestion_proveedores == 1 && this.parametrosgenerales.compras != 1) {

                this.columnas = [];
                this.columnas = [
                        {
                        header: 'Nº pedido',
                        field: 'id_pedido',
                    },
                    {
                        header: 'Proveedor',
                        field: 'nombre_proveedor',
                    }, {
                        header: 'Fecha',
                        field: 'fecha_pedido',
                    }, {
                        header: 'Fecha est. entrega',
                        field: 'fecha_limite_pedido',
                    },
                    {
                        header: 'Material',
                        field: 'material_pedido',
                    },
                    {
                        header: 'Estado Pedido',
                        field: 'estado_pedido',                        
                    },
                    {
                        header: 'Acciones',
                        data: null,
                        acciones: [               
                            {
                                nombre: 'Eliminar',
                                class: 'eliminar',
                                tipo: 'danger',
                                nombreId: 'id_pedido',
                                icono: 'far fa-trash-alt',
                                // Debe ser arrow function para que this sea este componente
                                accion: (id_pedido) => {
                                    console.log('Eliminar el pedido ' + id_pedido);
                                    if (confirm('Eliminar el pedido')) {
                                        this.eliminar(id_pedido);
                                    }
                                }
                            },
                        ]
                    }
                    
                ]
            }
            //this.columnas[0].header = this.$t("general.nombre");
            //this.columnas[1].header = this.$t("general.acciones");
        },
        async obtenerProveedores() {
            const api = new PwgsApi;
            let aux = await api.get('proveedores');
            this.proveedores = aux.datos;
        },
        async eliminar(id) {
           
            const api = new PwgsApi;
            if (this.mostrarnuevo == true) {
                await api.delete('compras-pwgs/' + id);
            }
            if (this.parametrosgenerales.gestion_proveedores == 1 && this.parametrosgenerales.compras != 1) {
                await api.delete('servicios/' + this.$props.id + '/pedidos/' + id);
            }
            this.obtenerPedidos();
        },
        async anyadirpedido() {
            const api = new PwgsApi();
            let mes = this.fechalimite.getMonth()+1;
            if (mes < 10) { mes = "0" + mes }
            let dia = this.fechalimite.getDate();
            if(dia<10){dia="0"+dia}
            let fechasubida = this.fechalimite.getFullYear() + "-" + mes + "-" + dia;
            let subidadatos = { id_proveedor: this.proveedorseleccionado, material: this.material, fecha_limite: fechasubida }
            try{
                await api.post('servicios/' + this.$props.id + '/pedidos', subidadatos);
                this.recargarpedidos();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        editar(id) {
            $('#card_lista_pedido').addClass("collapsed-card");
            $('#card_body_lista_pedido').hide();
            $('#card_pedido').show();
            this.id_pedido=id;
            this.indice_pedido++;    
        },
        recargarpedidos() {
            this.obtenerPedidos();
            $('#card_body_lista_pedido').show();  
            this.mostrarnuevo = false;
        },
        nuevopedido() {
            if (this.parametrosgenerales.gestion_proveedores == 1 && this.parametrosgenerales.compras != 1) {
                this.obtenerProveedores();
                this.mostrarnuevosincompras = true;
                
            }
            else {
                this.mostrarnuevo = true;
            }

            $('#card_pedido').hide();
            $('#card_body_lista_pedido').hide();
        },
        async descargarpdf(id) {
            this.direccionglobal = localStorage.getItem('direccion');
            const api = new PwgsApi;
            this.url = await api.get('compras-pwgs/' + id + '/documento/pdf');
            let aux = this.url.url_descarga.slice(5);
            window.open("https://"+this.direccionglobal + aux);
        },
        async descargarexcel(id) {
            this.direccionglobal = localStorage.getItem('direccion');

            const api = new PwgsApi;
            this.url = await api.get('compras-pwgs/' + id + '/documento/xls');
            let aux = this.url.url_descarga.slice(5);
            window.open("https://"+this.direccionglobal + aux);
        },
    },
    mounted() {
    },
    watch: {
        id() {
            this.id_pedido = '';
            $('#card_pedido').hide();
            $('#card_body_lista_pedido').show();
            this.obtenerPedidos();
        },
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>