<template>

    <a @click="cargarExpedienteAvisos(aviso.tipo_servicio,aviso.titulo)" href="#" class="dropdown-item"
        v-for="aviso in avisos" :key="aviso.id_aviso" :style="aviso.style">
        <div data-toggle="tooltip" data-placement="top">
            <i :class="aviso.class" :title="aviso.texto"></i> {{aviso.titulo}}
            <span class="badge bg-danger float-right" v-if="aviso.numero">{{aviso.numero_alertas}}</span>
            <span class="badge float-right" v-if="aviso.numero">&nbsp;&nbsp;</span>
            <span class="badge bg-warning float-right" v-if="aviso.numero">{{aviso.numero_avisos}}</span>
            <span class="float-right text-muted " v-else>{{aviso.texto}}</span>
        </div>
    </a>



</template>
<script>

export default({
    props:{
        avisos: Array
    },
    setup() {

        
    },
    methods:{
        cargarExpedienteAvisos: function(tipo,titulo){
            //alert(tipo);
           // window.location.href = "/escritorio";
           console.log('llegapulsaraviso');
            this.$emit('evento_expediente_avisos_tipo', tipo,titulo); //Es llamado por ./AvisosMenu.vue
        }
    },
    mounted(){
        
    },
    watch:{
        avisos(){
            console.log('avisos', this.avisos)
        }
    }
})
</script>
