<template>
    <div class="row">
        <div class="col-md-6">
            <adjuntos ref="adjuntos" @archivoSeleccionado="actualizarArchivosSeleccionados" @informeSeleccionado="actualizarInformesSeleccionados" :id="id" :servicio="servicio" :visitas="visitas" />
        </div>
        <div class="col-md-6">
            <documentos ref="documentos" @archivoSeleccionado="actualizarDocumentoSeleccionado" :id="id" :servicio="servicio"/>
            <email ref="email" @enviar="enviarCorreo" :id="id" :servicio="servicio" :visitas="visitas" :archivosadjuntar="archivosadjuntar" />
        </div>        
    </div>
    <div class="col-md-12">
        <gestor :id="id" :servicio="servicio"/>
    </div>    
</template>
<script>
import gestor from './gestordocumentos/gestordocumentos.vue';
import documentos from './documentos.vue';
import adjuntos from './adjuntos.vue';
import email from './email.vue';
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id','servicio'],
    setup() {
        return;
        
    },
    components: {
        adjuntos,
        email,
        documentos,
        gestor,
    },
    data() {
        return {
            visitas: [],
            archivosadjuntar:[],
            informesadjuntar:[],
            documentosadjuntar:[],
        }
    },
    methods: {
        async enviarCorreo(datos) {
            if(this.archivosadjuntar.length >0){
                datos.adjuntos = this.archivosadjuntar;
            }
            const api = new PwgsApi();
            if(datos.adjuntos){
                for(var informe of this.informesadjuntar){
                    datos.adjuntos.push(informe.documento);
                }
            }else{
                var informes =[];
                for(var informe2 of this.informesadjuntar){
                    informes.push(informe2.documento);
                }
                datos.adjuntos = informes;
            }
            if(datos.adjuntos){
                for(var documento of this.documentosadjuntar){
                    datos.adjuntos.push(documento);
                }
            }else{
                var documentos =[];
                for(var documento2 of this.documentosadjuntar){
                    documentos.push(documento2);
                }
                datos.adjuntos = documentos;
            }
            try{
                await api.put('servicios/'+this.id+'/enviar-email',datos);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Correo enviado con éxito.', life: 2000 });
                this.$refs.email.reset();
                this.$refs.adjuntos.reset();
                this.$refs.documentos.reset();
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar el correo.', life: 5000 });
            }
            
        },
        async cargarvisitas() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/visitas
            this.visitas = [];
            const api = new PwgsApi();
            this.visitas = await api.get('servicios/' + this.$props.id + '/visitas');
        },
        actualizarArchivosSeleccionados(archivos){
            this.archivosadjuntar = archivos;
            console.log('archvssubir',this.archivosadjuntar);
        },
        actualizarInformesSeleccionados(informe){
            this.informesadjuntar = informe;
            console.log('informessubir',this.informesadjuntar);
        },
        actualizarDocumentoSeleccionado(documentos){
            this.documentosadjuntar = documentos;
            console.log('documentossubir',this.documentosadjuntar);
        },
    },
    watch: {
        id() {
            this.cargarvisitas();

        }
    },
    mounted() {
        this.cargarvisitas();

    },
})



</script>