<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header bg-default">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.importar') }}</h3>    
                    <div class="card-tools">
                        <button type="button" @click="importartrabajos()" class="btn btn-success btn-xs" >{{ $t('general.importar') }} </button>
                    </div>                                          
                </div>
                <div class="card-body">
                    <v-select taggable v-model="trabajo_lista" placeholder="Seleccione trabajo" :options="listatrabajos" />
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header bg-default">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.anadir') }}</h3>    
                    <div class="card-tools">
                        <button type="button" @click="anyadirtrabajobaremo()" class="btn btn-success btn-xs" >{{ $t('general.anadir') }} </button>
                    </div> 
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <v-select
                                v-model="especialidadseleccionada"
                                taggable
                                placeholder="Seleccione especialidad"
                                :options="listaespecialidades"
                                label="nombre"
                                track-by="id"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <v-select v-model="trabajo_especialidad" taggable multiple placeholder="Seleccione trabajos de la especialidad"
                            :options="trabajos_especialidad"
                            label="nombre"
                            track-by="idBaremo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card" v-for="grupo in Object.entries(listatrabajosporespecialidad)" :key="grupo[0]">
            <div class="card-header bg-grey">
                <h3 class="card-title">{{ grupo[0] }}</h3>
            </div>
            <div class="card-body">
                <div class="col-md-12">
                    <servicio-presupuesto-concepto v-for="trabajo in grupo[1]" :trabajo="trabajo" :id="id" :listaespecialidadesnombre="listaespecialidadesnombre" :seleccionado="seleccionado" :key="trabajo.datos.idtrabajo_presupuesto"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../../services/PwgsApi';
import serviciopresupuestoconcepto from './presupuesto_concepto.vue';
export default ({
            props: ['seleccionado','id','idaux'],
        data() {
        return {
            listatrabajos: [],
            listatrabajosid:[],
            listaespecialidadesnombre: [],
            listaespecialidadesid: [],
            listatrabajosporespecialidad: [],
            trabajo_especialidad: [],
            trabajos_especialidad: [],
            trabajo_lista:  [],
            trabajo_listaux: '',
            especialidadseleccionada: '',
            trabajosaux: '',
            especialidadseleccionadaid: '',
            trabajospresupuesto: [],
            especialidades: [],
            especialidadesnombre: [],
            especialidadesbaremo: '',
            idtrabajo:[],
            listaespecialidades: []
        };
    },
    mounted() {
        this.cargartrabajos();
        this.cargarespecialidades();
        this.cargartrabajospresupuesto();
     },
    components: {
        'servicio-presupuesto-concepto': serviciopresupuestoconcepto,
    },
    methods: {
        async cargartrabajos() {
            //   modulos/pwgsapi/index.php/trabajos/:id_servicio/trabajos-servicio
            const api = new PwgsApi;
            this.listatrabajos = [];
            let subidadatos = { id_servicio: this.$props.id };
            this.trabajosaux = await api.get("trabajos/" + this.$props.id + '/trabajos-servicio/', subidadatos);
            for (let operarios in this.trabajosaux.operario) {
                {
                    for (let trabajo in operarios) {
                        this.listatrabajos.push(this.trabajosaux.operario[operarios].Compañia.trabajos[trabajo].datos.nombre);
                    }
                }
            }
        },
        async cargarespecialidades() {
            //GET  modulos/pwgsapi/index.php/especialidades
            const api = new PwgsApi();
            let datos = await api.get('especialidades');
            this.listaespecialidades = datos.datos.map(especialidad => ({
                nombre: especialidad.nombre,
                id: especialidad.idespecialidad
            }));
        },
        async cargartrabajoespecialidad() {
            for (let i = 0; i < this.trabajosaux.length; i++) {
                for (let j = 0; j < this.listaespecialidadesid.especialidadesbaremolength; j++) {
                    if (this.especialidadseleccionada == this.trabajosaux[i].datos.nombre)
                    {
                        this.trabajo_listaux = this.trabajo_listaux.push(this.trabajosaux[i].datos.nombre);
                    }
                }
            }
        },
        async importartrabajos() {
            //POST  modulos/pwgsapi/index.php/presupuestos/:id_presupuesto/importar-trabajo
            this.idtrabajo = [];
            const api = new PwgsApi();
            let subidadatos2 = { id_servicio: this.$props.id };
            this.trabajosaux = await api.get("trabajos/" + this.$props.id + '/trabajos-servicio/', subidadatos2);
              for (let operarios in this.trabajosaux.operario) {
                {
                      for (let trabajo in this.trabajosaux.operario[operarios].Compañia.trabajos)
                      {
                        if (this.trabajo_lista == this.trabajosaux.operario[operarios].Compañia.trabajos[trabajo].datos.nombre) {
                            this.idtrabajo = this.trabajosaux.operario[operarios].Compañia.trabajos[trabajo].datos.idtrabajo_servicio;
                        }
                }
            }
            }
            let subidadatos = { ids_trabajos: this.idtrabajo, aplicar_descuento_defecto:'0' }
            await api.post('presupuestos/' + this.$props.seleccionado.presupuesto.id_presupuesto + '/importar-trabajo', subidadatos);
            this.cargartrabajospresupuesto();
        },
        async cargartrabajospresupuesto(anadido) {
            // GET     modulos/pwgsapi/index.php/presupuestos/:id_presupuesto/trabajos
            const api = new PwgsApi;
            const datos = await api.get('presupuestos/' + this.$props.seleccionado.presupuesto.id_presupuesto + '/trabajos');
            this.trabajospresupuesto = datos.trabajos;
            console.log('presups', this.trabajospresupuesto);
            if(!this.trabajospresupuesto){
                this.listatrabajosporespecialidad = [];
            }else{
                var trabajosSeparados = {};
                for(var trabajo of this.trabajospresupuesto){
                    var especialidad = trabajo.datos.nombre_especialidad;
                    if(!trabajosSeparados[especialidad]){
                        trabajosSeparados[especialidad] = [];
                    }
                    trabajosSeparados[especialidad].push(trabajo);
                }
                if(anadido==true){
                    this.listatrabajosporespecialidad = [];
                }
                setTimeout(()=>{
                    this.listatrabajosporespecialidad = trabajosSeparados;
                },1000);
                console.log('trabajosespec',this.listatrabajosporespecialidad);
            }
        },
        async obtenerTrabajosEspecialidad(){
            // POST  modulos/pwgsapi/index.php/servicios/id_servicio/baremo
            this.trabajos_especialidad = [];
            const api = new PwgsApi();
            let subidadatos = { id_especialidad: this.especialidadseleccionadaid };
            this.especialidades = await api.post('servicios/' + this.$props.id + '/baremo', subidadatos);
            this.trabajos_especialidad = this.especialidades.map(item => {
                return { nombre: item.nombre, idBaremo: item.idtrabajo };
            });
        },
        async anyadirtrabajobaremo() { 
                // POST  modulos/pwgsapi/index.php/presupuestos/:id_trabajo/crear-trabajo Indicando id_especialidad e id_baremo.
            //console.log(this.trabajo_especialidad);
            let idsBaremosSeleccionados = this.trabajo_especialidad.map(trabajo => trabajo.idBaremo);
    
                const api = new PwgsApi(); // Asumimos que esta API está correctamente configurada
                
                if (idsBaremosSeleccionados.length > 0) {
                    // Si hay selecciones, se envía un POST por cada ID de baremo seleccionado
                    for (let idBaremo of idsBaremosSeleccionados) {
                        let subidadatos = {
                            id_especialidad: this.especialidadseleccionadaid,
                            id_baremo: idBaremo
                        };
                        await api.post('presupuestos/' + this.$props.idaux + '/crear-trabajo', subidadatos);
                    }
                } else {
                    // Si no hay selecciones, se envía un POST sin el ID de baremo
                    let subidadatos = {
                        id_especialidad: this.especialidadseleccionadaid
                    };
                    await api.post('presupuestos/' + this.$props.idaux + '/crear-trabajo', subidadatos);
                }
                
                // Cargar datos actualizados después de todas las operaciones
                this.cargartrabajos();
                this.cargartrabajospresupuesto();            
            
        },
    },
    watch: {
        seleccionado() { 
            this.cargartrabajos();
            this.cargartrabajospresupuesto();
        },
        especialidadseleccionada(newValue) { 
            //this.cargartrabajoespecialidad();
            this.especialidadseleccionadaid = newValue.id;
            //alert("valor "+newValue.id);
            this.obtenerTrabajosEspecialidad();
        },
        id() {
            this.cargartrabajos();
            this.cargartrabajospresupuesto();
         }
    }
})
</script>
