<template>
    <div class="container-fluid">
        <section class="content row">
            <div class="col-md-2" style="margin-top: 10px;">
                <!-- Datos Usuario -->
                <!--<usuario-resumen />-->
                <!-- Futura implementación -->
                <!--<usuario-puntuacion />-->
                <div class="callout callout-warning">
                    <h5>{{ $t('general.ultimoacceso') }}</h5>
                    <p>{{ new Date(datosusuario.tiempo_inicio_sesion_usuario*1000).toLocaleDateString('es')}}</p>
                </div>
                <div class="callout callout-success" v-if="administracion">
                    <h5>Tramitador</h5>
                    <select class="form-control" id="" v-model="tramitadorSelec">
                        <option value="-1">Seleccione tramitador</option>
                        <option v-for="tramitador in tramitadores" :value="tramitador" :key="tramitador.id">{{
                            tramitador.nombre }}</option>
                    </select>
                </div>
                <EscritorioCompanyias :companyias="companyias"></EscritorioCompanyias>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-12 content-header">
                        <div class="card card-default card-tabs">
                            <div class="card-header p-1 pt-1">
                                <ul class="nav nav-pills">
                                    <li class="nav-item"><a :class="tipo_servicio ? 'nav-link' : 'nav-link active' " @click="guardarpestanyaseleccionada('resumen')"
                                            href="#datos_resumen" data-toggle="tab">{{ $t('general.resumen') }}</a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link " @click="guardarpestanyaseleccionada('servicios'); seccionavisos = false;"
                                            href="#lista_servicios_activos" data-toggle="tab">Servicios <span
                                                class="badge badge-primary">{{numero_servicios}}</span></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link "
                                            @click="seccionasignaciones = true; seccionavisos = false; guardarpestanyaseleccionada('asignaciones')"
                                            href="#lista_servicios_asignaciones" data-toggle="tab">{{
                                            $t('general.asignaciones') }} <span
                                                class="badge badge-danger">{{numero_asignaciones}}</span></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link" v-if="!permisosusuario.includes('Plataforma')"
                                            @click="seccionsolicitudes = true; seccionavisos = false; guardarpestanyaseleccionada('solicitudes')"
                                            href="#lista_servicios_solicitudes" data-toggle="tab">{{
                                            $t('general.solicitudes') }} <span
                                                class="badge badge-warning">{{numero_solicitudes}}</span></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link " v-if="!permisosusuario.includes('Plataforma')"
                                            @click="secciontrabajosnorev = true; seccionavisos = false; guardarpestanyaseleccionada('trabajosnorevisados')"
                                            href="#lista_servicios_trabajos_no_revisados" id="tnr" data-toggle="tab">{{
                                            $t('general.trabajosnorevisados') }} <span
                                                class="badge badge-success">{{numero_trabajos_no_revisados}}</span></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link " v-if="permisosusuario.includes('Plataforma')"
                                            @click="seccionsiguientescitas = true; seccionavisos = false; guardarpestanyaseleccionada('siguientescitas')"
                                            href="#lista_servicios_siguientes_citas" id="sig" data-toggle="tab"> 
                                            Siguientes citas <span 
                                            class="badge badge-success">{{numero_siguientes_citas}}</span></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link " v-if="permisosusuario.includes('Plataforma')"
                                            @click="seccioncitasconfirmadas = true; seccionavisos = false; guardarpestanyaseleccionada('citasconfirmadas')"
                                            href="#lista_servicios_citas_confirmadas" id="conf" data-toggle="tab"> 
                                            Citas confirmadas <span 
                                            class="badge badge-success">{{numero_citas_confirmadas}}</span></a>
                                    </li> 
                                    <MenuSupAvisos :tipo_servicio="tipo_servicio"
                                        v-on:evento_expediente_avisos_tipo="cargarExpedienteAvisos"/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Ocultar esta columna en la creación de una usuario -->
                    <div class="col-md-12">
                        <div class="tab-content">
                            <div :class="tipo_servicio ? 'tab-pane' : 'tab-pane active'" id="datos_resumen">
                                <usuario-resumen-estadisticas ref="child" />
                            </div>
                            <div class="tab-pane" id="lista_servicios_activos">
                                <lista-servicios :companyias="companyias" :selectcompany="selectcompany"
                                    v-on:evento_total_servicios="obtenerTotalServicios" :satisfaselec="satis"/>
                            </div>
                            <div class="tab-pane" id="lista_servicios_asignaciones">
                                <lista-asignaciones v-if="seccionasignaciones==true"
                                    v-on:evento_total_asignaciones="obtenerTotalAsignaciones" />
                            </div>
                            <div class="tab-pane" v-if="!permisosusuario.includes('Plataforma')" id="lista_servicios_solicitudes">
                                <lista-solicitudes v-if="seccionsolicitudes==true"
                                    v-on:evento_total_solicitudes="obtenerTotalSolicitudes" />
                            </div>
                            <div class="tab-pane" v-if="!permisosusuario.includes('Plataforma')" id="lista_servicios_trabajos_no_revisados">
                                <lista-trabajos_no_revisados v-if="secciontrabajosnorev == true"
                                    v-on:evento_total_trabajos_no_revisados="obtenerTotalTrabajosNoRevisados" />
                            </div>
                            <div class="tab-pane" v-if="permisosusuario.includes('Plataforma')" id="lista_servicios_siguientes_citas">
                                <lista-siguientes-citas v-if="seccionsiguientescitas == true"
                                    v-on:evento_total_siguientes_citas="obtenerTotalSiguientesCitas" />
                            </div>
                            <div class="tab-pane" v-if="permisosusuario.includes('Plataforma')" id="lista_servicios_citas_confirmadas">
                                <lista-citas-confirmadas v-if="seccioncitasconfirmadas == true"
                                    v-on:evento_total_citas_confirmadas="obtenerTotalCitasConfirmadas" />
                            </div>
                            <div class="tab-pane" id="lista_servicios_avisos">
                                <component v-if="seccionavisos==true" v-bind:is="componentaviso"
                                    :tipo_servicio="tipo_servicio" :key="indice_aviso" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
//import UsuarioResumen from '../usuarios/UsuarioResumen.vue';
import EscritorioAvisosVisitasConfirmar from './EscritorioAvisosVisitasConfirmar.vue';
import EscritorioAvisosVisitas from './EscritorioAvisosVisitas.vue';
import UsuarioResumenEstadisticas from '../usuarios/resumen/UsuarioResumenEstadisticas.vue';
import UsuarioPuntuacion from '../usuarios/UsuarioPuntuacion.vue';
import ListaServicios from './EscritorioServicios.vue';
import ListaAsignaciones from './EscritorioAsignaciones.vue';
import ListaSolicitudes from './EscritorioSolicitudes.vue';
import ListaTrabajosNoRevisados from './EscritorioTrabajosNoRevisados.vue'; 
import ListaServiciosAvisos from './EscritorioAvisos.vue';
import MenuSupAvisos from '../menus/superior/avisos/AvisosMenu.vue';
import ListaSiguientesCitas from './EscritorioSiguientesCitas.vue';
import ListaCitasConfirmadas from './EscritorioCitasConfirmadas.vue';
import $ from 'jquery'
import { PwgsApi } from '../../../services/PwgsApi';
import EscritorioCompanyias from './EscritorioCompanyias.vue';
export default {
    props: ['tipo_buscador'],
    data() {
        return {
            tramitadorSelec:'-1',
            tramitadores:[],
            satis:'',
            usuario: false,
            numero_asignaciones : 0,
            numero_solicitudes : 0,
            numero_trabajos_no_revisados : 0,
            numero_siguientes_citas : 0,
            numero_citas_confirmadas : 0,            
            numero_servicios : 0,
            tipo_servicio : String,
            componentaviso:"",
            indice_aviso: 1,
            datosusuario: '',
            companyias: [],
            selectcompany: '',
            propsactivo: false,
            seccionservicios:false,
            seccionasignaciones:false,
            seccionsolicitudes:false,
            secciontrabajosnorev:false,
            seccionsiguientescitas:false,
            seccioncitasconfirmadas:false,
            seccionavisos:false,
            administracion:false,
            permisosusuario:[],
        }
    },
    methods: {
        obtenerTotalAsignaciones: function (valor) {
            this.numero_asignaciones = valor;
        },
        obtenerTotalSolicitudes: function (valor) {            
            this.numero_solicitudes = valor;   
        },
        obtenerTotalTrabajosNoRevisados: function (valor) {
            this.numero_trabajos_no_revisados = valor;
        },
        obtenerTotalSiguientesCitas: function (valor) {
            this.numero_siguientes_citas = valor;
        },
        obtenerTotalCitasConfirmadas: function (valor) {
            this.numero_citas_confirmadas = valor;
        },
        obtenerTotalServicios: function(valor) {
            this.numero_servicios = valor;
        },
        obtenerTipoServicioAvisos: function(valor) {
           alert(valor);
        },
        guardarpestanyaseleccionada(pestanya) {
            localStorage.setItem('pestanyaescritorio', pestanya);  
        },
        cargarpestanyaseleccionada() {
            let pestanyaseleccionada = localStorage.getItem('pestanyaescritorio');
            console.log("pestanyaseleccionada", pestanyaseleccionada);

            if (pestanyaseleccionada == 'servicios') {
                $('[href="#lista_servicios_activos"]').tab('show');
                this.seccionservicios = true;
            }
            if (pestanyaseleccionada == 'resumen') {
                $('[href="#datos_resumen"]').tab('show');

            }
            if (pestanyaseleccionada == 'trabajosnorevisados') {
                $('[href="#lista_servicios_trabajos_no_revisados"]').tab('show');
                this.secciontrabajosnorev = true;
            }
            if (pestanyaseleccionada == 'siguientescitas') {
                $('[href="#lista_servicios_trabajos_no_revisados"]').tab('show');
                this.seccionsiguientescitas = true;
            }
            if (pestanyaseleccionada == 'citasconfirmadas') {
                $('[href="#lista_servicios_trabajos_no_revisados"]').tab('show');
                this.seccioncitasconfirmadas = true;
            }            
            if (pestanyaseleccionada == 'asignaciones') {
                this.seccionasignaciones = true;
                $('[href="#lista_servicios_asignaciones"]').tab('show');

            }
            if (pestanyaseleccionada == 'solicitudes') {
                $('[href="#lista_servicios_solicitudes"]').tab('show');
                this.seccionsolicitudes = true;
            }
        },
        async cargardatosusuario() {
            let auxusuario = localStorage.getItem('usuario');
            const api = new PwgsApi();
            let aux = await api.get('usuarios/' + auxusuario);            
            this.datosusuario = aux.datos;
            if(this.datosusuario.permisos.includes('Administración'))this.administracion = true;
            let trams = await api.get('/usuarios/gestion');
            this.tramitadores = trams.datos;
            this.numero_asignaciones = localStorage.getItem("num_asignaciones");
            this.numero_solicitudes = localStorage.getItem("num_solicitudes");
            this.numero_trabajos_no_revisados = localStorage.getItem("num_trabajos");
            this.numero_siguientes_citas = localStorage.getItem("num_siguientes_citas");
            this.numero_citas_confirmadas = localStorage.getItem("num_citas_confirmadas");
        },
        cargarExpedienteAvisos: function (tipo) {
            
            this.seccionavisos = true;
            console.log('llegatt', tipo);
            if(tipo == "visitas_Nofinalizadas" || tipo == "no_finalizadas"){
                console.log('entranofin');
                this.tipo_servicio = "no_finalizadas";
                this.componentaviso = "lista-servicios-avisos-visitas";
            }else if(tipo == "visitas_Noendomicilio" || tipo == "no_en_domicilio"){
                this.tipo_servicio = "no_en_domicilio";
                this.componentaviso = "lista-servicios-avisos-visitas";
            }else if(tipo == "visitas_Sinconfirmar" || tipo == "sin_confirmar"){
                this.tipo_servicio = "sin_confirmar";
                this.componentaviso = "lista-servicios-avisos-visitas-sinconfirmar";
            }else if(tipo == "visitas_Sinfirma" || tipo == "sin_firmar"){
                this.tipo_servicio = "sin_firmar";
                this.componentaviso = "lista-servicios-avisos-visitas";
            }else if(tipo == "visitas_Sinfotos" || tipo == "sin_fotos"){
                this.tipo_servicio = "sin_fotos";
                this.componentaviso = "lista-servicios-avisos-visitas";
            }else{
                this.componentaviso = "lista-servicios-avisos";
                this.tipo_servicio=tipo;
            }
            this.indice_aviso++;
            $("#datos_resumen").removeClass("active");
            $("#lista_servicios_activos").removeClass("active");
            $("#lista_servicios_asignaciones").removeClass("active");
            $("#lista_servicios_solicitudes").removeClass("active");
            $("#lista_servicios_trabajos_no_revisados").removeClass("active");
            $("#lista_servicios_citas_confirmadas").removeClass("active");
            $('#lista_servicios_siguientes_citas').removeClass("active");
            $("#lista_servicios_avisos").addClass("active");
            document.getElementById('av').className = 'nav-link active';

        },
        removeractivo() {
            $('[href="#lista_servicios_activos"]').removeClass("active");
            $('[href="#datos_resumen"]').removeClass("active");
            $('[href="#lista_servicios_trabajos_no_revisados"]').removeClass("active");
            $('[href="#lista_servicios_asignaciones"]').removeClass("active");
            $('[href="#lista_servicios_solicitudes"]').removeClass("active");
            $("#lista_servicios_citas_confirmadas").removeClass("active");
            $('#lista_servicios_siguientes_citas').removeClass("active");
            document.getElementById('av').className = 'nav-link active';
            
            document.getElementById('lista_servicios_avisos').className = 'nav-link active';

           


        },
        async listadoCompanyas() {
            //GET  modulos/pwgsapi/index.php/companias
            const api = new PwgsApi();
            let aux = await api.get('companias/simple,servicios?sortField=nombre&sortOrder=1');
            this.companyias = aux.datos;
        },
      
        cargarseleccionada(compa) {
            this.selectcompany = compa;
            $('[href="#lista_servicios_activos"]').tab('show');
        },
        async comprobarpermisos(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
            var permisos = resppermisos.datos;
            for(let permiso of permisos){
                this.permisosusuario.push(permiso.nombre_permiso);
            }
        },
    },
    components: {
     //'usuario-resumen': UsuarioResumen,
        'lista-siguientes-citas': ListaSiguientesCitas,
        'lista-citas-confirmadas': ListaCitasConfirmadas,
        'usuario-resumen-estadisticas': UsuarioResumenEstadisticas,
        'usuario-puntuacion': UsuarioPuntuacion,
        'lista-servicios': ListaServicios,
        'lista-asignaciones': ListaAsignaciones,
        'lista-solicitudes': ListaSolicitudes,
        'lista-trabajos_no_revisados': ListaTrabajosNoRevisados,
        MenuSupAvisos,
        'lista-servicios-avisos-visitas-sinconfirmar':EscritorioAvisosVisitasConfirmar,
        'lista-servicios-avisos-visitas': EscritorioAvisosVisitas,
        'lista-servicios-avisos': ListaServiciosAvisos,
        EscritorioCompanyias,
    },
    created(){
        this.tipo_servicio = this.$route.params.aviso
    },
    activated() {
                this.cargarpestanyaseleccionada();

    },
    mounted() {
        this.comprobarpermisos();
        this.cargardatosusuario();
        this.listadoCompanyas();
        this.cargarpestanyaseleccionada();
    },
    beforeRouteLeave(to, from, next) {
        localStorage.setItem("tipobuscador", "defecto");
        next();
    },
    watch: {
        tramitadorSelec(value){
            console.log('entraa1');
            if (this.$refs.child) {
                if(value!='-1'){
                    console.log('entraa2');
                    this.$refs.child.avisar(value.id);
                }else{
                    console.log('entraa3');
                    this.$refs.child.avisar('todos');
                }
            }
        },
        tipo_buscador() {
            
            let tipoaux = localStorage.getItem("tipobuscador");
            console.log('tipobuscador', tipoaux);
            if(tipoaux== 0 || tipoaux== 1 || tipoaux== 2 || tipoaux== 3 || tipoaux== 4){
                this.satis = tipoaux;
                $('[href="#lista_servicios_activos"]').tab('show');
            }else if(tipoaux!="defecto"){
                console.log("asd", tipoaux);
                if (tipoaux) {
                    this.seccionavisos = true;
                    console.log("hola", tipoaux);

                
                    this.cargarExpedienteAvisos(tipoaux);//Es llamado a ./Escritorio.vue
                    document.getElementById('av').className = 'nav-link active';

                
                }
                else {
                    let aux = localStorage.getItem("tipobuscador");
                    this.seccionavisos = true;
                    console.log("holados", aux);
                    this.cargarExpedienteAvisos(aux); //Es llamado a ./Escritorio.vue


                }
            }
        }
    }
}
</script>