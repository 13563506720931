//importamos dependencias de adminlte


import './lib/css.js';
import './lib/scripts.js';





import { createApp } from 'vue';
import App from './App.vue';
import {createI18n} from 'vue-i18n';
import router from './router.js';
import store from './stores/store.js';
import "leaflet/dist/leaflet.css";
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Chart from 'primevue/chart';
import Dialog from 'primevue/dialog';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import AppLink from './components/AppLink.vue'
import NavItem from './components/NavItem.vue'
import NavMenu from './components/NavMenu.vue'
import BotonGuardar from './components/BotonGuardar.vue'
import SeccionDatos from './components/SeccionDatos.vue'
import es from "./locales/es.json";
import cat from "./locales/cat.json";
import en from "./locales/en.json";
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import vSelect from "vue-select";
import Calendar from 'primevue/calendar';

import ConfirmationService from 'primevue/confirmationservice';

import "vue-select/dist/vue-select.css";

const app = createApp(App)
app.component('app-link', AppLink);
app.component('nav-item', NavItem);
app.component('nav-menu', NavMenu);
app.component('boton-guardar', BotonGuardar);
app.component('seccion-datos', SeccionDatos);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Button', Button);
app.component('InputText', InputText);
app.component('Calendar', Calendar);
app.component('Chart', Chart);
app.component('Dialog', Dialog);
app.component('Toast', Toast);
app.component('Dropdown', Dropdown);
app.component('v-select', vSelect);
app.directive('tooltip', Tooltip);
const i18n = createI18n({

      // something vue-i18n options here ...

        locale: "es",
  fallbackLocale: "es",
  messages: { es,  cat ,en},

});
app.use(store);
app.use(router);
app.use(ToastService);
app.use(i18n);
app.use(Dropdown);
app.use(ConfirmationService);
app.use(PrimeVue, {
  locale: {
      firstDayOfWeek: 1, // Lunes como primer día
      dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
          "enero", "febrero", "marzo", "abril", "mayo", "junio", 
          "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ],
      monthNamesShort: [
          "ene", "feb", "mar", "abr", "may", "jun", 
          "jul", "ago", "sep", "oct", "nov", "dic"
      ],
      today: "Hoy",
      clear: "Limpiar"
  }
});
app.mount('#app')
