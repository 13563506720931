<template> 
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>{{ $t('general.listadocompanias') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                        <div class="card card-widget">
                            <!-- Add the bg color to the header using any of the bg-* classes -->
                            <div class="card-footer p-0">
                                <ul class="nav flex-column">
                                    <li class="nav-item active">
                                        <a href="#" class="nav-link">
                                            {{ $t('general.activas') }} <span class="float-right badge bg-primary">7</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            {{ $t('general.eliminadas') }} <span class="float-right badge bg-info">5</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                </div>
                <div class="col-md-10">
                    <div class="row" style="margin-bottom: 15px; margin-left: 0">
                        <Dropdown scrollHeight="600px" v-if="sistemas" showClear optionValue="nombre"
                            v-model="sistemaselec" :options="sistemas" @change="obtenerDatos()" filter
                            optionLabel="nombre" placeholder="Sistema">
                        </Dropdown>                        
                    </div>
                    <tabla-datos :columnas="columnas" :datos="companias" :filtros="filtros" :filtrosBuscador="filtrosBuscador"
                         @quieroDatos="obtenerDatos($event)" :total="total"></tabla-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
//import CompanyiasService from '../../../services/CompanyiasService.js'
import { FilterMatchMode } from 'primevue/api';
import { PwgsApi } from '../../../services/PwgsApi';
import TablaDatos from '../../TablaDatos.vue'
export default {
    components: {
        'tabla-datos': TablaDatos,
    },
    data() {
        return {
            sistemaselec:'',
            sistemas:null,
            companias: [],
            parametros: null, // query params de DataTable
            filtrosBuscador: ['nombre'], // filtros para el buscador
            filtros: { // filtros de columna
                'nombre': { value: '', matchMode: FilterMatchMode.CONTAINS },
                         },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre',
                    sortable: true,
                },
                {
                    header: 'Prefijo',
                    field: 'prefijo',
                    sortable: true,
                },
                {
                    header: 'Sistema',
                    field: 'sistema_compannia',
                },
                {
                    header: 'CIF',
                    field: 'cif',
                },
                /*{
                    header: 'Domicilio',
                    field: 'domicilio'
                },
                {
                    header: 'Código Postal',
                    field: 'cp',
                },
                {
                    header: 'Teléfono',
                    field: 'telefono',
                },
                {
                    header: 'E-Mail',
                    field: 'email',
                },
                {
                    header: 'Población',
                    field: 'poblacion',
                },
                {
                    header: 'Provincia',
                    field: 'provincia',
                },*/
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idcompañia',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar cia ' + id);
                                //this.obtenerUsuario(id);
                                this.$router.push({
                                    name: 'Compañía',
                                    params: {
                                        id:id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'idcompañia',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar cia ' + id);
                                if (confirm('Desea eliminar la compañía?')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ],
        }
    },
    methods: {
        async obtenerSistemas(){
          const api = new PwgsApi();
          var sist = await api.get('sistemas/usados');
          this.sistemas = sist.datos;
        },
        async obtenerDatos(parametros) {
            /* get  modulos/pwgsapi/index.php/companias/:id */
            const api = new PwgsApi();
            const params = parametros;
            var extra = '';
            if(this.sistemaselec){
                extra+='/sistemas,'+[this.sistemaselec];
            }
            const datos = await api.get('companias'+extra,params); 
            this.companias = datos.datos; 
            this.total = datos.n_total_registros;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.prefijo");
            this.columnas[2].header = this.$t("general.sistema");
            this.columnas[4].header = this.$t("general.acciones");
            let idusuario = localStorage.getItem('usuario');
            let aux = await api.get('usuarios/' + idusuario + '/permisos');
            let permisos = aux.datos;
            console.log("permisos", permisos);
            let soporte = false;
            for (let i = 0; i < permisos.length; i++){
                if (permisos[i].nombre_permiso == "soporte") {
                    soporte = true;
                }
            }
            if (soporte == false) {
                this.columnas[4].acciones = [{
                    nombre: 'Editar',
                    class: 'editar',
                    tipo: 'default',
                    nombreId: 'idcompañia',
                    icono: 'far fa-edit',
                    // Debe ser arrow function para que this sea este componente
                    accion: (id) => {
                        console.log('Editar cia ' + id);
                        //this.obtenerUsuario(id);
                        this.$router.push({
                            name: 'Compañía',
                            params: {
                                id: id
                            }
                        });
                    }
                }]
            }
        },
        async eliminar(id) {
            /* delete  modulos/pwgsapi/index.php/companias/:id */
            const api = new PwgsApi();
            console.log('eliminando compañía ' + id + '...');
            await api.delete('companias/' + id);
            this.obtenerDatos();
        },
    },
    activated() {
        this.obtenerDatos();
        this.obtenerSistemas();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>