<template>
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.adjuntos') }}</h3>
        </div>
        <div class="card-body">
            <TabView :scrollable="true">
                <TabPanel header="Comunicaciones">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in documentosbasicos" :key="archivos">
                            <div v-if="archivos.operarios_idoperario == 0">
                                <Image
                                    v-if="archivos.documento.substr(archivos.documento.length - 3) == 'jpg' || archivos.documento.substr(archivos.documento.length - 4) != '.' || archivos.documento.substr(archivos.documento.length - 3) == 'png' || archivos.documento.substr(archivos.documento.length - 4) == 'jpeg' || archivos.documento.substr(archivos.documento.length - 3) == 'bmp' || archivos.documento.substr(archivos.documento.length - 3) == 'gif' || archivos.documento.substr(archivos.documento.length - 4) == 'tiff' || archivos.documento.substr(archivos.documento.length - 4) == 'webp'"
                                    :src='"https://" + urlglobal +"/documentos.php?documento=" + archivos.documento + "&servicio=" + id'
                                    :id="archivos.id_documento" width="200" preview :alt="archivos.descripcion" />
                                <a v-else
                                    :href='"https://" + urlglobal +"/documentos.php?documento=" + archivos.documento + "&servicio=" + id'
                                    :title="archivos.descripcion">
                                    <img :alt="archivos.descripcion" :src='"https://"+urlglobal+"/images/pdf.gif"'>
                                </a>
                                <div v-if="parametros.campos_obligatorios.antes_despues == 1 && archivos.cuando==''" style="text-align: center;">
                                    <select v-model="archivos.cuando" id="tiempo">
                                        <option value="">Selecciona tiempo</option>
                                        <option value="Antes">Antes</option>
                                        <option value="Despues">Después</option>
                                    </select>
                                </div>
                                <div v-else style="text-align: center;">
                                    <span style="margin-right: 5px;" v-if="archivos.cuando">{{ archivos.cuando }}</span>
                                </div>
                                <div class="row">
                                    <div class="col-md-1">
                                        <input :id="archivos.id_documento"
                                            @click="archivos.checked = !archivos.checked; adjuntararchivo(archivos, archivos.checked, archivos.id_documento)"
                                            v-model="archivos.checked" type="checkbox">
                                    </div>
                                    <div class="col-md-10">
                                        <label v-if="archivos.documento.valueOf().length > 15"
                                            :for="archivos.id_documento"> {{ archivos.documento.substring(0, 15) + '...'
                                            }} </label>
                                        <label v-if="archivos.documento.valueOf().length <= 15"
                                            :for="archivos.id_documento"> {{ archivos.documento }} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 style="text-align:center">{{ $t('general.subirarchivos') }}</h4>
                    <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event)" multiple="true"
                        chooseLabel="Elegir Archivos" />
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <textarea placeholder="Descripcion del fichero" v-model="descrfichero" rows="5"
                                class="form-control"></textarea>
                        </div>
                    </div>
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <button @click="uploadFile()" class="btn btn-success btn-block" type="button">{{
                                $t('general.subirarchivos') }}</button>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel v-for="visita in visitas" :key="visita"
                    :header="new Date(visita.visita.fecha).toLocaleDateString() + ' ' + visita.visita.hora.slice(0, -3) + ' - ' + visita.visita.nombre_operario">
                    <h4 style="text-align:center">{{ $t('general.adjuntararchivosencorreo') }}</h4>
                    <div class="row">
                        <div class="col-sm-4" v-for="archivos in visita.archivos" :key="archivos">
                            <!---->
                            <div v-if="archivos.documento.substr(archivos.documento.length - 3) == 'jpg' || archivos.documento.substr(archivos.documento.length - 3) == 'png' || archivos.documento.substr(archivos.documento.length - 4) == 'jpeg' || archivos.documento.substr(archivos.documento.length - 3) == 'bmp' || archivos.documento.substr(archivos.documento.length - 3) == 'gif' || archivos.documento.substr(archivos.documento.length - 4) == 'tiff' || archivos.documento.substr(archivos.documento.length - 4) == 'webp'">   
                                <Image v-if="urlglobal != 'null.pwgs.app'"
                                    :src='"https://" + urlglobal +"/documentos.php?documento=" + archivos.documento + "&servicio=" +id '
                                    :id="archivos.id_documento" width="200" preview :alt="archivos.descripcion" />
                                <Image v-if="urlglobal == 'null.pwgs.app'"
                                    :src='"https://desarrollofront.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id '
                                    :id="archivos.id_documento" width="200" preview :alt="archivos.descripcion" />
                                <div v-if="parametros.campos_obligatorios.antes_despues == 1 && archivos.cuando==''" style="text-align: center;">
                                    <select v-model="archivos.cuando" id="tiempo">
                                        <option value="">Selecciona tiempo</option>
                                        <option value="Antes">Antes</option>
                                        <option value="Despues">Después</option>
                                    </select>
                                </div>
                                <div v-else style="text-align: center;">
                                    <span style="margin-right: 5px;" v-if="archivos.cuando">{{ archivos.cuando }}</span>
                                </div>
                            </div>
                            <div v-else>
                                <a  v-if="urlglobal != 'null.pwgs.app'"
                                    :href='"https://"+urlglobal+"/mostrar_imagen.php?imagen=" + archivos.documento + "&servicio=" + id  '
                                    :title="archivos.descripcion">
                                    <img :alt="archivos.descripcion" :src='"https://desarrollo.pwgs.app/images/pdf.gif"'>
                                </a>
                                <a  v-if="urlglobal == 'null.pwgs.app'"
                                    :href='"https://desarrollofront.pwgs.app/mostrar_imagen.php?imagen=" + archivos.documento + "&servicio=" + id  '
                                    :title="archivos.descripcion">
                                    <img :alt="archivos.descripcion" :src='"https://desarrollo.pwgs.app/images/pdf.gif"'>
                                </a>
                            </div>
                            <div class="row">
                                <div class="col-md-1">
                                    <input :id="archivos.id_documento"
                                        @click="archivos.checked=!archivos.checked; adjuntararchivo(archivos, archivos.checked, archivos.id_documento)"
                                        v-model="archivos.checked" type="checkbox">
                                </div>
                                <div class="col-md-10">
                                    <label v-if="archivos.documento.valueOf().length>15" :for="archivos.id_documento">
                                        {{ archivos.documento.substring(0,15)+'...' }} </label>
                                    <label v-if="archivos.documento.valueOf().length <= 15"
                                        :for="archivos.id_documento">
                                        {{ archivos.documento}} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <p></p>
                    <h4 style="text-align:center">{{ $t('general.subirarchivos') }}</h4>
                    <FileUpload mode="basic" :maxFileSize="1000000" @select="subirarchivo($event)" multiple="true"
                        chooseLabel="Elegir Archivos" />
                    <p></p>
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <textarea placeholder="Descripcion del fichero" v-model="descrfichero" rows="5"
                                class="form-control"></textarea>
                        </div>
                    </div>
                    <p></p>
                    <div class="row">
                        <div class="col-md-12">
                            <button
                                @click="uploadFile(visita.visita.operarios_idoperario, visita.visita.idvisitador,visita)"
                                class="btn btn-success btn-block" type="button">
                                {{$t('general.subirarchivos')}}
                            </button>
                        </div>
                    </div>                    
                </TabPanel>
            </TabView>            
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi'; 
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Image from 'primevue/image';
import FileUpload from 'primevue/fileupload';
export default ({
    props: ['id','visitas','servicio'],
    data() {
        return {
            archivosasubir: [],
            archivo: '',
            descrfichero: '',
            archivosubido: [],
            documentosbasicos: [],   
            urlglobal:'',
            informes:[],
            mostrarForm:[],
            envioSeleccionado:[],
            emailIntroducido:[],
            destinoSeleccionado:[],
            text:[],
            parametros:[],
            check:[],
            tiempos:[],
            tiempoImagenSelect:[],
            papSeleccionado:[],
            ipasSeleccionado:[],
            mutuaSeleccionada:[],
            multiasistenciaSeleccionada:[],
            creandoInforme:false,
            numfotos:1,
            apaisado:false,
            logo:false,
            expediente:false,
            descripciones:false,
            tatexto:'',
            tadescripcion:'',
            informesasubir:[],
        }
    }, 
    setup() {
        return;
    },
    components: {
        TabPanel,TabView,Image,FileUpload
    },
    methods: {
        async generarConsulta(visita){
            console.log(visita);
            const api = new PwgsApi();
            var ids = {};
            try{
                if(visita){
                    for(var archivo of visita.archivos){
                        if(archivo.checked){
                            if(this.parametros.campos_obligatorios.antes_despues == 1 && archivo.cuando == ""){
                                alert('Las fotografías seleccionadas no tienen indicado el antes/después.')
                                return;
                            }
                            ids[archivo.id_documento] = archivo.cuando;
                        }
                    }
                    await api.post('servicios/'+this.id+'/informes-fotograficos', {ids_documentos:ids, texto:this.tatexto, descripcion:this.tadescripcion,
                        n_fotos_pag:this.numfotos, orientacion: this.apaisado? 'L':'P', logo:this.logo?1:0, expediente: this.expediente?1:0,
                        muestra_descripcion: this.descripciones?1:0});
                } else{
                    await api.post('servicios/'+this.id+'/informes-fotograficos', {ids_documentos:ids, texto:this.tatexto, descripcion:this.tadescripcion,
                        n_fotos_pag:this.numfotos, orientacion: this.apaisado? 'L':'P', logo:this.logo?1:0, expediente: this.expediente?1:0,
                        muestra_descripcion: this.descripciones?1:0});
                }
                this.cargarInformes();
            }catch(error){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error, life: 2000 });
            }
        },
        descargar(link){
            var links = link.slice(5);
            let urlglobal = localStorage.getItem('direccion');
            window.open('https://'+urlglobal+links, '_blank');
        },
        reset(){
            this.archivosasubir = [];
            this.archivo= '';
            this.descrfichero= '';
            this.archivosubido= [];
            this.documentosbasicos= []  ;
            this.urlglobal= '';
            this.documentosServicio();
        },
        async documentosServicio() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/datos-basicos
            this.urlglobal = localStorage.getItem('direccion');
           // const api = new PwgsApi;
           //  let datos = await api.get('servicios/' + this.$props.id + '/datos-basicos');
           if(this.servicio.asegurado){
                console.log('entraser',this.servicio);
               this.documentosbasicos = this.$props.servicio.documentos.servicio;
           }
        }, 
        async uploadFile(idoperario, idvisita,visita) {
            console.log("visita123", visita);
            //POST  modulos/pwgsapi/index.php/servicios/:id/guardar-documento
            const formData = new FormData();
            if (idoperario>0 && idvisita>0){
            formData.append("id_operario", idoperario);
                formData.append("id_visita", idvisita);
            }
            else {
                formData.append("id_operario", 0);
                formData.append("id_visita", 0); this.mostrarplanning = true;
                this.cargardatos(this.$props.id);
                this.cargarmasdatos();
                this.obtenerEstadosPendientes();
                this.cargarEstadoPendiente();
            }
            formData.append("tipo_documento", 'ficherosvisitas');
            for (let i = 0; i < this.archivosubido.length; i++) {
                formData.append(this.archivosubido[i].name, this.archivosubido[i], this.archivosubido[i].name);
            }
            formData.append("descripcion", this.descrfichero);
            const api = new PwgsApi;
            await api.post('servicios/' + this.$props.id + '/guardar-documento', formData);  
            this.archivosubido = []; 
            this.$parent.cargarvisitas();
            this.documentosServicio();     
        },
        adjuntararchivo(archivo, checked, id) {
            let aux = 0;
            console.log('archivovv', archivo);
            if (checked == true) {
                if (this.archivosasubir.length > 0) {
                    for (let i = 0; i < this.archivosasubir.length; i++) {
                      if (this.archivosasubir[i] == id) {
                            aux = 1;
                        }
                    }
                }
                if (aux < 1) {
                    this.archivosasubir.push(id);
                }
            }
            if (checked == false && this.archivosasubir) { 
                for (let i = 0; i < this.archivosasubir.length; i++) {
                    if (this.archivosasubir[i] == id) {
                        this.archivosasubir.splice(i, 1);
                    }
                }
            }
            this.$emit('archivoSeleccionado', this.archivosasubir);
        },
        adjuntarinforme(informe){
            if(informe.checked){
                this.informesasubir.push(informe);
            }else{
                let index = this.informesasubir.indexOf(informe);
                if (index > -1) {
                    this.informesasubir.splice(index, 1);
                }
            }
            this.$emit('informeSeleccionado', this.informesasubir);
            console.log('informeAdjuntar',informe);
        },
        subirarchivo(event) {
            for (let i = 0; i < event.files.length;i++){
            this.archivosubido.push(event.files[i]);
            }
        },
        async cargarInformes(){
            this.informes = [];
            this.activado = [];
            this.activadoV = [];
            const api = new PwgsApi();
            const resp = await api.get('servicios/'+this.id+'/informes-fotograficos');
            this.informes = resp.informes;
            this.parametros = resp.parametros;
            for(let inf of this.informes){
                var id = inf.id_documento;
                this.mostrarForm[id]=false;
                this.emailIntroducido[id]="";
                this.envioSeleccionado[id]="";
                this.destinoSeleccionado[id]="";
                this.papSeleccionado[id]="";
                this.ipasSeleccionado[id]="";
                this.mutuaSeleccionada[id]="";
                this.multiasistenciaSeleccionada[id]="";
                this.text[id]="";
                inf.checked=false;
            }
            this.informes.forEach(informe => informe.marcado = false);
            console.log('informes', this.informes);
            console.log('parametros', this.parametros);
        },
    },
    mounted() {
        this.documentosServicio();
        this.cargarInformes();
    },
    watch: {
        id() {  
            this.documentosServicio();
        },
        servicio(){
            console.log('servss', this.servicio);
            this.documentosServicio();
        },
        visitas(){
            console.log('archvs', this.visitas);
        }
    }
})
</script>
<style>
.card-header::after{
    display: none;
}
</style>