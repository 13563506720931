<template>
    <div class="row">
        <div class="col-md-6">
            <seccion-datos titulo="Especialidades" :botonGuardar="false" class="card card-success">
                <div class="row">
                    <div class="col-md-7">
                        <div class="input-group">
                            <Dropdown scrollHeight="400px" v-model="seleccionado" :options="listado_especialidades"
                                filter optionLabel="nombre" placeholder="Especialidades">
                            </Dropdown>

                        </div>
                    </div>
                    <div class="col-md-2">
                        <input class="custom-control-input" type="checkbox" id="especifica" v-model="espefica">
                        <label for="especifica" class="custom-control-label">Especifica</label>
                    </div>
                    <div class="col-md-1">

                        <button class="btn btn-primary"
                            @click="añadirespecialidad(seleccionado)">{{$t('general.anadir')}}</button>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-sm table-striped">
                            <tbody>
                                
                                <tr v-for="oper in operario_especialidad_especificas" :key="oper.id">
                                    <td align="left"> {{ oper.nombre }}</td>
                                    <td align="center">
                                        <div class="col-xs-4" style="width:60px">
                                            <input checked class="custom-control-input" type="checkbox" :id="oper.id"
                                                @change="quitarespecifica(oper.id)">
                                            <label :for="oper.id" class="custom-control-label">Especifica</label>
                                        </div>
                                    </td>
                                    <td align="right">

                                        <div class="col-xs-4" style="width:60px">
                                            <button type="button" class="btn btn-block btn-outline-danger btn-xs"
                                                @click="eliminarespecialidad(oper.idespecialidad)">{{
                                                $t('general.eliminar')}}</button>
                                        </div>
                                    </td>
                                </tr>        
                                <tr v-for="oper in operario_especialidad_noespecificas" :key="oper.id">
                                    <td align="left"> {{ oper.nombre }}</td>
                                    <td align="center">
                                        <div class="col-xs-4" style="width:60px">
                                            <input class="custom-control-input" type="checkbox" :id="oper.id"
                                                @change="ponerespecifica(oper.id)">
                                            <label :for="oper.id" class="custom-control-label">Especifica</label>
                                        </div>
                                    </td>
                                    <td align="right">

                                        <div class="col-xs-4" style="width:60px">
                                            <button type="button" class="btn btn-block btn-outline-danger btn-xs"
                                                @click="eliminarespecialidad(oper.id)">{{ $t('general.eliminar')
                                                }}</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        Dropdown
    },
    props: ['id', 'operario'],
    data() {
        return {
            listado_especialidades: [],
            seleccionado: '',
            operario_especialidad_especificas: '',
            operario_especialidad_noespecificas: '',
            espefica: false,
            espeficadefecto: true,
            noespecificadefecto:false,
        }
    },
    methods: {     

        async cargarespecialidadesoperario() {
            //GET  modulos/pwgsapi/index.php/operarios/:id/especialidades
            const api = new PwgsApi();
            
            let aux;
            aux = await api.get('operarios/' + this.$props.id + '/especialidades');
            this.operario_especialidad_especificas = aux.especificas.datos;
            this.operario_especialidad_noespecificas = aux.no_especificas.datos;
             

        },
        async quitarespecifica(idesp) { 
            //put   modulos/pwgsapi/index.php/operarios/:id/especialidades/:id_especialidad

            const api = new PwgsApi();
            let subidadatos = { especifica: "NO" };
            await api.put('operarios/' + this.$props.id + '/especialidades/' + idesp, subidadatos);
            this.cargarespecialidadesoperario();

        },
        async ponerespecifica(idesp) {
            //put   modulos/pwgsapi/index.php/operarios/:id/especialidades/:id_especialidad
            const api = new PwgsApi();
            let subidadatos = { especifica: "SI" };
            await api.put('operarios/' + this.$props.id + '/especialidades/' + idesp,subidadatos);
            this.cargarespecialidadesoperario();


            
        },
        async eliminarespecialidad(id) {
            //DELETE  modulos/pwgsapi/index.php/operarios/:id/especialidades/:id_especialidad
            const api = new PwgsApi();
            await api.delete('operarios/' + this.$props.id + '/especialidades/' + id);
            this.cargarespecialidadesoperario();

        },
        async cargarespecialidades() {
            //GET 
            this.listado_especialidades = [];
            let aux;
            const api = new PwgsApi();
            aux = await api.get('especialidades');
            this.listado_especialidades = aux.datos;
            
        },
        async añadirespecialidad() {
            //post   modulos/pwgsapi/index.php/operarios/:id/especialidades
            const api = new PwgsApi();
            let subidadatos = { especifica: "NO", id_especialidad: this.seleccionado.idespecialidad };
            if (this.espefica == true) {
                subidadatos.especifica = "SI";
            }
            await api.post('operarios/' + this.$props.id + '/especialidades',subidadatos);
            this.cargarespecialidadesoperario()
        },        
    },
    watch: {
        id() {
            this.cargarespecialidades();
            this.cargarespecialidadesoperario();
        },
    },
    mounted() {
        
        this.cargarespecialidades();
        this.cargarespecialidadesoperario();
    }
}
</script>