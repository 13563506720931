<template>
    <div class="row">
        <div class="col-md-8">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.nombre') }}</label>
                <input type="text" v-model="auxperjudicado.nombre" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.razonsocial') }}</label>
                <input type="text" v-model="auxperjudicado.razon_social" class="form-control input-sm" >
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <!-- Los seleccionables con buscador-->
                <label for="exampleInputEmail1">{{ $t('general.direccion') }}</label>
                <input type="text" v-model="auxperjudicado.direccion" class="form-control input-sm">
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.telefono') }} 1</label>
                <div class="input-group">
                    <input type="text" v-model="auxperjudicado.telefono1" class="form-control input-sm">
                    <div class="input-group-append">
                        <span class="input-group-text" title="¿Es Móvil?">
                            <input type="checkbox" name="telefono1_movil" v-model="auxperjudicado.telefono1_movil"
                                v-bind:true-value="1" v-bind:false-value="0">
                        </span>
                    </div>
                </div>
                <textarea class="form-control input-sm" v-model="auxperjudicado.telefono1_descripcion"
                    :placeholder="$t('general.descripcion')"></textarea>
                <div class="row" style="justify-content: center; column-gap: 2em;">
                    <label for="radio1" style="margin: 0;">Preferente</label>
                    <input id="radio1" value="1" v-model="preferente" type="radio">
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.telefono') }} 2</label>
                <div class="input-group">
                    <input type="text" v-model="auxperjudicado.telefono2" class="form-control input-sm">
                    <div class="input-group-append">
                        <span class="input-group-text" title="¿Es Móvil?">
                            <input type="checkbox" name="telefono1_movil" v-model="auxperjudicado.telefono2_movil"
                                v-bind:true-value="1" v-bind:false-value="0">
                        </span>
                    </div>
                </div>
                <textarea class="form-control input-sm" v-model="auxperjudicado.telefono2_descripcion"
                    :placeholder="$t('general.descripcion')"></textarea>
                <div class="row" style="justify-content: center; column-gap: 2em;">
                    <label for="radio1" style="margin: 0;">Preferente</label>
                    <input id="radio1" value="2" v-model="preferente" type="radio">
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.telefono') }} 3</label>
                <div class="input-group">
                    <input type="text" v-model="auxperjudicado.telefono3" class="form-control input-sm">
                    <div class="input-group-append">
                        <span class="input-group-text" title="¿Es Móvil?">
                            <input type="checkbox" name="telefono1_movil" v-model="auxperjudicado.telefono3_movil"
                                v-bind:true-value="1" v-bind:false-value="0">
                        </span>
                    </div>
                </div>
                <textarea class="form-control input-sm" v-model="auxperjudicado.telefono3_descripcion"
                    :placeholder="$t('general.descripcion')"></textarea>
                <div class="row" style="justify-content: center; column-gap: 2em;">
                    <label for="radio1" style="margin: 0;">Preferente</label>
                    <input id="radio1" value="3" v-model="preferente" type="radio">
                </div>
            </div>
        </div>
    </div>  
       <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="exampleInputEmail1">{{ $t('general.nif') }}</label>
                    <input type="text" v-model="auxperjudicado.nif" class="form-control input-sm">
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-group">
                    <label for="exampleInputEmail1">email</label>
                    <input type="text" v-model="auxperjudicado.email" class="form-control input-sm">
                </div>
            </div>
        </div> 
          <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="exampleInputEmail1">{{ $t('general.personadecontacto') }}</label>
                        <input type="text" v-model="auxperjudicado.persona_contacto" class="form-control input-sm">
                    </div>
                </div>
                  <div class="col-md-6">
                        <div class="form-group">
                            <label for="exampleInputEmail1">{{ $t('general.poblacion') }}</label>
                            <input type="text" v-model="auxperjudicado.poblacion" class="form-control input-sm">
                        </div>
                    </div>
            </div> 
            <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="exampleInputEmail1">{{ $t('general.codigo_postal') }}</label>
                            <input type="text" v-model="auxperjudicado.codigo_postal" class="form-control input-sm">
                        </div>
                    </div> 
                      <div class="col-md-4">
                            <div class="form-group">
                                <label for="exampleInputEmail1">{{ $t('general.provincia') }}</label>
                                <input type="text" v-model="auxperjudicado.provincia" class="form-control input-sm">
                            </div>
                        </div>
                          <div class="col-md-4">
                            <div class="form-group">
                                <label for="exampleInputEmail1">{{ $t('general.subcuenta') }}</label>
                                <input type="text" v-model="auxperjudicado.subcuenta" class="form-control input-sm">
                            </div>
                        </div>
            </div> 
                        <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">{{ $t('general.descripcion') }}</label>
                                <textarea v-model="auxperjudicado.descripcion" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="exampleInputEmail1">{{ $t('general.observaciones') }}</label>
                                <textarea v-model="auxperjudicado.observaciones" class="form-control"></textarea>
                            </div>
                        </div>
                </div> 

</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';


export default ({
    props: ['id', 'servicio', 'perjudicado','tokenguardarperjudicado'],

    setup() {
    },
    data() {
        return {
            auxperjudicado:'',
            preferente:0,
        }
    },
    mounted() { 
        this.comprobarPreferente();
        this.auxperjudicado = this.$props.perjudicado;
        console.log('datosperjudicado', this.auxperjudicado);
    },
    methods: {
        comprobarPreferente(){
            if(this.perjudicado.telefono_prioritario!=0) {
                this.preferente = this.perjudicado.telefono_prioritario;
            }    
        },
        async guardarperjudicado() {
            //PUT  modulos/pwgsapi/index.php/clientes/:id/modificar-cliente-servicio/:id_servicio
            try {
                const api = new PwgsApi;
                
                const subidadatos = {
                    perjudicado: this.perjudicado.idcliente,
                    provincia: this.auxperjudicado.provincia, 
                    poblacion: this.auxperjudicado.poblacion,
                    nombre: this.auxperjudicado.nombre, 
                    razon_social: this.auxperjudicado.razon_social, 
                    nif: this.auxperjudicado.nif, 
                    telefono1: this.auxperjudicado.telefono1, 
                    telefono2: this.auxperjudicado.telefono2,
                    telefono3: this.auxperjudicado.telefono3,
                    telefono1_descripcion: this.auxperjudicado.telefono1_descripcion,
                    telefono2_descripcion: this.auxperjudicado.telefono2_descripcion,
                    telefono3_descripcion: this.auxperjudicado.telefono3_descripcion,
                    email: this.auxperjudicado.email, 
                    direccion: this.auxperjudicado.direccion, 
                    codigo_postal: this.auxperjudicado.codigo_postal, 
                    descripcion: this.auxperjudicado.descripcion, 
                    observaciones: this.auxperjudicado.observaciones 
                };
                              
                if(this.preferente!=0){                    
                    subidadatos.telefono_prioritario = this.preferente; 
                }
                await api.put('clientes/' + this.perjudicado.idcliente + '/modificar-cliente-servicio/' +this.$props.id, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.$emit('actualizarperjudicado');
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
    },
    watch: {
        id() {
            this.auxperjudicado = this.$props.perjudicado;            
            this.comprobarPreferente();
        },
        tokenguardarperjudicado() { 
            this.guardarperjudicado();
        }
    }
})


</script>