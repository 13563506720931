<template>
    <div class="card card-primary card-outline card-outline-tabs" >
        <div class="card-header p-0 pt-1">
            <ul class="nav nav-tabs"  role="tablist">
                <li class="pt-2 px-3"><h3 class="card-title">Presupuesto: {{ seleccionado.presupuesto.nombre_presupuesto  }}    </h3></li>
                <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-two-home-tab" data-toggle="pill" href="#custom-tabs-two-home" role="tab" aria-controls="custom-tabs-two-home" aria-selected="true">Generación</a>
                    </li>
                <li class="nav-item">
                    <a class="nav-link" id="custom-tabs-two-profile-tab" data-toggle="pill" href="#custom-tabs-two-profile" role="tab" aria-controls="custom-tabs-two-profile" aria-selected="false">Conceptos</a>
                </li>
            </ul>
        </div>
        <div class="card-body bg-light">
            <div class="tab-content" id="custom-tabs-two-tabContent">
                <div class="tab-pane fade active show" id="custom-tabs-two-home" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                <servicio-presupuesto-datos @actualizar="actualizar" :id="id" :seleccionado="seleccionado"/>
                </div>
                <div class="tab-pane fade" id="custom-tabs-two-profile" role="tabpanel" aria-labelledby="custom-tabs-two-profile-tab">
                <servicio-presupuesto-conceptos ref="concep" :seleccionado="seleccionado" :idaux="idaux" :id="id"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import serviciopresupuestodatos from './presupuesto/datos/presupuesto_datos.vue';
import serviciopresupuestoconceptos from './presupuesto/conceptos/presupuesto_conceptos.vue';
export default ({
    props: ['id','seleccionado','idaux'],
    

    setup() {
        return;
    },
    components: {
        'servicio-presupuesto-datos': serviciopresupuestodatos,
        'servicio-presupuesto-conceptos': serviciopresupuestoconceptos,
    },
       data() {
        return {
        
        }
    },
    methods: {
        actualizar() {
            console.log('llegaactu');
            setTimeout(()=>{
                this.$refs.concep.cargartrabajospresupuesto(true);
            },2000);
        },
        async cargardatos() {

            
        },
        test() {
            
        }
    },
    mounted() {
    },
    watch: {
        id() {
            this.cargardatos(this.$props.id);
        }
    }
})
</script>
<style>
.custom-select {
  background: rgb(255, 255, 255);
  width:auto;
}
</style>