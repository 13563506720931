<template>    
    <div class="col-md-12">
        <div class="card card-widget">
            <div class="card-body">
                <facturacionconceptos :id="id" :servicio="servicio" :tipodefactura="tipodefactura"
                    :destinatario="destinatario" :ffac="ffac" />
            </div>
        </div>
    </div>       
    <div class="col-md-12">
        <div class="card card-widget">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10 text-right">
                        <h4>{{$t('general.Base imponible')}} <strong>{{precios.base_imponible}}&euro;</strong></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10">
                        <div class="input-group mb-3" v-if="tipo_factura=='factura'">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{$t('general.descuento antes de impuesto')}}</span>
                            </div>
                            <input type="text" class="form-control"  v-model="conceptodescuento"
                                :placeholder="$t('general.motivo')">
                            <input type="text" class="form-control" @keyup="calcularTotal('porcentajeantes')" v-model="descuentoantesporcentaje" :placeholder="$t('general.porcentaje')"
                                style="max-width: 100px">
                            <input type="text" class="form-control" @keyup="calcularTotal('descuentoantes')" v-model="descuentoantes"
                                :placeholder="$t('general.valor')" style="max-width: 100px">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10 text-right">
                        <h4>{{$t('general.subtotal')}} <strong>{{precios.base_descuento}}&euro;</strong></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10 text-right">
                        <h4>I.V.A.<strong>{{precios.iva_normal}}&euro;</strong></h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10" v-if="tipo_factura=='factura'">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{$t('general.descuento después de impuesto')}}</span>
                            </div>
                            <input type="text" class="form-control" @blur="modificarConceptoDespues" v-model="conceptodescuentodespues"
                                :placeholder="$t('general.motivo')">
                            <input type="text" class="form-control" @keyup="calcularTotal('porcentajedespues')" @blur=" modificarPorcentajeDespues" v-model="porcentajedespues" :placeholder="$t('general.porcentaje')"
                                style="max-width: 100px">
                            <input type="text" class="form-control" @keyup="calcularTotal('descuentodespues')" @blur=" modificarValorDespues()" v-model="valorDespues"
                                :placeholder="$t('general.valor')" style="max-width: 100px">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                    </div>
                    <div class="col-md-10 text-right">
                        <h3>Total <strong>{{precios.total}}&euro;</strong></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import facturacionconceptos from './FacturacionNueva/FacturacionNuevaConceptos.vue';
import { PwgsApi } from '../../../../services/PwgsApi';

export default {
    props: ['id', 'servicio', 'direccion','datosfacturacion','tipo_factura','asegurado', 'facturar', 'ffac', 'listatrabajosfacturar', 'destinatario', 'serieseleccionada', 'numeroseleccionado', 'fechaseleccionada', 'vencimiento', 'codigocliente', 'pagoseleccionado', 'cuentabancariaseleccionada', 'descripcionfactura','especialidadseleccionada'],
    components: {
        facturacionconceptos,
    },
    data() {
        return {
            mostrarModulo: false,
            precios: '',         
            conceptodescuento:'',
            conceptodescuentodespues: '',
            valorDespues:'',
            porcentajedespues: '',
            descuentoantes: '',
            descuentoantesporcentaje:'',
        }
    },
    methods: {
        modificarValorDespues(){
            this.$parent.$parent.$parent.modificarValorDespues(this.valorDespues, this.destinatario[0]);
        },
        modificarPorcentajeDespues(){
            this.$parent.$parent.$parent.modificarPorcentajeDespues(this.porcentajedespues, this.destinatario[0]);
        },
        modificarConceptoDespues(){
            this.$parent.$parent.$parent.modificarConceptoDespues(this.conceptodescuentodespues, this.destinatario[0]);
        },
        cargarprecios() {
            
            this.precios = 0;
            if (this.$props.destinatario[1].totales){
            this.precios = this.$props.destinatario[1].totales;
            }
        },   
        guardarFactura(){ 
            console.log('guardarff', this.precios, this.conceptodescuentodespues, this.conceptodescuento);
            //POST  modulos/pwgsapi/index.php/facturas/:id_servicio/facturar
            var descuimporte = this.precios.dto_antes_iva.split(' ')[0];
            var descu = this.precios.dto_antes_iva.split(' ')[2];
            descu = descu.replace('(', '');
            descu = descu.replace(')', '');

            var descuimporteiva = this.precios.dto_despues_iva.split(' ')[0];
            var descuiva = this.precios.dto_despues_iva.split(' ')[2];
            descuiva = descuiva.replace('(', '');
            descuiva = descuiva.replace(')', '');

            var subidadatos = { 
                tipo: this.tipo_factura,
                id_serie: this.serieseleccionada, 
                numero: this.numeroseleccionado, 
                fecha_factura: this.fechaseleccionada,
               
                vencimiento_factura: this.vencimiento, 
                direccion_facturacion: this.asegurado.direccion, 
                codigo_cliente_contabilidad_factura: this.codigocliente, 
                forma_pago: this.pagoseleccionado, 
                cuenta_bancaria: this.cuentabancariaseleccionada, 
                descripcion_factura: this.descripcionfactura, 
                servicio_relacionado: this.$props.id, 
                gremio_albaran: this.especialidadseleccionada, 
                concepto_descuento_factura: this.conceptodescuento, 
                descuento:descu, 
                descuento_importe: descuimporte, 
                concepto_descuento_factura_despuesIVA: this.conceptodescuentodespues, 
                descuento_despuesIVA:descuiva,
                descuento_importe_despuesIVA: descuimporteiva 
            };

            if (this.$props.direccion != "defecto") {
                subidadatos.direccion_facturacion = this.$props.direccion;
            }
            this.$parent.$parent.$parent.facturacion(subidadatos);
        },
        async calcularTotal(referencia) {
            console.log('cscscs');
            //modulos/pwgsapi/index.php/facturas/:id_servicio/previo-facturacion
            const api = new PwgsApi();
            let auxdest = this.$props.destinatario[0];
            if (referencia == 'porcentajeantes') { this.descuentoantes = ''; }
            if (referencia == 'descuentoantes') { this.descuentoantesporcentaje = ''; }
            if (referencia == 'porcentajedespues') { this.valorDespues = ''; }
            if (referencia == 'descuentodespues') { this.porcentajedespues = ''; }
            let subidadatos = { descuento: this.descuentoantesporcentaje, descuento_importe: this.descuentoantes, descuento_despuesIVA: this.porcentajedespues, descuento_importe_despuesIVA: this.valorDespues }
            let aux = await api.post('facturas/' + this.$props.id + '/previo-facturacion', subidadatos);
            if (aux[auxdest].totales){
            let auxporcentajeantes = aux[auxdest].totales.dto_antes_iva.split('(')[1];
            let auxporcentajedespues = aux[auxdest].totales.dto_despues_iva.split('(')[1];

            this.descuentoantesporcentaje = auxporcentajeantes.slice(0, -2);
            
            this.descuentoantes = aux[auxdest].totales.dto_antes_iva.split(' ')[0];

            this.porcentajedespues = auxporcentajedespues.slice(0,-2);
            this.valorDespues = aux[auxdest].totales.dto_despues_iva.split(' ')[0];
            this.precios = aux[auxdest].totales;
            }
        }   
        
    },
    mounted(){
        this.cargarprecios();
        this.calcularTotal();
      
    },
    watch: {
        id() { 
           this.cargarprecios();
        },
        facturar() {         
            this.guardarFactura();
        },
        datosfacturacion() {
            this.descuentoantes = this.$props.datosfacturacion.descuento.importe;
            this.conceptodescuento = this.$props.datosfacturacion.descuento.concepto;
        },
        
      
    }
}
</script>