<template>
    <Dialog v-model:visible="visible" modal header="Datos adicionales" :style="{ width: '800px'}" @hide="this.$parent.visible = false">
        <div v-if="adicionales.length == 0">
            No hay datos adicionales para este equipo.
        </div>
        <div v-else>
            <div class="card card-default card-tabs">
                <div class="card-header p-1 pt-1">
                    <ul class="nav nav-pills" ref="tabHeaders">
                        <li v-for="(info, index) in Object.entries(adicionales)" :key="info[0]" class="nav-item">
                            <a class="nav-link" :href="'#'+info[0]" @click.prevent="cambiarTab(index)"
                            :class="{ 'active': index === activeTab }">
                                {{ info[0] }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <br>
            <div class="tab-content" ref="tabContents">
                <div v-for="(info, index) in Object.entries(adicionales)" :key="info[0]" class="tab-pane container-grid"
                    :id="info[0]" :class="{ 'active': index === activeTab }">
                    <div class="container">
                        <div v-for="(adicional, index2) in info[1]" :key="index2" class="form">
                            <label :for="'value_' + index" class="font-semibold">{{ adicional.nombre_campo }}</label>
        
                            <input v-model="valoresPorDefecto[adicional.id_campo]" v-if="adicional.tipo_campo === 'texto'" type="text" :id="'value_' + index"/>
        
                            <v-select v-model="valoresPorDefecto[adicional.id_campo]" v-if="adicional.tipo_campo === 'valores'" :options="getValores(adicional.valores_campo)" placeholder="Selecciona un valor"></v-select>
        
                            <input v-model="valoresPorDefecto[adicional.id_campo]" v-if="adicional.tipo_campo === 'numero'" type="number" :id="'value_' + index"/>
        
                            <textarea v-model="valoresPorDefecto[adicional.id_campo]" v-if="adicional.tipo_campo === 'multilinea'" name="tt" rows="6" cols="30" :id="'value_' + index"></textarea>
        
                            <Editor v-model="valoresPorDefecto[adicional.id_campo]" v-if="adicional.tipo_campo === 'formateado'" editorStyle="height: 160px" :id="'value_' + index"/>
        
                            <label v-if="adicional.tipo_campo === 'formula'" :id="'value_' + index">{{ valoresPorDefecto[adicional.id_campo] }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex; border-top: 1px solid grey; margin-top: 10px; justify-content: end;">
                <button class="btn btn-light btn-sm" style="border: 1px solid; margin-top: 15px;" @click="guardarDatos">{{ guardar }}</button>
            </div> 
        </div>
    </Dialog>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import Editor from 'primevue/editor';
export default ({
    props: ['idservicio', 'equipo', 'idequipo'],
    components:{
        Dialog,
        Editor,
    },
    data(){
        return{
            adicionales:[],            
            valoresPorDefecto: [],
            visible:false,
            activeTab: 0,
            guardar :'Guardar',
        }
    },
    mounted(){
        this.cargarAdicionales()
    },
    methods:{
        hacerVisible(){
            this.visible = true;
        },
        async guardarDatos(){
            const api = new PwgsApi();
            try{
                for(let adicional of Object.entries(this.adicionales)){
                    for(var j in adicional[1]){
                        var id = adicional[1][j].id_campo;
                        var valor = this.valoresPorDefecto[id];
                        const body={'id_campo':id,'valor_campo':valor};
                        const resp = await api.put('servicios/'+this.idservicio+'/equipos/'+this.idequipo, body);
                        console.log('campoGuadado', resp);
                    }
                }
                this.guardar = 'Guardado';
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
            }
        },
        cambiarTab(index) {
            this.activeTab = index;
            this.$nextTick(() => {
                this.mostrarTab(index);
            });
        },
        activarPrimerTab() {
            this.mostrarTab(this.activeTab);
        },
        async cargarAdicionales() {
            const api = new PwgsApi();
            try {
                const servs = await api.get('servicios/'+ this.idservicio +'/gestion-doc?tipo=equipos_en_servicio');
                this.adicionales = servs.datos_adicionales.equipos_en_servicio;

                if (typeof this.equipo !== 'object' || this.equipo === null) {
                    console.warn('this.equipo no es un objeto válido:', this.equipo);
                    return;
                }
                
                for(let adicion of Object.entries(this.adicionales)){
                    adicion[1].forEach((adicional) => {
                        const id = adicional.id_campo;
    
                        if (this.equipo[id]) {
                                const equipoItem = this.equipo[id];
                                this.valoresPorDefecto[id]= equipoItem.valor_campo;
                            } else {
                            this.valoresPorDefecto[id] = adicional.valor_defecto_campo;
                            }
                        });                    
                }
            } catch (error) {
                console.error('er',error);
            }
        },
        getValores(serialized){
            const regex = /i:\d+;s:\d+:"(.*?)";/g;
            let match;
            const result = [];

            // Usar un bucle para encontrar todas las coincidencias y extraer los valores
            while ((match = regex.exec(serialized)) !== null) {
                result.push(match[1] );
            }
            return result;
        },
    },
    watch:{
        idservicio(){
            this.valoresPorDefecto = {};
            this.adicionales =  [];
            this.cargarAdicionales()
        },
    }
});
</script>
<style scoped>
.container{
    display: grid;
    height: 400px;
    overflow: auto;
}
.form{
    margin-bottom: 30px;
}
.form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold; 
}

.form textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}
.form editor{
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.form select{
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 50px;
}

.form input{
    display: block;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}
.btns{
    display: flex;
    justify-content: end;
    gap:15px;
    padding-bottom: 10px;
}
.btn-size:hover{
    background-color:rgb(163, 163, 163);
}
</style>