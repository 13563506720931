<template>
    <Dialog v-model:visible="$parent.visible" modal header="Modificar visita" :style="{width: '900px'}" @hide="this.$parent.visible = false">
        <div class="cen">
            <div style="margin: 0;gap: 35px; justify-content: center;" class="row">
                <div style="display: flex; flex-direction: column; align-items: center">
                    <h3 style="text-align: center;">Inicio</h3>
                    <div class="row" style="justify-content: center;column-gap: 10px;">
                        <div class="form-group" style="display: grid;">
                            <label for="fecha">Fecha</label>
                            <input class="form-control" type="date" id="fecha" v-model="fechaSeleccionadaInicio">
                        </div>
                        <div class="form-group" style="display: grid;">
                            <label for="hora">Hora</label>
                            <input class="form-control" type="time" id="hora" v-model="horaSeleccionadaInicio">
                        </div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: center">
                    <h3 style="text-align: center;">Fin</h3>
                    <div class="row" style="justify-content: center;column-gap: 10px;">
                        <div class="form-group" style="display: grid;">
                            <label for="fecha">Fecha</label>
                            <input class="form-control" type="date" id="fecha" v-model="fechaSeleccionadaFin">
                        </div>
                        <div class="form-group" style="display: grid;">
                            <label for="hora">Hora</label>
                            <input class="form-control" type="time" id="hora" v-model="horaSeleccionadaFin">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="ipas && razones.length>0" style="width: 50%;">
                <h3 style="margin-top: 30px;text-align: center;">Comunicación con IPAS</h3>
                <div class="form-group">
                    <label for="idrazon">Razón:</label>
                    <select v-model="razonipas" class="form-control" id="idrazon">
                        <option value="">--------</option>
                        <option v-for="razon in razones" :key="razon.valor" :value="razon.valor">{{ razon.texto }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="subrazon">Sub-Razón:</label>
                    <select v-model="subrazonipas" class="form-control" id="subrazon">
                        <option value="">--------</option>
                        <option v-for="subrazon in subrazones" :key="subrazon.valor" :value="subrazon.valor">{{ subrazon.texto }}</option>
                    </select>
                </div>
                <div v-if="cancelacion" class="form-group">
                    <label for="cancel">Fecha y hora cancelación:</label>
                    <Calendar style="width: 100%;" dateFormat="dd/mm/yy" v-model="cancelacion" id="cancel" showTime hourFormat="24"/>
                </div>
                <div class="form-group">
                    <label for="motivo">Motivo:</label>
                    <textarea class="form-control" v-model="motivo" id="motivo"></textarea>
                </div>
            </div>
            <div v-if="!finalizada">
                <h3 style="margin-top: 30px;text-align: center;">Descripción</h3>
                <textarea class="form-control" cols="40" rows="4" v-model="descripcion"></textarea>
            </div>
        </div>
        <div class="final">
            <div style="display: grid; justify-content: center;">
                <label for="prioritaria">Prioritaria</label>
                <input v-model="priorit" type="checkbox" id="prioritaria">
            </div>
            <div style="display: grid; justify-content: center;">
                <label for="nomodif">No modificar</label>
                <input v-model="nomod" type="checkbox" id="nomodif">
            </div>
            <div style="display: grid;align-items: end;">
                <button class="btn btn-sm btn-light btn-outline-secondary" @click=" modificarDatos()">Modificar</button>
            </div>
        </div>
        <div class="pie" style="margin-top: 30px;">
            <p style="margin:0 ;">
                Operarios: {{ operario }}
            </p>
            <p>
                Siniestro: {{ siniestro }}  
            </p> 
        </div>                  
    </Dialog>
</template>
<script>
import Calendar from 'primevue/calendar';
import { PwgsApi } from '../../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
export default ({
    props: ['idservicio','idvisita', 'visita', 'servicio'],
    components:{
        Dialog,
        Calendar
    },
    data(){
        return{
            operario: this.visita.visita.nombre_operario,
            siniestro: this.visita.visita.codigo_servicio,
            fechaSeleccionadaFin: this.visita.visita.fecha_fin,
            fechaSeleccionadaInicio: this.visita.visita.fecha,
            horaSeleccionadaFin: this.visita.visita.hora_fin,
            horaSeleccionadaInicio: this.visita.visita.hora,
            priorit: this.visita.visita.prioritaria==1,
            nomod: this.visita.visita.no_modificar==1,
            descripcion: this.visita.visita.descripcion_visitadores,
            ipas: false,
            finalizada: false,
            razones: [],
            subrazones:[],
            razonipas:'',
            subrazonipas:'',
            valoresipas:[],
            motivo:'',
            cancelacion:'',
        }
    },
    mounted() {
        this.verfinalizada();
        this.comprobarIpas();
        console.log("fechaselect", this.fechaSeleccionadaInicio);
    },
    methods:{
        async comprobarIpas(){
            console.log('entraipas', this.servicio);
            this.servicio.compania.sistema=="IPAS_WS"?this.ipas =true : this.ipas = false;
            if(this.ipas){
                console.log('entraipas2');
                const api = new PwgsApi();
                this.valoresipas = await api.post('/planning-pwgs/'+this.idservicio+'/formulario-modifica-visita/'+this.idvisita);
                console.log('razonipas', this.valoresipas);
                try{
                    for(let valor of Object.values(this.valoresipas)){
                        console.log('valors',valor);
                        if(valor.nombre == 'razones_cancelacion_cita_mensaje'){
                            console.log('entra', valor);
                            this.razones = Object.values(valor.valores);
                            console.log('entra2', this.razones);
                        }
                    }
                }catch(e){
                    console.log('fallo');
                }
            }
        },
        verfinalizada(){
            this.finalizada == this.visita.visita.realizado_operario=='SI'?true:false;
        },
        async modificarDatos(){
            const api = new PwgsApi();
            const body = {fecha_cita_inicio: this.formatearFecha(this.fechaSeleccionadaInicio), fecha_cita_fin: this.formatearFecha(this.fechaSeleccionadaFin), 
                hora_cita_inicio: this.horaSeleccionadaInicio, hora_cita_fin: this.horaSeleccionadaFin};
            if(!this.finalizado){
                body.descripcion_cita = this.descripcion;
            }
            if(this.priorit){
                body.prioritaria_cita = 1;
            }else{
                body.prioritaria_cita = 0;
            }
            if(this.nomod){
                body.no_modificar_cita = 1;
            } else{
                body.no_modificar_cita = 0;
            }
            if(this.ipas){                
                console.log('ipascosas',this.razonipas, this.subrazonipas, this.convertir(this.cancelacion), this.motivo);
                if(this.razonipas == ''){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'Debes elegir una razón', life: 5000 });
                    return;                    
                }
                if(this.subrazonipas==''){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'Debes elegir una subrazón', life: 5000 })
                    return;
                }
                if(this.motivo==''){
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'Debes indicar un motivo', life: 5000 })
                    return;
                }
                body.razones_cancelacion_cita_mensaje = this.razonipas;
                body.motivo_cancelacion_cita = this.motivo;
                body.razones_cancelacion_cita_contrato_mensaje = this.subrazonipas;
                if(this.cancelacion){
                    var fecha = this.convertir(this.cancelacion).split(', ')[0];
                    var hora = this.convertir(this.cancelacion).split(', ')[1];
                    body.fecha_cancelacion_proximo_contacto_cita = fecha;
                    body.hora_cancelacion_proximo_contacto_confirmacion_cita = hora;
                }
                for(let valor of Object.values(this.valoresipas)){
                    console.log('valors',valor);
                    if(valor.nombre == 'PROFESSION_NU_cancelacion_cita'){
                        body.PROFESSION_NU_cancelacion_cita=valor.valor;
                    }
                    if(valor.nombre == 'fecha_unix_visita_origen'){
                        body.fecha_unix_visita_origen=valor.valor;
                    }
                }
            }
            try{
                const resp = await api.put('planning-pwgs/'+this.idservicio+'/modificar-visita/'+this.idvisita, body);
                console.log('modifvisi', resp);
                this.$parent.visible = false;
                this.$parent.$parent.obtenerVisitas(this.$props.idservicio, this.$props.visita.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Visita modificada correctamente', life: 2000 });
            }catch(e){
                alert(e);
                console.error('error',e);
            }
        },
        formatearFecha(fecha){
            const fechaSplit = fecha.split('-');
            return `${fechaSplit[2]}/${fechaSplit[1]}/${fechaSplit[0]}`;
        },
        async obtenersubrazones(){
            if(this.razonipas== ''){
                this.subrazones = [];
            }else{
                const api = new PwgsApi();
                const resp = await api.post('/planning-pwgs/'+this.idservicio+'/formulario-modifica-visita/'+this.idvisita,{razones_cancelacion_cita_mensaje:this.razonipas});
                console.log('subras', resp);
                var fecha = '';
                var hora = '';
                for(let res of resp){
                    if(res.nombre == 'razones_cancelacion_cita_contrato_mensaje'){
                        this.subrazones = Object.values(res.valores);
                    }
                    if(res.nombre == 'fecha_cancelacion_proximo_contacto_cita'){
                        fecha = res.valor;
                    }
                    if(res.nombre == 'hora_cancelacion_proximo_contacto_confirmacion_cita'){
                        hora = res.valor;
                    }
                }
                if(fecha!= ''){
                    var fechatotal = this.convertirAFecha(fecha+' '+hora);
                    console.log('fechatotal', fechatotal);
                    this.cancelacion = fechatotal; 
                }
            }
        },
        convertir(fecha){
            return fecha.toLocaleString();
        },
        convertirAFecha(fechaStr) {
            // Asume que el formato es "dd/mm/yyyy hh:mm"
            const [fecha, hora] = fechaStr.split(' ');
            const [dia, mes, año] = fecha.split('/');
            const [horas, minutos] = hora.split(':');
            
            return new Date(año, mes - 1, dia, horas, minutos);
        },
    },
    watch:{
        razonipas(){
            this.obtenersubrazones();
        }
    }

});
</script>
<style>

.pie{
    display:grid;
    justify-content: end;
}
.final{
    display: flex;
    column-gap: 15px;
    margin-top: 30px;
    justify-content: center;
}
.cen{
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>